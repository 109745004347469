import * as d3 from 'd3';
import { debounce } from 'lodash';
import D3Circle from './circle';
import { numberWithCommas } from './utils/helpers';
import { UNAUTHORIZED } from '../../../utils/constants';
import D3InfoComponent from './infoComponent';
import D3NotificationPopup from './notificationPopup';
import Token from '../../okta/Token';
import parseDate from './utils/formatTimeHelpers';
import dw from './utils/Data_Wrangle.js';
import DeviceData from '../../../containers/DevicesBubbles/DeviceData';

class D3DevicesBubbles {
  constructor(options) {
    this.options = options;
    this.menu = null;
    this.changeBubbleDate = options.changeBubbleDate;
  }

  /**
   * Create instance of D3.js component
   * @param el
   */
  create = el => {
    const { dataApi, filters, logoutUser } = this.options;

    const hue = filters.colors ? filters.colors.primaryHue : 120;

    let data;
    const config = {
      showTotalDeviceCount: true,
      showBubbleDeviceCount: true,
    };

    let width, height;

    const svg = d3.select('#devices');
    const filterControlsDiv = d3
      .select('#devices-bubbles')
      .append('div')
      .attr('id', 'filterControls');

    let primaryKey = 'brand';
    let appliedFilters = {};
    const sortDimensions = ['category'];
    const filterDimensions = ['brand', 'category', 'icon'];

    const setSize = () => {
      width = window.innerWidth;
      height = window.innerHeight - 100; // menu bar height... this may change.
      svg.attr('width', width).attr('height', height);
    };
    setSize();

    let stats = {};
    let filterInputs = {};
    // const matchDataFormat = d3.timeFormat('%Y-%m-%d');

    const aggregateData = dataSet => {
      let deviceData = null;
      //filter the dataset using the filter Inputs
      //the appliedFilters object contains keys that are equeal to the key to be filtered and the value to keep
      let filter_data = dataSet;
      if (
        Object.keys(appliedFilters).length > 0 &&
        appliedFilters.category !== 'All'
      ) {
        //if the appliedFilters has filter we iterate on it to filter our dataSet
        Object.keys(appliedFilters).forEach(val => {
          //appliedFilter hold value all when we want all data so we will not filter
          if (appliedFilters[val] !== 'All') {
            filter_data = dw.filter(
              filter_data,
              d => d[val] === appliedFilters[val],
            );
          }
        });

        const secondFilterData = dataSet.filter(
          d => d.category === appliedFilters.category,
        );
        deviceData = new DeviceData(secondFilterData);
      } else {
        deviceData = new DeviceData(dataSet);
      }

      let rolledUp = dw.hashMap_sum(
        filter_data,
        primaryKey,
        'device_cummulative_cnts',
      );
      const mappedResults = dw.name_value(rolledUp);

      stats.totalClients = d3.sum(mappedResults, d => d.value);

      if (config.showTotalDeviceCount) {
        d3.select('#totalClients').html(
          ' Last 30-Day Active Devices: <div>' +
            numberWithCommas(deviceData.devices) +
            '</div>',
        );
      }

      d3.select('#percentDevicesIdentified').html(
        '% of Devices Identified: <div>' +
          d3.format('.0%')(deviceData.percentOfDevicesIdentified) +
          '</div>',
      );

      d3.select('#deviceTypes').html(
        'Device types: <div>' +
          numberWithCommas(deviceData.deviceTypes) +
          '</div>',
      );

      d3.select('#deviceBrands').html(
        'Brands: <div>' + numberWithCommas(deviceData.brands) + '</div>',
      );

      // on with the show...
      return mappedResults.sort((a, b) => b.value - a.value);
    };

    const redraw = () => {
      //update menus based on current data
      d3.selectAll('.filterRow').classed('rowActive', d => {
        return d === primaryKey;
      });

      d3.selectAll('.filterRow').style(
        'background-color',
        'hsla(' + hue + ', 30%, 90%, .1)',
      );
      d3.selectAll('.rowActive').style(
        'background-color',
        'hsla(' + hue + ', 40%, 40%, .9)',
      );
      // transition
      const transition = d3.transition().duration(750);

      const pack = d3
        .pack()
        .size([width, height])
        .padding(1.5);

      let displayData = aggregateData(data);
      // const summedNull = d3.sum(displayData, d =>
      //   d.name === 'null' || d.name === '(null)' || d.name === '(null)'
      //     ? d.value
      //     : 0,
      // );
      //update to filter out null values
      displayData = dw.filter(displayData, d => {
        return d.name !== 'null' && d.name !== '(null)' && d.name !== '(null)';
      });
      // d3.select('#totalDevicesIdentified').html(
      //   'Percentage of Devices Identified: <div>' +
      //     d3.format('.0%')(
      //       (stats.totalClients - summedNull) / stats.totalClients,
      //     ) +
      //     '</div>',
      // );

      const h = d3.hierarchy({ children: displayData }).sum(d => d.value);

      const outerRingRadius = Math.max(width, height) / 2 + 100;

      const circlesOptions = {
        data: pack(h).leaves(),
        config,
        transition,
        outerRingRadius,
        filters,
        totalNumber: stats.totalClients,
      };
      const circles = new D3Circle(circlesOptions);
      circles.create(svg);

      //adjust filter inputs
      d3.selectAll('.filterSelect').classed('disabled', d => {
        return d === primaryKey;
      });

      d3.selectAll('#search-input')
        .classed('disabled', d => {
          return d !== primaryKey;
        })
        .classed('visible', d => {
          return d === primaryKey;
        });

      d3.selectAll('#search-button')
        .classed('disabled', d => {
          return d !== primaryKey;
        })
        .classed('visible', d => {
          return d === primaryKey;
        });

      d3.selectAll('.filterReset')
        .classed('visible', d => {
          return d !== primaryKey;
        })
        .classed('disabled', d => {
          if (d3.select('.filterSelect.' + d).node() !== null)
            return d3.select('.filterSelect.' + d).node().value === 'All';
        });
    };

    if (window.spinner) {
      window.spinner.init();
    }

    const oktaToken = new Token(localStorage['okta-token-storage']);

    let token = 'Bearer ' + oktaToken.accessToken;

    d3.json(`${dataApi}`, {
      headers: { Authorization: token },
    })
      .then(rawData => {
        // finish loader
        if (window.spinner) {
          window.spinner.finish();
        }

        //const deviceData = new DeviceData(rawData.data);
        const uniqFilterPush = (valuesObj, valueKey, val) => {
          if (!valuesObj[valueKey]) {
            valuesObj[valueKey] = [];
          }
          if (valuesObj[valueKey].indexOf(val) === -1) {
            valuesObj[valueKey].push(val);
          }
        };

        data = rawData.data;

        //if device count has no data then we just filter it out
        data = dw.filter(data, d => {
          return d.device_cummulative_cnts !== '';
        });

        this.timeStamp = data[0].ts;
        this.changeBubbleDate(parseDate(this.timeStamp));

        this.createTimeStamp();

        data = data.map((d, i) => {
          if (isNaN(d.device_cummulative_cnts)) {
            if (!isNaN(d.ts)) {
              d.device_cummulative_cnts = d.ts;
            }
          }
          return d;
        });

        data = dw.empty_to_null(data);

        data.forEach(row => {
          Object.entries(row).forEach(([key, value]) => {
            if (sortDimensions.indexOf(key) > -1) {
              if (value) {
                // skip nulls
                uniqFilterPush(filterInputs, key, value);
              }
            }
          });
        });

        const filterInputsHolder = {};
        //sort menu items
        Object.entries(filterInputs).forEach(([key, value], i) => {
          value = value.map(val => {
            return val.toString();
          });

          value = value.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          });

          value.splice(0, 0, 'All');

          filterInputsHolder[key] = value;
        });

        filterInputs = filterInputsHolder;

        const filterRowJoin = filterControlsDiv
          .selectAll('filterRow')
          .data(sortDimensions);

        var newFilterRow = filterRowJoin
          .enter()
          .append('div')
          .classed('filterRow', true);

        newFilterRow
          .append('div')
          .classed('filterLabel', true)
          .text(d => d)
          .on('click', d => {
            if (primaryKey !== d) {
              primaryKey = d;
              appliedFilters[d] = 'All';
              redraw();
            }
          });

        newFilterRow
          .append('select')
          .attr('class', d => d)
          .classed('filterSelect', true)
          .attr('name', d => d)
          .on('change', (menuCategory, i, a) => {
            appliedFilters[menuCategory] = a[i].value;
            redraw();
          });

        newFilterRow.filter(d => {
          return filterDimensions.indexOf(d) > -1;
        });

        d3.selectAll('.filterSelect').each((d, i, a) => {
          const optionJoin = d3
            .select(a[i])
            .selectAll('option')
            .data(filterInputs[d]);
          optionJoin
            .enter()
            .append('option')
            .attr('value', od => {
              return od;
            })
            .text(od =>
              od === '(null)' || od === 'null' || od === '(null'
                ? 'Unknown'
                : od,
            );

          optionJoin.exit().remove();
        });

        filterRowJoin.exit().remove();

        //create a reset button

        d3.select('#filterControls')
          .append('button')
          .attr('class', 'full-reset')
          .attr('type', 'button')
          .text('Reset')
          .on('mousedown', function() {
            d3.select(this).style('background', 'gray');
          })
          .on('click', function(d) {
            appliedFilters = {};
            primaryKey = 'brand';
            d3.selectAll('.filterSelect').property('value', 'All');
            redraw();
          })
          .on('mouseout', function() {
            d3.select(this).style('background', 'rgba(61, 121, 143, 0.9)');
          })
          .on('mouseup', function() {
            d3.select(this).style('background', 'rgba(61, 121, 143, 0.9)');
          });

        new D3InfoComponent({
          title: `Updated as of ${d3.timeFormat('%b %d, %Y')(
            new Date(rawData.dateCreated),
          )}`,
          description: `Last 30 Day Active Devices:
           <br />
        <span class="DB-info-tiext"> Total count of devices that  connected and acquired a DHCP leased IP address on a Plume Network in the given Plume deployment for any period of time over the past 30 days. Only devices that acquire a DHCP IP lease are considered for device typing.</span>
        <br />
        <br />
        % of Devices Identified:  <br />
        <span class="DB-info-tiext">% of devices for which at least the device brand was detected.</span>
        <br />
        <br />
         Device Types:  <br />
        <span class="DB-info-tiext"> Number of unique types comprising device Brand, Name and Model</span>
         <br />
         <br />
         Brands:  <br />
        <span class="DB-info-tiext"> Detected device Brands representing the name of the company of the device as marketed and recognized by consumers. E.g. Apple iPhone X has the brand of Apple.</span>
        `,
          dateUpdated: '',
        }).create(el);

        redraw();
      })
      .catch(err => {
        // finish loader
        if (window.spinner) {
          window.spinner.finish();
        }
        const status = parseInt(err.message);
        if (status === UNAUTHORIZED) {
          logoutUser();
        } else if (isNaN(status)) {
          new D3NotificationPopup({
            title: 'Error!',
            message: `Currently there is no data for this module for the specific client`,
            type: 'error',
          }).create(el);
        } else {
          new D3NotificationPopup({
            title: 'Error while fetching data!',
            message: `Please try again later`,
            type: 'error',
          }).create(el);
        }
      });

    window.addEventListener(
      'resize',
      debounce(() => {
        if (window.location.href.includes('devices-bubbles')) {
          redraw();
        }
      }, 400),
    );
  };

  /**
   * Cleaning code...
   */

  // method for creating the timestamp that displays when the data is generated
  // the date is given through this.timeStamp which is assigned in the creation of the chart
  // this is called when the chart was created
  createTimeStamp = () => {
    this.timeStampG = d3
      .select('#devices')
      .append('g')
      .attr('class', 'data-bubble-disclaimer')
      .attr('transform', 'translate(' + 20 + ',' + 180 + ')');
  };

  destroy = () => {
    // this.helloWorld && this.helloWorld.remove();
  };
}

export default D3DevicesBubbles;
