import { processData } from './processData';

const pieData = (data, category = 'category', nestKeys) => {
  const dataKeys = processData(data, nestKeys);

  const keys = dataKeys[1];

  const pieData = {};

  keys.forEach(elem => {
    pieData[elem] = 0;
  });

  data.forEach(elem => {
    pieData[elem[category]] = pieData[elem[category]] + elem.value;
  });

  const pieData_value_name = Object.keys(pieData).map(key => {
    return { name: key, value: pieData[key] };
  });

  return pieData_value_name;
};

export { pieData };
