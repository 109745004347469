import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter, Redirect } from 'react-router-dom';

import { updateUser } from '../../actions/me_actions';


class Profile extends Component {
  state = {
    image: '',
    status: '',
    loading: false,
    isImageHidden: false,
    buttonPicked: false,
    isUploadHidden: true,
    redirect: false,
  };
  componentDidMount() {
    this.setState({ image: this.props.initialValues.image });
  }

  onUpload = files => {
    this.setState({
      image: files.filesUploaded[0].url,
      isImageHidden: !this.state.isImageHidden,
      isUploadHidden: !this.state.isUploadHidden,
    });
  };

  onOpenImageUpload = () => {
    this.setState({
      isImageHidden: !this.state.isImageHidden,
      isUploadHidden: !this.state.isUploadHidden,
    });
  };

  testFunction = event => {
    if (this.state.buttonPicked) {
      this.setState({
        isImageHidden: !this.state.isImageHidden,
        isUploadHidden: !this.state.isUploadHidden,
      });
    }
    this.setState({ buttonPicked: true });
  };

  handleRedirectClick = () => {
    this.setState({
      redirect: true,
    });
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
  };

  render() {
    const { loading } = this.state;

    return (
      <div
        className="profile__page"
        ref={c => {
          this._rootNode = c;
        }}
      >
        {this.renderRedirect()}
        <div className="profile-background">
          <form className="profile-form">
            <img src="/img/logo-plume@3x.png" alt="Plume Logo" />
            <h1>Your Profile</h1>
            <div className="input-form">
              <div className="input-row">
                <Field
                  required
                  disabled={loading}
                  name="first"
                  type="text"
                  placeholder="First Name"
                  component={renderTextField}
                />
                <Field
                  required
                  disabled={loading}
                  name="last"
                  type="text"
                  placeholder="Last Name"
                  component={renderTextField}
                />
              </div>
              <div className="input-row">
                <Field
                  required
                  disabled
                  name="username"
                  type="text"
                  placeholder="Username"
                  component={renderTextField}
                />
                <Field
                  required
                  disabled
                  name="email"
                  type="email"
                  placeholder="Email"
                  component={renderTextField}
                />
              </div>
            </div>
          </form>
          <button
            className="redir-todash-but"
            onClick={this.handleRedirectClick}
          >
            Back to Dashboard
          </button>
        </div>
      </div>
    );
  }
}

const renderTextField = ({ input, placeholder, ...props }) => (
  <div className="input-div">
    <label>{placeholder}</label>
    <input
      className="form-control"
      placeholder={placeholder}
      {...input}
      {...props}
    />
  </div>
);

const mapStateToProps = state => ({
  initialValues: { ...state.me.currentUser, password: '', confirmPassword: '' },
});

export default withRouter(
  connect(
    mapStateToProps,
    { updateUser },
  )(
    reduxForm({
      form: 'profileForm',
      enableReinitialize: true,
    })(Profile),
  ),
);
