import React, { Component } from 'react';
import './slider_styles.scss';

class Slider extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={
          this.props.className === undefined
            ? 'slider-container'
            : this.props.className
        }
      >
        <input
          onChange={el => {
            this.props.handleChange(el);
          }}
          type="range"
          value={this.props.value}
          name="slider"
          min={this.props.min === undefined ? 0 : this.props.min}
          max={this.props.max === undefined ? 100 : this.props.max}
        />{' '}
        <div className="slider-value">{this.props.value} </div>
      </div>
    );
  }
}

export default Slider;
