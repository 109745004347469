import React from 'react';
import PropTypes from 'prop-types';

import './filtersButton.scss';

const FiltersButton = ({ openClose }) => {
  return (
    <div className="KPI-filters-filters-button" onClick={() => openClose()}>
      Filters
    </div>
  );
};

FiltersButton.propTypes = {
  openClose: PropTypes.func.isRequired,
};

export default FiltersButton;
