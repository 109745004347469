import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './deploymentItem.scss';

const DeploymentItem = ({ name, selected, colorScale, handleKeyFilter }) => {
  return (
    <div
      className="KPI-filter-depl"
      onClick={() => {
        handleKeyFilter(name);
      }}
    >
      <span
        style={{ background: colorScale(name) }}
        className={classNames('KPI-filter-depl-circle', {
          'circle-off': !selected,
        })}
      />
      <span className="KPI-filter-depl-name">{name}</span>{' '}
    </div>
  );
};

DeploymentItem.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default DeploymentItem;
