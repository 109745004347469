import { formatters, sigDecimals } from '../helpers/d3/utils/formatters';

export const formatDeltaValuesNew = data => {
  if (data.numberPrior === 0) {
    data.deltaPct = 'from 0';
  } else {
    let deltaPctRaw = calculateDeltaPctRaw(data.number, data.numberPrior);

    data.deltaPct = deltaPctRaw.toFixed(sigDecimals(deltaPctRaw));
  }
  return data;
};

export const getFirstNumber = data => {
  let returnString = '';
  if (!isNaN(parseInt(data.number)) && !isNaN(parseInt(data.numberPrior))) {
    returnString = data.formatter
      ? formatters[data.formatter](data.number - data.numberPrior)
          .slice(0, 3)
          .join('')
      : data.number - data.numberPrior;
  }
  return returnString;
};

export const getSecondNumber = data => {
  let returnString = '';
  if (!isNaN(parseInt(data.deltaPct))) {
    if (data.deltaPct !== 'from 0') {
      returnString += formatters
        .percentage(data.deltaPct)
        .slice(0, 3)
        .join('');
    } else {
      returnString += 'from 0';
    }
  }
  return returnString;
};

export const calculateDeltaPctRaw = (number, numberPrior) => {
  if (number < 0 && numberPrior < 0) {
    return (
      ((parseFloat(number) - parseFloat(numberPrior)) /
        Math.abs(parseFloat(numberPrior))) *
      100
    );
  }

  if (numberPrior < 0 && number >= 0) {
    return (
      ((parseFloat(number) - parseFloat(numberPrior)) /
        Math.abs(parseFloat(numberPrior))) *
      100
    );
  }
  return (
    ((parseFloat(number) - parseFloat(numberPrior)) / parseFloat(numberPrior)) *
    100
  );
};
