import { ME_GET } from '../actions/me_actions';

const INIT_STATE = {
  currentUser: JSON.parse(localStorage.getItem('user')) || null,
  error: null,
};

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case ME_GET:
      if (!localStorage.getItem('user'))
        localStorage.setItem('user', JSON.stringify(action.payload));
      return { currentUser: action.payload, error: null };
    default:
      return state;
  }
}
