import { connect } from 'react-redux';
import PeopleChord from '../../components/Charts/react_charts/PeopleChoard';
import {
  arcClick,
  arcMouseOver,
  arcMouseLeave,
  setState,
  changeRibbonStyle,
} from '../../actions/people_actions';

const mapStateToProps = (state, ownProps) => {
  return {
    archs: state.people.archs,
    archClicked: state.people.archClicked,
    ribbons: state.people.ribbons,
    height: ownProps.height,
    width: ownProps.width,
    ribbonsAsChords: state.people.ribbonsAsChords,
    data: ownProps.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    arcClick: i => {
      dispatch(arcClick(i));
    },
    arcMouseOver: i => {
      dispatch(arcMouseOver(i));
    },
    arcMouseLeave: i => {
      dispatch(arcMouseLeave());
    },
    setChordState: payload => {
      dispatch(setState(payload));
    },
    changeRibbonStyle: () => {
      dispatch(changeRibbonStyle());
    },
  };
};

const PeopleChordCont = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleChord);

export default PeopleChordCont;
