import {
  TURN_FILTER_ON_OFF,
  TURN_FILTER_OFF,
} from '../actions/filter_chart_actions';

const init_state = {
  visible: false,
};

export default function filterChartReducer(state = init_state, action = null) {
  switch (action.type) {
    case TURN_FILTER_ON_OFF:
      return { ...state, ...{ visible: !state.visible } };
    case TURN_FILTER_OFF:
      return { ...state, ...{ visible: false } };
    default:
      return state;
  }
}
