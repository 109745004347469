import {
  RATINGS_DATA_FETCHED,
  CHANGE_YEAR,
  LINE_DATA_FETCHED,
  FILTER_DEVICES,
  HIGHLIGHT_CIRCLE_ON,
  HIGHLIGHT_CIRCLE_OFF,
} from '../actions/ratings_actions';

function ratingsReducer(
  state = {
    year: 2019,
    monthSelected: null,
    data: null,
    lineData: null,
    circleSelected: null,

    device: 'All Platforms',
  },
  action = null,
) {
  switch (action.type) {
    case RATINGS_DATA_FETCHED:
      return {
        ...state,
        ...{
          data: action.payload,
        },
      };

    case CHANGE_YEAR:
      return {
        ...state,
        ...{
          year: action.year,
        },
      };
    case LINE_DATA_FETCHED:
      return {
        ...state,
        ...{
          lineData: action.payload,
        },
      };

    case FILTER_DEVICES:
      return {
        ...state,
        ...{
          device: action.device,
        },
      };

    case HIGHLIGHT_CIRCLE_ON:
      return {
        ...state,
        ...{
          circleSelected: action.index,
        },
      };

    case HIGHLIGHT_CIRCLE_OFF:
      return {
        ...state,
        ...{
          circleSelected: null,
        },
      };
    default:
      return state;
  }
}

export default ratingsReducer;
