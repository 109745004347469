export function wrangleLiveDataSegment(data) {
  const formattedData = {
    breakdown: {},
    breakdownPrior: {},
    csvName: 'devicesseennow',
    delta: null,
    deltaPct: null,
    formatter: 'bigNum',
    links: [],
    name: 'Total Devices Online',
    number: null,
    numberPrior: null,
  };
  // filter data by csv name
  const dataFilter = data.filter(d => d[1] === formattedData.csvName);
  const dataFilterSortedByDate = dataFilter.sort(
    (a, b) => new Date(a[0]) - new Date(b[0]),
  );
  const lastTime = dataFilterSortedByDate[dataFilterSortedByDate.length - 1]
    ? dataFilterSortedByDate[dataFilterSortedByDate.length - 1][0]
    : new Date();
  const secondToLastTime = findSecondToLastTime(
    dataFilterSortedByDate,
    lastTime,
  );

  const lastData = makeDataObject(dataFilter, lastTime);
  const secondToLastData = makeDataObject(dataFilter, secondToLastTime);

  formattedData.breakdown = lastData.breakDown;
  formattedData.number = lastData.total;
  formattedData.numberPrior = secondToLastData.total;
  formattedData.breakdownPrior = secondToLastData.breakDown;
  formattedData.delta = calculateDelta(
    formattedData.number,
    formattedData.numberPrior,
  );
  formattedData.deltaPct = calculatePercent(
    formattedData.number,
    formattedData.numberPrior,
  );

  return { name: 'Live', color: 'Red', segments: [formattedData] };
}

//find the second to last time for change comparison
function findSecondToLastTime(dataFilterSortedByData, lastTime) {
  let secondToLastTime = null;
  let i = dataFilterSortedByData.length - 2;

  while (secondToLastTime === null || secondToLastTime === lastTime) {
    secondToLastTime = dataFilterSortedByData[i]
      ? dataFilterSortedByData[i][0]
      : new Date();
    i--;
  }
  return secondToLastTime;
}

function makeDataObject(data, time) {
  const dataObject = { breakDown: {}, total: null };
  const timeFilteredData = data.filter(d => d[0] === time);

  timeFilteredData.forEach(d => {
    if (d[3] === 'total') {
      dataObject.total = parseFloat(d[2]);
    } else {
      dataObject.breakDown[d[3]] = parseFloat(d[2]);
    }
  });
  return dataObject;
}

function calculateDelta(now, nowminusOne) {
  return now - nowminusOne;
}

function calculatePercent(now, nowMinusOne) {
  if (now === nowMinusOne) return 0;
  if (now > nowMinusOne) return (now - nowMinusOne) / nowMinusOne;
  return ((nowMinusOne - now) / nowMinusOne) * -1;
}
