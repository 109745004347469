import React, { Component } from 'react';

import D3Number from '../helpers/d3/number';

class NumberComponent extends Component {
  constructor(props) {
    super(props);
    this.d3Instance = new D3Number(props);
  }

  componentDidMount() {
    this.d3Instance.create(this._rootNode);
  }

  componentDidUpdate() {
    const { number, decPlaces } = this.props;
    this.d3Instance.updateMsg(number, decPlaces);
  }

  componentWillUnmount() {
    this.d3Instance.destroy();
  }

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  render() {
    const { showBreakDownClick } = this.props;

    return (
      <span
        onClick={showBreakDownClick}
        className="odometer"
        ref={this._setRef.bind(this)}
        data-testid="number-component"
      />
    );
  }
}

export default NumberComponent;
