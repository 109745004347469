import moment from 'moment';
import React from 'react';
import { dayTicksTest } from '../timeslider_react/timeSlider_help';

//this file contains several formatters that are used throughout the application
//grainFormattersformats grains from the api and displays it correctly on the Dashboard
//dataFormatters are used everywhere for formatting dates in the app

const grains = [
  'daily',
  'month',
  'qtr',
  'year',
  'ttm',
  'alltime',
  // 'now'
];

const grainFormatters = {
  qtr: function() {
    return 'Quarter';
  },
  month: function() {
    return 'Month';
  },
  daily: function() {
    return 'Day';
  },
  alltime: function() {
    return 'All Time';
  },
  year: function() {
    return 'Year';
  },
  ttm: function() {
    return 'TTM';
  },
  now: function() {
    return 'Now';
  },
};

const dateFormatters = {
  qtr: function(ts) {
    return moment(ts).format('\\QQ YYYY');
  },
  month: function(ts) {
    return moment(ts).format('M/YY');
    // return moment(ts).month() === 0 ? moment(ts).format('M/YY') : moment(ts).format('MMM');
  },
  daily: function(ts) {
    return moment(ts).format("MMM DD 'YYYY");
  },
  alltime: function(ts) {
    return moment(ts).format('MMM YYYY');
  },
  year: function(ts) {
    return moment(ts).format('YYYY');
  },
  // test v ttm
  ttm: function(ts) {
    return (
      moment(ts)
        .subtract(11, 'Months')
        .format('M/YY') +
      '-' +
      moment(ts).format('M/YY')
    );
  },
  now: function(ts) {
    return moment(ts).format('MMM DD hh:mm');
  },
};

function dailyTickBreaks(tick, grain) {
  if (grain !== 'daily') {
    return (
      <text fill="currentColor" y="9" dy="0.71em">
        {dateFormatters[grain](new Date(tick))}{' '}
      </text>
    );
  } else {
    var dailyTick = dateFormatters[grain](new Date(tick));
    dailyTick = dailyTick.split(' ');
    const ticksWithBreaks = dailyTick.map((value, i) => {
      var y = 4;
      if (i === 1) {
        y = 30;
      }
      if (i === 2) {
        y = 42;
      }
      return (
        <tspan x="0" y={y}>
          {value}
        </tspan>
      );
    });
    return (
      <text fill="currentColor" y="9" dy="0.71em">
        {ticksWithBreaks}
      </text>
    );
  }
}

const dateFormatters_Formal = {
  qtr: function(ts) {
    return moment.utc(ts).format('\\QQ YYYY');
  },
  month: function(ts) {
    return moment.utc(ts).format('MMM YYYY');
  },
  daily: function(ts) {
    return moment.utc(ts).format('MMM DD, YYYY');
  },
  alltime: function(ts) {
    return moment.utc(ts).format('MMM YYYY');
  },
  year: function(ts) {
    return moment.utc(ts).format('YYYY');
  },
  // test v ttm
  ttm: function(ts) {
    return (
      moment
        .utc(ts)
        .subtract(11, 'Months')
        .format('MMM YYYY') +
      ' - ' +
      moment.utc(ts).format('MMM YYYY')
    );
  },
  now: function(ts) {
    return moment.utc(ts).format('MMM DD hh:mm');
  },
};

const unit = {
  daily: 'day',
  month: 'month',
  qtr: 'quarter',
  year: 'year',
  ttm: 'month',
  alltime: 'month',
};

const makeRangeValues = (grain, series) => {
  let rangeValues = [];
  var tickValues = [];
  for (let i = 0; i < series.length; i++) {
    rangeValues.push(moment(series[i]).valueOf());
  }
  if (grain !== 'ttm') {
    rangeValues.push(
      moment(rangeValues[rangeValues.length - 1])
        .add(1, unit[grain])
        .valueOf(),
    );
  }
  if (grain !== 'daily' && grain !== 'ttm') {
    tickValues = [...rangeValues];
  } else {
    const jumpVal = grain === 'daily' ? 10 : 2;
    for (let i = 0; i < rangeValues.length; i += jumpVal) {
      tickValues.push(rangeValues[i]);
      if (i + jumpVal >= rangeValues.length)
        tickValues.push(rangeValues[rangeValues.length - 1]);
    }
  }
  return dayTicksTest(tickValues, grain);
};

export {
  grainFormatters,
  dateFormatters,
  grains,
  dailyTickBreaks,
  makeRangeValues,
  dateFormatters_Formal,
};
