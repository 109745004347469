import dotenv from 'dotenv';

dotenv.config();

export const API_URL =
  process.env.REACT_APP_API_URL ||
  'https://plume-dash-backend-dev.herokuapp.com/okta';
export const OKTA_ISSUER =
  process.env.REACT_APP_OKTA_ISSUER ||
  'https://dev-741674.oktapreview.com/oauth2/default/okta';
export const OKTA_CLIENT_ID =
  process.env.REACT_APP_OKTA_CLIENT_ID || '0oajx4lk5kQZFt74R0h7';
export const OKTA_ORG_URL =
  process.env.REACT_APP_OKTA_ORG_URL ||
  'https://dev-741674.oktapreview.com/oauth2/default';

export const OKTA_URL =
  process.env.REACT_APP_OKTA_URL || 'https://dev-741674.oktapreview.com';

export const OKTA_REDIRECT_URI = window.location.origin + '/implicit/callback';

export const PORT = process.env.PORT || process.env.REACT_APP_PORT || 8080;

export const ALLOW_HTTP = process.env.REACT_APP_ALLOW_HTTP || false;
