import * as d3 from 'd3';
import { dataSkeleton } from '../../utils/dataSkeletonNew';
import { formatYAxis } from './SeriesChart/formatters';

export const getStackKeys = data => {
  const uniqueKeys = {};

  data.forEach(d => {
    if (uniqueKeys[d.deployment] === undefined && d.deployment !== 'total') {
      uniqueKeys[d.deployment] = true;
    }
  });

  const keys = Object.keys(uniqueKeys).map(key => {
    return { name: key, selected: true };
  });

  return keys;
};

export const getChartTitleFromMetric = metric => {
  let found = false;
  let title;
  let index = 0;

  while (!found && index < dataSkeleton.length) {
    let seriesIndex = 0;
    while (!found && seriesIndex < dataSkeleton[index].segments.length) {
      if (dataSkeleton[index].segments[seriesIndex].csvName === metric) {
        title = dataSkeleton[index].segments[seriesIndex].name;
        found = true;
      }
      seriesIndex++;
    }
    index++;
  }
  return title === null ? '' : title;
};

export const sortName = (a, b) => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
};

//this finds the series chart link through the metric
export const getTotal = metric => {
  let found = false;
  let total = false;
  let index = 0;
  let length = dataSkeleton.length;

  while (!found && index < length) {
    let seriesIndex = 0;
    let seriesLength = dataSkeleton[index].segments.length;
    while (!found && seriesIndex < seriesLength) {
      if (dataSkeleton[index].segments[seriesIndex].csvName === metric) {
        let linksLength =
          dataSkeleton[index].segments[seriesIndex].links.length;
        let linksIndex = 0;
        while (!found && linksIndex < linksLength) {
          if (
            dataSkeleton[index].segments[seriesIndex].links[linksIndex].type ===
            'series'
          ) {
            total =
              dataSkeleton[index].segments[seriesIndex].links[linksIndex]
                .options === 'total'
                ? true
                : false;
            found = true;
          }
          linksIndex++;
        }
      }
      seriesIndex++;
    }
    index++;
  }
  return total ? true : false;
};

export const getDataFromMetric = metric => {
  let found = false;
  let length = dataSkeleton.length;
  let data = null;
  let index = 0;

  while (!found && index < length) {
    let segmentIndex = 0;
    let segmentsLength = dataSkeleton[index].segments.length;
    while (!found && segmentIndex < segmentsLength) {
      if (dataSkeleton[index].segments[segmentIndex].csvName === metric) {
        data = dataSkeleton[index].segments[segmentIndex];
      }
      segmentIndex++;
    }
    index++;
  }
  return data;
};

export const getEndDataFromMetric = metric => {
  const data = getDataFromMetric(metric);
  return dataKey => {
    return data[dataKey];
  };
};

export const yAxisFormatters = format => {
  const hashMap = {
    percentage: t => d3.format('.1%')(t / 100),
    currency: t => formatYAxis(t),
    bigCurrency: t => formatYAxis(t),
    bigNum: t => formatYAxis(t),
    bigData: t => formatYAxis(t),
    uptime: t => d3.format('.0%')(t / 100),
  };

  return hashMap[format];
};
