import React, { useEffect, useState, useMemo, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import PlumeSpinner from '../PlumeSpinner';
import Toggle from 'react-toggle';

import GroupBarChart from '../GroupBarChart';
import SeriesChart from './SeriesChart';
import ChartLegend from '../communal/ChartLegend';

import { getDashboardData } from '../../actions/dashboard_actions';
import { selectFilteredData } from '../../selectors';

import {
  getTotal,
  getStackKeys,
  getChartTitleFromMetric,
  sortName,
} from './dataHelpers';
import { color } from './uiHelpers';
import Filters from './Filters';

import './series.scss';

const Series = ({ getDashboardData, init_total, filters, data }) => {
  const {
    metric,
    //,
    grain,
  } = filters;
  const [keys, setKeys] = useState(null);
  const [total, setTotal] = useState(init_total);
  const [group, setGroup] = useState(false);
  const [fullData, setFullData] = useState(grain !== 'daily' ? true : false);
  const holdKeys = useRef(null);
  const initRender = useRef(true);
  const title = useMemo(() => getChartTitleFromMetric(metric), [metric]);
  const color_by_grain = useMemo(() => color(metric), [metric]);

  function handleKeyFilter(name) {
    const new_keys = keys.map(item => {
      if (item.name === name) item.selected = !item.selected;
      return item;
    });
    setKeys(new_keys);
  }

  function changeGroup() {
    setTotal(false);
    setGroup(!group);
  }

  function changeFullData() {
    setFullData(!fullData);
  }

  useEffect(
    () => {
      if (data === null) {
        getDashboardData();
      }
    },
    [null],
  );

  useEffect(
    () => {
      if (data !== null) {
        setKeys(getStackKeys(data).sort(sortName));
      }
    },
    [data],
  );

  useEffect(
    () => {
      if (data !== null) {
        if (total) {
          holdKeys.current = keys;
          setKeys([{ name: 'total', selected: true }]);
        } else {
          if (!initRender.current) {
            if (holdKeys.current !== null) {
              setKeys(holdKeys.current);
            }
          }
        }
      }
      initRender.current = false;
    },
    [total, data],
  );
  function changeTotal() {
    if (init_total) return;
    setTotal(!total);
  }
  if (keys === null) {
    return (
      <div className="KPIDash-series-pg">
        <PlumeSpinner />
      </div>
    );
  }

  const rendered_chart = group ? (
    <GroupBarChart
      filters={filters}
      data={data}
      keys={keys}
      total={false}
      color={color_by_grain}
      fullData={fullData}
    />
  ) : (
    <SeriesChart
      filters={filters}
      data={data}
      keys={keys}
      total={false}
      color={color_by_grain}
      fullData={fullData}
    />
  );

  return (
    <div className="KPIDash-series-pg">
      <div
        className="KPIDash-series-title"
        style={{ color: color_by_grain('title') }}
      >
        {title}
      </div>
      <div className="KPIDash-series-chart-legend-cont">
        {rendered_chart}
        <div className="KPIDash-legend-options-cont">
          <ChartLegend
            options={keys}
            total={total}
            metric={metric}
            changeTotal={changeTotal}
            color={color_by_grain}
          />
          <Filters
            handleKeyFilter={handleKeyFilter}
            keys={keys}
            data={data}
            colorScale={color_by_grain}
          />
          <div className="KPI-dash-series-toggles">
            <div className="KPI-dash-series-toggle">
              {total ? null : (
                <Fragment>
                  <Toggle
                    onChange={() => {
                      changeGroup();
                    }}
                    checked={group}
                  />
                  <span>{'  '}Grouped</span>
                </Fragment>
              )}
            </div>
            {group ? null : (
              <div className="KPI-dash-series-toggle">
                <Toggle
                  onChange={() => {
                    changeTotal();
                  }}
                  checked={total}
                />
                <span>{'  '}Total</span>
              </div>
            )}
            {grain !== 'daily' ? null : (
              <div className="KPI-dash-series-toggle">
                <Toggle
                  onChange={() => {
                    changeFullData();
                  }}
                  checked={fullData}
                />
                <span>{'  '} All Time</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  data:
    state.dashboard.dashboardData === null ? null : selectFilteredData(state),
  filters: state.filters,
  init_total: getTotal(state.filters.metric),
});

const mapDispatchToProps = dispatch => ({
  getDashboardData: () => {
    dispatch(getDashboardData());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Series);
