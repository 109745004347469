import BaseXYChart from './BaseXYChart';
import * as d3 from 'd3';
import parseDate from '../../helpers/d3/utils/formatTimeHelpers';

//line chart must have data that is and array of objects and x takes a time value (d.date)
//y takes a value d.value

//everything is drawn to this.mainG which is inherited from the BaseXYChart mainDraw

class LineChart extends BaseXYChart {
  constructor(options, lineOptions) {
    super(options);
    this.strokeColor = lineOptions.strokeColor;
    this.changeLine =
      lineOptions.changeLine !== true || lineOptions.changeLine === undefined
        ? false
        : true;
    this.changeLineColor =
      lineOptions.changeLineColor === undefined
        ? undefined
        : lineOptions.changeLineColor;
  }

  setLineGenerator() {
    if (this.time === true) {
      this.lineGenerator = d3
        .line()
        .x(d => this.xScale(new Date(parseDate(d[this.xValue]).getTime())))
        .y(d => this.yScale(d[this.yValue]));
    } else {
      this.lineGenerator = d3
        .line()
        .x(d => this.xScale(d[this.xValue]))
        .y(d => this.yScale(d[this.yValue]));
    }
  }

  makeChangeLine = () => {
    const lines = churnPositiveLine(this.data);

    this.lines = this.g
      .append('g')
      .attr('class', 'upward-line-chart-line')
      .selectAll('.line-path-upward')
      .data(lines);

    this.lines
      .enter()
      .append('path')
      .attr('class', 'line-path-upward')
      .attr('d', this.lineGenerator)
      .attr('stroke-width', '2px')
      .attr('fill', 'none')
      .attr('stroke', this.changeLineColor);
  };

  drawLine() {
    this.line = this.g
      .append('g')
      .attr('class', 'line-chart-line-g')
      .append('path')
      .attr('class', 'line-chart-line')
      .datum(this.data);

    this.line
      .attr('d', this.lineGenerator)
      .attr('fill', 'none')
      .attr('stroke-width', '2px')
      .attr('stroke', this.strokeColor);
  }

  mainLine() {
    this.setLineGenerator();
    this.drawLine();

    if (this.changeLine) {
      this.makeChangeLine();
    }
  }
}

function churnPositiveLine(data) {
  let lines = [];

  data.forEach((val, i) => {
    let line = [];
    if (i === 0) {
      return null;
    }
    if (i === 1) {
      if (val.value < data[0].value) {
        line.push(data[0]);
        line.push(val);
        lines.push(line);
      }
      return null;
    }

    if (val.value < data[i - 1].value) {
      line.push(data[i - 1]);
      line.push(val);
      lines.push(line);
    }
  });

  return lines;
}

export default LineChart;
