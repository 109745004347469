import BaseXYChart from './BaseXYChart';
import * as d3 from 'd3';
class GroupedByChart extends BaseXYChart {
  makeRects() {
    this.mainG
      .append('g')
      .attr('class', 'KPI-stacked-g')
      .selectAll('.bar-groups')
      .data(this.data)
      .join('g')
      .attr('transform', d => `translate(${this.xScale(d.ts)},0)`)
      .selectAll('rect')
      .data(d =>
        Object.keys(d.subdata).map(key => ({
          name: key,
          value: d.subdata[key],
        })),
      )
      .join('rect')
      .attr('x', d => this.innerBandScale(d.name))
      .attr('height', d => this.height - this.yScale(d.value))
      .attr('y', d => this.yScale(d.value))
      .attr('width', d => this.innerBandScale.bandwidth())
      .attr('fill', d => this.colorScale(d.name))
      .attr('name', d => d.name)
      .attr('value', d => d.value);
  }

  makePopOver() {
    let self = this;
    this.mainG.selectAll('rect').on('mouseenter', function(d) {
      const deployment = d.name;
      const value = parseInt(d.value);
      const formattedValue = d3.format('~s')(value);
      const mouse = d3.mouse(d3.select('body').node());
      const x = mouse[0] - 200;
      const y = mouse[1] - 75;
      const date = d3.select(this.parentNode).data()[0].ts;
      d3.select('body')
        .append('div')
        .attr('class', 'kpi-series-popup-cont')
        .html(self.makePopOverHTML(deployment, formattedValue, x, y, date));
    });
    this.mainG.selectAll('rect').on('mouseout', d => {
      d3.selectAll('.kpi-series-popup-cont').remove();
    });
  }

  makePopOverHTML(deployment, formattedValue, x, y, date) {
    return `<div style="left:${x}px; top:${y}px" class='kpi-series-popup'>
         <div class='kpi-series-popup-title'>${deployment}:   ${formattedValue} </div>
         <div class='kpi-series-popup-title'>date: ${date}</div>
    </div>`;
  }

  render() {
    this.makeMainSVG();
    this.makeScales();
    this.makeAxis();
    this.makeRects();
    this.makePopOver();
  }

  reRender(options) {
    this.data = options.data;
    this.xAxisTicks = options.xAxisTicks === undefined? this.xAxisTicks:options.xAxisTicks;
    this.mainSVG.remove();
    this.render();
  }

  remove() {
    this.mainSVG.remove();
  }
  redraw({ height, width }) {
    this.height = height - (this.margin.t + this.margin.b);
    this.width = width - (this.margin.l + this.margin.r);
    this.render();
  }
}

export default GroupedByChart;
