import React, { Component } from 'react';
import * as cloud from 'd3-cloud';
import * as d3 from 'd3';

class WordCloud extends Component {
  constructor(props) {
    super(props);
    this.margin = { l: 0, t: 0, r: 25, b: 25 };

    this.divWidth =
      this.props.dimensions.width - (this.margin.l + this.margin.r);
    this.divHeight =
      this.props.dimensions.height - (this.margin.t + this.margin.b);

    this.width = 500 - (this.margin.l + this.margin.r);
    this.height = 500 - (this.margin.t + this.margin.b);
  }

  componentDidMount() {
    this.drawCloud();
  }
  createWordObject() {
    this.words = {};

    const words = wordCount(this.props.words);
    this.words = words;
  }

  createScales() {
    this.xScale = d3
      .scaleLinear()
      .domain(
        d3.extent(this.words, d => {
          return d.value;
        }),
      )
      .range([10, this.width / 2])
      .clamp(true);
    var self = this;
    cloud()
      .size([this.width - 5, this.height - 5])
      .timeInterval(20)
      .padding(3)
      .words(this.words)
      .fontSize(d => {
        return this.xScale(+d.value);
      })
      .text(function(d) {
        return d.key;
      })
      .rotate(function() {
        return ~~(Math.random() * 2) * 270;
      })
      .on('end', draw)
      .start();

    function draw(words) {
      d3.select(self._rootNode)
        .append('svg')
        .attr('width', self.width)
        .attr('height', self.height)
        .append('g')
        .attr(
          'transform',
          'translate(' + self.width / 2 + 5 + ',' + self.height / 2 + ')',
        )
        .selectAll('text')
        .data(self.words)
        .enter()
        .append('text')
        .style('font-size', d => {
          return self.xScale(d.value) + 'px';
        })
        .style('font-family', 'Impact')
        .attr('stroke', 'lightgrey')
        .attr('stroke-width', '0.5')
        .attr('opacity', '0.8')
        .style('fill', function(d, i) {
          return 'none';
        })
        .attr('text-anchor', 'middle')
        .attr('transform', function(d) {
          return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')';
        })
        .text(function(d) {
          return d.key;
        });
    }
    cloud().stop();
  }

  drawCloud() {
    this.createWordObject();
    this.createScales();
  }

  render() {
    return (
      <div className="word-cloud-container">
        <div className="word-cloud" />
        <div id="word-cloud-title"> Word Cloud</div>
        <div
          className="wordcloud-div-cont"
          style={{
            width: this.divWidth,
            height: this.divHeight,
            overflowX: 'scroll',
            overflowY: 'scroll',
          }}
        >
          <svg
            width={this.width + (this.margin.l + this.margin.r)}
            height={this.height + (this.margin.t + this.margin.b)}
          >
            <g
              transform={`translate(${this.margin.l},${this.margin.t}`}
              ref={el => {
                this._rootNode = el;
              }}
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default WordCloud;

function wordCount(data) {
  const wordCount = {};

  var common =
    "poop,i,me,my,myself,we,us,our,ours,ourselves,you,your,yours,yourself,yourselves,he,him,his,himself,she,her,hers,herself,it,its,itself,they,them,their,theirs,themselves,what,which,who,whom,whose,this,that,these,those,am,is,are,was,were,be,been,being,have,has,had,having,do,does,did,doing,will,would,should,can,could,ought,i'm,you're,he's,she's,it's,we're,they're,i've,you've,we've,they've,i'd,you'd,he'd,she'd,we'd,they'd,i'll,you'll,he'll,she'll,we'll,they'll,isn't,aren't,wasn't,weren't,hasn't,haven't,hadn't,doesn't,don't,didn't,won't,wouldn't,shan't,shouldn't,can't,cannot,couldn't,mustn't,let's,that's,who's,what's,here's,there's,when's,where's,why's,how's,a,an,the,and,but,if,or,because,as,until,while,of,at,by,for,with,about,against,between,into,through,during,before,after,above,below,to,from,up,upon,down,in,out,on,off,over,under,again,further,then,once,here,there,when,where,why,how,all,any,both,each,few,more,most,other,some,such,no,nor,not,only,own,same,so,than,too,very,say,says,said,shal, &,-,i'm,it’s,also,app,just,1,2,3,4,5,i'm,two";

  data.forEach((word, i) => {
    word = word.toLowerCase();
    if (!common.includes(word)) {
      if (!(word in wordCount)) {
        wordCount[word] = 0;
      }
      wordCount[word] += 1;
    }
  });

  const filteredWordCount = {};

  Object.keys(wordCount).forEach((key, i) => {
    if (wordCount[key] > 15) {
      filteredWordCount[key] = wordCount[key];
    }
  });

  return d3.entries(filteredWordCount);
}
