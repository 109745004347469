// this is a dashboard that holds all the LineBubbleChart churns for all the deployments that we have
import React, { Component } from 'react';
import * as d3 from 'd3';
import ChurnLineBubble from './ChurnLineBubble';
import Filter from '../../tools/Filter';
import './chartXYStyles.scss';
import { getVizFilters } from '../../../utils/helper';
import { debounce } from 'underscore';
//ChurnLegend is removed for now it is not deleted because it may come back in the future
//import ChurnLegend from './ChurnLegend';

class MainChurnDash extends Component {
  constructor(props) {
    super(props);

    //setcorrect redux this.state

    this.state = {
      selectValue: 'Active Households',
      filter: {
        top: null,
        right: null,
      },
    };

    const { colors } = getVizFilters(this.props.filters, this.props.match);

    this.colors = colors;

    const titles = ['beta', 'chi', 'delta', 'gamma', 'kappa', 'theta', 'total'];

    this.z = d3
      .scaleSequential()
      .domain([0, titles.length])
      .interpolator(
        d3.interpolateHsl(
          'hsla(' + this.colors.primaryHue + ', 100%, 50%, .7)',
          'hsla(' + this.colors.tertiaryHue1 + ', 100%, 50%, .7)',
        ),
      );

    this.colorMaps = d3.map();

    titles.forEach((el, i) => {
      if (el === 'total') {
        this.colorMaps.set(el, '#6269ff');
      } else {
        this.colorMaps.set(el, this.z(i));
      }
    });
  }

  handleSVGOnClick = ev => {
    this.setState({
      filter: {
        top: ev.pageY,
        right: window.innerWidth - ev.pageX,
      },
    });

    this.props.toolOnOff();
  };

  componentDidMount() {
    if (window.spinner) {
      window.spinner.finish();
    }

    this.props.getData();

    this.retSize = () => {
      this.props.churnResize();
    };

    this.reSizeDebounce = debounce(this.retSize, 10);

    window.addEventListener('resize', this.reSizeDebounce);
  }

  componentWillUnmount = () => {
    this.props.setRadiusVariable('Active Households'.toLowerCase());
    this.props.turnChurnEnvironmentsTrue();
    this.props.resetEnvironments();

    window.removeEventListener('resize', this.reSizeDebounce);
  };

  handleSelectChange = event => {
    this.props.setRadiusVariable(event.currentTarget.textContent.toLowerCase());

    this.setState({
      selectValue: event.currentTarget.textContent,
    });
  };

  render() {
    if (this.props.data === null || this.props.churn_env === null) {
      return null;
    }

    const filterTool =
      this.props.environments === null ||
      this.state.filter.top === null ? null : (
        <Filter
          options={this.props.environments}
          display={this.props.showFilterTool}
          environmentsFilter={this.props.environmentsFilter}
          colors={this.colors}
          toolOnOff={this.props.toolOnOff}
          graphMode={'series'}
          churn={true}
          top={this.state.filter.top}
          right={this.state.filter.right - 200}
          noneOption={true}
          showNone={this.props.turnAllChurnOff}
          thetaColorOn={true}
          thetaColor={'rgb(79, 246, 255)'}
        />
      );

    const data = this.props.data.filter(val => {
      var on = true;

      this.props.churn_env.forEach(env => {
        if (val.key === env.name) {
          on = env.on;
        }
      });

      return on;
    });

    let heightSubtraction = window.innerHeight > 842 ? 48 : 48;

    const lineChartBubbles = data.map((d, i) => {
      return (
        <div
          className="area-chart-row-cont"
          style={
            i === data.length - 1 && i % 2 === 0
              ? {
                  display: 'inline-block',
                  width: '94%',
                  height: `calc(${100 /
                    Math.ceil(data.length / 2)}% - ${heightSubtraction}px)`,
                  borderColor:
                    d.key === 'theta'
                      ? 'rgb(79, 246, 255)'
                      : this.colorMaps.get(d.key),
                }
              : {
                  display: 'inline-block',
                  width: '45%',
                  height: `calc(${100 /
                    Math.ceil(data.length / 2)}% - ${heightSubtraction}px)`,
                  borderColor:
                    d.key === 'theta'
                      ? 'rgb(79, 246, 255)'
                      : this.colorMaps.get(d.key),
                }
          }
          key={d.key}
        >
          <ChurnLineBubble
            radiusMax={this.props.radiusMax}
            title={d.key}
            data={d}
            index={i}
            radiusVariable={this.props.radiusVariable}
            outerValue={this.props.outerValue}
            checkData={data}
            timeAxisValues={this.props.timeAxisValues}
            color={
              d.key === 'theta'
                ? 'rgb(79, 246, 255)'
                : this.colorMaps.get(d.key)
            }
          />
        </div>
      );
    });

    return (
      <div className="title-churn-cont">
        <div className="churn-title"> Churn Facet </div>
        <div className="full-churn-container">
          <div className="churn-charts-container">
            <div className="churn-dash-main-cont">{lineChartBubbles}</div>
          </div>
          <div className="churn-legend-cont">
            <div className="churn-legend" />
            <div className="container-select-households">
              <div
                style={
                  this.state.selectValue === 'Active Households'
                    ? { backgroundColor: 'white', color: 'black' }
                    : {}
                }
                onClick={this.handleSelectChange}
                className="select-households-item"
                value="Active Households"
              >
                Active Households
              </div>
              <div
                style={
                  this.state.selectValue === 'New Activations'
                    ? { backgroundColor: 'white', color: 'black' }
                    : {}
                }
                onClick={this.handleSelectChange}
                className="select-households-item"
                value="New Activations"
              >
                New Activations
              </div>
            </div>

            <div
              style={{
                position: 'static',
                paddingTop: '10px',
                paddingLeft: '10px',
                marginLeft: '-15%',
              }}
              className="filter-icon"
            >
              <svg
                className="filter-icon-main"
                xmlns="http://www.w3.org/2000/svg"
                onClick={this.handleSVGOnClick}
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 64 64"
                width="40px"
                height="40px"
              >
                <rect
                  className="filter-svg"
                  x="0"
                  y="0"
                  width="64px"
                  height="64px"
                  stroke={this.props.showFilterTool ? 'white' : 'grey'}
                  fill="none"
                  strokeWidth="5px"
                />
                <path
                  fill={this.props.showFilterTool ? 'white' : 'grey'}
                  className="filter-svg"
                  id="filter-object"
                  d="M52.5,19.67l-16,20h0a6.24,6.24,0,0,0-1.37,3.9V57L30.6,54.74a3.12,3.12,0,0,1-1.73-2.79V43.58h0a6.24,6.24,0,0,0-1.37-3.9l-16-20a5,5,0,0,1-1.35-3.24c0-5.17,9.78-9.36,21.85-9.36s21.85,4.19,21.85,9.36A5,5,0,0,1,52.5,19.67Zm-20.5,3c8.62,0,15.61-2.79,15.61-6.24s-7-6.24-15.61-6.24S16.39,13,16.39,16.43,23.38,22.67,32,22.67Z"
                />
              </svg>
              <div className="filter-tool-tip">
                <span>Filters</span>
              </div>

              {filterTool}
            </div>
            <div className="churn-circle-legend">
              {/* <ChurnLegend name={this.props.radiusVariable} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainChurnDash;
