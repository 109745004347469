import * as d3 from 'd3';

//this is to help speed up the filtering we will pre group the data
//@params data     takes the data and groups it so that filtering can be much faster when the click happens
//this returns a map back to values

export const groupByCategory = data => {
  const map = new Map();

  const group = d3
    .nest()
    .key(d => d['category'])
    .entries(data);

  group.forEach((val, i) => {
    map.set(val.key, val.values);
  });
  return map;
};
