import React from 'react';

import './header.scss';

const Header = ({ close }) => {
  return (
    <div className="KPI-filter-header">
      <span
        className="KPI-filter-close"
        onClick={() => {
          close();
        }}
      >
        &#215;
      </span>
    </div>
  );
};

export default Header;
