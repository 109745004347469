function roundNumbers(num, n) {
  switch (n) {
    case 1:
      return Math.round(10 * num) / 10; // round X to tenths
    case 2:
      return Math.round(100 * num) / 100; // round X to hundredths
    case 3:
      return Math.round(1000 * num) / 1000; // round X to thousandths
  }
}

export { roundNumbers };
