function makeWordArray(data) {
  var comments = data.map((d, i) => {
    return d.content;
  });
  comments = comments.join('');
  comments = comments.split(' ');

  return comments;
}

export { makeWordArray };
