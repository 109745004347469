class DeviceData {
  constructor(data) {
    this.data = data;
    this.totalDevices = this.devices;
  }

  get devices() {
    let totalDevices = 0;
    this.data.forEach(d => {
      totalDevices += parseInt(d.device_cummulative_cnts);
    });
    return totalDevices;
  }

  get brands() {
    let brand = {};
    this.data.forEach(d => {
      if (
        d.brand !== null &&
        brand[d.brand.toLowerCase()] === undefined &&
        d.brand.toLowerCase() !== ''
      ) {
        brand[d.brand.toLowerCase()] = true;
      }
    });

    return Object.keys(brand).length;
  }
  get percentOfDevicesIdentified() {
    let number =
      (this.totalDevices - this.nonIdentifiedDevices) / this.totalDevices;
    return number;
  }

  get nonIdentifiedDevices() {
    let nonIdentifiedDevices = this.data.find(
      d =>
        d.brand === null &&
        d.category === null &&
        d.icon === null &&
        d.name === null &&
        d.idtype === null,
    );
    nonIdentifiedDevices =
      nonIdentifiedDevices === undefined
        ? 0
        : parseInt(nonIdentifiedDevices.device_cummulative_cnts);
    return nonIdentifiedDevices;
  }

  get deviceTypes() {
    return this.nonIdentifiedDevices === 0
      ? this.data.length
      : this.data.length - 1;
  }
}

export default DeviceData;
