import React from 'react';
import { connect } from 'react-redux';

import './liveMenu.scss';

import DataComponent from '../../DataComponent';

const LiveMenu = ({ data, dateRanges, changeFilters, history }) => {
  const dataItem = data[0].segments[0];

  function handleRedirect(path, graphTypes) {
    const redirect = (path, graphTypes) => {
      const metric = 'devicesseennew';
      const grain = 'now';
      if (path.includes('timeseries')) {
        changeFilters({ graphTypes, metric, grain });
      }
      changeFilters({ metric, grain });
      history.push(path);
    };

    redirect(path, graphTypes);
  }
  return (
    <div className="KPI-live-menu-cont">
      <DataComponent
        key={'live-data'}
        data={dataItem}
        grain={'now'}
        dateRanges={dateRanges}
        redirect={handleRedirect}
        color={'Red'}
        show={true}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  dateRanges: state.filters.dateRanges,
});

export default connect(
  mapStateToProps,
  undefined,
)(LiveMenu);

//just a note
//dataFormat
// {
//   delta: changeamount
//   deltaPct: pct change
//   formatter: "bigNum",
//   links:[];
//   name:
//   number:
//   numberPrior
// }
