import React, { Component } from 'react';
import RadialCont from '../RadialCont';
import './responsiveStyles/radial.scss';

class RadialRespCont extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        hieght: 0,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.setState({
      dimensions: {
        height: this._rootNode.getBoundingClientRect().height,
        width: this._rootNode.getBoundingClientRect().width,
      },
    });
  }

  render() {
    const chart =
      this.state.dimensions.width <= 0 ||
      this.state.dimensions.height <= 0 ? null : (
        <RadialCont
          height={this.state.dimensions.height}
          width={this.state.dimensions.width}
        />
      );
    return (
      <div
        className="radial-responsive-container"
        ref={el => {
          this._rootNode = el;
        }}
      >
        {chart}
      </div>
    );
  }
}

export default RadialRespCont;
