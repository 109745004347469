//this is a utitlity class that will be used to wrangle data
//when data is wrangled it needs to be set to this.data  and then returned

class Data_Wrangle {
  constructor() {
    this.data = null;
  }

  // accessors are callback functions
  filter(data, accesor) {
    this.data = data;
    const new_data = this.data.filter(accesor);
    return new_data;
  }
  // this function takes an array of objects and replaces all empty attributes with null ''=>null
  empty_to_null(data) {
    this.data = data;

    const new_data = this.data.map((val, i) => {
      const newObject = {};
      Object.keys(val).forEach(key => {
        const attribute = val[key] === '' ? null : val[key];
        newObject[key] = attribute;
      });
      return newObject;
    });

    return new_data;
  }

  //distinct returns distinct attributes for the given attr
  //@params attr is used for the attr we want to find distint it should be a string
  distinct(data, attr) {
    this.data = data;
    const array = [];
    data.forEach(d => {
      try {
        if (d[attr] !== null) array.push(d[attr]);
      } catch (err) {
        alert(err);
      }
    });
    const distinct = [...new Set(array)];

    return distinct;
  }

  //this takes a hashmap an returns an array with an object with the key as name and the value as value
  name_value(data) {
    this.data = data;
    const array = [];
    Object.keys(this.data).forEach((key, i) => {
      const value = this.data[key];
      const object = { name: key, value: value };
      array.push(object);
    });
    return array;
  }

  //this mathes the primarykey to the device count while summing the differnt devices
  //@params data - data to be hashed should be an array of objects
  //@param primary_key -is the key you want to match to a value
  //@params value -the value to match the primary key

  hashMap_sum(data, primary_key, value) {
    const hashMap_sum = {};

    data.forEach(element => {
      hashMap_sum[element[primary_key]] = hashMap_sum[element[primary_key]]
        ? hashMap_sum[element[primary_key]] + parseInt(element[value])
        : parseInt(element[value]);
    });
    return hashMap_sum;
  }
}

const dw = new Data_Wrangle();

export default dw;
