import * as d3 from 'd3';

class D3InfoComponent {
  constructor(options) {
    this.options = options;
  }

  create = el => {
    const { title, description, position } = this.options;
    let wrapper, contentWrapper;

    if (!d3.select('#info-popup').node()) {
      wrapper = d3
        .select(el)
        .append('div')
        .attr('id', 'info-popup')
        .html(`&nbsp;`);

      contentWrapper = wrapper.append('div').attr('class', 'info-content');
    } else {
      wrapper = d3.select('#info-popup');
      contentWrapper = d3.select('.info-content');
      contentWrapper.html('');
    }

    if (position) {
      if (position === 'tl') {
        wrapper.style('top', '40px').style('left', '55px');
      } else if (position === 'tc') {
        wrapper.style('top', '40px').style('left', '43%');
      } else if (position === 'tr') {
        wrapper.style('top', '40px').style('rgith', '45px');
      } else if (position === 'bl') {
        wrapper.style('bottom', '45px').style('left', '55px');
      } else if (position === 'bc') {
        wrapper.style('bottom', '45px').style('left', '43%');
      }
    } else {
      // default: bottom right
      wrapper.style('bottom', '45px').style('right', '45px');
    }

    contentWrapper.append('h4').text(title);
    contentWrapper
      .append('div')
      .classed('infoContent', true)
      .html(description);
    // contentWrapper
    //   .append('div')
    //   .classed('updateInfo', true)
    //   .html(
    //     `Date last updated:<br /> ${moment(dateUpdated).format('D MMM YYYY')}`,
    //   );
  };

  update = description => {
    d3.select('.infoContent').html(description);
  };
}

export default D3InfoComponent;
