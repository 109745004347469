import * as d3 from 'd3';
import { formatDate } from '../containers/RadialCont/calculateTime';
import Token from '../components/okta/Token';
import { API_URL } from '../config';

export const RATINGS_DATA_FETCHED = 'RATINGS_DATA_FETCHED';
export const CHANGE_YEAR = 'CHANGE_YEAR';
export const LINE_DATA_FETCHED = 'LINE_DATA_FETCHED';
export const FILTER_DEVICES = 'FILTER_DEVICES';
export const HIGHLIGHT_CIRCLE_ON = 'HIGHLIGHT_CIRCLE_ON';
export const HIGHLIGHT_CIRCLE_OFF = 'HIGHLIGHT_CIRCLE_OFF';

//ACTIONS

function ratingsDataFetched(payload) {
  return {
    type: RATINGS_DATA_FETCHED,
    payload,
  };
}

function lineDataFetched(payload) {
  return {
    type: LINE_DATA_FETCHED,
    payload,
  };
}

export function changeYear(year) {
  return {
    type: CHANGE_YEAR,
    year,
  };
}

export function filterDeviceAction(device) {
  return {
    type: FILTER_DEVICES,
    device,
  };
}

export function highlightCircleOn(index) {
  return {
    type: HIGHLIGHT_CIRCLE_ON,
    index,
  };
}

export function highlightCircleOff() {
  return {
    type: HIGHLIGHT_CIRCLE_OFF,
  };
}
//THUNKS

export function fetchRatingsData() {
  const oktaToken = new Token(localStorage['okta-token-storage']);
  const token = 'Bearer ' + oktaToken.accessToken;

  return dispatch => {
    const dataApi = `${API_URL}/data/dashboard`;

    // const getIosData = d3.json(
    //   'https://itunes.apple.com/us/rss/customerreviews/id=1086207508/limit=200/json',
    // );
    const getDatafromDashboard = d3.json(`${dataApi}`, {
      headers: { Authorization: token },
    });

    const reviewApi = `${API_URL}/data/reviews`;
    const getDataFromRatings = d3.json(`${reviewApi}`, {
      headers: { Authorization: token },
    });

    const reviewStaticApi = `${API_URL}/data/reviews_static`;
    const getDataFromStaticRatings = d3.json(`${reviewStaticApi}`, {
      headers: { Authorization: token },
    });
    // const ext_id = {
    //   android: 'com.plumewifi.plume',
    //   amazon: 'B07GVPQG3Y',
    //   ios: '1086207508',
    // };

    // const amazonURL = ratingsURLGenerator(ext_id['amazon']);
    // const iosURL = ratingsURLGenerator(ext_id['ios']);
    // const androidURL = ratingsURLGenerator(ext_id['android']);

    //promises for getting data from appfollow
    // const getAmazonAppFollowData = d3.json(amazonURL);
    // const getiosURLAppFollowData = d3.json(iosURL);
    // const getandroidURLAppFollowData = d3.json(androidURL);

    Promise.all([
      getDatafromDashboard,
      getDataFromRatings,
      getDataFromStaticRatings,
      // getAmazonAppFollowData,
      // getiosURLAppFollowData,
      // getandroidURLAppFollowData,
    ])
      .then(datas => {
        let lineData = datas[0].data.items.filter(d => {
          return d[1] === 'ratings';
        });

        let androidReviewsData = datas[1].data.androidReview.reviews.list.map(
          (val, i) => {
            return {
              device: 'android',
              rating: parseInt(val.rating),
              content: val.content,
              created: val.created,
              date: val.date,
              author: val.author,
            };
          },
        );

        let amazonReviewsData = datas[1].data.amazonReview.reviews.list.map(
          (val, i) => {
            return {
              device: 'amazon',
              rating: parseInt(val.rating),
              content: val.content,
              created: val.created,
              date: val.date,
              author: val.author,
            };
          },
        );

        const endDate = new Date('May 17, 2019').getTime();
        amazonReviewsData = amazonReviewsData.filter((d, i) => {
          return endDate < new Date(d.date).getTime();
        });

        let amazonReviewsDataStatic = datas[2].data.map(val => {
          return {
            device: 'amazon',
            rating: parseInt(val.rating),
            content: val.content,
            created: pareTimeDate(val.date),
            date: pareTimeDate(val.date),
            author: val.author,
          };
        });

        amazonReviewsData = amazonReviewsData.concat(amazonReviewsDataStatic);

        const iosReviewsData = datas[1].data.iosReview.reviews.list.map(
          (val, i) => {
            return {
              device: 'IOs',
              rating: parseInt(val.rating),
              content: val.content,
              created: val.created,
              date: val.date,
              author: val.author,
            };
          },
        );

        const allReviewsData = androidReviewsData.concat(
          amazonReviewsData,
          iosReviewsData,
        );

        // datas[1].reviews.list = datas[1].reviews.list.map((review, i) => {
        //   return { ...review, ...{ ext_id: 'B07GVPQG3Y' } };
        // });
        //
        // datas[2].reviews.list = datas[2].reviews.list.map((review, i) => {
        //   return { ...review, ...{ ext_id: '1086207508' } };
        // });
        //
        // datas[3].reviews.list = datas[3].reviews.list.map((review, i) => {
        //   return { ...review, ...{ ext_id: 'com.plumewifi.plume' } };
        // });
        //
        // const reviewsData = datas[1].reviews.list.concat(
        //   datas[2].reviews.list,
        //   datas[3].reviews.list,
        // );

        lineData = lineData.map((d, i) => {
          const date = new Date(formatDate(d[0]));
          //date.setDate(date.getDate() + 1);

          return {
            date: date,
            device: d[3],
            rating: parseFloat(d[2]),
            grain: d[4],
          };
        });

        const formattingMap = {
          android: 0,
          ios: 1,
          amazon: 2,
          total: 3,
        };

        lineData = lineData.filter((d, i) => {
          return (
            new Date('Wed May 01 2019 00:00:00 GMT-0700').getTime() >
              new Date(d.date).getTime() || d.grain === 'daily'
          );
        });

        //for the first of the month there are several data that overlap daily quarterly monthly
        // to creat smoothing I filter out all data that is not monthly for the first of the month

        lineData = lineData.filter(el => {
          if (new Date(el.date).getDate() === 1 && el.grain !== 'month') {
            return false;
          }
          return true;
        });

        const formattedLineData = [[], [], [], []];

        lineData.forEach((d, i) => {
          formattedLineData[formattingMap[d.device]].push({
            date: d.date,
            rating: d.rating,
            device: d.device,
          });
        });

        return [allReviewsData, formattedLineData];
      })
      .then(parsedData => {
        dispatch(lineDataFetched(parsedData[1]));
        dispatch(ratingsDataFetched(parsedData[0]));
      });
  };
}

export function filterDevice(device) {
  return dispatch => {
    //remove all devices
    dispatch(filterDeviceAction('blank'));
    setTimeout(function() {
      dispatch(filterDeviceAction(device));
    }, 10);
  };
}

// this function formats dates to look like this //"2019-05-15"
const pareTimeDate = date => {
  date = new Date(date);
  const parseDate = d3.timeFormat('%Y-%m-%d');
  return parseDate(date);
};
