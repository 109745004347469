import { SET_BUBBLE_DATE } from '../actions/bubbleChart_actions';

const INIT_STATE = {
  date: null,
};

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case SET_BUBBLE_DATE:
      return { ...state, ...{ date: action.date } };
    default:
      return state;
  }
}
