export const MENU_ON = 'MENU_ON';
export const MENU_OFF = 'MENU_OFF';
export const MENU_ON_OFF = 'MENU_ON_OFF';

export function menuOff() {
  return {
    type: MENU_OFF,
  };
}

export function menuOn() {
  return {
    type: MENU_ON,
  };
}

export function menuOnOff() {
  return {
    type: MENU_ON_OFF,
  };
}
