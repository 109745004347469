//when creating dates a timestamp '2019-07-16' returns a date that is for Mon Jul 15 2019 17:00:00 GMT-0700 (Pacific Daylight Time)
//this function takes that day and parses so that the ui shows the correct data
function parseDate(input) {
  if (input === undefined) {
    return;
  }
  var parts = input.match(/(\d+)/g);
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
}

export default parseDate;
