import * as d3 from 'd3';
import Token from '../components/okta/Token';
import { API_URL } from '../utils/constants';
import dw from '../components/helpers/d3/utils/Data_Wrangle.js';
import { changeDeploymentEnvironments } from './filter_actions';

import {
  filterEnvironments,
  turnEnvironmentsOff,
} from './filter_chart_actions';

export const CHURN_DATA_FETCHED = 'CHURN_DATA_FETCHED';
export const FETCHING_CHURN_DATA = 'FETCHING_CHURN_DATA';
export const SET_MAX_RADIUS_VALUE = 'SET_MAX_RADIUS_VALUE';
export const SET_RADIUS_VARIABLE = 'SET_RADIUS_VARIABLE';
export const SET_CHURN_ENVIRONMENTS = 'SET_CHURN_ENVIRONMENTS';
export const TURN_CHURN_ENVIRONMENTS_FALSE = 'TURN_CHURN_ENVIRONMENTS_FALSE';
export const TURN_CHURN_ENVIRONMENTS_TRUE = 'TURN_CHURN_ENVIRONMENTS_TRUE';

function churnDataFetched(data) {
  return {
    type: CHURN_DATA_FETCHED,
    data,
  };
}

function setMaxRadiusValue(value) {
  return {
    type: SET_MAX_RADIUS_VALUE,
    value,
  };
}
function setRadiusVariable(variable) {
  return {
    type: SET_RADIUS_VARIABLE,
    variable,
  };
}

function setChurnEnvironments(env) {
  return {
    type: SET_CHURN_ENVIRONMENTS,
    env,
  };
}

function turnChurnEnvironmentsFalse() {
  return {
    type: TURN_CHURN_ENVIRONMENTS_FALSE,
  };
}

function turnChurnEnvironmentsTrue() {
  return {
    type: TURN_CHURN_ENVIRONMENTS_TRUE,
  };
}
//thunk

const fetchData = () => {
  const oktaToken = new Token(localStorage['okta-token-storage']);
  const token = 'Bearer ' + oktaToken.accessToken;
  return function(dispatch, getState) {
    const dataApi = `${API_URL}/data/dashboard`;

    d3.json(`${dataApi}`, {
      headers: { Authorization: token },
    }).then(data => {
      const filter_data = data.data.items.filter(val => {
        return val[4] === 'month' && val[1] === 'churn';
      });

      //these are joins for the data
      //this is not abstracted becuase it is really unique
      let groupedByDeployment = d3
        .nest()
        .key(d => d[3])
        .entries(filter_data);

      let house_holds = dw.filter(
        data.data.items,
        val => val[4] === 'month' && val[1] === 'activehh',
      );

      let house_holds_grouped = d3
        .nest()
        .key(d => d[3])
        .entries(house_holds);

      let new_households = dw.filter(
        data.data.items,
        val => val[4] === 'month' && val[1] === 'newhh',
      );

      let new_households_grouped = d3
        .nest()
        .key(d => d[3])
        .entries(new_households);

      let new_housolds_underContract = dw.filter(
        data.data.items,
        val => val[4] === 'month' && val[1] === 'householdsundercontract',
      );

      let new_housolds_underContract_grouped = d3
        .nest()
        .key(d => d[3])
        .entries(new_housolds_underContract);

      //join households to deployments this will be done through a mapStateToProps
      //maps are immutable so I made two
      var groupedMap = d3.map();
      var new_households_map = d3.map();
      var new_housolds_underContract_map = d3.map();
      var households_map = d3.map();

      groupedByDeployment.forEach(val => {
        val.values.forEach(jal => {
          groupedMap.set(val.key + ' ' + jal[0], jal);
        });
      });

      house_holds_grouped.forEach(val => {
        val.values.forEach(jal => {
          var key = val.key + ' ' + jal[0];
          if (groupedMap.get(key) !== undefined) {
            households_map.set(val.key + ' ' + jal[0], jal[2]);
          }
        });
      });

      new_households_grouped.forEach(val => {
        val.values.forEach(jal => {
          var key = val.key + ' ' + jal[0];
          if (groupedMap.get(key) !== undefined) {
            new_households_map.set(val.key + ' ' + jal[0], jal[2]);
          }
        });
      });

      new_housolds_underContract_grouped.forEach(val => {
        val.values.forEach(jal => {
          var key = val.key + ' ' + jal[0];
          if (groupedMap.get(key) !== undefined) {
            new_housolds_underContract_map.set(val.key + ' ' + jal[0], jal[2]);
          }
        });
      });

      groupedByDeployment.forEach(val => {
        val.values = val.values.map(jal => {
          var key = val.key + ' ' + jal[0];

          const house_holds =
            households_map.get(key) === undefined ? 0 : households_map.get(key);
          const new_households =
            new_households_map.get(key) === undefined
              ? 0
              : new_households_map.get(key);
          const new_housolds_underContract =
            new_housolds_underContract_map.get(key) === undefined
              ? 0
              : new_housolds_underContract_map.get(key);

          return groupedMap
            .get(key)
            .concat([house_holds, new_households, new_housolds_underContract]);
        });
      });

      groupedByDeployment.sort(function(a, b) {
        var nameA = a.key.toUpperCase(); // ignore upper and lowercase
        var nameB = b.key.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      const groupedByDeployment_environments = groupedByDeployment.map(val => {
        return { name: val.key, on: true };
      });

      dispatch(
        changeDeploymentEnvironments({
          environments: groupedByDeployment_environments,
        }),
      );
      dispatch(setChurnEnvironments(groupedByDeployment_environments));
      dispatch(setMaxRadiusValue(false));
      dispatch(setRadiusVariable('active households'));
      dispatch(churnDataFetched(groupedByDeployment));
    });
  };
};

//this is a thunk that blinks (it gets rid of all the churn visualizations and then adds back the ones that are needed)
//this is done through unmounting and remounting the charts so that they redraw

function filterChurn(key) {
  return function(dispatch, getState) {
    //first change filter environmnets
    dispatch(filterEnvironments(key));

    //get current environments
    const env = getState().filters.environments;

    //unmount all the environments
    dispatch(turnChurnEnvironmentsFalse());
    //remount all the environments
    setTimeout(() => {
      dispatch(setChurnEnvironments(env));
    }, 5);
  };
}

function turnAllChurnOff() {
  return function(dispatch, getState) {
    dispatch(turnChurnEnvironmentsFalse());
    dispatch(turnEnvironmentsOff());

    //first change filter environmnets
  };
}

function resizeChurnOn() {
  return function(dispatch, getState) {
    const env = getState().filters.environments;

    dispatch(turnChurnEnvironmentsFalse());

    setTimeout(() => {
      dispatch(setChurnEnvironments(env));
    }, 5);
  };
}

export {
  fetchData,
  setMaxRadiusValue,
  setRadiusVariable,
  filterChurn,
  turnAllChurnOff,
  resizeChurnOn,
  turnChurnEnvironmentsTrue,
};
