import * as d3 from 'd3';

const xFormatMap = {
  daily: d3.timeFormat('%m/%d'),
  month: d3.timeFormat('%m/%y'),
  qtr: d3.timeFormat('%m/%y'),
  year: d3.timeFormat('%Y'),
  ttm: d3.timeFormat('%m/%y'),
  alltime: d3.timeFormat('%m/%Y'),
};

// const xAxisTickShow = {
//   daily: 5,
//   month: 1,
// };

export const formatXAxis = grain => {
  return xFormatMap[grain];
};

export const formatYAxis = tick => {
  if (parseFloat(tick) === 0) {
    return tick;
  }
  if (Math.abs(tick) < 1) {
    return d3.format('.2f')(tick);
  }
  return d3.format('.2s')(tick);
};

export const formatRectWidth = (d, height, xScale) => {
  const width = Math.min(xScale.bandwidth(), height, 25);
  return width;
};

export const formatRectPosition = (d, width, xScale) => {
  const position = xScale(d.ts);
  const offset = (xScale.bandwidth() - width) / 2;
  return position + offset;
};

export const formatHeight = (d, yScale) => {
  return Math.abs(yScale(d[0]) - yScale(d[1]));
};

export const formatRX = width => {
  return width / 2;
};

export const formatY = (d, yScale) => {
  if (d[1] > 0) return yScale(d[1]);
  return yScale(0);
};

export const correctDateOffset = dateString => {
  const date = new Date(dateString);
  return new Date(date.getTime() - date.getTimezoneOffset() * -60000);
};
