import React, { Component } from 'react';
import * as d3 from 'd3';
import colors from './helpers/colors';
import dw from '../../helpers/d3/utils/Data_Wrangle';
//import { annotationCalloutElbow, annotation } from 'd3-svg-annotation';
import { pieData } from './helpers/pieData';

import ToolTip from './ToolTip';

class DonutChart extends Component {
  constructor(props) {
    super(props);

    this.margin = { l: 20, t: 0, r: 20, b: 20 };
    this.createPieData();
    this.createPieShape();
    this.createColorScale();
    this.state = {
      tooltip: {
        show: false,
        top: null,
        left: null,
        title: null,
        body: null,
      },
    };
  }

  createColorScale = () => {
    this.colors = d3.map();

    [{ name: '' }].concat(this.pieData).forEach((val, i) => {
      this.colors.set(val.name, colors[i]);
    });
  };

  createPieData() {
    const filtered_data = dw.filter(
      this.props.data,
      d => d.value >= this.props.minLeafValue,
    );

    const category =
      this.props.level === 0
        ? 'category'
        : this.props.level === 1
        ? 'brand'
        : 'idtype';
    const pieDataKeys =
      this.props.level === 0
        ? ['category', 'brand', 'idtype']
        : this.props.level === 1
        ? ['brand', 'idtype']
        : ['idtype'];

    this.pieData = pieData(filtered_data, category, pieDataKeys);

    const paddingAngle = this.props.topNode ? 0.01 : 0.0000001;

    const pie = d3
      .pie()
      .padAngle(paddingAngle)
      .sort((a, b) => a.value - b.value)
      .value(d => d.value);

    const arcs = pie(this.pieData);
    this.arcs = arcs;
  }

  createPieShape = () => {
    const dimension = Math.min(this.props.width, this.props.height);
    this.arc = d3
      .arc()
      .innerRadius(dimension / 2 - (this.margin.l + this.margin.r) - 60)
      .outerRadius(dimension / 2 - (this.margin.l + this.margin.r));
  };

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidMount() {
    this.annotations();
  }

  annotations = () => {
    d3.selectAll('.donut-chart-text').remove();
    const totalDevices = d3.sum(this.arcs, d => d.data.value);

    this.totalDevices = totalDevices;

    this.g = d3.select('.donut-chart-trans-cont').select('.title-group');
    const text = this.g.selectAll('.donut-chart-text').data(this.arcs);

    text.exit().remove();

    text
      .enter()
      .append('text')
      .attr('class', 'donut-chart-text')
      .attr('x', d => this.arc.centroid(d)[0] - 6)
      .attr('y', d => this.arc.centroid(d)[1])
      .attr('fill', 'black')
      //.attr('stroke','black')
      .style('font-size', '12px')
      .text(d =>
        d.data.value / totalDevices >= 0.025
          ? d3.format('.0%')(d.data.value / totalDevices)
          : '',
      );
  };

  handleMouseoverPath = (e, d) => {
    const title = d.data.name === '' ? 'Unknown' : d.data.name;
    const body =
      'percent of devices shown ' +
      d3.format('.0%')(d.data.value / this.totalDevices);

    const left = e.pageX - 10;
    let top = e.pageY - 15;

    top = top + 200 > window.innerHeight ? window.innerHeight - 200 : top;

    this.setState({
      tooltip: {
        show: true,
        top: top,
        left: left,
        title: title,
        body: body,
      },
    });
  };

  handleMouseLeavePath = (e, d) => {
    this.setState({
      tooltip: {
        show: false,
        top: null,
        left: null,
        title: null,
        body: null,
      },
    });
  };

  render() {
    this.createPieData();
    this.annotations();

    const arcs = this.arcs.map(arc => {
      return (
        <path
          className="donut-paths"
          strokeWidth={'0.75px'}
          stroke={'white'}
          d={this.arc(arc)}
          fill={
            this.props.topNode !== true
              ? this.colors.get(this.props.currentcategory)
              : this.colors.get(arc.data.name)
          }
          onMouseEnter={e => {
            this.handleMouseoverPath(e, arc);
          }}
          onMouseLeave={e => {
            this.handleMouseLeavePath(e, arc);
          }}
        />
      );
    });

    return (
      <div className="main-donut-chart">
        <svg
          className="donut-chart-annotation"
          width={this.props.width}
          height={this.props.height}
        >
          <g transform={`translate(${0},${0})`}>
            <g
              className="donut-chart-trans-cont"
              transform={`translate(${this.props.width / 2},${this.props
                .height / 2})`}
            >
              {arcs}
              <g className="title-group" />
            </g>
          </g>
        </svg>
        <ToolTip
          show={this.state.tooltip.show}
          top={this.state.tooltip.top}
          left={this.state.tooltip.left}
          title={this.state.tooltip.title}
          body={this.state.tooltip.body}
        />
      </div>
    );
  }
}

export default DonutChart;
