import React, { useEffect, useRef, useCallback } from 'react';
import D3LoadingSpinner from '../helpers/d3/loadingSpinner';

const PlumeSpinner = () => {
  const spinner = useRef(new D3LoadingSpinner({}));
  const measuredRef = useCallback(node => {
    if (node !== null) {
      spinner.current.create(node);
    }
  }, []);

  useEffect(() => {
    return () => {
      spinner.current.destroy();
    };
  }, null);

  return <div ref={measuredRef} className="KPI-plume-spinner" />;
};

export default PlumeSpinner;
