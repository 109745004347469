import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './device_styles.scss';

import D3DevicesBubbles from '../../components/helpers/d3/devicesBubbles';
import { API_URL } from '../../config';
import { logoutUser } from '../../actions/auth_actions';
import { changeFilters } from '../../actions/filter_actions';
import { setBubbleDate } from '../../actions/bubbleChart_actions';
import { getVizFilters } from '../../utils/helper';

class DevicesBubbles extends Component {
  constructor(props) {
    super(props);

    this.d3Instance = new D3DevicesBubbles({
      dataApi: `${API_URL}/data/devices-bubbles`,
      logoutUser: this.props.logoutUser,
      filters: getVizFilters(this.props.filters, this.props.match.params),
      changeBubbleDate: this.props.bubbleChart,
    });
  }
  componentDidMount() {
    this.d3Instance.create(this._rootNode);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div
        className="main-container flex-parent"
        id="devices-bubbles"
        ref={c => {
          this._rootNode = c;
        }}
      >
        {/* <svg className="spinner" id="spinner" /> */}

        <svg id="devices">
          <defs>
            <radialGradient
              id="circleGradient"
              cx="50%"
              cy="50%"
              r="50%"
              fx="50%"
              fy="50%"
            >
              <stop
                offset="0%"
                style={{ stopColor: 'hsla(196, 80%, 80%, 0.2)' }}
              />
              <stop
                offset="100%"
                style={{ stopColor: 'hsla(196, 80%, 80%, 1)' }}
              />
            </radialGradient>
          </defs>
        </svg>

        <div id="stats">
          <div id="totalClients" className="stat" />
          <div id="percentDevicesIdentified" className="stat" />
          <div id="deviceTypes" className="stat" />
          <div id="deviceBrands" className="stat" />
        </div>
        <div className="title-for-devices">Gamma Devices Bubble Chart</div>
        <style type="text/css">{`.menuFullContainer{display: none;} .infoContent{font-size:12px; line-height:16px; background:rgba(0,0,0,.99)}  .info-content{width:300px; right:40px; font-size:18px} .DB-info-tiext{color:rgb(210,210,210)}
             #info-popup .info-content h4{font-size:18px}
          `}</style>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.filters,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeFilters: () => {
      dispatch(changeFilters());
    },
    logoutUser: () => {
      dispatch(logoutUser());
    },
    bubbleChart: date => {
      dispatch(setBubbleDate(date));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DevicesBubbles),
);
