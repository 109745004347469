import React, { Component } from 'react';
import * as d3 from 'd3';
import './peopleChordToolStyles.scss';

class PeopleChordTool extends Component {
  constructor(props) {
    super(props);
    this.barChartOne = this.barChartOne.bind(this);
    this.barChartTwo = this.barChartTwo.bind(this);
  }

  componentDidMount() {
    //first barChart

    this.barChartOne(this._barOneNode);
    this.barChartTwo(this._barTwoNode);
  }

  barChartOne(el) {
    const margin = { l: 28, t: 10, r: 10, b: 20 };
    const width = 200 - (margin.l + margin.r);
    const height = 200 - (margin.t + margin.b);
    const title = this.props.data.title;

    const svg = d3
      .select(el)
      .append('svg')
      .attr('width', width + (margin.l + margin.r))
      .attr('height', height + (margin.t + margin.b));
    const g = svg
      .append('g')
      .attr('width', width)
      .attr('height', height)
      .attr('transform', `translate(${margin.l},${margin.t})`);

    //scales
    const yScale = d3
      .scaleLinear()
      .domain([0, this.props.data.totalEmployees])
      .nice()
      .range([height, 0])
      .nice();

    const xScale = d3
      .scaleBand()
      .domain(['total', title])
      .range([0, width])
      .round(true)
      .paddingOuter(0.2)
      .paddingInner(0.2);

    //axis
    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale).ticks(5);

    g.append('g')
      .attr('class', 'x-total-axis')
      .attr('transform', 'translate(' + 0 + ',' + height + ')')
      .call(xAxis);

    g.append('g')
      .attr('class', 'y-total-axis')
      .call(yAxis);

    const data = [
      { title: 'total', value: this.props.data.totalEmployees },
      { title: title, value: this.props.data.value },
    ];

    g.append('g')
      .selectAll('tool-total-bars')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', d => {
        return xScale(d.title);
      })
      .attr('width', d => {
        return xScale.bandwidth();
      })
      .attr('y', d => {
        return yScale(d.value);
      })
      .attr('height', d => {
        return height - yScale(d.value);
      })
      .attr('fill', 'black');
  }

  barChartTwo(el) {
    const margin = { l: 28, t: 10, r: 10, b: 20 };
    const width = 200 - (margin.l + margin.r);
    const height = 200 - (margin.t + margin.b);

    const svg = d3
      .select(el)
      .append('svg')
      .attr('width', width + (margin.l + margin.r))
      .attr('height', height + (margin.t + margin.b));
    const g = svg
      .append('g')
      .attr('width', width)
      .attr('height', height)
      .attr('transform', `translate(${margin.l},${margin.t})`);

    //scales
    let bar_data = this.processBarChartTwoData(this.props.rawData);
    bar_data.sort((a, b) => b.value - a.value);
    const titles = bar_data.map((val, i) => {
      return val.title;
    });

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(bar_data, d => d.value)])
      .nice()
      .range([height, 0])
      .nice();

    const xScale = d3
      .scaleBand()
      .domain(titles)
      .range([0, width])
      .round(true)
      .paddingOuter(0.1)
      .paddingInner(0.1);

    //axis
    const xMap = createLabelMap();

    const xAxis = d3.axisBottom(xScale).tickFormat(t => {
      return this.props.left ? xMap.get(t) : t;
    });
    const yAxis = d3.axisLeft(yScale).ticks(5);

    g.append('g')
      .attr('class', 'x-total-axis')
      .attr('transform', 'translate(' + 0 + ',' + height + ')')
      .call(xAxis);

    g.append('g')
      .attr('class', 'y-total-axis')
      .call(yAxis);

    g.append('g')
      .selectAll('tool-total-bars')
      .data(bar_data)
      .enter()
      .append('rect')
      .attr('x', d => {
        return xScale(d.title);
      })
      .attr('width', d => {
        return xScale.bandwidth();
      })
      .attr('y', d => {
        return yScale(d.value);
      })
      .attr('height', d => {
        return height - yScale(d.value);
      })
      .attr('fill', 'black');
  }

  processBarChartTwoData(data) {
    var firstLevel = this.props.left ? 'Country' : 'Department';
    var secondLevel = this.props.left ? 'Department' : 'Country';

    const groupedData = d3
      .nest()
      .key(d => d[firstLevel])
      .key(d => d[secondLevel])
      .entries(data);

    const filtered_groupedData = groupedData.filter((val, i) => {
      return val.key === this.props.data.title;
    });

    let bar_data = [];

    filtered_groupedData[0].values.forEach((val, i) => {
      bar_data.push({ title: val.key, value: val.values.length });
    });

    return bar_data;
  }

  render() {
    const { x, y, data } = this.props;

    return (
      <div
        className="people-chord-tool"
        style={{
          left: x,
          top: y + 20 < 0 ? 0 : y + 20,
          zindex: 1010,
        }}
      >
        <div className="people-chord-tool-title">{data.title}</div>
        <div
          className="tool-barchart-one"
          ref={el => {
            this._barOneNode = el;
          }}
        />
        <div
          className="tool-barchart-two"
          ref={el => {
            this._barTwoNode = el;
          }}
        />
      </div>
    );
  }
}

function createLabelMap() {
  var map = d3
    .map()
    .set('Cloud engineer', 'CE')
    .set('Delivery team', 'DT')
    .set('QA', 'QA')
    .set('DevOps', 'DO')
    .set('Delivery Team', 'DT')
    .set('SW', 'SW')
    .set('Support & Operations', 'S&O')
    .set('S&M', 'S&M')
    .set('HW', 'HW')
    .set('R&D', 'R&D')
    .set('PLM', 'PLM')
    .set('G&A', 'G&A');
  return map;
}

export default PeopleChordTool;
