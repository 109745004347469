import dataSkeleton from '../components/Skeleton/dataSkeleton';

const getFromSegments = (metric, dataSkeleton) => {
  let colors;
  let name;
  let axisType;
  dataSkeleton.forEach(data => {
    data.segments.forEach(segment => {
      if (segment.csvName === metric) {
        colors = data.colors;
        name = segment.name;
        axisType = segment.axisType === undefined ? '' : segment.axisType;
      }
    });
  });

  return { colors: colors, name: name, axisType: axisType };
};

export const getVizFilters = (filters, urlParams) => {
  if (urlParams.metric === undefined) return filters;
  let skele = new dataSkeleton();

  let segment = null;
  skele.dataSkeleton.forEach(el => {
    el.segments.forEach(el1 => {
      if (el1.name === filters.name) {
        segment = el1;
        return;
      }
    });
  });

  let graphType = undefined;

  if (segment)
    segment.links.forEach(el => {
      if (el.type === 'series') {
        graphType = el.options;
        return;
      }
    });

  let newFilters = filters;
  let segments = getFromSegments(urlParams.metric, skele.dataSkeleton);

  newFilters.metric = urlParams.metric;
  newFilters.grain = filters.grain;
  newFilters.timestamp = urlParams.timestamp;
  newFilters.colors = segments.colors;
  newFilters.graphTypes = graphType;
  newFilters.name = segments.name;
  newFilters.axisType = segments.axisType;
  return newFilters;
};
