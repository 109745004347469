const colorMap = {
  Scale: 'rgb(255,85,51)',
  Households: 'rgb(255,238,51)',
  Revenue: 'rgb(119,255,51)',
  Profitability: 'rgb(51,255,136)',
  Operations: 'rgb(51, 221, 255)',
  'Customer Experience': 'rgb(51, 68, 255)',
  "SaaS EMCLOUD Index Metrics": 'rgb(255,185,5)',
  People: 'rgb(187, 51, 255)',
};

export { colorMap };
