import React from 'react';
import PropTypes from 'prop-types';

const ChartLegendItem = ({ name, color }) => (
  <div classname="KPI-legend-item">
    <span className="KPI-legend-name">{name}</span>
    <span
      style={{ backgroundColor: color(name) }}
      className="KPI-legend-item"
    />
  </div>
);

ChartLegendItem.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ChartLegendItem;
