import React, { Fragment, useEffect } from 'react';
import { selectWrangledData } from '../../selectors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { logoutUser } from '../../actions/auth_actions';
import { changeFilters } from '../../actions/filter_actions';
import { oktaGetMe } from '../../actions/me_actions';
import { getDashboardData } from '../../actions/dashboard_actions';
import DashBoardData from '../../components/DashBoardData/DashBoardData';

import BoxComponent from '../../components/BoxComponent';
import Live from '../../components/Live';
import { getFilteredData } from './helper';
import PlumeSpinner from '../../components/PlumeSpinner';

//import { getDateRanges } from '../../components/helpers/d3/utils/helpers.js';

// class Menu extends Component {
//   d3Instance = null;
//   d3Spinner = new D3LoadingSpinner({});
//   constructor(props) {
//     super(props);
//
//     this.timeOutFunction = null;
//   }
//
//   componentDidMount() {
//     const { oktaGetMe, getDashboardData } = this.props;
//     oktaGetMe();
//     try {
//       //  let intervalTime = 60 * 1000 * 3.3;
//       getDashboardData();
//       // this.timeOutFunction = setInterval(() => {
//       //   getDashboardData();
//       //   // eslint-disable-next-line
//       //   console.log('=====> firing',intervalTime)
//       // }, intervalTime);
//     } catch (e) {
//       // eslint-disable-next-line
//       console.error(e);
//     }
//
//     if (!window.spinner) {
//       this.d3Spinner.create('body');
//       const { d3Spinner } = this;
//       window.spinner = d3Spinner;
//     }
//   }
//
//   componentDidUpdate() {
//     const {
//       dashboardData,
//       filters,
//       changeFilters,
//       loading,
//       error,
//     } = this.props;
//
//     const f = localStorage.getItem('filters');
//
//     if (dashboardData && !filters.dateRanges && !f) {
//       const data = [];
//       dashboardData.data.items.forEach(item => {
//         const obj = {};
//         dashboardData.data.map.forEach((key, i) => {
//           obj[key] = item[i];
//         });
//         data.push(obj);
//       });
//       const dateRanges = getDateRanges(data, {});
//       changeFilters({ dateRanges });
//     }
//     if (error && !loading) {
//       new D3NotificationPopup({
//         title: 'Error while fetching data!',
//         message: `Please try again later`,
//         type: 'error',
//       }).create(this._rootNode);
//     }
//     return false;
//   }
//
//   componentWillUnmount() {
//     clearTimeout(this.timeOutFunction);
//   }
//
//   render() {
//     const {
//       filters: { grain, dateRanges },
//       loading,
//       dashboardData,
//       filters,
//       history,
//     } = this.props;
//     const { spinner } = window;
//     if (loading && !dashboardData && !dateRanges) {
//       spinner.init();
//       return null;
//     }
//     if (spinner && !loading) {
//       spinner.finish();
//     }
//
//     let data = [];
//     if (dateRanges && grain) {
//       data = getFilteredData(grain, dateRanges, dashboardData, filters);
//     }
//     return (
//       <Fragment>
//         <Live history={history} changeFilters={this.props.changeFilters} />
//         <div
//           className="main-container flex"
//           id="dataviz-menu"
//           data-testid="dataviz-menu"
//         >
//           <div id="menuV1">
//             {data &&
//               data.map((item, index) => {
//                 return (
//                   <BoxComponent
//                     key={index}
//                     data={item}
//                     filters={filters}
//                     history={history}
//                     changeFilters={this.props.changeFilters}
//                   />
//                 );
//               })}
//           </div>
//         </div>
//       </Fragment>
//     );
//   }
// }

const Menu = ({
  dashboardData,
  dataMap,
  getDashboardData,
  history,
  data,
  filters,
  rawData,
  changeFilters,
  loading,
  error,
}) => {
  if (filters.dateRanges && filters.grain && rawData !== null) {
    let rawFormattedData = {};
    rawFormattedData.data = {};
    rawFormattedData.data.items = new DashBoardData({
      data: rawData,
      dataMap: dataMap,
    });
    rawFormattedData.data.map = dataMap;
    let formattedData = new DashBoardData({ data: rawData, dataMap: dataMap });
    data = getFilteredData(
      filters.grain,
      filters.dateRanges,
      formattedData.wrangledData,
      filters,
    );
  }
  useEffect(
    () => {
      getDashboardData();
    },
    [null],
  );

  if (error && !loading) {
    return <div className="headerMenu">API/data error</div>;
  }

  if (dashboardData === null) {
    return <PlumeSpinner />;
  }

  return (
    <Fragment>
      <Live history={history} changeFilters={changeFilters} />
      <div
        className="main-container flex"
        id="dataviz-menu"
        data-testid="dataviz-menu"
      >
        <div id="menuV1">
          {data &&
            data.map((item, index) => {
              return (
                <BoxComponent
                  key={index}
                  data={item}
                  filters={filters}
                  history={history}
                  changeFilters={changeFilters}
                />
              );
            })}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    filters: state.filters,
    dashboardData:
      state.dashboard.dashboardData === null ? null : selectWrangledData(state),
    loading: state.dashboard.loading,
    error:
      state.dashboard.error ||
      (state.dashboard.dashboardData === null && !state.dashboard.loading),
    rawData: state.dashboard.dashboardData,
    dataMap: state.dashboard.dataMap,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { changeFilters, logoutUser, oktaGetMe, getDashboardData },
  )(Menu),
);
