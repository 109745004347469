import React, { Component } from 'react';
import * as d3 from 'd3';
import './filterStyles.scss';
import ChangeXYCont from './ChangeXY';
import './changeXYStyles.scss';

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      top: this.props.top === undefined ? 70 : this.props.top,
      right: this.props.right === undefined ? 130 : this.props.right,
      moving: false,
      startX: null,
      startY: null,
      startRight: null,
      startTop: null,
    };
    this.handleIconExitClick = this.handleIconExitClick.bind(this);
  }
  handleOnChange = (ev, value) => {
    this.props.environmentsFilter(value);
  };

  handleIconExitClick() {
    this.props.toolOnOff();
  }

  handleToolFilterDwon = ev => {
    let eventX = ev.pageX;
    let eventY = ev.pageY;
    this.setState(state => {
      return {
        ...this.state,
        ...{
          moving: true,
          startX: eventX,
          startY: eventY,
          startRight: state.right,
          startTop: state.top,
        },
      };
    });

    window.addEventListener('mousemove', this.handfleFilterMove);
  };
  handfleFilterMove = ev => {
    if (!this.state.moving) {
      return false;
    }
    const mouseX = ev.pageX;
    const mouseY = ev.pageY;

    const top = this.state.startTop + (mouseY - this.state.startY);
    const right = this.state.startRight + (this.state.startX - mouseX);

    this.setState({ ...this.state, ...{ right: right, top: top } });
  };
  handleToolFilterUp = ev => {
    window.removeEventListener('mousemove', this.handfleFilterMove);
    this.setState({ moving: false });
  };

  handleShowNone = () => {
    this.props.showNone();
  };

  componentDidMount() {}

  render() {
    this.z = d3
      .scaleSequential()
      .domain([0, this.props.options.length])
      .interpolator(
        d3.interpolateHsl(
          'hsla(' + this.props.colors.primaryHue + ', 100%, 50%, .7)',
          'hsla(' + this.props.colors.tertiaryHue1 + ', 100%, 50%, .7)',
        ),
      );

    const firstHalfOptions = this.props.options.slice(
      0,
      Math.ceil(this.props.options.length / 2),
    );
    const secondHalfOptions = this.props.options.slice(
      Math.ceil(this.props.options.length / 2),
      this.props.options.length,
    );

    const optionsOne = firstHalfOptions.map((value, i) => {
      return (
        <div
          className="filter-selection"
          onClick={ev => {
            this.handleOnChange(ev, value.name);
          }}
        >
          <div
            style={
              value.name === 'total'
                ? {
                    borderRadius: '100%',
                    backgroundColor: value.on ? '#6269ff' : 'grey',
                    display: 'inline-block',
                    width: '20px',
                    height: '20px',
                  }
                : {
                    borderRadius: '100%',
                    backgroundColor: value.on
                      ? this.props.thetaColorOn === true &&
                        value.name === 'theta'
                        ? this.props.thetaColor
                        : this.z(i)
                      : 'grey',
                    display: 'inline-block',
                    width: '20px',
                    height: '20px',
                  }
            }
            name={value.name}
            type="checkbox"
            checked={value.on}
            className="filter-selection-input"
            value={value.name}
          />
          <div style={{ display: 'inline-block' }}>{value.name}</div>
        </div>
      );
    });

    const optionsTwo = secondHalfOptions.map((value, i) => {
      return (
        <div
          className="filter-selection"
          onClick={ev => {
            this.handleOnChange(ev, value.name);
          }}
        >
          <div
            style={
              value.name === 'total'
                ? {
                    borderRadius: '100%',
                    backgroundColor: value.on ? '#6269ff' : 'grey',
                    display: 'inline-block',
                    width: '20px',
                    height: '20px',
                  }
                : {
                    borderRadius: '100%',
                    backgroundColor: value.on
                      ? this.props.thetaColorOn === true &&
                        value.name === 'theta'
                        ? this.props.thetaColor
                        : this.z(i + optionsOne.length)
                      : 'grey',
                    display: 'inline-block',
                    width: '20px',
                    height: '20px',
                  }
            }
            name={value.name}
            type="checkbox"
            checked={value.on}
            onChange={this.handleOnChange}
            className="filter-selection-input"
            value={value.name}
          />
          <div style={{ display: 'inline-block' }}>{value.name}</div>
        </div>
      );
    });

    const changeXY =
      this.props.axisLabels === null ||
      this.props.axisLabels === undefined ? null : (
        <ChangeXYCont axisLabels={this.props.axisLabels} />
      );

    return (
      <div
        style={
          this.state.moving
            ? {
                top: this.state.top,
                right: this.state.right,
                border: '1px solid darkblue',
              }
            : { top: this.state.top, right: this.state.right }
        }
        className={
          this.props.display
            ? 'filter-tool-container tool-visible'
            : 'filter-tool-container tool-hidden'
        }
      >
        <div
          onMouseDown={this.handleToolFilterDwon}
          onMouseUp={this.handleToolFilterUp}
          className="move-filter-icon"
        >
          move
        </div>
        <svg
          onClick={() => {
            this.handleIconExitClick();
          }}
          className="filter-exit-icon"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="250 180 512 128"
          style={{ enableBackground: 'new 0 0 256 128' }}
        >
          <path
            d="M512,32c-123.7,0-224,100.3-224,224c0,123.7,100.3,224,224,224c123.7,0,224-100.3,224-224C736,132.3,635.7,32,512,32L512,32
       L512,32z M512,450.1c-107,0-194.1-87.1-194.1-194.1C317.9,149,405,61.9,512,61.9c107,0,194.1,87.1,194.1,194.1
       C706.1,363,619,450.1,512,450.1L512,450.1L512,450.1z M534.4,255.5l69.2-68.4c6.3-6.2,6.3-16.3,0.1-22.6c-6.2-6.3-16.4-6.3-22.6-0.1
       l-69.3,68.5l-68.2-68.4c-6.3-6.3-16.4-6.3-22.6,0c-6.3,6.2-6.3,16.4,0,22.6l68.1,68.3l-68.7,68c-6.3,6.2-6.3,16.3-0.1,22.6
       c3.1,3.2,7.2,4.7,11.4,4.7c4.1,0,8.1-1.6,11.2-4.6l68.8-68l69.3,69.6c3.1,3.1,7.2,4.7,11.3,4.7c4.1,0,8.2-1.6,11.3-4.7
       c6.3-6.2,6.3-16.4,0-22.6L534.4,255.5L534.4,255.5z"
          />
        </svg>
        <div className="filter-title-environments">Filter Deployments</div>
        <div className="filter-selection-container">
          <div
            className={
              this.props.graphMode === 'series'
                ? 'hidden'
                : 'filter-selection-column show'
            }
          >
            This Chart currently Shows total values hence it has no Filters
          </div>
          <div
            className={
              this.props.graphMode === 'series'
                ? 'filter-selection-column show'
                : 'hidden'
            }
          >
            {optionsOne}
          </div>
          <div
            className={
              this.props.graphMode === 'series'
                ? 'filter-selection-column show'
                : 'hidden'
            }
          >
            {optionsTwo}
          </div>
        </div>
        {this.props.noneOption === true ? (
          <button
            onClick={() => this.handleShowNone()}
            className="filter-show-none"
          >
            {' '}
            Show None
          </button>
        ) : null}
        <div className="change-xy-container">{changeXY}</div>
      </div>
    );
  }
}

export default Filter;
