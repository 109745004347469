import * as d3 from 'd3';

class D3Number {
  constructor(options) {
    this.options = options;
    this.helloWorld = null;
    this.el = null;
  }

  /**
   * Create instance of D3.js component
   * @param el
   */
  create = el => {
    const { options } = this;
    this.helloWorld = d3.select(el);
    this.el = el;
    this.updateMsg(options.number, options.decPlaces);
  };

  /**
   * Update D3.js component
   * @param msg
   */
  updateMsg = (number, decPlaces) =>{
    
    d3.select(this.el)
      .transition()
      .duration(500)
      .on('start', function repeat() {
        d3.active(this)
          .tween('text', () => {
            const that = d3.select(this);
            let beforeNumber = that.text();
            if (beforeNumber === 'NaN' || beforeNumber ==='n/a') {
              beforeNumber = '0';
            }
            const i = d3.interpolateNumber(beforeNumber, number);
            return function(t) {
              let newVal = parseFloat(i(t)).toFixed(decPlaces);

              let temporalText = i(t) === 'n/a' || isNaN(i(t))  ? 'n/a' : newVal;
              that.text(temporalText);
            };
          })
          .transition();
      });
  };

  /**
   * Cleaning code...
   */
  destroy = () => {
    this.helloWorld && this.helloWorld.remove();
  };
}

export default D3Number;
