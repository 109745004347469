import * as d3 from 'd3';

function wrap(text, width) {
  text.each(function() {
    var text = d3.select(this),
      words = text
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      y = text.attr('y'),
      dy = parseFloat(text.attr('dy')),
      tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 40)
        .attr('y', y)
        .attr('dy', width > 100 ? 0 : -10);

    var i = 0;
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(' '));

      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        if (i === 0) {
          dy = -10;
        }
        if (i === 1) {
          dy = 10;
        }
        tspan = text
          .append('tspan')
          .attr('x', 40)
          .attr('y', 5)
          .attr('dy', +dy)
          .text(word);
      }
      i += 1;
    }
  });
}

export { wrap };
