export const TURN_FILTER_ON_OFF = 'TURN_FILTER_ON_OFF';
export const FILTER_ENVIRONMENTS = 'FILTER_ENVIRONMENTS';
export const RESET_ENVIRONMENTS = 'RESET_ENVIRONMENTS';
export const TURN_FILTER_OFF = 'TURN_FILTER_OFF';
export const TURN_ENVIRONMENTS_OFF = 'TURN_ENVIRONMENTS_OFF';

export function turnFilterOnOff() {
  return {
    type: TURN_FILTER_ON_OFF,
  };
}

export function filterEnvironments(env) {
  return {
    env,
    type: FILTER_ENVIRONMENTS,
  };
}

export function resetEnvironments() {
  return {
    type: RESET_ENVIRONMENTS,
  };
}

export function turnFilterOff() {
  return {
    type: TURN_FILTER_OFF,
  };
}

export function turnEnvironmentsOff() {
  return {
    type: TURN_ENVIRONMENTS_OFF,
  };
}
