import React from 'react';
import ReactDOM from 'react-dom';
import ReduxThunk from 'redux-thunk';
import ReduxPromise from 'redux-promise';
import { Provider } from 'react-redux';

import { createStore, applyMiddleware } from 'redux';
import createDebounce from 'redux-debounced';

import './utils/axios';
import './index.css';
import './styles/manifest.scss';
import reducers from './reducers';

import App from './containers/app/App';

const createStoreWithMiddleware = applyMiddleware(
  ReduxThunk,
  ReduxPromise,
  createDebounce(),
)(createStore);
const store = createStoreWithMiddleware(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement,
);
