import React, { Component } from 'react';
import * as d3 from 'd3';
import { wrap } from '../../../helpers/wrap';

class RatingsMatchLegend extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setup();
    this.drawReviewChart();
    this.createLinearScale();
    this.createLineGenerator();
    this.drawRatingsChart();
  }
  setup() {
    this.innerRadius = 30;
    this.svg = d3
      .select(this._rootNode)
      .append('svg')
      .attr('height', '300px')
      .attr('width', this.props.width - 15);

    this.circleData = [0, 1, 2, 3];
    this.axisRadiusSize = [
      this.innerRadius,
      this.innerRadius / 1.75,
      this.innerRadius / 5,
    ];

    this.margin = { l: 25, t: 25, r: 25, b: 25 };

    this.translateX = parseInt(this.innerRadius / 2) + this.margin.l;
    this.translateY = parseInt(this.innerRadius / 2) + this.margin.t;
  }

  drawReviewChart() {
    this.circleG = this.svg
      .append('g')
      .attr(
        'transform',
        'translate(' + this.translateX + ',' + this.translateY + ')',
      );
    this.circleG
      .append('circle')
      .attr('r', this.axisRadiusSize[0])
      .attr('stroke-width', '1px')
      .attr('opacity', '0.40')
      .attr('stroke', 'lightgrey');

    this.circleG
      .append('circle')
      .attr('r', this.axisRadiusSize[1])
      .attr('stroke-width', '1px')
      .attr('opacity', '0.40')
      .attr('stroke', 'lightgrey');

    this.circleG
      .append('circle')
      .attr('r', this.axisRadiusSize[2])
      .attr('stroke-width', '1px')
      .attr('opacity', '0.40')
      .attr('stroke', 'lightgrey');

    const legendCircleFillColors = [
      '#f08804',
      'rgb(0,122,255)',
      '#A4C639',
      '#f08804',
    ];

    this.circleG
      .selectAll('.circle-filled-legend')
      .data(this.circleData)
      .enter()
      .append('circle')
      .attr('class', 'circle-filled-legend')
      .attr('r', '4')
      .attr('fill', (d, i) => {
        return legendCircleFillColors[i];
      })
      .attr('cy', (d, i) => {
        if (i < 3) {
          return this.axisRadiusSize[i];
        }
        return this.axisRadiusSize[1];
      })
      .attr('transform', (d, i) => {
        return `rotate(${90 * i})`;
      });

    this.reviewTextG = this.svg
      .append('g')
      .attr(
        'transform',
        'translate(' + this.translateX + ',' + this.translateY + ')',
      );

    var textWidth = 1000;

    if (window.innerWidth < 1470) {
      textWidth = 100;
    }

    this.reviewTextG
      .append('text')
      .attr('fill', 'white')
      .attr('font-size', '20')
      .attr('y', '5')
      .attr('x', this.innerRadius + 10)
      .text('Written Reviews')
      .call(wrap, textWidth);
    //.attr()
  }
  createLinearScale() {
    this.timeScale = d3
      .scaleTime()
      .domain([
        this.props.dateAxis[0],
        d3.timeYear.offset(this.props.dateAxis[0], 1),
      ])
      .range([0, Math.PI * 2]);

    this.radialScale = d3
      .scaleLinear()
      .domain([0, 5])
      .range([this.axisRadiusSize[2], this.axisRadiusSize[0]]);
  }

  createLineGenerator() {
    this.timeScale = d3
      .scaleTime()
      .domain([
        this.props.dateAxis[0],
        d3.timeYear.offset(this.props.dateAxis[0], 1),
      ])
      .range([0, Math.PI * 2]);

    this.radialScale = d3
      .scaleLinear()
      .domain([0, 5])
      .range([this.axisRadiusSize[2], this.axisRadiusSize[0]]);

    const lineGenerator = d3
      .lineRadial()
      .angle(d => this.timeScale(d.date))
      .radius(d => this.radialScale(d.rating))
      .curve(d3.curveCatmullRom.alpha(0.8));

    this.lineGenerator = lineGenerator;
  }

  drawRatingsChart() {
    this.circleRatingG = this.svg
      .append('g')
      .attr(
        'transform',
        'translate(' + this.translateX + ',' + (+this.translateY + 80) + ')',
      );

    this.circleRatingG
      .append('circle')
      .attr('r', this.axisRadiusSize[0])
      .attr('stroke-width', '1px')
      .attr('opacity', '0.40')
      .attr('stroke', 'lightgrey');

    this.circleRatingG
      .append('circle')
      .attr('r', this.axisRadiusSize[1])
      .attr('stroke-width', '1px')
      .attr('opacity', '0.40')
      .attr('stroke', 'lightgrey');

    this.circleRatingG
      .append('circle')
      .attr('r', this.axisRadiusSize[2])
      .attr('stroke-width', '1px')
      .attr('opacity', '0.40')
      .attr('stroke', 'lightgrey');

    this.circleRatingG
      .selectAll('.legend-line-paths')
      .data(this.props.lineData)
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('class', (d, i) => {
        return d[0].device.toUpperCase() + '-class';
      })
      .attr('stroke-width', '2px')
      .attr('d', this.lineGenerator);

    this.reviewTextG = this.svg
      .append('g')
      .attr(
        'transform',
        'translate(' + this.translateX + ',' + (+this.translateY + 80) + ')',
      );

    var textWidth = 1000;

    if (window.innerWidth < 1470) {
      textWidth = 100;
    }

    this.reviewTextG
      .append('text')
      .attr('fill', 'white')
      .attr('font-size', '20')
      .attr('y', '5')
      .attr('x', this.innerRadius + 10)
      //.attr()
      .text('Ratings avg.')
      .call(wrap, textWidth);
  }

  render() {
    return (
      <div className="left-legend-visual">
        <div
          className="left-legend-visual-container"
          ref={el => {
            this._rootNode = el;
          }}
        />
      </div>
    );
  }
}

export default RatingsMatchLegend;
