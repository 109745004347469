import React, { Component } from 'react';
import Toggle from 'react-toggle';
import Slider from './Slider';

class Controls extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="filters-controller-containers">
        <div className="treemap-slider-cont">
          <div className="slider-title--cont">
            {' '}
            Move Slider to Filter deeper devices below an amount.
          </div>
          <Slider
            handleChange={this.props.handleSliderChange}
            value={this.props.filterValue}
            max={2500}
          />
        </div>

        <div className="toggle-container">
          <div className="unknown-toggle-cont">
            <div className="unkown-toggle-title toggle-title">
              Show Unkown Values
            </div>
            <Toggle
              defaultChecked={this.props.showNull}
              onChange={this.props.handleNullChange}
              checked={this.props.showNull}
            />
          </div>
          <div className="deeper-toggle-cont">
            <div className="deeper-toggle-title toggle-title">
              Show Deeper Nodes
            </div>
            <Toggle
              defaultChecked={this.props.showDeeper}
              onChange={this.props.handleDeeperChange}
              checked={this.props.showDeeper}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Controls;
