export const colorMap = {
  devicesseennew: {
    beta: "rgba(255, 43, 0, 0.9)",
    chi: "rgba(255, 99, 0, 0.9)",
    delta: "rgba(255, 156, 0, 0.9)",
    gamma: "rgba(255, 213, 0, 0.9)",
    iota: "rgba(15, 220, 255, 0.9)",
    kappa: "rgba(241, 255, 0, 0.9)",
    sigma: "rgba(184, 255, 0, 0.9)",
    tau: "rgba(128, 255, 0, 0.9)",
    theta: "rgba(71, 255, 0, 0.9)",
    total: "hsla(10, 100%, 60%, 1)",
    title: "rgb(255, 85, 51)"
  },
  activenode: {
    beta: "rgba(255, 43, 0, 0.7)",
    chi: "rgba(255, 99, 0, 0.7)",
    delta: "rgba(255, 156, 0, 0.7)",
    gamma: "rgba(255, 213, 0, 0.7)",
    iota: "rgba(15, 220, 255, 0.7)",
    kappa: "rgba(241, 255, 0, 0.7)",
    sigma: "rgba(184, 255, 0, 0.7)",
    tau: "rgba(128, 255, 0, 0.7)",
    theta: "rgba(71, 255, 0, 0.7)",
    total: "hsla(10, 100%, 60%, 1)",
    title: "rgb(255, 85, 51)"
  },
  cloudstorage: {
    beta: "rgba(255, 43, 0, 0.7)",
    chi: "rgba(255, 99, 0, 0.7)",
    delta: "rgba(255, 156, 0, 0.7)",
    gamma: "rgba(255, 213, 0, 0.7)",
    iota: "rgba(15, 220, 255, 0.7)",
    kappa: "rgba(241, 255, 0, 0.7)",
    sigma: "rgba(184, 255, 0, 0.7)",
    tau: "rgba(128, 255, 0, 0.7)",
    theta: "rgba(71, 255, 0, 0.7)",
    total: "hsla(10, 100%, 60%, 1)",
    title: "rgb(255, 85, 51)"
  },
  newhh: {
    beta: "rgba(255, 213, 0, 0.7)",
    chi: "rgba(241, 255, 0, 0.7)",
    delta: "rgba(184, 255, 0, 0.7)",
    gamma: "rgba(128, 255, 0, 0.7)",
    iota: "rgba(15, 220, 255, 0.7)",
    kappa: "rgba(71, 255, 0, 0.7)",
    sigma: "rgba(14, 255, 0, 0.7)",
    tau: "rgba(0, 255, 43, 0.7)",
    theta: "rgba(0, 255, 99, 0.7)",
    total: "hsla(50, 100%, 60%, 1)",
    title: "#ffee33"
  },
  activehh: {
    beta: "rgba(255, 213, 0, 0.7)",
    chi: "rgba(241, 255, 0, 0.7)",
    delta: "rgba(184, 255, 0, 0.7)",
    gamma: "rgba(128, 255, 0, 0.7)",
    iota: "rgba(15, 220, 255, 0.7)",
    kappa: "rgba(71, 255, 0, 0.7)",
    sigma: "rgba(14, 255, 0, 0.7)",
    tau: "rgba(0, 255, 43, 0.7)",
    theta: "rgba(0, 255, 99, 0.7)",
    total: "hsla(50, 100%, 60%, 1)",
    title: "#ffee33"
  },
  householdsundercontract: {
    total: "hsla(50, 100%, 60%, 1)",
    title: "#ffee33"
  },
  revenue: {
    total: "hsla(90, 100%, 60%, 1)",
    title: "77ff33"
  },
  arr: {
    total: "hsla(90, 100%, 60%, 1)",
    title: "77ff33"
  },
  arpu: {
    total: "hsla(90, 100%, 60%, 1)",
    title: "77ff33"
  },
  gm: {
    total: "hsla(130, 100%, 60%, 1)",
    title: "#40bf75"
  },
  ebitda: {
    total: "hsla(130, 100%, 60%, 1)",
    title: "#40bf75"
  },
  fcf: {
    total: "hsla(130, 100%, 60%, 1)",
    title: "#40bf75"
  },
  cac: {
    total: "hsla(170, 100%, 60%, 1)",
    title: "rgb(51, 221, 255)"
  },
  cir: {
    total: "hsla(170, 100%, 60%, 1)",
    title: "rgb(51, 221, 255)"
  },
  uptime: {
    total: "hsla(170, 100%, 60%, 1)",
    title: "rgb(51, 221, 255)"
  },
  nps: {
    total: "hsla(210, 100%, 60%, 1)",
    title: "rgb(51, 68, 255)"
  },
  churn: {
    total: "hsla(210, 100%, 60%, 1)",
    title: "rgb(51, 68, 255)"
  },
  ratings: {
    total: "hsla(210, 100%, 60%, 1)",
    title: "rgb(51, 68, 255)"
  },
  revenuegrowthrate: {
    total: "#ffb92d",
    title: "#ffb92d"
  },
  ltmfcfmargin: {
    total: "#ffb92d",
    title: "#ffb92d"
  },
  efficiency: {
    total: "#ffb92d",
    title: "#ffb92d"
  },
  satisfaction: {
    total: "hsla(290, 100%, 60%, 1)",
    title: "#9540bf"
  },
  attrition: {
    total: "hsla(290, 100%, 60%, 1)",
    title: "#9540bf"
  },
  newhire: {
    total: "hsla(290, 100%, 60%, 1)",
    title: "#9540bf"
  }
};
