import * as d3 from 'd3';
import parseDate from '../helpers/d3/utils/formatTimeHelpers';

function testForAxisChange(startTick, endTick, currentDate, grain) {
  if (grain !== 'daily' && grain !== 'month') {
    return false;
  }

  //this fixes a ui bug where the month bar does not update the ticks for the month

  endTick =
    grain === 'daily'
      ? endTick
      : new Date(startTick).getMonth() === 0
      ? d3.timeMonth.offset(new Date(endTick), -1).getTime()
      : endTick;

  let currentDateTest =
    grain === 'month'
      ? new Date(parseDate(currentDate))
      : new Date(currentDate);
  if (
    new Date(startTick).getTime() <= currentDateTest.getTime() &&
    new Date(endTick).getTime() >= currentDateTest.getTime()
  ) {
    return false;
  }

  return true;
}

function axisIndexStartStop(tickValues, currentDate, grain) {
  if (grain !== 'daily' && grain !== 'month') {
    return [0, tickValues.length - 1];
  }

  if (grain === 'month') {
    const date = new Date(parseDate(currentDate));
    const middle_month = new Date(tickValues[Math.ceil(tickValues.length / 2)]);

    if (date.getTime() >= middle_month.getTime()) {
      return [Math.ceil(tickValues.length / 2), tickValues.length - 1];
    } else {
      return [0, Math.ceil(tickValues.length / 2)];
    }
  }

  currentDate = new Date(currentDate);

  let endTickIndex = tickValues.length - 1;
  let startTick = endTickIndex - 6;

  if (currentDate.getTime() < new Date(tickValues[0]).getTime()) {
    return [0, 6];
  }

  while (new Date(tickValues[startTick]).getTime() > currentDate.getTime()) {
    endTickIndex = startTick;
    startTick = endTickIndex - 6;

    if (startTick < 0) {
      startTick = 0;
      endTickIndex = startTick + 6;
    }
  }

  return [startTick, endTickIndex];
}

//this function test if the second to last tickvalue and the last tick value are within a day and a half of eachother
// as the ticks overlap when when i is within one daily

const dayTicksTest = (tickValues, grain) => {
  if (grain !== 'daily') {
    return tickValues;
  }

  const lastTick = tickValues[tickValues.length - 1];
  const secondToLastTick = tickValues[tickValues.length - 2];

  const timeOne = new Date(lastTick);
  const timeTwo = new Date(secondToLastTick);

  const twoDaysDifference = d3.timeHour.offset(timeOne, -36);

  if (timeTwo.getTime() < twoDaysDifference) {
    return tickValues;
  }

  return tickValues
    .slice(0, tickValues.length - 2)
    .concat(tickValues.slice(tickValues.length - 1, tickValues.length));
};

export { axisIndexStartStop, testForAxisChange, dayTicksTest };
