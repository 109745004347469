import DashBoardData from '../../components/DashBoardData/DashBoardData';

export const dispatchFilters = (data, dataMap, filters, getDateRanges) => {
  const data_inst = new DashBoardData({ data: data, dataMap: dataMap });
  const dateRanges = getDateRanges(data_inst.data, {});
  let combinedDateRanges = null;
  if (filters.dateRanges !== undefined) {
    const currentDateRanges = filters.dateRanges;
    combinedDateRanges = mergerDateRanges(dateRanges, currentDateRanges);
  }
  return filters.dateRanges !== undefined ? combinedDateRanges : dateRanges;
};

function mergerDateRanges(dateRanges, currentDateRanges) {
  const combinedDateRanges = {};
  for (var time in dateRanges) {
    combinedDateRanges[time] = {
      series: dateRanges[time].series,
      selected: currentDateRanges[time]
        ? currentDateRanges[time].selected
        : false,
    };
  }
  return combinedDateRanges;
}
