import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import NavHeader from '../../containers/NavHeader';

export const checkPermissions = (Component, history) => {
  return (
    <div>
      <div id="animated-switch-div">
        <NavHeader history={history} />
        <Component />
      </div>
    </div>
  );
};

const SecureRouteComponent = ({
  component: Component,
  ...rest
}) => {
  return (
    <SecureRoute
      {...rest}
      render={({ history, params }) =>
        checkPermissions(Component, history)
      }
    />
  );
};

export default SecureRouteComponent;
