import React, { Component } from 'react';
import CurrentRange from './CurrentRange';
import {
  grainFormatters,
  dateFormatters_Formal,
  grains,
} from '../helpers/menuFormatters';

class RangeArea extends Component {
  constructor(props) {
    super(props);
  }
  handleOnClick(ev, grain) {
    this.props.changeGrain(grain);
  }

  render() {
    var { dates, availableGrains, currentGrain } = this.props;

    if (!this.props.isDash) {
      availableGrains = availableGrains.filter(val => {
        return val !== 'qtr';
      });
    }

    const selections = !this.props.selected
      ? null
      : grains.map((value, i) => {
          if (dates[value] === undefined) {
            return null;
          }
          const timeIndex = dates[value].selected;
          return (
            <div
              key={value}
              onClick={ev => {
                this.handleOnClick(ev, value);
              }}
              className={
                this.props.uniqueId
                  ? 'range'
                  : availableGrains.includes(value)
                  ? 'range'
                  : 'range disabled'
              }
              id={value}
            >
              <div className={`label headerArea grain`}>
                {' '}
                {grainFormatters[value]()}
              </div>
              <div className="label headerArea timeframe">
                {' '}
                {dateFormatters_Formal[value](dates[value].series[timeIndex])}
              </div>
            </div>
          );
        });
    const date = dateFormatters_Formal[currentGrain](
      dates[currentGrain].series[dates[currentGrain].selected],
    );

    return (
      <div
        className={
          this.props.showRangeArea && this.props.selected
            ? 'range-area-main-container open-range'
            : 'range-area-main-container'
        }
      >
        {this.props.showRangeArea || !this.props.selected ? null : (
          <div
            onMouseOver={ev => {
              if (this.props.uniqueId) {
                return null;
              }
              this.props.showRange(ev, this.props.uniqueName);
            }}
          >
            <CurrentRange
              uniqueId={this.props.uniqueId}
              grain={currentGrain}
              date={date}
            />
          </div>
        )}
        {this.props.selected ? null : (
          <div>
            <CurrentRange
              uniqueId={this.props.uniqueId}
              grain={currentGrain}
              date={date}
            />
          </div>
        )}
        {this.props.showRangeArea && this.props.selected ? (
          <div
            onMouseLeave={ev => {
              this.props.showCurrentRange(ev);
            }}
            id={
              this.props.uniqueId === 'menu-range-area'
                ? this.props.uniqueId
                : 'rangeArea'
            }
          >
            {selections}
          </div>
        ) : null}
      </div>
    );
  }
}

export default RangeArea;
