import { formatDate } from './calculateTime';
import * as d3 from 'd3';

function filterDataByDate(start, end, data) {
  data = data.filter((d, i) => {
    return (
      new Date(formatDate(d.date)).getTime() >= start.getTime() &&
      new Date(formatDate(d.date)).getTime() <= end.getTime()
    );
  });

  return data;
}

function filterDataByDateLine(start, end, data) {
  data = data.map((line, i) => {
    return line.filter((d, i) => {
      return (
        new Date(d.date).getTime() >= start.getTime() &&
        new Date(d.date).getTime() <= end.getTime()
      );
    });
  });

  return data;
}

const calcStartAndEnd = dateAxis => {
  const start = dateAxis[0];
  const end = d3.timeMonth.offset(dateAxis[11], 1);

  return [start, end];
};

function calculateDeviceCount(data) {
  var deviceCount = {};

  data.forEach((d, i) => {
    if (!(d.device in deviceCount)) {
      deviceCount[d.device] = 0;
    }
    deviceCount[d.device] += 1;
  });
  return deviceCount;
}

function formatBulletTime(lineData) {
  const deviceRating = {};
  const length = lineData[0].length - 1;
  lineData.forEach((d, i) => {
    if (d[0].device !== 'amazon') {
      deviceRating[d[length].device] = d[length].rating;
    }
  });

  return deviceRating;
}

export {
  calcStartAndEnd,
  filterDataByDate,
  filterDataByDateLine,
  calculateDeviceCount,
  formatBulletTime,
};
