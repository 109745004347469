import * as d3 from 'd3';
import * as _ from 'lodash';
import D3TimeRangeNew, { dateFormatters } from './timeRangeNew';
import D3InfoComponent from './infoComponent';
import dataSkeleton from '../../Skeleton/dataSkeleton';
import { formatters } from './utils/formatters';
import { getDateRanges } from './utils/helpers';

// const metrics = {
//   Revenue: 'revenue',
//   'Total Device Population': 'devicesseen',
//   'Active Nodes': 'activenode',
//   'Cloud Data Stored': 'cloudstorage',
// };

class D3RelatedData {
  constructor(options) {
    this.options = options;
    this.filters = options.filters;
    this.timeRange = null;
    this.menu = null;
  }

  /**
   * Create instance of D3.js component
   * @param el
   */
  create = el => {
    const component = this;

    const { changeFilters, rawData } = this.options;
    let { dateRanges } = this.filters;

    // let metricIndex = 0;
    const deployments = [false];
    const metricsCorrelations = [{}];
    let filterSelected = false;
    let deploymentSwitch = false;

    const getDistinctVals = (array, key) => {
      var result = [];
      loop1: for (var i = 0; i < array.length; i++) {
        var keyVal = array[i][key];
        for (var i2 = 0; i2 < result.length; i2++) {
          if (result[i2] === keyVal) {
            continue loop1;
          }
        }
        result.push(keyVal);
      }
      return result;
    };

    const padding = 50;
    let width, height;

    let showButton = true;

    const config = {
      transitionDuration: 500,
      transitionDelay: 250,
      selectorHeight: 40,
      buttonRadius: 25,
      buttonGrowth: 5,
    };

    const dimLookup = {
      activehh: 'households',
      devicesseen: 'devices',
      activenode: 'nodes',
    };

    let activeDimension = 0;

    const svg = d3.select('#chartDiv').append('svg');

    const setSize = () => {
      width = window.innerWidth;
      height = window.innerHeight - padding;

      svg.attr('width', width).attr('height', height);
    };

    setSize();

    let chartData;
    let readData;
    let timelyMetrics;

    let {
      metric,
      // colors,
    } = this.filters;

    const relationFormatters = {
      revenue: {
        title: 'Per Dollar',
        multiplier: 1,
        referenceFormatter: 'currency',
      },
      activenode: {
        title: 'Per Active Node',
        multiplier: 1,
        referenceFormatter: 'default',
      },
      activehh: {
        title: 'Per Active Household',
        multiplier: 1,
        referenceFormatter: false,
      },
      cloudstorage: {
        title: 'Per Gigabyte',
        multiplier: 1000,
        referenceFormatter: 'smallData',
      },
      householdsundercontract: {
        title: 'Per Contracted Household',
        multiplier: 1,
        referenceFormatter: false,
      },
      devicesseen: {
        title: 'Per Device',
        multiplier: 1,
        referenceFormatter: 'default',
      },
    };

    let activeDeployment = 'total';

    /* METRICS MENU OF OPTIONS ------------
          "ratings"
          "newhire"
          "cac"
          "fcf"
          "ebitda"
          "gm"
          "arpu"
          "arr"
          "revenue"
          "nps"
          "satisfaction"
          "uptime"
          "cir"
          "activenode"
          "activehh"
          "newhh"
          "cloudstorage"
          "householdsundercontract"
          "churn"
          "attrition"
          "devicesseen"
        ------------------------------------- */

    const relevantRelations = {
      revenue: [
        'activenode',
        'activehh',
        // 'householdsundercontract',
        'cloudstorage',
        'devicesseen',
      ],
      devicesseen: [
        'activenode',
        'activehh',
        // 'householdsundercontract',
        'cloudstorage',
      ],
      activenode: [
        'activehh',
        // 'householdsundercontract',
        'cloudstorage',
      ],
      cloudstorage: [
        'activenode',
        'activehh',
        // 'householdsundercontract',
        'devicesseen',
      ],
    };

    const barY = (d, i, a) => {
      return (
        height / 2 -
        (a.length * config.selectorHeight * 1.5) / 2 +
        i * config.selectorHeight * 1.5 +
        config.selectorHeight * 0.75
      );
    };

    const skeleton = new dataSkeleton();

    const errorHandler = (message = '') => {
      d3.select('#errorWrap').text(message);
    };

    const initTimeRange = data => {
      if (this.timeRange) return;
      dateRanges = getDateRanges(data, dateRanges);
      let menuItems = _(dateRanges)
        .keys()
        .orderBy([], ['asc'])
        .value();
      changeFilters({ dateRanges });

      this.timeRange = new D3TimeRangeNew({
        menuItems,
        redraw: () => {},
        filters: this.filters,
        changeFilters,
      });
      this.timeRange.create(el);
    };

    function checkEqualityOfDeployments(deployments) {
      const deployLength = deployments.length - 1;
      deployments.filter(function(item, counter) {
        if (counter !== deployLength) {
          if (item.length !== deployments[counter + 1]) showButton = false; //not the same length

          item.filter(function(deployment, index) {
            if (deployment !== deployments[counter + 1][index + 1])
              showButton = false; // not the same elements
          });
        }
      });
    }

    function getMetricDeployments(data) {
      let allDeployments = [];

      data.filter(function(item, index) {
        allDeployments.push(item.deployments.sort()); //sorted deployments by alphabeth
      });

      checkEqualityOfDeployments(allDeployments);
    }

    function loadSeries() {
      d3.select('#titleText').text('Metric Correlations');
      // TODO throw an error if metric is not defined
      if (!metric) {
        metric = 'activehh';
        changeFilters({ metric });
      }

      const init = () => {
        new D3InfoComponent({
          title: 'Metric Correlations',
          description: `Cross comparison of various data as it relates to the selected metric.`,
          dateUpdated: rawData.dateUpdated,
        }).create(el);

        let tempData = [];

        rawData.data.items.forEach(item => {
          const obj = {};
          rawData.data.map.forEach((key, i) => {
            obj[key] = item[i];
          });
          tempData.push(obj);
        });
        initTimeRange(tempData);
        readData = tempData;

        initChart();
      };

      const getRelevantMetrics = d => {
        return Object.keys(relevantRelations).filter(key => {
          return d.metric === key;
        });
      };

      //getting all deployments from metrics that are on the current screen
      const showDeploymentFilter = () => {
        let datesArr =
          component.filters.dateRanges[component.filters.grain].series;
        let dateId =
          component.filters.dateRanges[component.filters.grain].selected;
        let selectedDate = datesArr[dateId];
        readData.filter(d => {
          if (d.ts === selectedDate) {
            if (
              !metricsCorrelations[0].hasOwnProperty('deployments') &&
              metricsCorrelations.length === 1
            ) {
              if (getRelevantMetrics(d).length > 0)
                metricsCorrelations[0] = {
                  metric: d.metric,
                  deployments: [d.deployment],
                };
            } else {
              for (let i = 0; i < metricsCorrelations.length; i++) {
                if (getRelevantMetrics(d).length > 0) {
                  if (metricsCorrelations[i].metric === d.metric) {
                    if (
                      !metricsCorrelations[i].deployments.includes(d.deployment)
                    ) {
                      metricsCorrelations[i].deployments.push(d.deployment);
                      break;
                    } else {
                      break;
                    }
                  } else if (i === metricsCorrelations.length - 1) {
                    metricsCorrelations.push({
                      metric: d.metric,
                      deployments: [d.deployment],
                    });
                    break;
                  }
                }
              }
            }
          }
        });

        getMetricDeployments(metricsCorrelations);
      };

      init();
      showDeploymentFilter();
    }

    function parseSeries() {
      let datesArr =
        component.filters.dateRanges[component.filters.grain].series;
      let dateId =
        component.filters.dateRanges[component.filters.grain].selected;
      let selectedDate = datesArr[dateId];
      let tempData = [];

      if (!relevantRelations[metric]) {
        metric = 'revenue';
        changeFilters({ metric });
      }

      if (!readData) return;

      tempData = readData.filter(d => {
        if (
          deployments.indexOf(d.deployment) === -1 &&
          d.deployment !== 'total' &&
          (relevantRelations[metric].indexOf(d.metric) > -1 ||
            d.metric === metric)
        )
          deployments.push(d.deployment);

        return (
          d.grain === component.filters.grain &&
          d.ts === selectedDate &&
          d.deployment === activeDeployment &&
          (relevantRelations[metric].indexOf(d.metric) > -1 ||
            d.metric === metric)
        );
      });

      timelyMetrics = getDistinctVals(
        readData.filter(d => {
          return (
            d.grain === component.filters.grain &&
            d.ts === selectedDate &&
            d.deployment === activeDeployment
          );
        }),
        'metric',
      );

      chartData = tempData;
    }

    function initChart() {
      //TODO delete this stuff?
      d3.select('#slider3').style('display', 'inline');
      d3.select('#controls').style('display', 'inherit');

      component.updateChart();
    }

    this.updateChart = () => {
      // UPDATE TITLE
      const selectedGrain = component.filters.grain;
      const selectedRangeData = component.filters.dateRanges[selectedGrain];
      const selectedIndex = selectedRangeData.selected;
      const selectedDate = selectedRangeData.series[selectedIndex];
      const selectedDisplay = dateFormatters[selectedGrain](selectedDate);
      d3.select('#subtitleText').text(selectedDisplay);

      parseSeries();
      if (!timelyMetrics) return;

      let relevantKeyMetrics = Object.keys(relevantRelations).filter(key => {
        return timelyMetrics.findIndex(datum => datum === key) > -1;
      });
      if (relevantKeyMetrics.length < 1) {
        errorHandler('No data available');
        return;
      }
      errorHandler();

      //Put the datum selector onscreen.
      const processMetricNav = () => {
        const datumSelectorJoin = svg
          .selectAll('.datumSelectorWrap')
          .data(relevantKeyMetrics, d => d);

        const datumSelectorEnter = datumSelectorJoin.enter();

        const datumSelectorWrap = datumSelectorEnter
          .append('g')
          .classed('datumSelectorWrap', true);

        datumSelectorWrap
          .append('text')
          .classed('datumSelector', true)
          .attr('dominant-baseline', 'middle');
        datumSelectorWrap
          .append('rect')
          .classed('datumSelectorOutline', true)
          .attr('width', width / 4 - padding)
          .attr('height', config.selectorHeight)
          .attr('rx', config.selectorHeight / 2)
          .attr('x', padding)
          .attr('y', (d, i, a) => {
            return barY(d, i, a) - config.selectorHeight / 2;
          })
          .on('mouseenter', d => {
            if (metric !== d) {
              metric = d;
              changeFilters({ metric });
            }
          });
        datumSelectorWrap
          .append('path')
          .classed('datumSelectorConnector', true);

        //remove old junk
        datumSelectorJoin.exit().remove();

        //update metric controls
        svg
          .selectAll('.datumSelector')
          .classed('active', d => d === metric)
          .attr('x', width / 4)
          .attr('y', (d, i, a) => {
            return barY(d, i, a);
          })
          .text(d => skeleton.getByCsvName(d).name);
        svg
          .selectAll('.datumSelectorOutline')
          .classed('active', d => d === metric)
          .attr('x', padding)
          .attr('y', (d, i, a) => {
            return barY(d, i, a) - config.selectorHeight / 2;
          })
          .style('stroke', d => {
            const hue = skeleton.getByCsvName(d).colors.primaryHue;
            const opacity = d === metric ? 1 : 0.3;
            return 'hsla(' + hue + ',50%,50%,' + opacity + ')';
          })
          .style('fill', d => {
            const hue = skeleton.getByCsvName(d).colors.primaryHue;
            const opacity = d === metric ? 0.2 : 0;
            return 'hsla(' + hue + ',50%,50%,' + opacity + ')';
          })
          .attr('stroke-width', d => {
            return d === metric ? 2 : 1;
          });
      };

      processMetricNav();

      //update metric connector
      let primaryMetricD;

      const processPrimaryMetric = () => {
        const headerJoin = svg.selectAll('.mainMetric').data(
          chartData.filter(datum => {
            if (datum.metric === metric) {
              primaryMetricD = datum;
              return true;
            } else return false;
          }),
          d => d.metric,
        );

        if (chartData.length === 0) return;
        if (!primaryMetricD) {
          metric = chartData[0].metric;
          changeFilters({ metric });
          primaryMetricD = chartData[0];
        }

        //ENTER
        const headerG = headerJoin
          .enter()
          .append('g')
          .classed('mainMetric', true);
        headerG
          .append('text')
          .attr('alignment-baseline', 'middle')
          .classed('mainMetricHeader', true);
        headerG
          .append('text')
          .attr('alignment-baseline', 'middle')
          .classed('mainMetricValue', true);
        headerG
          .append('circle')
          .classed('mainMetricBg', true)
          .attr('cx', width / 2)
          .attr('cy', height / 2)
          .attr('r', Math.min(width, height) / 3 - padding * 2)
          .style('fill', d => {
            const hue = skeleton.getByCsvName(d.metric).colors.primaryHue;
            return 'hsla(' + hue + ',50%,50%,0.1)';
          });

        //REMOVE
        headerJoin.exit().remove();

        //UPDATE
        svg
          .selectAll('.mainMetricHeader')
          .attr('x', width / 2)
          .attr('y', (d, i, a) => {
            return (height / (a.length + 1)) * (i + 1);
          })
          .text(d => skeleton.getByCsvName(d.metric).name);
        svg
          .selectAll('.mainMetricValue')
          .attr('x', width / 2)
          .attr('y', (d, i, a) => {
            return (height / (a.length + 1)) * (i + 1);
          })
          .text(d => {
            const [infoObj] = chartData.filter(row => row.metric === d.metric);
            return formatters[skeleton.getByCsvName(d.metric).formatter](
              infoObj.value,
              true,
            );
          });
        svg
          .selectAll('.mainMetricBg')
          .transition()
          .duration(config.transitionDuration)
          .style('stroke', d => {
            const hue = skeleton.getByCsvName(d.metric).colors.primaryHue;
            return 'hsla(' + hue + ',50%,50%,1)';
          });
        svg
          .selectAll('.datumSelectorConnector')
          .transition()
          .duration(config.transitionDuration)
          .attr('d', d => {
            const x = width / 2 - (Math.min(width, height) / 3 - padding * 2); // TODO helper 2
            const y = height / 2; // TODO helper 1
            const startX = width / 4;
            const startY = barY(
              d,
              relevantKeyMetrics.indexOf(d),
              relevantKeyMetrics,
            );
            const endControlX = x - (x - startX) / 2;
            const endControlY = y;
            const startControlX = startX + (x - startX) / 2;
            const startControlY = startY;
            return `M ${startX} ${startY} C ${startControlX} ${startControlY} ${endControlX} ${endControlY} ${x} ${y}`;
          })
          .style('stroke', d => {
            const hue = skeleton.getByCsvName(d).colors.primaryHue;
            return 'hsla(' + hue + ',50%,50%,1)';
          })
          .style('opacity', d => {
            return d === metric ? 1 : 0;
          })
          .attr('stroke-width', d => {
            return d === metric ? 1 : 1;
          });
      };

      const processSecondaryMetrics = () => {
        const arc = d3
          .arc()
          .startAngle((d, i, a) => (Math.PI / a.length) * i)
          .endAngle((d, i, a) => (Math.PI / a.length) * (i + 1))
          .cornerRadius(10)
          .padAngle(0.03)
          .innerRadius(d => Math.min(width, height) / 3 - padding - 10)
          .outerRadius(d => Math.min(width, height) / 3 - padding);

        const detailItemJoin = svg.selectAll('.secondaryMetric').data(
          chartData.filter(datum => {
            return datum.metric !== metric;
          }),
          d => {
            return d.metric + metric;
          },
        );

        //ENTER
        const relationG = detailItemJoin
          .enter()
          .append('g')
          .classed('secondaryMetric', true)
          .style('opacity', 0);
        relationG
          .append('path')
          .classed('secondaryMetricArc', true)
          .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');
        relationG.append('path').classed('connectingLine', true);
        relationG
          .append('circle')
          .classed('startDot', true)
          .attr('cx', (d, i, a) => arc.centroid(d, i, a)[0] + width / 2)
          .attr('cy', (d, i, a) => arc.centroid(d, i, a)[1] + height / 2)
          .attr('r', 2.5);
        relationG
          .append('circle')
          .classed('endDot', true)
          .attr('cx', (width / 4) * 3)
          .attr('cy', (d, i, a) => {
            return (height / (a.length + 1)) * (i + 1);
          })
          .attr('r', 2.5);
        relationG
          .append('text')
          .classed('secondaryMetricHeader', true)
          .attr('alignment-baseline', 'middle');
        relationG
          .append('text')
          .classed('secondaryMetricValue', true)
          .attr('alignment-baseline', 'middle');

        //REMOVE
        detailItemJoin.exit().remove();

        //UPDATE
        svg
          .selectAll('.secondaryMetric')
          .transition()
          .duration(config.transitionDuration)
          .delay(config.transitionDelay)
          .style('opacity', 1);
        svg
          .selectAll('.secondaryMetricArc')
          .transition()
          .duration(config.transitionDuration)
          .style('fill', d => {
            const hue = skeleton.getByCsvName(d.metric).colors.primaryHue;
            return 'hsla(' + hue + ',50%,50%,.7)';
          })
          .attr('d', arc);
        svg
          .selectAll('.connectingLine')
          .transition()
          .duration(config.transitionDuration)
          .style('stroke', d => {
            const hue = skeleton.getByCsvName(d.metric).colors.primaryHue;
            return 'hsla(' + hue + ',50%,50%,1)';
          })
          .attr('d', (d, i, a) => {
            const x = (width / 4) * 3; // TODO helper 2
            const y = (height / (a.length + 1)) * (i + 1); // TODO helper 1
            const centroid = arc.centroid(d, i, a);
            const startX = centroid[0] + width / 2;
            const startY = centroid[1] + height / 2;
            const endControlX = x - (x - startX) / 2;
            const endControlY = y;
            const startControlX = startX + (x - startX) / 2;
            const startControlY = startY;
            return `M ${startX} ${startY} C ${startControlX} ${startControlY} ${endControlX} ${endControlY} ${x} ${y}`;
          })
          .style('fill', 'none');
        svg
          .selectAll('.startDot')
          .transition()
          .duration(config.transitionDuration)
          .style('fill', d => {
            const hue = skeleton.getByCsvName(d.metric).colors.primaryHue;
            return 'hsla(' + hue + ',50%,70%,1)';
          })
          .attr('cx', (d, i, a) => {
            return arc.centroid(d, i, a)[0] + width / 2;
          })
          .attr('cy', (d, i, a) => {
            return arc.centroid(d, i, a)[1] + height / 2;
          })
          .attr('r', 2.5);
        svg
          .selectAll('.endDot')
          .transition()
          .duration(config.transitionDuration)
          .style('fill', d => {
            const hue = skeleton.getByCsvName(d.metric).colors.primaryHue;
            return 'hsla(' + hue + ',50%,70%,1)';
          })
          .attr('cx', (width / 4) * 3)
          .attr('cy', (d, i, a) => {
            return (height / (a.length + 1)) * (i + 1);
          })
          .attr('r', 2.5);
        svg
          .selectAll('.secondaryMetricHeader')
          .text(d => {
            return relationFormatters[d.metric].title;
          })
          .transition()
          .duration(config.transitionDuration)
          .attr('x', (width / 4) * 3)
          .attr('y', (d, i, a) => {
            return (height / (a.length + 1)) * (i + 1);
          });
        svg
          .selectAll('.secondaryMetricValue')
          .text((d, i) => {
            const [infoObj] = chartData.filter(row => row.metric === d.metric);
            const formatterName = relationFormatters[primaryMetricD.metric]
              .referenceFormatter
              ? relationFormatters[primaryMetricD.metric].referenceFormatter
              : skeleton.getByCsvName(primaryMetricD.metric).formatter;
            const value =
              parseFloat(primaryMetricD.value / infoObj.value) /
              relationFormatters[infoObj.metric].multiplier;
            return formatters[formatterName](value, true);
          })
          .transition()
          .duration(config.transitionDuration)
          .attr('x', (width / 4) * 3) // TODO heloer 2
          .attr('y', (d, i, a) => {
            return (height / (a.length + 1)) * (i + 1); // TODO helper 1
          });
      };

      const getHue = () => {
        return skeleton.getByCsvName(Object.keys(dimLookup)[activeDimension])
          .colors.primaryHue;
      };

      const addDeploymentMenu = () => {
        svg
          .selectAll('.buttonHeader')
          .data(['Filter by deployment'])
          .enter()
          .append('text')
          .classed('buttonHeader', true)
          .text(d => d)
          .style('fill', 'hsla(' + getHue() + ', 20%, 70%, .8)')
          .style('text-anchor', 'right')
          .style('text-baseline', 'middle')
          .attr('x', config.buttonRadius + config.buttonGrowth)
          .attr('y', (d, i) => {
            return height - (80 + 100 * i - config.buttonGrowth);
          });

        const deploymentButtonEnter = svg
          .selectAll('.deploymentButtonWrap')
          .data(deployments)
          .enter();

        const deploymentButtonG = deploymentButtonEnter
          .append('g')
          .classed('deploymentButtonWrap', true)
          .classed('collapsed', true)
          .attr('x', config.buttonRadius + config.buttonGrowth)
          .attr('y', (d, i) => {
            return height - (i - config.buttonGrowth);
          })
          .attr('transform', (d, i) => {
            return 'translate(0,' + (0 - i * (20 + 2)) + ')';
          });

        deploymentButtonG
          .append('rect')
          .classed('deploymentButton', true)
          .on('click', (d, i, a) => {
            if (d3.select(a[i].parentNode).classed('collapsed')) {
              d3.selectAll('.deploymentButtonWrap').classed('collapsed', false);
              d3.selectAll('.datumSelectorWrap').classed(
                'non-pointer-events',
                true,
              );
            } else {
              d3.selectAll('.deploymentButtonWrap').classed('collapsed', true);
              d3.selectAll('.datumSelectorWrap').classed(
                'non-pointer-events',
                false,
              );
              filterSelected = d === 'false' ? false : d;
              if (deploymentSwitch !== filterSelected) {
                d3.selectAll(a).classed('active', false);
                d3.select(a[i]).classed('active', true);
                deploymentSwitch = filterSelected;
                activeDeployment = d !== false ? d : 'total';
                component.updateChart();
              }
            }
          })
          .attr('width', 80)
          .attr('height', 19)
          .attr('rx', 10)
          .attr('ry', 10)
          .attr('y', (d, i) => height - 90)
          .attr('x', 200)
          .attr('fill', 'hsla(' + getHue() + ', 20%, 50%, .8)');

        d3.selectAll('.deploymentButtonWrap').classed(
          'active',
          d => deploymentSwitch === (d === 'false' ? false : d),
        );
        deploymentButtonG
          .append('text')
          .attr('dominant-baseline', 'middle')
          .text(d => {
            let value = d || 'total';
            value = value.toUpperCase();
            return value;
          })
          .attr('y', (d, i) => height - 90 + 10)
          .attr('x', () => 240)
          .attr('fill', 'hsla(' + getHue() + ', 30%, 90%, 1)');
      };

      processPrimaryMetric();
      processSecondaryMetrics();

      if (showButton) {
        addDeploymentMenu();
      }
    };

    loadSeries();
  };

  update = newFilters => {
    this.filters = newFilters;

    if (this.timeRange) this.timeRange.update(this.filters);
    this.updateChart();
  };

  destroy = () => {
    d3.select('#chartDiv')
      .select('svg')
      .remove();
  };
}

export default D3RelatedData;
