import React from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

import { logoutUser } from '../../actions/auth_actions';

class UserActions extends React.Component {
  state = {
    showMenu: false,
    loggedIn: true,
  };

  componentDidMount() {
    document.addEventListener('click', this.closeMenu);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeMenu);
  }

  logoutAction = () => {
    this.props.authService.logout('/');
    this.setState({ loggedIn: false });
  };

  changeClient = e => {
    const { value } = e.target;
    const user = JSON.parse(localStorage.getItem('user'));
    if (value !== user.client) {
      user.client = value;
      localStorage.setItem('user', JSON.stringify(user));
      window.location.reload();
    }
  };

  toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) document.exitFullscreen();
    }
  };

  toggleMenu = () => {
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
  };

  closeMenu = e => {
    const { id, className } = e.target;
    if (
      id === 'toggle-menu-div' ||
      id === 'toggle-menu-picture' ||
      className === 'switch-client-li' ||
      id === 'switch-client-select'
    )
      return;
    this.setState({ showMenu: false });
  };

  redirect() {
    return <Redirect to="/login" />;
  }

  render() {
    const { showMenu } = this.state;
    const {
      clients,
      me: { currentUser: propsUser },
    } = this.props;
    const user = JSON.parse(localStorage.getItem('user')) || propsUser;

    if (!this.state.loggedIn) {
      return this.redirect();
    }

    return (
      <div id="userActions-popup">
        <div id="userActions-hover">
          <div id="toggle-menu-div" onClick={this.toggleMenu}>
            {user && `${user.first} ${user.last}`}
            <div
              id="toggle-menu-picture"
              onClick={this.toggleMenu}
              style={{
                backgroundImage: `url(${(user && user.image) ||
                  '/img/plume-logo-white.svg'}`,
              }}
            />
          </div>

          <div
            className="userActions-content"
            style={{ display: showMenu ? 'flex' : 'none' }}
          >
            <ul>
              {user && user.isSuperAdmin && (
                <li className="switch-client-li">
                  Switch Client:
                  <select
                    id="switch-client-select"
                    onChange={this.changeClient}
                    value={user.client}
                  >
                    {clients.map(({ _id, name }) => (
                      <option key={_id} value={_id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </li>
              )}
              <li className="switch-client-li">
                <button onClick={this.toggleFullScreen}>
                  Toggle Fullscreen
                </button>
              </li>
              <li className="switch-client-li">
                <button onClick={this.logoutAction}>LogOut</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

UserActions.propTypes = {};

const mapStateToProps = ({ me, clients }) => ({ me, clients });

export default withRouter(
  connect(
    mapStateToProps,
    { logoutUser },
  )(withOktaAuth(UserActions)),
);
