import { connect } from 'react-redux';
import { formatDate, calculateTime, calculateHighlight } from './calculateTime';
import RatingsRadial from '../../components/Charts/react_charts/RatingsRadial/RatingsRadial';
import {
  fetchRatingsData,
  changeYear,
  filterDevice,
  highlightCircleOn,
  highlightCircleOff,
} from '../../actions/ratings_actions';

import { getDashboardData } from '../../actions/dashboard_actions';
import {
  filterDataByDate,
  calcStartAndEnd,
  filterDataByDateLine,
  calculateDeviceCount,
  formatBulletTime,
} from './filterTime';

const mapStateToProps = (state, ownProps) => {
  const months = calculateTime(new Date(formatDate(getTime(state))));
  return {
    height: ownProps.height,
    width: ownProps.width,
    data:
      state.ratings.data === null
        ? null
        : filterDataByDate(
            calcStartAndEnd(months)[0],
            calcStartAndEnd(months)[1],
            state.ratings.data,
          ),
    year: state.ratings.year,
    lineData:
      state.ratings.lineData === null || state.filters.dateRanges === undefined
        ? null
        : filterDataByDateLine(
            calcStartAndEnd(months)[0],
            calcStartAndEnd(months)[1],
            state.ratings.lineData,
          ),
    device: state.ratings.device,
    filters: state.filters,
    grain: state.filters.grain,
    time: new Date(formatDate(getTime(state))),
    dateAxis: months,
    colors: state.filters.colors,
    datesToHighlight: calculateHighlight(
      new Date(formatDate(getTime(state))),
      state.filters.grain,
    ),
    deviceCount:
      state.ratings.data === null
        ? null
        : calculateDeviceCount(
            filterDataByDate(
              calcStartAndEnd(months)[0],
              calcStartAndEnd(months)[1],
              state.ratings.data,
            ),
          ),
    circleSelected: state.ratings.circleSelected,
    currentRatings:
      state.ratings.lineData === null
        ? null
        : formatBulletTime(state.ratings.lineData),
    dateRangesSet: state.filters.dateRanges === undefined ? false : true,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getData: () => dispatch(fetchRatingsData()),
    handleYearChange: year => dispatch(changeYear(year)),
    deviceFilter: device => dispatch(filterDevice(device)),
    highlightCircle: i => dispatch(highlightCircleOn(i)),
    highlightCircleOff: () => dispatch(highlightCircleOff()),
    getDashboardData: () => dispatch(getDashboardData()),
  };
};

const RadialCont = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RatingsRadial);

export default RadialCont;

function getTime(state) {
  if (state.filters.dateRanges === undefined) {
    return null;
  }
  const grain = state.filters.grain;
  const time =
    state.filters.dateRanges[grain].series[
      state.filters.dateRanges[grain].selected
    ];

  return time;
}
