import * as d3 from 'd3';

class SeriesData {
  constructor(options) {
    this.data = options.data;
  }

  set filters(filters) {
    const { time, grain } = filters;
    this.filterData = this.data.filter(d => d.ts === time && d.grain === grain);
  }

  stackedData(keys) {}

  get xTicks() {
    return this.filterData.map(d => d.ts);
  }
}

export const stackedData = (data, keys, fullData, ts) => {
  //make the dataset an array of values by time
  let uniqueTime = {};
  let uniqueArray = [];
  const xAxis = [];
  data.forEach(d => {
    if (uniqueTime[d.ts] === undefined) {
      uniqueTime[d.ts] = {};
      uniqueTime[d.ts].ts = d.ts;
      uniqueTime[d.ts][d.deployment] = d.value;
    } else {
      uniqueTime[d.ts][d.deployment] = d.value;
    }
  });

  Object.keys(uniqueTime).forEach(key => {
    xAxis.push(key);
    uniqueArray.push(uniqueTime[key]);
  });

  uniqueArray.sort(sortTime);
  xAxis.sort(sortAxis);

  const index = binarySearchTime(
    uniqueArray,
    ts,
    0,
    uniqueArray.length - 1,
    100,
  );
  const [start, end] = findDataSlice(fullData, index, uniqueArray.length);
  let unique_array_slice = uniqueArray.slice(start, end);
  let xAxis_slice = xAxis.slice(start, end);

  let series = d3.stack().keys(keys)(unique_array_slice);

  return [series, xAxis_slice, unique_array_slice];
};

export const groupedByData = stackedData => {
  const uniqueArray = stackedData[2];

  const new_data = uniqueArray.map(d => {
    const ts = d.ts;
    delete d.ts;
    delete d.total;
    return { ts: ts, subdata: d };
  });

  return [stackedData[1], new_data];
};

const sortTime = (a, b) => {
  if (a.ts === undefined || a.ts === '') return -1;
  if (b.ts === undefined || b.ts === '') return 1;

  const aTime = new Date(a.ts);
  const bTime = new Date(b.ts);

  if (aTime.getTime() < bTime.getTime()) return -1;
  if (aTime.getTime() > bTime.getTime()) return 1;
  return 0;
};

const sortAxis = (a, b) => {
  if (a === undefined || a === '') return -1;
  if (b === undefined || b === '') return 1;

  const aTime = new Date(a);
  const bTime = new Date(b);

  if (aTime.getTime() < bTime.getTime()) return -1;
  if (aTime.getTime() > bTime.getTime()) return 1;
  return 0;
};

// function wrangleDataByTime(data) {
//   const raw_stack_data = {};
//
//   data.forEach(d => {
//     if (raw_stack_data[d.ts] != undefined) {
//       raw_stack_data[d.ts][d.deployment] = d.value;
//     } else {
//       raw_stack_data[d.ts][d.deployment] = d.value;
//     }
//   });
// }

const binarySearchTime = (data, ts, start, end, count) => {
  if (count === 0) return 0;
  const index = Math.round((start + end) / 2);

  if (ts === '' || ts === undefined) return 0;
  if (data[index].ts === ts) return index;
  const indexDate = new Date(data[index].ts).getTime();
  const currentDate = new Date(ts).getTime();

  if (indexDate > currentDate)
    return binarySearchTime(data, ts, start, index - 1, count - 1);
  return binarySearchTime(data, ts, index + 1, end, count - 1);
};

const findDataSlice = (fullData, index, length) => {
  if (fullData) return [0, length];
  if (index <= 45) return [0, 45];
  if (index >= length-45) return [length - 45, length];
  return [index - 22, index + 23];
};

export default SeriesData;
