function formatIDs(id) {
  if (id == null) {
    id = id.split('-');

    id = id.join(' ');

    return id;
  }
  return id;
}

export { formatIDs };
