import React, { Component } from 'react';
import dataSkeleton from '../../Skeleton/dataSkeleton';
import * as d3 from 'd3';
import Toggle from 'react-toggle';
import './chordStyles.scss';
import './toggleStyles.css';
import PeopleChordTool from './PeopleChordTool';

class PeopleChord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolTip: {
        on: true,
        show: false,
        data: {
          title: null,
          value: null,
          totalEmployees: null,
        },
        x: null,
        y: null,
        left: null,
        rawData: null,
      },
    };

    //binding functions
    this.processData = this.processData.bind(this);

    this.skele = new dataSkeleton(this.options);

    this.colors = this.skele.dataSkeleton[6].colors;

    [
      this.data,
      this.totalEmployees,
      this.total_offices,
      this.total_teams,
    ] = this.processData(this.props.data);

    this.chordData = d3.chord().padAngle(0.03)(this.data);

    this.ribbon = d3.ribbon().radius(200);
    this.labelRadiusOffset = 40;

    this.margin = { l: 250, t: 250, r: 250, b: 150 };

    this.width = this.props.width - (this.margin.l + this.margin.r);
    this.height = this.props.height - (this.margin.l + this.margin.r);

    this.outerRadius =
      this.height < 250 ? this.props.height / 2 - 50 : this.height / 1.25;
    //this.height = this.height<250 ?250:this.height;

    this.innerRadius = this.outerRadius - 15;

    let translate =
      this.props.width < 850
        ? this.props.width / 2 - this.outerRadius - 145
        : this.props.width / 2 - this.outerRadius - 175;
    translate = translate > 200 ? 200 : translate;

    if (translate < 10) {
      this.outerRadius = this.props.width / 2 - (20 + 145);
      this.innerRadius = this.outerRadius - 15;

      translate =
        this.props.width < 850
          ? this.props.width / 2 - this.outerRadius - 145
          : this.props.width / 2 - this.outerRadius - 175;
      translate = translate > 200 ? 200 : translate;
    }

    this.outerRadius = this.innerRadius < 110 ? 110 : this.innerRadius;
    this.innerRadius = this.outerRadius - 15;
    // if(translate<10){
    //   this.outerRadius=
    // }

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.toolTipOnOff = this.toolTipOnOff.bind(this);

    this.offset = 2 * Math.PI * 0.088;

    // (Math.PI * this.emptyPercentage/2 )* 360;

    this.arc = d3
      .arc()
      .startAngle(this.startAngle)
      .endAngle(this.endAngle)
      .innerRadius(this.innerRadius)
      .outerRadius(this.outerRadius);

    this.lineArc = d3
      .arc()
      .startAngle(this.startAngle)
      .endAngle(this.endAngle)
      .innerRadius(this.innerRadius)
      .outerRadius(this.innerRadius);

    this.lineGenerator = d3
      .line()
      .x(d => d[0])
      .y(d => d[1]);

    this.ribbon = d3
      .ribbon()
      .startAngle(this.startAngle)
      .endAngle(this.endAngle)
      .radius(this.innerRadius);
  }

  componentDidMount() {
    this.props.setChordState({
      ribbons: this.matrixKeys.map((key, i) => {
        return {
          title: key,
          show:
            i === this.matrixKeys.indexOf('dummy2') ||
            i === this.matrixKeys.indexOf('dummy1')
              ? 'hide'
              : 'visible',
        };
      }),
      archs: this.matrixKeys.map((key, i) => {
        return { title: key, clicked: false };
      }),
      matrixKeys: this.matrixKeys,
    });

    window.addEventListener('resize', this.handleWindowResize);

    if(window.spinner){
      window.spinner.finish();
    }

  }

  handleWindowResize() {}

  componentDidUpdate() {}
  processData = data => {
    const dataMatrix = [];

    const groupedData = d3
      .nest()
      .key(d => d.Country)
      .key(d => {
        if ('Delivery team' === d.Department) {
          return 'Delivery Team';
        }
        return d.Department;
      })
      .entries(data);

    const allWorkersTotal = totalWorkers(groupedData);

    //how much of this should be empty

    this.emptyPercentage = 0.4;
    this.emptyNumber = 2 / 3;

    this.dummyAmount = Math.round(allWorkersTotal * this.emptyNumber);

    this.matrixKeys = ['dummy1'];

    //this loop is twice becuase the countries have to come first
    groupedData.forEach((val, i) => {
      this.matrixKeys.push(val.key);
    });

    //this var holds the amount of offices
    const total_offices = this.matrixKeys.length-1;
    //Dummy Key for making the split

    this.matrixKeys.push('dummy2');

    groupedData.forEach((val, i) => {
      val.values.forEach((dep, j) => {
        if (!this.matrixKeys.includes(dep.key)) {
          this.matrixKeys.push(dep.key);
        }
      });
    });

    // this var holds how many Teams we have
    const total_teams = this.matrixKeys.length - (total_offices + 2);

    //Second dummy key for making the split

    this.matrixKeys = this.matrixKeys.reverse();

    //build an empty matrix

    this.matrixKeys.forEach(() => {
      const array = [];
      this.matrixKeys.forEach(() => {
        array.push(0);
      });
      dataMatrix.push(array);
    });

    groupedData.forEach((country, i) => {
      const countryIndex = this.matrixKeys.indexOf(country.key);

      country.values.forEach((dep, j) => {
        const depIndex = this.matrixKeys.indexOf(dep.key);
        const totalWorkers = dep.values.length;

        //assign value into the dataMatrix

        dataMatrix[countryIndex][depIndex] = totalWorkers;
        dataMatrix[depIndex][countryIndex] = totalWorkers;
      });
    });

    //add dummy variables to matrix
    const dummy1_index = this.matrixKeys.indexOf('dummy1');
    const dummy2_index = this.matrixKeys.indexOf('dummy2');

    dataMatrix[dummy1_index][dummy2_index] = this.dummyAmount;
    dataMatrix[dummy2_index][dummy1_index] = this.dummyAmount;

    return [dataMatrix, allWorkersTotal, total_offices, total_teams];
  };

  startAngle = d => {
    return d.startAngle + this.offset;
  };

  endAngle = d => {
    return d.endAngle + this.offset;
  };

  handleMouseOver(e, i, arc) {
    const value = this.chordData.groups[i].value;

    const pageX = e.nativeEvent.offsetX;

    let pageY = e.nativeEvent.offsetY;
    //fixes location so tooltip is always visible on screen
    pageY = window.innerHeight - pageY < 427 + 35 ? pageY - 450 : pageY;
    const angle = (arc.startAngle + arc.endAngle) / 2 + this.offset;
    const left = angle > Math.PI;

    this.setState(state => ({
      toolTip: {
        on: state.toolTip.on,
        show: true,
        data: {
          title: this.matrixKeys[i],
          value: value,
          totalEmployees: this.totalEmployees,
        },
        x: pageX,
        y: pageY,
        left: left,
        rawData: this.props.data,
      },
    }));

    if (this.props.archClicked === true) {
      return null;
    }
    e.preventDefault();

    this.props.arcMouseOver(i);
  }

  handleMouseLeave(e) {
    this.setState(state => ({
      toolTip: {
        on: state.toolTip.on,
        show: false,
        data: state.toolTip.data,
        x: state.toolTip.x,
        y: state.toolTip.y,
        left: state.toolTip.left,
        rawData: state.toolTip.rawData,
      },
    }));

    if (this.props.archClicked === true) {
      return null;
    }
    e.preventDefault();

    this.props.arcMouseLeave();
  }

  handleArcClick(e, i) {
    e.preventDefault();

    this.props.arcClick(i);
  }
  handleCheckboxChange() {
    this.props.changeRibbonStyle();
  }
  toolTipOnOff() {
    this.setState(state => ({
      toolTip: {
        ...state.toolTip,
        ...{ on: !state.toolTip.on },
      },
    }));
  }

  render() {
    const {
      archs,
      height,
      width,
      ribbonsAsChords,
      ribbons: propsRibbons,
    } = this.props;
    if (archs.length < 1) {
      return null;
    }

    const arcs = this.chordData.groups.map((arc, i) => {
      return (
        <g key={i}>
          <path
            key={arc.index}
            display={
              i === this.matrixKeys.indexOf('dummy2') ||
              i === this.matrixKeys.indexOf('dummy1')
                ? 'none'
                : ''
            }
            className={'people-arc ' + i + '-people-chord'}
            d={this.arc(arc)}
            fill={'hsla(' + this.colors.primaryHue + ', 100%, 65%, 1)'}
            strokeWidth="1"
            onMouseOver={e => this.handleMouseOver(e, i, arc)}
            onMouseLeave={e => this.handleMouseLeave(e)}
            onClick={e => this.handleArcClick(e, i)}
          />
        </g>
      );
    });

    const ribbons = this.chordData.map((ribbon, i) => {
      var class_name;

      if (
        propsRibbons[ribbon.source.index].show === 'visible' ||
        propsRibbons[ribbon.target.index].show === 'visible'
      ) {
        class_name = `ribbon-visible people-arc ${i}-people-chord`;
      } else if (
        propsRibbons[ribbon.source.index].show === 'hidden' ||
        propsRibbons[ribbon.source.index].show === 'hidden'
      ) {
        class_name = `ribbon-hidden people-arc ${i}-people-chord`;
      } else {
        class_name = `ribbon-hide people-arc ${i}-people-chord`;
      }
      //let class_name_two = ' ribbon-'+propsRibbons[ribbon.target.index].show;

      //   propsRibbons[ribbon.source.index].show ||
      //   propsRibbons[ribbon.target.index].show
      //     ? 'ribbon-show people-arc ' + i + '-people-chord'
      //     : 'ribbon-hide people-arc ' + i + '-people-chord';
      //
      // if (archClicked) {
      //   class_name =
      //     archs[ribbon.source.index].clicked ||
      //     archs[ribbon.target.index].clicked
      //       ? 'ribbon-show people-arc ' + i + '-people-chord'
      //       : 'ribbon-hide people-arc ' + i + '-people-chord';
      // }

      return (
        <path
          className={class_name + 'i'}
          key={i}
          style={{
            display:
              ribbon.source.index === this.matrixKeys.indexOf('dummy2')
                ? 'none'
                : '',
          }}
          d={this.ribbon(ribbon)}
          fill={'hsla(' + this.colors.tertiaryHue2 + ', 100%, 65%, 1)'}
          opacity="0.55"
          strokeWidth="1"
        />
      );
    });

    const lines = this.chordData.map((ribbon, i) => {
      var class_name;

      if (
        propsRibbons[ribbon.source.index].show === 'visible' ||
        propsRibbons[ribbon.target.index].show === 'visible'
      ) {
        class_name = `ribbon-visible people-arc ${i}-people-chord`;
      } else if (
        propsRibbons[ribbon.source.index].show === 'hidden' ||
        propsRibbons[ribbon.source.index].show === 'hidden'
      ) {
        class_name = `ribbon-hidden people-arc ${i}-people-chord`;
      } else {
        class_name = `ribbon-hide people-arc ${i}-people-chord`;
      }

      //  propsRibbons[ribbon.source.index].show ||
      //   propsRibbons[ribbon.target.index].show
      //     ? 'ribbon-show people-arc ' + i + '-people-chord'
      //     : 'ribbon-hide people-arc ' + i + '-people-chord';
      //
      // if (archClicked) {
      //   class_name =
      //     archs[ribbon.source.index].clicked ||
      //     archs[ribbon.target.index].clicked
      //       ? 'ribbon-show people-arc ' + i + '-people-chord'
      //       : 'ribbon-hide people-arc ' + i + '-people-chord';
      // }

      return (
        <g
          key={i}
          className={class_name}
          opacity="0.55"
          style={{
            display:
              ribbon.source.index === this.matrixKeys.indexOf('dummy2')
                ? 'none'
                : '',
          }}
        >
          <ChordLines
            data={ribbon}
            lineArcGenerator={this.lineArc}
            lineGenerator={this.lineGenerator}
          />
        </g>
      );
    });

    const dotGroups = this.chordData.groups.map((arc, i) => {
      return (
        <g
          key={i}
          className="employee-circle-group"
          display={
            i === this.matrixKeys.indexOf('dummy2') ||
            i === this.matrixKeys.indexOf('dummy1')
              ? 'none'
              : ''
          }
        >
          <DotGroup
            data={arc}
            arcOffset={this.offset}
            matrixKeys={this.matrixKeys}
            innerRadius={this.innerRadius}
          />
        </g>
      );
    });

    const chordLabels = this.chordData.groups.map((arc, i) => {
      return (
        <g
          key={i}
          className="chord-label-group"
          display={
            i === this.matrixKeys.indexOf('dummy2') ||
            i === this.matrixKeys.indexOf('dummy1')
              ? 'none'
              : ''
          }
        >
          <ChordLabel
            data={arc}
            offset={this.offset}
            matrixKeys={this.matrixKeys}
            innerRadius={this.innerRadius}
            outerRadius={this.outerRadius}
            labelRadiusOffset={this.labelRadiusOffset}
            arcGenerator={this.arc}
            arc={arc}
            //width={this.width}
            //height={this.height}
            i={i}
            mouseOver={this.handleMouseOver.bind(this)}
            mouseLeave={this.handleMouseLeave.bind(this)}
            mouseClick={this.handleArcClick.bind(this)}
            height={height}
            width={width}
          />
        </g>
      );
    });

    let mainTitle_adjustment =
      this.innerRadius > (this.height + this.margin.t + this.margin.b) / 2
        ? (this.height + this.margin.t + this.margin.b) / 2
        : this.innerRadius;
    mainTitle_adjustment = mainTitle_adjustment * -1;
    const mainTitleTranslations =
      width < 800 || height < 600
        ? `translate(${this.width / 2 + this.margin.l},${this.height / 2 +
            this.margin.t})translate(0,${mainTitle_adjustment})translate(-55,0)`
        : `translate(${this.width / 2 + this.margin.l},${this.height / 2 +
            this.margin
              .t})translate(0,${mainTitle_adjustment})translate(-85,45)`;
    return (
      <div
        className="people-chord-div"
        ref={el => {
          this._rootNode = el;
        }}
      >
        <svg
          className="people-chord-svg"
          width={this.width + (this.margin.l + this.margin.r)}
          height={this.height + (this.margin.t + this.margin.b)}
        >
          <g
            className="main-chord-title chord-title"
            transform={mainTitleTranslations}
          >
            <text
              fill="white"
              x="0"
              fontSize={width < 850 || height < 600 ? '66px' : '110px'}
            >
              {`${this.totalEmployees}`}
            </text>
            <text
              fill="white"
              x="0"
              y="35"
              fontSize={width < 850 || height < 600 ? '24px' : '40px'}
            >
              Plumians
            </text>
          </g>
          <g
            className="title-text chord-title"
            transform={height < 780 ? `translate(0,65)` : `translate(0,120)`}
          >
            <text
              fill={'white'}
              transform={`translate(${this.width / 2 -
                this.innerRadius * 0.8 +
                this.margin.l -
                147},0)`}
              className="title-text"
            >
              {this.total_offices + ' Offices'}
            </text>
            <text
              fill={'white'}
              className="title-text"
              transform={`translate(${this.width / 2 +
                this.innerRadius * 0.8 +
                this.margin.l},0)`}
            >
              {this.total_teams + ' Teams'}
            </text>
          </g>
          <g
            width={this.width}
            height={this.height}
            transform={
              'translate(' +
              (this.width + (this.margin.l + this.margin.r)) / 2 +
              ',' +
              (this.height / 2 + this.margin.t) +
              ')'
            }
          >
            <g className="chord-chart-arcs">{arcs}</g>
            <g className="chord-chart-ribbons">
              {ribbonsAsChords ? ribbons : lines}
            </g>
            <g className="eployee-circle-groups">{dotGroups}</g>
            <g className="chord-label-groups">{chordLabels}</g>
          </g>
        </svg>
        <label id="first-toggle-label" style={{ color: 'white' }}>
          {' '}
          Solid Ribbons
        </label>
        <Toggle id="ribbon-style-change" onChange={this.handleCheckboxChange} />
        <label id="second-toggle-label" style={{ color: 'white' }}>
          {' '}
          Tooltips
        </label>
        <Toggle
          id="tool-tip-on-off-toggle"
          defaultChecked={this.state.toolTip.on}
          onChange={this.toolTipOnOff}
        />

        {this.state.toolTip.show && this.state.toolTip.on ? (
          <PeopleChordTool
            data={this.state.toolTip.data}
            x={this.state.toolTip.x}
            y={this.state.toolTip.y}
            rawData={this.state.toolTip.rawData}
            left={this.state.toolTip.left}
          />
        ) : null}
      </div>
    );
  }
}

export default PeopleChord;

const totalWorkers = groupedData => {
  let total = 0;

  groupedData.forEach((val, i) => {
    val.values.forEach((dep, j) => {
      total += dep.values.length;
    });
  });

  return total;
};

const DotGroup = ({ data, matrixKeys, innerRadius, arcOffset }) => {
  const startAngle = data.startAngle;
  const endAngle = data.endAngle;
  const number_employees = data.value;

  const offset = (endAngle - startAngle) / number_employees;

  const dots = [];
  for (let j = 0; j < number_employees; j++) {
    dots.push(j);
  }

  const Dots = dots.map((index, i) => {
    const angle = offset * index + startAngle + arcOffset;

    return (
      <g
        key={'dot' + data.index + index}
        transform={`rotate(${(angle * 180) / Math.PI -
          90}) translate(${innerRadius + 26})${
          angle > -179.01 ? 'rotate(180)' : ''
        }`}
      >
        <circle r="1" opacity="1" fill="blue" />
      </g>
    );
  });
  return <g className="employee_circle_dep_country">{Dots}</g>;
};

const ChordLabel = ({
  offset,
  innerRadius,
  outerRadius,
  labelRadiusOffset,
  data,
  matrixKeys,
  arc,
  arcGenerator,
  i,
  mouseOver,
  mouseLeave,
  mouseClick,
  width,
  height,
}) => {
  const angle = (data.startAngle + data.endAngle) / 2 + offset;

  const label =
    matrixKeys[data.index] === 'Support & Operations'
      ? 'S & O'
      : matrixKeys[data.index];

  const left = angle > Math.PI;

  let translate =
    width < 850 ? width / 2 - outerRadius - 145 : width / 2 - outerRadius - 175;
  translate = translate > 200 ? 200 : translate;
  translate = translate <= 20 ? 20 : translate;

  const x_translate_text = left
    ? -(innerRadius + (translate + 10))
    : innerRadius + (translate + 10);
  const x_translate_line = left
    ? -(innerRadius + translate)
    : innerRadius + translate;

  const fontSize = width < 850 || height < 850 ? 10 : 17;
  const lineHeightMiddle = fontSize / 2.5;

  const lineGenerator = d3
    .line()
    .x(d => d[0])
    .y(d => d[1]);
  const lineData = [
    [x_translate_line, arcGenerator.centroid(arc)[1]],
    [arcGenerator.centroid(arc)[0], arcGenerator.centroid(arc)[1]],
  ];

  return (
    <g className="label-group">
      <circle
        r="3"
        cx={arcGenerator.centroid(arc)[0]}
        cy={arcGenerator.centroid(arc)[1]}
        display={
          i === matrixKeys.indexOf('dummy2') ||
          i === matrixKeys.indexOf('dummy1')
            ? 'none'
            : ''
        }
        fill="white"
      />
      <g
        transform={`translate(${x_translate_text},${
          arcGenerator.centroid(arc)[1]
        })`}
        onMouseOver={e => {
          mouseOver(e, i, data);
        }}
        onMouseLeave={e => {
          mouseLeave(e);
        }}
        onClick={e => {
          mouseClick(e, i);
        }}
      >
        <text
          fill="white"
          textAnchor={left ? 'end' : ''}
          y={lineHeightMiddle}
          fontSize={fontSize}
        >
          {label + ` (${arc.value})`}
        </text>
      </g>
      <circle
        r="3"
        cx={arcGenerator.centroid(arc)[0]}
        cy={arcGenerator.centroid(arc)[1]}
        display={
          i === matrixKeys.indexOf('dummy2') ||
          i === matrixKeys.indexOf('dummy1')
            ? 'none'
            : ''
        }
        fill="white"
      />

      <path
        strokeWidth="1"
        stroke="white"
        d={lineGenerator(lineData)}
        fill="none"
      />
      <circle
        r="3"
        cx={x_translate_line}
        cy={arcGenerator.centroid(arc)[1]}
        fill="white"
      />
    </g>
  );
};

const ChordLines = ({ lineArcGenerator, lineGenerator, data }) => {
  lineGenerator.curve(d3.curveBundle.beta(0.75));
  const groupLinePoints = [];
  let startPoints = [];
  let endPoints = [];
  let midpoint = [];
  const value = data.source.value;
  const sourceIncrement =
    (data.source.endAngle - data.source.startAngle) / value;
  const targetIncrement =
    (data.target.endAngle - data.target.startAngle) / value;
  for (let i = 0; i < data.source.value; i++) {
    //find the startpoint for each line
    const sourceStartAngle = data.source.startAngle + i * sourceIncrement;
    const sourceEndAngle = sourceStartAngle + sourceIncrement;
    const startPoint = lineArcGenerator.centroid({
      startAngle: sourceStartAngle,
      endAngle: sourceEndAngle,
    });

    //find the endpoint for eachline
    const targetStartAngle = data.target.startAngle + i * targetIncrement;
    const targetEndAngle = targetStartAngle + targetIncrement;
    const endPoint = lineArcGenerator.centroid({
      startAngle: targetStartAngle,
      endAngle: targetEndAngle,
    });

    startPoints.push(startPoint);
    midpoint = [0, 0];
    endPoints.push(endPoint);
  }
  endPoints = endPoints.reverse();
  //startPoints = startPoints.reverse();
  startPoints.forEach((start, i) => {
    groupLinePoints.push([start, midpoint, endPoints[i]]);
  });

  const lines = groupLinePoints.map((points, i) => {
    return (
      <path
        key={i}
        className="ribbon-line-paths"
        d={lineGenerator(points)}
        strokeWidth={1.1}
        stroke="#a2ada6"
        fill="none"
      />
    );
  });

  return <g>{lines}</g>;
};
