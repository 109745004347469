import * as d3 from 'd3';
import { find, forEach, forOwn, orderBy } from 'lodash';

export const deepCopy = obj => {
  return JSON.parse(JSON.stringify(obj));
};

export const numberWithCommas = d3.format(',');

export const makeSelector = x => {
  if (x && x.length > 0) {
    return x.replace(/[\/\(\)\,\.]/g,''); // eslint-disable-line
  }
};

export const scale = (num, in_min, in_max, out_min, out_max) => {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

export const getPercentageDistance = (x, range) => {
  return (x - range[0]) / (range[1] - range[0]);
};

export const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {},
  );

export const rollupGroupStats = (stats, average) => {
  let sum = 0;
  Object.entries(stats).forEach(deployment => {
    sum += deployment[1];
  });

  return !average ? sum : sum / Object.entries(stats).length;
};

export const formatters = {
  currency: x => {
    return '<span>$</span>' + numberWithCommas(x.toFixed(2));
  },
  bigCurrency: x => {
    return (
      '<span>$</span>' +
      numberWithCommas((x / 1000000).toFixed(2) + '<span>Mil</span>')
    );
  },
  rating: x => {
    return numberWithCommas(x.toFixed(2)) + '<span>/5</span>';
  },
  integer: x => {
    return numberWithCommas(x);
  },
  bigInt: x => {
    return numberWithCommas((x / 1000000).toFixed(2)) + '<span>Mil</span>';
  },
  bigData: x => {
    return numberWithCommas(x.toFixed(1)) + '<span>Tb</span>';
  },
  percentage: x => {
    return x + '<span>%</span>';
  },
};

// Create Event Handlers for mouse - device bubbles
export const handleMouseOverDeviceBubbles = svg => (d, i, arr) => {
  // Add interactivity
  // Use D3 to select element, change color and size
  // d3.select(arr[i]).classed('activated', true);

  svg.classed('circleActivated', true);
  const deviceName = arr[i].getAttribute('data-selector');
  d3.selectAll('[data-selector="' + deviceName + '"]')
    .filter((d, i) => i < 3)
    .classed('activated', true);

  // d3.selectAll('text.' + deviceName).classed('activated', true);
};
export const handleMouseOutDeviceBubbles = svg => (d, i, arr) => {
  // Add interactivity
  // Use D3 to select element, change color and size
  // d3.select(arr[i]).classed('activated', false);
  svg.classed('circleActivated', false);
  const deviceName = arr[i].getAttribute('data-selector');
  d3.selectAll('[data-selector="' + deviceName + '"]').classed(
    'activated',
    false,
  );
  // d3.selectAll('text.' + deviceName).classed('activated', false);
};

export const updateUrlParameter = (key, value) => {
  var uri = window.location.href;
  // remove the hash part before operating on the uri
  var i = uri.indexOf('#');
  var hash = i === -1 ? '' : uri.substr(i);
  uri = i === -1 ? uri : uri.substr(0, i);

  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  var separator = uri.indexOf('?') !== -1 ? '&' : '?';

  if (!value) {
    // remove key-value pair if value is empty
    uri = uri.replace(new RegExp('([&]?)' + key + '=.*?(&|$)', 'i'), '');
    if (uri.slice(-1) === '?') {
      uri = uri.slice(0, -1);
    }
  } else if (uri.match(re)) {
    uri = uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    uri = uri + separator + key + '=' + value;
  }
  // return uri + hash;
  window.history.pushState('', '', uri + hash);
};

export const scrollbarVisible = () => {
  const element = d3.select('#animated-switch-div').node();
  return element.scrollHeight > element.clientHeight;
};

export const getDateRanges = (data, dateRanges) => {
  let ranges = {};
  forEach(data, datum => {
    if (!ranges[datum.grain]) {
      ranges[datum.grain] = {
        series: [],
      };
    }
    if (
      !find(ranges[datum.grain].series, time => {
        return time === datum.ts;
      })
    ) {
      ranges[datum.grain].series.push(datum.ts);
    }
  });
  forOwn(ranges, (value, key) => {
    ranges[key].series = orderBy(value.series, [], ['asc']);
    ranges[key].selected =
      dateRanges && dateRanges[key]
        ? dateRanges[key].selected
        : ranges[key].series.length - 1;
  });
  return ranges;
};
