import { createSelector } from 'reselect';
import DashBoardData from '../components/DashBoardData/DashBoardData';

import * as dashboard from '../reducers/dashboard_reducer';

export const selectWrangledData = state =>
  dashboard.selectWrangledData(state.dashboard);

//these are selectors that need the whole state

const grainSelector = state => state.filters.grain;
const dateRangeSelector = state => state.filters.dateRanges;
const metricSelector = state => state.filters.metric;
const dashBoardDataSelector = state => state.dashboard.dashboardData;
const dashBoardDataMapSelector = state => state.dashboard.dataMap;

const timeSelector = createSelector(
  grainSelector,
  dateRangeSelector,
  (grain, dateRanges) => {
    const timeIndex = dateRanges[grain].selected;
    const selectedTime = dateRanges[grain].series[timeIndex];

    return selectedTime;
  },
);

//this is data is an array of objects
const selectKeyedData = createSelector(
  dashBoardDataSelector,
  dashBoardDataMapSelector,
  (data, dataMap) => {
    const data_inst = new DashBoardData({ data: data, dataMap: dataMap });
    return data_inst.data;
  },
);

export const selectFilteredData = createSelector(
  grainSelector,
  timeSelector,
  metricSelector,
  selectKeyedData,
  (grain, time, metric, data) => {
    const filteredData = data.filter(
      d => d.grain === grain && d.metric === metric,
    );
    return filteredData;
  },
);
