import {
  CHANGE_FILTERS,
  CHANGE_GRAIN,
  CHANGE_FILTER_GRAIN_SELECTED,
  CHANGE_DEPLOYMENT_ENVIRONMENTS,
} from '../actions/filter_actions';

import { setInitialFilterState } from './helpers';

import {
  FILTER_ENVIRONMENTS,
  RESET_ENVIRONMENTS,
  TURN_ENVIRONMENTS_OFF,
} from '../actions/filter_chart_actions';

import { AUTH_LOGOUT } from '../actions/auth_actions';

//it is best to explicitly state in our init_state what goes into the state it works as documentation for
//future developers and it makes testin possible

// dateRanges:null,
// graphTypes:null,
// axisType:null,
// name:null,
// colors:null,
// timeStamp:null,
// graphMode:null,

const INIT_STATE = setInitialFilterState();

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case CHANGE_FILTERS:
      const filters = JSON.stringify(Object.assign({}, state, action.payload)); // eslint-disable-line
      localStorage.setItem('filters', filters);
      return { ...state, ...action.payload };
    case CHANGE_GRAIN:
      return { ...state, ...{ grain: action.grain } };
    case CHANGE_FILTER_GRAIN_SELECTED:
      var deepObject = {
        ...state.dateRanges[state.grain],
        ...{ selected: action.index },
      };
      var higherObject = {};
      higherObject[state.grain] = deepObject;

      return {
        ...state,
        ...{
          dateRanges: { ...state.dateRanges, ...{ ...higherObject } },
        },
      };
    case AUTH_LOGOUT:
      return { grain: 'daily', metric: 'activenode' };
    case CHANGE_DEPLOYMENT_ENVIRONMENTS:
      return { ...state, ...{ ...action.payload } };

    case FILTER_ENVIRONMENTS:
      return {
        ...state,
        ...{
          environments: state.environments.map((val, i) => {
            if (val.name !== action.env) {
              return val;
            }
            return { ...val, ...{ on: !state.environments[i].on } };
          }),
        },
      };
    case RESET_ENVIRONMENTS:
      return {
        ...state,
        ...{
          environments:
            state.environments === null
              ? null
              : state.environments.map((val, i) => {
                  return { ...val, ...{ on: true } };
                }),
        },
      };
    case TURN_ENVIRONMENTS_OFF:
      return {
        ...state,
        ...{
          environments:
            state.environments === null
              ? null
              : state.environments.map((val, i) => {
                  return { ...val, ...{ on: false } };
                }),
        },
      };

    default:
      return state;
  }
}
