import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { wrangleLiveDataSegment } from './helpers/wrangleLiveDataSegment';

import LiveTitle from './LiveTitle';

import LiveMenu from './LiveMenu';
import { getDashboardData } from '../../actions/dashboard_actions';

import './live.scss';

const Live = ({
  dashboardData,
  dateRanges,
  changeFilters,
  history,
  getDashData,
}) => {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [data, setData] = useState(null);

  function openClick() {
    setOpen(true);
  }
  function closeClick() {
    setOpen(false);
  }

  useEffect(
    () => {
      if (dashboardData !== null) wrangleLiveDataSegment(dashboardData);
      if (dateRanges !== undefined && dashboardData !== null) {
        const filterdData = [wrangleLiveDataSegment(dashboardData)];
        setData(filterdData);
      }
    },
    [dashboardData, dateRanges],
  );

  if (data === null) {
    return null;
  }

  return (
    <div className="KPI-live-block-cont">
      <LiveTitle openClick={openClick} closeClick={closeClick} open={open} />
      {open ? (
        <LiveMenu data={data} changeFilters={changeFilters} history={history} />
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  dashboardData: state.dashboard.dashboardData,
  dateRanges: state.filters.dateRanges,
});

const mapDispatchToProps = dispatch => ({
  getDashData: () => {
    dispatch(getDashboardData());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Live);
