export const dataSkeleton = [
  {
    name: 'Scale',
    color: 'Red',
    segments: [
      {
        name: 'Total Cumulative Unique Devices',
        csvName: 'devicesseennew',
        formatter: 'bigNum',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'seriesOrTotal',
            availGrains: ['daily', 'month', 'qtr', 'total'],
          },
          {
            type: 'Bubble',
            name: 'device bubbles',
            icon: 'icon-bubbles',
            url: '../devices-bubbles',
          },
          {
            type: 'relations',
            icon: 'icon-relations',
            url: '../metric-correlations',
          },
          // {
          //   type: 'relations',
          //   icon: 'treemap',
          //   url: '../treemap',
          // },
        ],
      },
      {
        name: 'Active Nodes',
        csvName: 'activenode',
        formatter: 'bigNum',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'seriesOrTotal',
            availGrains: ['daily', 'month', 'qtr', 'total'],
          },
          {
            type: 'relations',
            icon: 'icon-relations',
            url: '../metric-correlations',
          },
        ],
      },
      {
        name: 'Cloud Data Stored',
        csvName: 'cloudstorage',
        formatter: 'bigData',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'seriesOrTotal',
            availGrains: ['daily', 'month', 'qtr', 'total'],
          },
          {
            type: 'relations',
            icon: 'icon-relations',
            url: '../metric-correlations',
          },
        ],
      },
    ],
  },
  {
    name: 'Households',
    color: 'Yellow',
    segments: [
      {
        name: 'New Activations',
        csvName: 'newhh',
        formatter: 'bigNum',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'seriesOrTotal',
            availGrains: ['daily', 'month', 'qtr', 'total'],
          },
        ],
      },
      {
        name: 'Active Households',
        csvName: 'activehh',
        formatter: 'bigNum',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'seriesOrTotal',
            availGrains: ['daily', 'month', 'qtr', 'total'],
          },
        ],
      },
      {
        name: 'New Households Under Contract',
        formatter: 'bigNum',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
          // {
          //   type: 'Sprout',
          //   icon: 'icon-undercontract',
          //   url: '../under-contract',
          // },
        ],
        csvName: 'householdsundercontract',
      },
    ],
  },
  {
    name: 'Revenue',
    color: 'GreenLight',
    segments: [
      {
        name: 'Revenue',
        csvName: 'revenue',
        formatter: 'bigCurrency',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month', 'qtr'],
          },
          {
            type: 'relations',
            icon: 'icon-relations',
            url: '../metric-correlations',
          },
        ],
        showTimeSeries: ['month', 'qtr'],
        graphTypes: 'total',
      },
      {
        name: 'ARR',
        csvName: 'arr',
        formatter: 'bigCurrency',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month', 'qtr'],
          },
        ],
      },
      {
        name: 'ARPU',
        csvName: 'arpu',
        formatter: 'currency',
        axisType: 'currency',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month', 'qtr'],
          },
        ],
      },
    ],
  },
  {
    name: 'Profitability',
    color: 'Green',
    segments: [
      {
        name: 'GM',
        csvName: 'gm',
        formatter: 'percentage',
        axisType: 'percentage',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month', 'qtr'],
          },
        ],
      },
      {
        name: 'EBITDA',
        csvName: 'ebitda',
        formatter: 'bigCurrency',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
        ],
      },
      {
        name: 'FCF',
        csvName: 'fcf',
        formatter: 'bigCurrency',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
        ],
      },
    ],
  },
  {
    name: 'Operations',
    color: 'BlueLight',
    segments: [
      {
        name: 'NRR',
        csvName: 'uptime',
        formatter: 'percentage',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
        ],
        // invertDelta: true,
        axisType: 'percentage',
      },
      {
        name: 'LTV',
        csvName: 'cir',
        formatter: 'bigCurrency',
        axisType: 'currency',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
        ],
      },
      {
        name: 'CAC',
        csvName: 'cac',
        formatter: 'bigCurrency',
        axisType: 'currency',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['qtr'],
          },
        ],
        // invertDelta: true,
      },
    ],
  },
  {
    name: 'Customer Experience',
    color: 'Blue',
    segments: [
      {
        name: 'NPS',
        csvName: 'nps',
        formatter: 'default',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
        ],
      },
      {
        name: 'HH Churn',
        csvName: 'churn',
        formatter: 'percentage',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
          // {
          //   type: 'churn-facet',
          //   icon: 'icon-churn',
          //   url: '../churn-facet',
          //   options: 'total',
          //   availGrains: ['month'],
          // },
        ],
        invertDelta: true,
        axisType: 'percentage',
      },
      {
        name: 'Ratings',
        csvName: 'ratings',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
          // {
          //   type: 'radial',
          //   icon: 'icon-timerange',
          //   url: '../radial',
          //   options: 'total',
          //   availGrains: ['daily', 'month', 'qtr', 'year'],
          // },
        ],
        tooltip: 'App and Amazon ratings average',
        formatter: 'rating',
      },
    ],
  },
  {
    name: 'SaaS EMCLOUD Index Metrics',
    color: 'Orange',
    segments: [
      {
        name: 'Revenue Growth Rate',
        csvName: 'revenuegrowthrate',
        formatter: 'percentage',
        axisType: 'percentage',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
        ],
      },
      {
        name: 'LTM FCF Margin',
        csvName: 'ltmfcfmargin',
        formatter: 'percentage',
        axisType: 'percentage',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
        ],
      },
      {
        name: 'Efficiency Score',
        csvName: 'efficiency',
        formatter: 'percentage',
        axisType: 'percentage',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
        ],
      },
    ],
  },
  {
    name: 'People',
    color: 'Purple',
    segments: [
      {
        name: 'Engagement',
        csvName: 'satisfaction',
        formatter: 'default',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
        ],
      },
      {
        name: 'Attrition',
        csvName: 'attrition',
        formatter: 'integer',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
        ],
        invertDelta: true,
      },
      {
        name: 'New Hires',
        csvName: 'newhire',
        formatter: 'integer',
        links: [
          {
            type: 'series',
            icon: 'icon-series',
            url: '../timeseries',
            options: 'total',
            availGrains: ['month'],
          },
          // {
          //   type: 'splitchord',
          //   icon: 'icon-splitchord',
          //   url: '../splitchord',
          //   options: 'seriesOrTotal',
          //   availGrains: ['month'],
          // },
        ],
      },
    ],
  },
];
