import * as d3 from 'd3';

function setArrowStyles(filters) {
  const isTimeseries = window.location.href.includes('/timeseries');

  if (isTimeseries && filters.grain !== 'daily') {
    d3.select('#left-menu-button')
      .classed('button-off', true)
      .style('opacity', 0.4);

    d3.select('#right-menu-button')
      .classed('button-off', true)
      .style('opacity', 0.4);
  } else {
    d3.select('#left-menu-button')
      .classed('button-off', false)
      .style('opacity', 1);

    d3.select('#right-menu-button')
      .classed('button-off', false)
      .style('opacity', 1);
  }
}

export { setArrowStyles };
