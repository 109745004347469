import {
  ARC_CLICK,
  ARC_MOUSEOVER,
  ARC_MOUSELEAVE,
  SET_STATE,
  CHANGE_RIBBON_STYLE,
} from '../actions/people_actions';

export default function peopleReducer(
  state = {
    ribbons: [],
    archs: [],
    archClicked: false,
    number_archs_clicked: 0,
    ribbonsAsChords: false,
  },
  action = null,
) {
  switch (action.type) {
    case SET_STATE:
      return {
        ...state,
        ...{
          ribbons: action.payload.ribbons,
          archs: action.payload.archs,
          matrixKeys: action.payload.matrixKeys,
        },
      };
    case ARC_CLICK:
      return {
        ...state,
        ...{
          archClicked:
            state.archs[action.index].clicked === true &&
            state.number_archs_clicked === 1
              ? false
              : true,
          archs:
            state.archs[action.index].clicked === true &&
            state.number_archs_clicked === 1
              ? state.archs.map(arch => {
                  return { ...arch, ...{ clicked: false } };
                })
              : state.archs.map((arch, j) => {
                  return action.index === j
                    ? {
                        ...arch,
                        ...{ clicked: !state.archs[action.index].clicked },
                      }
                    : { ...arch };
                }),
          ribbons:
            state.archs[action.index].clicked === true &&
            state.number_archs_clicked === 1
              ? state.ribbons.map((ribbon, z) => {
                  return { ...ribbon, ...{ show: 'visible' } };
                })
              : state.ribbons.map((ribbon, t) => {
                  if (t === action.index && state.archClicked === false) {
                    return { ...ribbon, ...{ show: 'visible' } };
                  }
                  if (t === action.index) {
                    return {
                      ...ribbon,
                      ...{
                        show:
                          ribbon.show === 'hide' || ribbon.show === 'hidden'
                            ? 'visible'
                            : 'hide',
                      },
                    };
                  }
                  if (state.archs[t].clicked === true) {
                    return { ...ribbon, ...{ show: 'visible' } };
                  }
                  return {
                    ...ribbon,
                    ...{ show: ribbon.show === 'hidden' ? 'hidden' : 'hide' },
                  };
                }),

          number_archs_clicked:
            state.archs[action.index].clicked === true
              ? state.number_archs_clicked - 1
              : state.number_archs_clicked + 1,
        },
      };

    case ARC_MOUSELEAVE:
      return {
        ...state,
        ...{
          ribbons: state.ribbons.map((ribbon, j) => {
            return {
              ...ribbon,
              ...{
                show:
                  j === state.matrixKeys.indexOf('dummy2') ||
                  j === state.matrixKeys.indexOf('dummy1')
                    ? 'hide'
                    : 'visible',
              },
            };
          }),
        },
      };
    case ARC_MOUSEOVER:
      return {
        ...state,
        ...{
          ribbons: state.ribbons.map((ribbon, j) => {
            return action.index === j
              ? { ...ribbon, ...{ show: 'visible' } }
              : { ...ribbon, ...{ show: 'hide' } };
          }),
        },
      };
    case CHANGE_RIBBON_STYLE:
      return {
        ...state,
        ...{
          ribbonsAsChords: !state.ribbonsAsChords,
          ribbons: state.ribbons.map((ribbon, j) => {
            return ribbon.show === 'hide'
              ? { ...ribbon, ...{ show: 'hidden' } }
              : { ...ribbon };
          }),
        },
      };
    default:
      return state;
  }
}
