import * as d3 from 'd3';
import moment from 'moment';
import dataSkeleton from '../../Skeleton/dataSkeleton';
import { debounce } from 'lodash';
import D3RangeSlider from './rangeSlider';

// to seperate grain/date
const grains = [
  'daily',
  'month',
  'qtr',
  'year',
  'ttm',
  'alltime',
  // 'now'
];

export const grainFormatters = {
  qtr: function() {
    return 'Quarter';
  },
  month: function() {
    return 'Month';
  },
  daily: function() {
    return 'Day';
  },
  alltime: function() {
    return 'All Time';
  },
  year: function() {
    return 'Year';
  },
  ttm: function() {
    return 'TTM';
  },
  now: function() {
    return 'Now';
  },
};

export const dateFormatters = {
  qtr: function(ts) {
    return moment.utc(ts).format('\\QQ YYYY');
  },
  month: function(ts) {
    return moment.utc(ts).format('MMM YYYY');
  },
  daily: function(ts) {
    return moment.utc(ts).format('MMM DD, YYYY');
  },
  alltime: function(ts) {
    return moment.utc(ts).format('MMM YYYY');
  },
  year: function(ts) {
    return moment.utc(ts).format('YYYY');
  },
  // test v ttm
  ttm: function(ts) {
    return (
      moment
        .utc(ts)
        .subtract(11, 'Months')
        .format('MMM YYYY') +
      ' - ' +
      moment.utc(ts).format('MMM YYYY')
    );
  },
  now: function(ts) {
    return moment.utc(ts).format('MMM DD hh:mm');
  },
};

class D3TimeRangeNew {
  constructor(options) {
    this.options = options;
    this.filters = options.filters;
  }

  create = el => {
    const component = this;

    let { menuItems, changeFilters } = this.options;

    this.changeFilter = this.options.changeFilters;

    let { dateRanges } = this.filters;

    let grainIndex = grains.indexOf(this.filters.grain);

    const skele = new dataSkeleton();
    const availGrains =
      skele.getByCsvName(this.filters.metric) === undefined
        ? skele.getByCsvName('devicesseennew').links[0].availGrains
        : skele.getByCsvName(this.filters.metric).links[0].availGrains;
    const containsGrain = g => availGrains.includes(g);
    const isTimeseries = window.location.href.includes('/timeseries');

    //build menu

    var menuWrap = d3
      .select(el)
      .append('div')
      .attr('id', 'headerMenu')
      .classed('headerArea', true)
      .classed('open', true)
      .classed('hidden', true)
      .on('mouseleave', function() {
        menuWrap.classed('hidden', true);
        //caretDrop.classed('hidden', false);
        d3.select('#headerMenu').classed('open', false);
      });

    var rangeArea = menuWrap
      .append('div')
      .attr('id', 'rangeArea')
      .on('mouseenter', function() {
        d3.select('#headerMenu').classed('open', true);
      })
      .on('mouseleave', function() {
        d3.select('#headerMenu').classed('open', false);
      });

    let sortedMenu = ['daily', 'month', 'qtr', 'year', 'ttm', 'alltime', 'now'];

    let newMenu = [];
    sortedMenu.forEach(item => {
      const isInMenu = menuItems.indexOf(item);
      if (isInMenu >= 0) {
        newMenu.push(item);
      }
    });

    // any not in sortedMenu but in menuItems ... push to end (i.e. unsorted)
    menuItems.forEach(item => {
      const isInMenu = sortedMenu.indexOf(item);
      if (isInMenu < 0) {
        newMenu.push(item);
      }
    });

    menuItems = newMenu;
    var menuItemSelect = rangeArea.selectAll('.range').data(newMenu);
    var menuItemEnter = menuItemSelect.enter();

    var menuItemDivs = menuItemEnter
      .append('div')
      .classed('range', true)
      .classed('disabled', d => isTimeseries && !containsGrain(d))
      .attr('id', d => d)
      .on('click', function(grain) {
        //if open...
        if (d3.select('#headerMenu').classed('open')) {
          if (isTimeseries && !containsGrain(grain)) return;

          grainIndex = grains.indexOf(grain);
          changeFilters({ grain });
          component.update({ ...component.filters, grain });
        }

        //if closed...
        else {
          d3.select('#headerMenu').classed('open', true);
        }
        // toggle...
      });

    menuItemDivs
      .append('div')
      .classed('label', true)
      .classed('headerArea', true)
      .classed('grain', true)
      .html(function(d) {
        if (grainFormatters[d]) {
          return grainFormatters[d](
            dateRanges[d].series[dateRanges[d].selected],
          );
        } else {
          // console.error('CRITICAL ERROR: Unable to parse time format ', d);
          return 'UNKNOWN: ' + d;
        }
        // return timeFormatters[d](dateRanges[d].series[dateRanges[d].selected]);
      });

    menuItemDivs
      .append('div')
      .classed('label', true)
      .classed('headerArea', true)
      .classed('timeframe', true)
      .html(function(d) {
        if (grainFormatters[d]) {
          return dateFormatters[d](
            dateRanges[d].series[dateRanges[d].selected],
          );
        } else {
          // console.error('CRITICAL ERROR: Unable to parse time format ', d);
          return 'UNKNOWN: ' + d;
        }
        // return timeFormatters[d](dateRanges[d].series[dateRanges[d].selected]);
      });

    setTimeout(() => {
      d3.select(`#${grains[grainIndex]}`).classed('active', true);
    }, 1500);

    /* RANGE DATE SLIDER ======================================== */
    menuWrap
      .append('div')
      .attr('id', 'left-menu-button')
      .attr('class', 'time-slider-arrows')
      .attr('color', 'white')
      .text('<<');
    const rangeSliderParent = menuWrap.append('div').attr('id', 'rangeSlider');
    menuWrap
      .append('div')
      .attr('id', 'right-menu-button')
      .attr('class', 'time-slider-arrows')
      .attr('color', 'white')
      .text('>>');

    const handleLeftArrowClick = (d, i) => {
      let grain = grains[grainIndex];
      const dateRanges = { ...component.filters.dateRanges };

      if (isTimeseries && grain !== 'daily') return;
      if (dateRanges[grain].selected > 0) {
        dateRanges[grain].selected -= 1;
        changeFilters({ dateRanges });
        component.update({ ...component.filters, dateRanges });
      }
    };

    const handleRightArrowClick = (d, i) => {
      let grain = grains[grainIndex];
      const dateRanges = { ...component.filters.dateRanges };

      if (isTimeseries && grain !== 'daily') return;
      if (dateRanges[grain].selected < dateRanges[grain].series.length - 1) {
        dateRanges[grain].selected += 1;
        changeFilters({ dateRanges });
        component.update({ ...component.filters, dateRanges });
      }
    };

    d3.select('#left-menu-button').on('click', handleLeftArrowClick);
    d3.select('#right-menu-button').on('click', handleRightArrowClick);

    let sliderOptions = {
      filters: this.filters,
      changeFilters,
    };
    this.rangeSlider = new D3RangeSlider(sliderOptions);
    this.rangeSlider.create(rangeSliderParent);

    /* ======================================== RANGE DATE SLIDER */

    window.onresize = debounce(() => {
      // TODO update the size of the range slider to window.innerWidth - 80;
    }, 500);

    const KEYBOARD = {
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      DOWN: 40,
      ESC: 27,
      ENTER: 13,
    };
    // listen to keyboard changes
    window.onkeydown = function(e) {
      const key = e.keycode ? e.keycode : e.which;
      if (
        key === KEYBOARD.UP ||
        key === KEYBOARD.DOWN ||
        key === KEYBOARD.LEFT ||
        key === KEYBOARD.RIGHT
      )
        e.preventDefault();
    };

    window.onkeyup = function(e) {
      var key = e.keyCode ? e.keyCode : e.which;
      let grain = grains[grainIndex];

      const dateRanges = { ...component.filters.dateRanges };
      if (!d3.select('#headerMenu').node()) return;

      switch (key) {
        case KEYBOARD.ENTER: {
          d3.select('#headerMenu').classed(
            'open',
            !d3.select('#headerMenu').classed('open'),
          );
          break;
        }
        case KEYBOARD.ESC: {
          d3.select('#headerMenu').classed('open', false);
          break;
        }
        case KEYBOARD.LEFT:
          if (isTimeseries && grain !== 'daily') return;
          if (dateRanges[grain].selected > 0) {
            dateRanges[grain].selected -= 1;
            changeFilters({ dateRanges });
            component.update({ ...component.filters, dateRanges });
          }
          break;
        case KEYBOARD.UP:
          if (grainIndex > 0) {
            grainIndex -= 1;
            grain = grains[grainIndex];
            if (isTimeseries && !containsGrain(grain)) {
              grainIndex -= 1;
              grain = grains[grainIndex];
              return;
            }

            changeFilters({ grain });
            component.update({ ...component.filters, grain });
          }
          break;
        case KEYBOARD.RIGHT:
          if (isTimeseries && grain !== 'daily') return;
          if (
            dateRanges[grain].selected <
            dateRanges[grain].series.length - 1
          ) {
            dateRanges[grain].selected += 1;
            changeFilters({ dateRanges });
            component.update({ ...component.filters, dateRanges });
          }
          // navClick(grain, grainIndex, d3.selectAll('div.nav.next').nodes());
          break;
        case KEYBOARD.DOWN:
          if (grainIndex < grains.length - 1) {
            grainIndex += 1;
            grain = grains[grainIndex];
            if (isTimeseries && !containsGrain(grain)) {
              grainIndex -= 1;
              grain = grains[grainIndex];
              return;
            }

            changeFilters({ grain });
            component.update({ ...component.filters, grain });
          }
          break;
        default:
          break;
      }
    };
  };

  resetOnClick = () => {
    let grainIndex = grains.indexOf(this.filters.grain);

    const isTimeseries = window.location.href.includes('/timeseries');

    const handleLeftArrowClick = (d, i) => {
      let grain = grains[grainIndex];
      const dateRanges = { ...this.filters.dateRanges };

      if (isTimeseries && grain !== 'daily') return;
      if (dateRanges[grain].selected > 0) {
        dateRanges[grain].selected -= 1;
        this.changeFilter({ dateRanges });
        this.update({ ...this.filters, dateRanges });
      }
    };

    const handleRightArrowClick = (d, i) => {
      let grain = grains[grainIndex];
      const dateRanges = { ...this.filters.dateRanges };

      if (isTimeseries && grain !== 'daily') return;
      if (dateRanges[grain].selected < dateRanges[grain].series.length - 1) {
        dateRanges[grain].selected += 1;
        this.changeFilter({ dateRanges });
        this.update({ ...this.filters, dateRanges });
      }
    };

    d3.select('#left-menu-button').on('click', handleLeftArrowClick);
    d3.select('#right-menu-button').on('click', handleRightArrowClick);
  };

  update = filters => {
    this.filters = filters;
    const { grain, dateRanges } = this.filters;

    d3.select('#' + grain)
      .selectAll('.reset')
      .classed('active', function() {
        return dateRanges[grain].selected < dateRanges[grain].series.length - 1;
      });

    //update the display...
    d3.selectAll('#headerMenu .range').classed('active', false);
    d3.select('#menuGrain').html(function() {
      if (grainFormatters[grain]()) {
        return grainFormatters[grain]();
      }
    });

    const grainNow = grain;
    let rangeNow = 'unknown';

    if (dateFormatters[grainNow]) {
      rangeNow = dateFormatters[grainNow](
        dateRanges[grainNow].series[dateRanges[grainNow].selected],
      );
    }
    d3.select('#menuRange').html(rangeNow);

    d3.select('#' + grain + '.range')
      .classed('active', true)
      .selectAll('.timeframe')
      .html(function() {
        return dateFormatters[grain](
          dateRanges[grain].series[dateRanges[grain].selected],
        );
      });

    if (this.rangeSlider) {
      this.rangeSlider.update(this.filters);

      let grainIndex = grains.indexOf(this.filters.grain);

      const isTimeseries = window.location.href.includes('/timeseries');

      const handleLeftArrowClick = (d, i) => {
        let grain = grains[grainIndex];
        const dateRanges = { ...this.filters.dateRanges };

        if (isTimeseries && grain !== 'daily') return;
        if (dateRanges[grain].selected > 0) {
          dateRanges[grain].selected -= 1;
          this.changeFilter({ dateRanges });
          this.update({ ...this.filters, dateRanges });
        }
      };

      const handleRightArrowClick = (d, i) => {
        let grain = grains[grainIndex];
        const dateRanges = { ...this.filters.dateRanges };

        if (isTimeseries && grain !== 'daily') return;
        if (dateRanges[grain].selected < dateRanges[grain].series.length - 1) {
          dateRanges[grain].selected += 1;
          this.changeFilter({ dateRanges });
          this.update({ ...this.filters, dateRanges });
        }
      };

      d3.select('#left-menu-button').on('click', handleLeftArrowClick);
      d3.select('#right-menu-button').on('click', handleRightArrowClick);
    }
  };
}

export default D3TimeRangeNew;
