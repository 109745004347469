import {
  CHURN_DATA_FETCHED,
  SET_MAX_RADIUS_VALUE,
  SET_RADIUS_VARIABLE,
  SET_CHURN_ENVIRONMENTS,
  TURN_CHURN_ENVIRONMENTS_FALSE,
  TURN_CHURN_ENVIRONMENTS_TRUE,
} from '../actions/churn_actions';

const INIT_STATE = {
  data: null,
  radiusMax: 0,
  radiusVariable: null,
  env: null,
};

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case CHURN_DATA_FETCHED:
      return { ...state, ...{ data: action.data } };
    case SET_MAX_RADIUS_VALUE:
      return { ...state, ...{ radiusMax: action.value } };
    case SET_RADIUS_VARIABLE:
      return { ...state, ...{ radiusVariable: action.variable } };
    case SET_CHURN_ENVIRONMENTS:
      return { ...state, ...{ env: action.env } };
    case TURN_CHURN_ENVIRONMENTS_FALSE:
      return {
        ...state,
        ...{
          env: state.env.map(val => {
            return { ...val, ...{ on: false } };
          }),
        },
      };
    case TURN_CHURN_ENVIRONMENTS_TRUE:
      return {
        ...state,
        ...{
          env: state.env.map(val => {
            return { ...val, ...{ on: true } };
          }),
        },
      };
    default:
      return state;
  }
}
