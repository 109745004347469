import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { Security } from '@okta/okta-react';

//import Login from '../../containers/Login';
import Menu from '../../containers/Menu';
import CustomLoginCallback from '../../containers/Login/CustomLoginCallback';
import Home from '../../containers/Home';
import Profile from '../../containers/Profile';
import DevicesBubbles from '../../containers/DevicesBubbles';
import UnderContract from '../../containers/UnderContract';
import RelatedData from '../../containers/RelatedData';
//import Timeseries from '../../containers/Timeseries';
import ChurnLineBubbleCont from '../../containers/churnCont/ChurnLineBubbleCont';
import TreemapCont from '../../containers/TreemapCont/TreemapCont';
import Series from '../Series';

import ResponsiveContainer from '../Charts/react_charts/ResponsiveContainer';
import RadialRespCont from '../../containers/ResponsiveContainers/RadialRespCont';
import Glossary from '../Glossary';

import { OKTA_REDIRECT_URI, OKTA_CLIENT_ID, OKTA_ORG_URL } from '../../config';

import SecureRoute from '../SecureRoute'; //checkPermissions

// function onAuthRequired({ history }) {
//   history.push('/login');
// }

class RouterComponent extends Component {
  render() {
    return (
      <Router>
        <Security
          issuer={OKTA_ORG_URL}
          client_id={OKTA_CLIENT_ID}
          redirect_uri={OKTA_REDIRECT_URI}
          scope={['openid', 'email', 'profile', 'plume', 'dataviz']}
        >
          <Switch>
            <Route path="/implicit/callback" component={CustomLoginCallback} />

            <SecureRoute exact path="/" component={Home} />
            <SecureRoute exact path="/dashboard" component={Menu} />

            <SecureRoute
              exact
              path="/timeseries/:metric/:grain/:timestamp"
              component={Series}
            />
            <SecureRoute
              exact
              path="/metric-correlations"
              component={RelatedData}
            />
            <SecureRoute
              exact
              path="/metric-correlations/:metric/:grain/:timestamp"
              component={RelatedData}
            />
            <SecureRoute
              exact
              path="/under-contract"
              component={UnderContract}
            />
            <SecureRoute
              exact
              path="/under-contract/:metric/:grain/:timestamp"
              component={UnderContract}
            />
            <SecureRoute
              exact
              path="/devices-bubbles"
              component={DevicesBubbles}
            />

            <SecureRoute
              exact
              path="/devices-bubbles/:metric/:grain/:timestamp"
              component={DevicesBubbles}
            />

            <SecureRoute exact path="/radial" component={RadialRespCont} />
            <SecureRoute
              exact
              path="/radial/:metric/:grain/:timestamp"
              component={RadialRespCont}
            />
            <SecureRoute
              exact
              path="/churn-facet"
              component={ChurnLineBubbleCont}
            />
            <SecureRoute
              exact
              path="/churn-facet/:metric/:grain/:timestamp"
              component={ChurnLineBubbleCont}
            />
            <SecureRoute exact path="/treemap" component={TreemapCont} />
            <SecureRoute
              exact
              path="/treemap/:metric/:grain/:timestamp"
              component={TreemapCont}
            />
            <SecureRoute
              exact
              path="/splitchord"
              component={ResponsiveContainer}
            />
            <SecureRoute
              exact
              path="/splitchord/:metric/:grain/:timestamp"
              component={ResponsiveContainer}
            />
            <Route exact path="/glossary" component={Glossary} />
            <SecureRoute exact path="/profile" component={Profile} />
            <SecureRoute path="/" component={Home} />
          </Switch>
        </Security>
      </Router>
    );
  }
}

export default RouterComponent;
