/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

import { formatters } from '../helpers/d3/utils/formatters';

import {
  formatDeltaValuesNew,
  getFirstNumber,
  getSecondNumber,
} from './helpers';

import Breakdown from '../Breakdown';
import Icon from '../Icon';
import Info from '../Info';
import NumberComponent from './number';

class DataComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoIsOpen: false,
      showBreakdown: false,
    };
  }

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  closeInfo = () => {
    this.setState({
      infoIsOpen: false,
    });
  };

  openInfo = () => {
    this.setState({
      infoIsOpen: true,
    });
  };

  holdBreakDown = e => {
    e.preventDefault();

    this.setState({ showBreakdown: true });
  };
  hideBreakdown = e => {
    e.preventDefault();
    this.setState({ showBreakdown: false });
  };

  openGlossary = () => {
    window.open('../glossary', '_blank');
  };

  render() {
    const { data, grain, dateRanges, color, metric } = this.props;
    const { infoIsOpen } = this.state;
    const { invertDelta } = this.props.data;

    const statContentArray =
      data.formatter && !isNaN(data.number)
        ? formatters[data.formatter](data.number)
        : [null, data.number || 'n/a', null];

    const formatedData = formatDeltaValuesNew(data);
    const firstNumber = getFirstNumber(formatedData);
    const secondNumber = getSecondNumber(formatedData);
    return (
      <div
        className="tier2"
        style={
          this.props.data.number === undefined && !this.props.show
            ? { opacity: 0.1, pointerEvents: 'none' }
            : { opacity: 1, pointerEvents: 'auto' }
        }
        onMouseLeave={this.hideBreakdown}
        data-testid="data-component"
      >
        <div className="statDiv">
          <div className="statName">
            <span className={'subtitle' + color} data-testid="title">
              {data.name}
            </span>
          </div>
          <div className="statWrapMult">
            <span className="stat" onMouseEnter={this.holdBreakDown}>
              <span className="prefix" />
              <NumberComponent
                showBreakDownClick={this.holdBreakDown}
                number={statContentArray[1]}
                decPlaces={statContentArray[3]}
              />
              <span className="suffix">{statContentArray[2]}</span>
              <Breakdown
                tohideBreakdown={this.hideBreakdown}
                toShowBreakdown={this.holdBreakDown}
                show={this.state.showBreakdown}
                data={data}
                color={color}
              />
            </span>
          </div>
          <div
            className={
              formatedData.delta < 0 && invertDelta
                ? 'statDelta positive'
                : invertDelta
                ? 'statDelta'
                : formatedData.delta > 0
                ? 'statDelta positive'
                : 'statDelta'
            }
          >
            {firstNumber}
            {formatedData.delta > 0 && <span>&nbsp;&uarr;&nbsp;</span>}
            {formatedData.delta < 0 && <span>&nbsp;&darr;&nbsp;</span>}
            {formatedData.delta === 0 && <span>&nbsp;&bull;&nbsp;</span>}
            {secondNumber}
          </div>
          <div className="flex icons flex-icons">
            <a
              className={'iconBt icon-info active button' + color}
              onClick={() => this.openInfo()}
              data-testid="icon-component"
            >
              <div className="tooltip">Info</div>
            </a>
            {formatedData.links.map((item, index) => (
              <Icon
                item={item}
                key={index}
                grain={grain}
                csvName={formatedData.csvName}
                dateRanges={dateRanges}
                onClick={this.props.redirect}
                color={color}
                metric={metric}
              />
            ))}
          </div>
        </div>
        <Info
          formatedData={formatedData}
          color={color}
          infoIsOpen={infoIsOpen}
          closeInfo={this.closeInfo}
          openGlossary={this.openGlossary}
        />
      </div>
    );
  }
}

export default DataComponent;
