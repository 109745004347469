import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';

import D3RelatedData from '../../components/helpers/d3/relatedData';
import { UNAUTHORIZED_MESSAGE } from '../../utils/constants';
import { API_URL } from '../../config';

import { getVizFilters } from '../../utils/helper';

import { logoutUser } from '../../actions/auth_actions';
import { changeFilters } from '../../actions/filter_actions';
import D3NotificationPopup from '../../components/helpers/d3/notificationPopup';

import Token from '../../components/okta/Token';

class RelatedData extends Component {
  d3Instance = null;

  state = {
    rawData: null,
    data: {
      received: false,
    },
  };

  redirect = path => {
    this.props.history.push(path);
  };

  async componentDidMount() {

    if(window.spinner){
      window.spinner.init();
    }
    
    try {
      const oktaToken = new Token(localStorage['okta-token-storage']);
      const token = 'Bearer ' + oktaToken.accessToken;
      const res = await axios.get(`${API_URL}/data/dashboard`, {
        headers: {
          Authorization: token,
        },
      });
      if (!res.data) {
        new D3NotificationPopup({
          title: 'Error!',
          message: `Currently there is no data for this module for the specific client`,
          type: 'error',
        }).create(this._rootNode);
        return;
      }

      this.d3Instance = new D3RelatedData({
        filters: getVizFilters(this.props.filters, this.props.match.params),
        changeFilters: this.props.changeFilters,
        rawData: res.data,
      });
      this.d3Instance.create(this._rootNode);
      this.setState({
        rawData: res.data,
        data: {
          received: true,
        },
      });


      if(window.spinner){
        window.spinner.finish();
      }

    } catch (err) {
      if (err.response && err.response.data === UNAUTHORIZED_MESSAGE) {
        this.props.logoutUser();
      } else {
        new D3NotificationPopup({
          title: 'Error while fetching data!',
          message: `Please try again later`,
          type: 'error',
        }).create(this._rootNode);
      }

      if(window.spinner){
        window.spinner.finish();
      }
    }
    return Promise.resolve();
  }

  componentDidUpdate() {
    if (this.state.data.received) this.d3Instance.update(this.props.filters);
    //return false;
  }

  // shouldComponentUpdate(nextProps) {
  //   const { rawData } = this.state;
  //   const { grain, metric } = this.props.filters;
  //   if (
  //     rawData &&
  //     (metric !== nextProps.metric ||
  //       grain !== nextProps.filters.grain ||
  //       this.props.filters.dateRanges[grain].selected !==
  //         nextProps.filters.dateRanges[grain].selected)
  //   ) {
  //     this.d3Instance.destroy(this._rootNode);
  //     this.d3Instance = new D3RelatedData({
  //       filters: nextProps.filters,
  //       changeFilters: this.props.changeFilters,
  //       rawData,
  //     });
  //     this.d3Instance.create(this._rootNode);
  //     return true;
  //   }
  //   return false;
  // }

  render() {
    return (
      <div
        className="full-size metric-correlations"
        ref={c => {
          this._rootNode = c;
        }}
      >
        <div id="titleText" />
        <div id="subtitleText" />
        <div id="errorWrap" />
        <div id="chartDiv" />
      </div>
    );
  }
}

const mapStateToProps = ({ filters }) => ({ filters });

export default withRouter(
  connect(
    mapStateToProps,
    { changeFilters, logoutUser },
  )(RelatedData),
);
