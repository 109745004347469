import { connect } from 'react-redux';
import MenuController from '../../components/timeslider_react/MenuController';

import { menuOn, menuOff, menuOnOff } from '../../actions//headerMenu_actions';
import {
  changeFilterGrainSelectedThunk,
  changeGrainThunk,
} from '../../actions/filter_actions';

import { parseURL } from '../../components/helpers/parseURL';
import dataSkeleton from '../../components/Skeleton/dataSkeleton';

const mapStateToProps = state => {
  const urlInfo = parseURL();
  const timeIndex =
    state.filters.dateRanges !== undefined &&
    state.filters.dateRanges !== null &&
    state.filters.grain !== undefined &&
    state.filters.dateRanges[state.filters.grain] !== undefined
      ? state.filters.dateRanges[state.filters.grain].selected
      : null;

  const pagesContainingGrainMenu = [
    'dashboard',
    'timeseries',
    'metric-correlations',
  ];

  const skele = new dataSkeleton();
  let {
    filters: { metric },
  } = state;

  if (metric === undefined) {
    metric = urlInfo.title;
  }

  let availableGrains =
    urlInfo.chart === 'dashboard'
      ? ['daily', 'month', 'qtr', 'year', 'ttm', 'alltime', 'now']
      : skele.csvLookup[metric].links[0].availGrains || [];
  availableGrains.push('total');

  return {
    grain:
      state.filters.dateRanges !== undefined &&
      state.filters.dateRanges !== null
        ? state.filters.grain
        : null,
    currentDate:
      state.filters.dateRanges !== undefined &&
      state.filters.dateRanges !== null &&
      state.filters.grain !== undefined &&
      state.filters.dateRanges[state.filters.grain] !== undefined
        ? state.filters.dateRanges[state.filters.grain].series[timeIndex]
        : null,
    headerMenu_visible:
      state.filters.dateRanges !== undefined &&
      state.filters.dateRanges !== null
        ? state.headerMenu.visible
        : null,
    dates:
      state.filters.dateRanges !== undefined &&
      state.filters.dateRanges !== null
        ? state.filters.dateRanges
        : null,

    availableGrains,
    showGrainMenu: pagesContainingGrainMenu.includes(urlInfo.chart),
    isChoropleth: urlInfo.chart === 'choropleth-zoom',
    chartTitle: urlInfo.title,
    choroplethDataCreation: state.choropleth.time,
    isDashboard: urlInfo.chart === 'dashboard',
    bubbleChartDate: state.bubble.date,
    isBubbleChart: urlInfo.chart === 'devices-bubbles',
    isTreemap: urlInfo.chart === 'treemap',
    treemapChartDate:
      urlInfo.chart === 'treemap' && state.treemap.data !== null
        ? state.treemap.data[0].ts
        : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    menuOn: () => {
      dispatch(menuOn());
    },
    menuOff: () => {
      dispatch(menuOff());
    },
    menuOnOff: () => {
      dispatch(menuOnOff());
    },
    changeGrain: grain => {
      dispatch(changeGrainThunk(grain));
    },
    changeSelected: index => {
      dispatch(changeFilterGrainSelectedThunk(index));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuController);
