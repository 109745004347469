import { connect } from 'react-redux';
import MainChurnDash from '../../components/Charts/Churn/MainChurnDash';
import {
  fetchData,
  setRadiusVariable,
  filterChurn,
  turnAllChurnOff,
  resizeChurnOn,
  turnChurnEnvironmentsTrue,
} from '../../actions/churn_actions';

import {
  turnFilterOnOff,
  resetEnvironments,
  turnEnvironmentsOff,
} from '../../actions/filter_chart_actions';

import { changeDeploymentEnvironments } from '../../actions/filter_actions';

const mapStateToProps = state => {
  return {
    data: state.churn.data,
    radiusMax: state.churn.radiusMax,
    radiusVariable: state.churn.radiusVariable,
    environments: state.filters.environments,
    churn_env: state.churn.env,
    filters: state.filters,
    match: { grain: 'month', metric: 'devicesseen', timestamp: '2019-07-01' },
    showFilterTool: state.filter_chart.visible,
    outerValue:
      state.churn.radiusVariable === 'active households'
        ? 'new activations'
        : null,
    timeAxisValues:
      state.churn.data === null ? undefined : state.churn.data[0].values,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getData: () => {
      dispatch(fetchData());
    },
    setRadiusVariable: variable => {
      dispatch(setRadiusVariable(variable));
    },
    toolOnOff: () => {
      dispatch(turnFilterOnOff());
    },
    resetEnvironments: () => {
      dispatch(resetEnvironments());
    },
    changeDeploymentEnvironments: environment => {
      dispatch(changeDeploymentEnvironments(environment));
    },
    environmentsFilter: env => {
      dispatch(filterChurn(env));
    },
    turnEnvironmentsOff: () => {
      dispatch(turnEnvironmentsOff());
    },
    turnAllChurnOff: () => {
      dispatch(turnAllChurnOff());
    },

    churnResize: () => {
      dispatch(resizeChurnOn());
    },
    turnChurnEnvironmentsTrue: () => {
      dispatch(turnChurnEnvironmentsTrue());
    },
  };
};

const ChurnLineBubbleCont = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainChurnDash);

export default ChurnLineBubbleCont;
