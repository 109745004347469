import { MENU_ON, MENU_OFF, MENU_ON_OFF } from '../actions/headerMenu_actions';

const init_state = {
  visible: false,
};

export default function headerMenuReducer(state = init_state, action = null) {
  switch (action.type) {
    case MENU_ON:
      return { ...state, ...{ visible: true } };
    case MENU_OFF:
      return { ...state, ...{ visible: false } };
    case MENU_ON_OFF:
      return { ...state, ...{ visible: !state.visible } };
    default:
      return state;
  }
}
