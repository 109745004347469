import React, { Component } from 'react';

class PopUp extends Component {
  render() {
    return (
      <div
        className="react-popup-component"
        style={{
          position: 'absolute',
          top: this.props.y,
          left: this.props.x,
          zIndex: 10000,
          borderRadius: '10px',
        }}
      >
        {this.props.text}
      </div>
    );
  }
}

export default PopUp;
