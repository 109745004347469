//this function is used to get the correct index of the min and max of the x axis
//@params series   whole times series for the data of every  available date for the grain
//@params axisLabels   the axislables that are shown  when the chart is intitialized
//@params xscale       the current xScale for the brush

function getStartEndPix(series, axisLabels, xScale) {
  let minStartIndex = 0;
  let maxEndIndex = 0;
  series.forEach((time, i) => {
    if (time === axisLabels[0]) {
      minStartIndex = i;
    }
    if (time === axisLabels[axisLabels.length - 1]) {
      maxEndIndex = i;
    }
  });

  const startPix = xScale(series[minStartIndex]);
  const endPix = xScale(series[maxEndIndex]);
  return [startPix, endPix];
}

export { getStartEndPix };
