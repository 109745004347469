import axios from 'axios';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGGED_OUT = 'AUTH_LOGGED_OUT';

export const authUser = tokenRaw => {
  const AUTH_TOKEN = 'Bearer ' + tokenRaw;
  localStorage.setItem('token', AUTH_TOKEN);
  axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
};

export const oktaAuthUser = () => {};

// function getTokenFromLocalStorage() {
//   const OKTA_TOKEN_STORAGE = localStorage.getItem('okta-token-storage');
//
//   if (OKTA_TOKEN_STORAGE) {
//     const json = JSON.parse(OKTA_TOKEN_STORAGE);
//     if (json.accessToken && json.accessToken.accessToken) {
//       return json.accessToken.accessToken;
//     }
//   }
//   return null;
// }

export const oktaLoginUser = () => {
  return {
    type: AUTH_USER,
  };
};

export const loginUser = () => async dispatch => {
  // return await axios
  //   .post(`${API_URL}/auth/login`)
  //   .then(async response => {
  //     await authUser(response.data.token);
  //     dispatch({ type: AUTH_USER });
  //     return { success: true };
  //   })
  //   .catch(error => {
  //     dispatch(handleErrors(error, AUTH_ERROR));
  //     return { success: false };
  //   });

  return { success: true };
};

export const logoutUser = () => {
  const currentRoute = localStorage.getItem('current_route');
  localStorage.setItem('current_route', currentRoute);
  delete axios.defaults.headers.common.Authorization;

  return {
    type: AUTH_LOGOUT,
  };
};
