import * as d3 from 'd3';

import {
  //numberWithCommas,
  makeSelector,
  handleMouseOverDeviceBubbles,
  handleMouseOutDeviceBubbles,
} from './utils/helpers';

class D3Circle {
  constructor(options) {
    this.options = options;
    this.circle = null;
    this.text = null;
    this.count = null;
    this.totalNumber = options.totalNumber;
  }

  /**
   * Create instance of D3.js component
   * @param el
   */
  create = el => {
    const { data, config } = this.options;
    this.circle = el.selectAll('circle').data(data, d => d.data.name);
    this.text = el.selectAll('text.label').data(data, d => d.data.name);
    if (config.showBubbleDeviceCount) {
      this.count = el.selectAll('text.count').data(data, d => d.data.name);
    }

    //EXIT
    // this.exit();

    //UPDATE
    this.update(el);

    //ENTER
    // this.enter(el);
  };

  exit = () => {
    const { circle, text, count, options } = this;
    const { config, transition, outerRingRadius, filters } = options;

    const hue = filters.colors ? filters.colors.primaryHue : 120;

    circle
      .exit()
      // .classed('exiting',true)
      .transition(transition)
      .duration(1000)
      .attr('cx', d => {
        d.orbitCoords = this.getRandomRingCoords(outerRingRadius);
        return d.orbitCoords[0];
      })
      .attr('cy', d => d.orbitCoords[1])
      .attr('r', 1e-6)
      //  .style('fill', 'hsla(196, 40%, 10%, 1)')
      .style('fill', 'hsla(' + hue + '), 40%, 10%, 1)')
      .style('opacity', 0)
      .remove();

    text
      .exit()
      // .transition(transition)
      // .duration(300)
      // .attr('x',d => d.orbitCoords[0])
      // .attr('y',d => d.orbitCoords[1])
      // .style('opacity', 1e-6)
      .remove();

    if (config.showBubbleDeviceCount) {
      count
        .exit()
        // .transition(transition)
        // .duration(1000)
        // .attr('x',(d) => {
        //   d.orbitCoords = this.getRandomRingCoords(outerRingRadius);
        //   return d.orbitCoords[0];
        // })
        // .attr('y',(d) => d.orbitCoords[1])
        // .style('opacity', 1e-6)
        .remove();
    }
  };

  update = el => {
    const { circle, text, count, options } = this;
    const { config, transition } = options;

    // EXIT
    this.exit();

    // UPDATE
    circle
      .transition(transition)
      .duration(500)
      .attr('r', d => d.r || 0)
      .attr('cx', d => d.x)
      .attr('cy', d => d.y);

    text
      .transition(transition)
      .duration(500)
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .style('opacity', (d, index, parent) => {
        d.showTitle =
          d.r * 2 - 10 > parent[index].getComputedTextLength() * 0.75;
        return d.showTitle ? 1 : 1e-6; //the 10 is a fudged number to avoid cutting off text.
      });

    if (config.showBubbleDeviceCount) {
      count
        .text((d, e, f) =>
          d3.format('.2%')(parseInt(d.data.value) / parseInt(this.totalNumber)),
        )
        .transition(transition)
        .duration(500)
        .attr('x', d => d.x)
        .attr('y', d => d.y + 15)
        .style('opacity', d => (d.showTitle ? 1 : 0)); // the 10 is a fudged number to avoid cutting off text.
    }

    // ENTER
    this.enter(el);
  };

  getRandomRingCoords = radius => {
    const angle = Math.random() * Math.PI * 2;
    const x = Math.cos(angle) * radius + window.innerWidth / 2;
    const y = Math.sin(angle) * radius + window.innerHeight / 2;
    return [x, y];
  };

  enter = el => {
    const { circle, text, count, options } = this;
    const { config, transition, outerRingRadius, filters } = options;

    const hue = filters.colors ? filters.colors.primaryHue : 120;

    circle
      .enter()
      .append('circle')
      .classed('bubbleCircle', true)
      .attr('r', 1e-6)
      .attr('cx', d => {
        d.orbitCoords = this.getRandomRingCoords(outerRingRadius);
        return d.orbitCoords[0];
      })
      .attr('cy', d => d.orbitCoords[1])
      // .attr('cx', d => d.x)
      // .attr('cy', d => d.y)
      .style('fill', 'hsla(' + hue + ', 40%, 10%, 1)')
      // .style('fill', '#fff')
      .on('mouseover', handleMouseOverDeviceBubbles(el))
      .on('mouseout', handleMouseOutDeviceBubbles(el))
      .attr('data-selector', d => {
        return makeSelector(d.data.name);
      })
      .transition(transition)
      .duration(1000)
      .delay(500)
      // .style('fill', 'hsla(196, 100%, 60%, 1)')
      .style('fill', 'hsla(' + hue + ', 100%, 60%, 1)')
      .attr('cx', d => d.x)
      .attr('cy', d => d.y)
      // .style('fill', 'hsla(196, 100%, 60%, 1')
      // .style("fill", "url(#circleGradient)")
      .attr('r', d => d.r || 0);

    text
      .enter()
      .append('text')
      .style('opacity', 0)
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .classed('label', true)
      .style('alignment-baseline', d =>
        config.showBubbleDeviceCount ? 'after-edge' : 'middle',
      )
      .text(d =>
        d.data.name === 'null' || d.data.name === '(null'
          ? 'Unknown'
          : d.data.name,
      )
      .attr('data-selector', d => {
        return makeSelector(d.data.name);
      })
      // .each(function(d) {
      //   d3.select(this).classed(makeSelector(d.data.name), true);
      // }, true
      .style('opacity', (d, index, parent) => {
        d.showTitle =
          d.r * 2 - 10 > parent[index].getComputedTextLength() * 0.75;
        return d.showTitle ? 1 : 1e-6; //the 10 is a fudged number to avoid cutting off text.
      });

    if (config.showBubbleDeviceCount) {
      count
        .enter()
        .append('text')
        .style('opacity', 0)
        .attr('x', d => d.x)
        .attr('y', d => d.y + 15)
        .classed('count', true)
        .style('alignment-baseline', d =>
          config.showBubbleDeviceCount ? 'before-edge' : 'middle',
        )
        .each((d, i, a) => {
          d3.select(a[i]).classed(makeSelector(d.data.name), true);
        }, true)
        .text(d =>
          d.data.value
            ? d3.format('.2%')(
                parseInt(d.data.value) / parseInt(this.totalNumber),
              )
            : '',
        )
        .transition(transition)
        .delay(750)
        .style('opacity', (d, index, parent) => (d.showTitle ? 1 : 0));
    }
  };

  destroy = () => {};
}

export default D3Circle;
