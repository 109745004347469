import React, { Component } from 'react';
import { withRouter } from 'react-router';

import D3Glossary from '../helpers/d3/glossary';
import D3LoadingSpinner from '../../components/helpers/d3/loadingSpinner';

class GlossaryNew extends Component {
  d3Spinner = new D3LoadingSpinner({});

  redirect = path => {
    this.props.history.push(path);
  };

  d3Instance = new D3Glossary({
    redirect: this.redirect,
  });

  componentDidMount() {
    this.d3Spinner.create(this._rootNode);
    window.spinner = this.d3Spinner;
    this.d3Instance.create(this._rootNode);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div style={{ marginTop: '-40px' }} id="animated-switch-div">
        <div
          className="full-size glossary-module"
          width="800"
          height="800"
          ref={c => {
            this._rootNode = c;
          }}
        >
          <div id="scrollPane" />
        </div>
      </div>
    );
  }
}

export default withRouter(GlossaryNew);
