import { SET_DATA_TIME } from '../actions/choropleth_actions';

const INIT_STATE = {
  time: null,
};

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case SET_DATA_TIME:
      return { ...state, ...{ time: action.time } };
    default:
      return state;
  }
}
