import * as d3 from 'd3';
import { debounce } from 'lodash';
import moment from 'moment';

import { UNAUTHORIZED } from '../../../utils/constants';
import D3NotificationPopup from './notificationPopup';
import dataSkeleton from '../../Skeleton/dataSkeleton';
import Token from '../../okta/Token';

class D3UnderContract {
  constructor(options) {
    this.options = options;
    this.underContract = null;
    this.totalsColumn = {};
  }

  /**
   * Create instance of D3.js component
   * @param el
   */
  create = el => {

    const { dataApi, logoutUser } = this.options;

    let data, width, height;
    const body = (this.underContract = d3.select(el));
    const svg = body.append('svg').attr('id', 'stage');

    let skele = new dataSkeleton(this.options);
    const primaryHue = skele.getByCsvName('householdsundercontract').colors
      .primaryHue;

    const svgDefs = [
      `<defs>
        <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4" patternTransform="rotate(90)">
          <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" style="stroke:hsla(` +
        primaryHue +
        `, 100%, 70%, 1); stroke-width:1.5" />
        </pattern>
    </defs>`,
      `<defs>
      <marker id="arrow" markerWidth="10" markerHeight="10" refX="0" refY="3" orient="auto" markerUnits="strokeWidth">
        <path d="M0,0 L0,6 L9,3 z" fill="#fff" />
      </marker>
    </defs>
  `,
    ];

    const config = {
      margins: 20,
      padding: 10,
    };

    const redraw = () => {
      // finish loader
      width = window.innerWidth - 200;
      height = window.innerHeight - 40;

      const columns = Object.getOwnPropertyNames(data[0]).slice(1);

      const slotWidth = Math.max(
        150,
        (width - config.margins * 2) / (columns.length + 3),
      );
      const slotHeight = (height - config.margins * 2) / (data.length + 1);

      let extraColumns = columns.length + 3 - Math.ceil(width / slotWidth);

      const extraWidth = slotWidth * extraColumns + slotWidth / 2;

      svg.attr('width', width + extraWidth).attr('height', height);
      // svg.style('padding-right', '300px');

      svg.html(svgDefs.join(''));
      body.selectAll('.metaTrigger').remove();
      body.selectAll('.totalCol').remove();
      body.selectAll('#total_container').remove();

      const maxDimension = Math.floor(Math.min(slotWidth, slotHeight));
      const maxDimensionPadded = maxDimension - config.padding * 2;

      const today = new Date();
      const thisYear = parseInt(today.getFullYear());

      const currentYearRowIndex = data.findIndex(
        datum => datum.Year === thisYear,
      );

      //get the biggest/smallest values from the market cap column.
      let minValue = Infinity;
      let maxValue = 0;
      data.forEach((datum, i) => {
        let arr = [];
        Object.values(datum)
          .slice(1, columns.length + 1)
          .forEach(({ households, deployed }) => {
            if (households) {
              arr.push(households);
            }
            if (deployed) {
              arr.push(deployed);
            }
          });
        minValue = Math.min(minValue, ...arr);
        maxValue = Math.max(
          maxValue,
          d3.sum(
            Object.values(datum).slice(1, columns.length + 1),
            val => val.households,
          ),
        );
      });
      const percentageDiff = minValue / maxValue;

      //figure out our biggest area extents
      const maxArea = Math.PI * Math.pow(maxDimensionPadded, 2);
      const minArea =
        Math.PI * Math.pow((percentageDiff * maxDimensionPadded) / 2, 2);

      const getCoords = (xIndex, yIndex) => {
        const cx = xIndex * slotWidth + slotWidth / 2 + config.margins;
        const cy = yIndex * slotHeight + slotHeight / 2 + config.margins;
        return [cx, cy];
      };

      //method to get the radius of a circle based on the numeric value

      const radiusDerivedFromArea = val => {
        if (val > 0) {
          const proportion = d3
            .scaleLinear()
            .domain([minValue, maxValue])
            .range([minArea, maxArea]);
          const targetArea = proportion(val);
          return Math.sqrt(targetArea / Math.PI) / 2;
        } else return 0;
      };

      const radiusDerivedFromAreaFloater = val => {
        if (val > 0) {
          const proportion = d3
            .scaleLinear()
            .domain([minValue, maxValue])
            .range([
              minArea,
              Math.PI * Math.pow(width / 8.7 / (width / height), 2),
            ]);
          const targetArea = proportion(val);
          return Math.sqrt(targetArea / Math.PI) / 2;
        } else return 0;
      };

      const appendTotalsCol = () => {
        const floater = body
          .append('div')
          .attr('class', 'total_container')
          .attr('id', 'total_container');
        // .style('width', '100%');
        const floaterSvg = floater.append('svg').attr('id', 'floater');

        const pos = getCoords(0, 0);

        floater
          .append('div')
          .classed('totalCol', true)
          .style('left', '0px')
          .style('top', config.padding + 'px')
          .style('width', slotWidth + 'px')
          .style('height', height + 'px')
          .style('opacity', 0)
          .transition()
          .duration(1000)
          .style('opacity', 0.5);

        const floaterLabelG = floaterSvg
          .append('g')
          .attr('transform', 'translate(' + pos[0] + ',' + slotHeight + ')');

        floaterLabelG
          .append('text')
          .attr('transform', () => {
            return (
              'translate(0 ' + (0 - (slotHeight / 2 + config.padding)) + ')'
            );
          })
          .text('Total')
          .classed('xLabel', true)
          .style('opacity', 0)
          .transition()
          .duration(500)
          .style('opacity', 1);

        floaterLabelG
          .append('text')
          .attr('transform', 'translate(0 ' + (0 - slotHeight / 3) + ')')
          .text(
            parseFloat(
              this.totalsColumn.data[currentYearRowIndex].households,
            ).toFixed(1) + ' Mil',
          )
          .classed('xLabelNum', true)
          .style('opacity', 0)
          .transition()
          .duration(500)
          .style('opacity', 1);

        floaterLabelG
          .append('circle')
          .attr('r', 2)
          .attr('cx', 0)
          .attr('cy', 0 - slotHeight / 3 + config.margins)
          .classed('xLabelDot', true)
          .style('opacity', 0)
          .transition()
          .duration(1000)
          .delay(500)
          .style('opacity', 1);

        // LINE FOR LAST COL. STATISTICS
        const lastItemList = this.totalsColumn.data;
        const label = this.totalsColumn.data[0];
        lastItemList.forEach(({ households, deployed }, i) => {
          const pos = getCoords(0, i + 1);

          floaterSvg
            .append('line')
            .attr('x1', pos[0] + radiusDerivedFromAreaFloater(households))
            .attr('y1', pos[1])
            .attr('x2', pos[0] - 15 + (3 * slotWidth) / 4)
            .attr('y2', pos[1])
            .classed('connectingLineTotal', true)
            .style('stroke-width', '1px');
          floaterSvg
            .append('circle')
            .attr('r', 2)
            .attr('cx', pos[0] - 15 + (3 * slotWidth) / 4)
            .attr('cy', pos[1])
            .classed('totalDot', true)
            .style('opacity', 0)
            .transition()
            .duration(1000)
            .delay(500)
            .style('opacity', 1);

          const percentage = (deployed * 100) / households;
          floater
            .append('div')
            .classed('metaTrigger', true)
            .style('left', pos[0] + slotWidth / 2 + 5 + 'px')
            .style(
              'top',
              pos[1] -
                (this.totalsColumn.column.Year > thisYear ? 25 : 33) +
                'px',
            )
            .style('width', slotWidth + 'px')
            .style('height', slotHeight + 'px')
            .style('color', '#fff')
            .style('text-align', 'center')
            .style('font-size', '14px')
            .html(
              `<span class="totalHeader">${deployed} Mil</span>${
                this.totalsColumn.column.Year > thisYear
                  ? ''
                  : `<br/>of ${households.toFixed(1)} Mil`
              }<br/><span class="totalDenominator">${percentage.toFixed(
                1,
              )}%</span>`,
            );
        });
        data.forEach((value, j) => {
          // if (j === 0) return;
          let pos2 = getCoords(0, j + 1);
          floaterSvg
            .append('circle')
            .attr('cx', pos2[0])
            .attr('cy', pos2[1])
            .classed('marketCap', true)
            .classed(
              'marketCapProjected',
              () => parseInt(value.Year) > thisYear,
            )
            .attr('r', 0)
            .style('opacity', 0)
            .style('fill', 'hsla(' + primaryHue + ', 100%, 70%, 1);')
            .transition()
            .duration(1000)
            .style('opacity', 1)
            .attr('r', () => {
              return radiusDerivedFromAreaFloater(
                this.totalsColumn.data[j].households,
              );
            });

          floaterSvg
            .append('line')
            .attr('x1', pos2[0])
            .attr('x2', pos2[0])
            .attr('y1', () => {
              return (
                pos2[1] -
                radiusDerivedFromAreaFloater(
                  this.totalsColumn.data[j].households,
                )
              );
            })
            .attr('y2', () => {
              let y2Val = 0;
              if (j === 0) {
                y2Val = 0 + (slotHeight / 3) * 2 + config.margins;
              } else {
                let n = 0 + config.margins;
                if (j > 0) {
                  n = radiusDerivedFromAreaFloater(
                    Object.values(this.totalsColumn.data[j].households || 0),
                  );
                }

                y2Val =
                  pos2[1] -
                  slotHeight +
                  n +
                  radiusDerivedFromAreaFloater(
                    this.totalsColumn.data[j - 1].households || 0,
                  );
              }

              return y2Val;
            })
            .classed('connectingLineVert', true)
            .style('opacity', 0)
            .transition()
            .duration(1000)
            .delay(500)
            .style('opacity', 1);

          floaterSvg
            .append('circle')
            .attr('cx', pos2[0])
            .attr('cy', pos2[1])
            .classed('activeHouseholds', true)
            .classed(
              'activeHouseholdsProjected',
              () => parseInt(label) > thisYear,
            )
            .attr('r', 0)
            .style('opacity', 0)
            .style('fill', 'hsla(' + primaryHue + ', 100%, 70%, 1)')
            .transition()
            .duration(1000)
            .delay(500)
            .style('opacity', 1)
            .attr('r', () => {
              return radiusDerivedFromAreaFloater(
                this.totalsColumn.data[j].deployed,
              );
            });
        });
      };
      appendTotalsCol();

      //X axis labels
      columns.forEach((datum, i) => {
        // if (i > 0) {
        const pos = getCoords(i + 1, 0);
        const labelG = svg
          .append('g')
          .attr('transform', 'translate(' + pos[0] + ',' + slotHeight + ')');
        labelG
          .append('text')
          .attr('transform', () => {
            return (
              'translate(0 ' + (0 - (slotHeight / 2 + config.padding)) + ')'
            );
          })
          .text(datum)
          .classed('xLabel', true)
          .style('opacity', 0)
          .transition()
          .duration(500)
          .style('opacity', 1);

        labelG
          .append('text')
          .attr('transform', 'translate(0 ' + (0 - slotHeight / 3) + ')')
          .text(
            parseFloat(data[currentYearRowIndex][datum].households).toFixed(2) +
              ' Mil',
          )
          .classed('xLabelNum', true)
          .style('opacity', 0)
          .transition()
          .duration(500)
          .style('opacity', 1);

        labelG
          .append('circle')
          .attr('r', 2)
          .attr('cx', 0)
          .attr('cy', 0 - slotHeight / 3 + config.margins)
          .classed('xLabelDot', true)
          .style('opacity', 0)
          .transition()
          .duration(1000)
          .delay(500)
          .style('opacity', 1);
        // }
      });

      //outer circles
      data.forEach((dataRow, index) => {
        let pos;
        // if (index > 0) {
        Object.values(dataRow).forEach(({ households, deployed }, i) => {
          if (i === 0) return;
          pos = getCoords(i, index + 1);
          svg
            .append('circle')
            .attr('cx', pos[0])
            .attr('cy', pos[1])
            .classed('marketCap', true)
            .classed(
              'marketCapProjected',
              () => parseInt(dataRow.Year) > thisYear,
            )

            .attr('r', 0)
            .style('opacity', 0)
            .style('fill', 'hsla(' + primaryHue + ', 100%, 70%, 1);')
            .transition()
            .duration(1000)
            .style('opacity', 1)
            .attr('r', radiusDerivedFromArea(households));

          svg
            .append('line')
            .attr('x1', pos[0])
            .attr('x2', pos[0])
            .attr('y1', () => {
              return pos[1] - radiusDerivedFromArea(households);
            })
            .attr('y2', () => {
              const y2Val =
                i === 0
                  ? 0 + (slotHeight / 3) * 2 + config.margins
                  : pos[1] -
                    slotHeight +
                    (index > 0
                      ? radiusDerivedFromArea(
                          Object.values(data[index - 1])[i].households || 0,
                        )
                      : 0 + config.margins);
              return y2Val;
            })
            .classed('connectingLineVert', true)
            .style('opacity', 0)
            .transition()
            .duration(1000)
            .delay(500)
            .style('opacity', 1);
        });
        // }
      });

      //draw the inner circles and labels
      data.forEach((datum, i) => {
        const values = Object.values(datum);
        // if (i > 0) {
        //Year Labels
        const label = values.shift();

        let pos = getCoords(0, i + 1);
        svg
          .append('text')
          .attr('x', pos[0])
          .attr('y', pos[1])
          .text(label)
          .classed('yLabel', true)
          .attr('alignment-baseline', 'middle')
          .style('opacity', 0)
          .transition()
          .duration(500)
          .style('opacity', 1);

        //Circles
        values.forEach((value, j) => {
          pos = getCoords(j + 1, i + 1);
          svg
            .append('circle')
            .attr('cx', pos[0])
            .attr('cy', pos[1])
            .classed('activeHouseholds', true)
            .classed(
              'activeHouseholdsProjected',
              () => parseInt(label) > thisYear,
            )
            .attr('r', 0)
            .style('opacity', 0)
            .style('fill', 'hsla(' + primaryHue + ', 100%, 70%, 1)')
            .transition()
            .duration(1000)
            .delay(500)
            .style('opacity', 1)
            .attr('r', function() {
              return radiusDerivedFromArea(value.deployed);
            });

          const currentYear = parseInt(moment().format('YYYY'));
          const deploymentLabel =
            currentYear >= datum.Year ? 'Deployed' : 'Deployment estimate';

          body
            .append('div')
            .classed('metaTrigger', true)
            .style('left', pos[0] - slotWidth / 2 + 'px')
            .style('top', pos[1] - slotHeight / 2 + 'px')
            .style('width', slotWidth + 'px')
            .style('height', slotHeight + 'px')
            .append('div')
            .classed('metaTriggerTooltip', true)
            .html(d => {
              return `
                <div>Households: <span>${value.households} Mil</span></div>
                 <div>${deploymentLabel}: <span>${
                value.deployed
              } Mil</span></div>
                ${
                  parseFloat(value.deployed / value.households) > 0
                    ? `<div><span>${(value.deployed / value.households).toFixed(
                        2,
                      )}%</span> ${deploymentLabel}</div>`
                    : ``
                }
              `;
            });
        });
      });
    };

    if(window.spinner){
    window.spinner.init();
  }
    const oktaToken = new Token(localStorage['okta-token-storage']);
    const token = 'Bearer ' + oktaToken.accessToken;

    d3.json(`${dataApi}`, {
      headers: { Authorization: token },
    })
      .then((rawData, err) => {
        if (err) {
          alert(err);
        }
        data = rawData.data;

        this.totalsColumn = {
          column: 'Total',
          // data: [
          //   81.1,
          //   { households: 24.413, deployed: 0.2 },
          //   { households: 85.5294, deployed: 5.475 },
          //   { households: 85.6294, deployed: 23.61 },
          //   { households: 85.5294, deployed: 39.87 },
          //   { households: 85.5294, deployed: 56.63 },
          // ],
          data: [],
        };

        data.forEach(yearData => {
          let households = d3.sum(
            Object.values(yearData).slice(1),
            deploymentRow => deploymentRow.households,
          );
          let deployed = d3.sum(
            Object.values(yearData).slice(1),
            deploymentRow => deploymentRow.deployed,
          );

          households = parseFloat(households.toFixed(2));
          deployed = parseFloat(deployed.toFixed(2));

          this.totalsColumn.data.push({
            households: households,
            deployed: deployed,
          });
        });
        if(window.spinner){
        window.spinner.finish();
      }

        redraw();
      })
      .catch(err => {
        // finish loader
        if(window.spinner){
        window.spinner.finish();
      }
        const status = parseInt(err.message);
        if (status === UNAUTHORIZED) {
          logoutUser();
        } else if (isNaN(status)) {
          new D3NotificationPopup({
            title: 'Error!',
            message: `Currently there is no data for this module for the specific client`,
            type: 'error',
          }).create(el);
        } else {
          new D3NotificationPopup({
            title: 'Error while fetching data!',
            message: `Please try again later`,
            type: 'error',
          }).create(el);
        }
      });

    window.addEventListener(
      'resize',
      debounce(() => {
        if (window.location.href.includes('under-contract')) {
          redraw();
        }
      }, 400),
    );
  };

  /**
   * Cleaning code...
   */
  destroy = () => {
    if (this.underContract) {
      d3.select('svg').remove();
    }
  };
}

export default D3UnderContract;
