const parseURL = () => {
  const url = window.location.href;

  const urlInstance = new URL(url);
  const pathhName = urlInstance.pathname;
  const splitPathhName = pathhName.split('/');

  return {
    chart: splitPathhName[1],
    title: splitPathhName[2],
    grain: splitPathhName[3],
    date: splitPathhName[4],
  };
};

export { parseURL };
