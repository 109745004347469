import React from 'react';
import DeploymentItem from '../DeploymentItem';
import PropTypes from 'prop-types';

const DeploymentList = ({ deployments, colorScale, handleKeyFilter }) => {
  return (
    <div className="KPI-filter-depl-list">
      {deployments.map(item => (
        <DeploymentItem
          {...item}
          colorScale={colorScale}
          handleKeyFilter={handleKeyFilter}
        />
      ))}
    </div>
  );
};

DeploymentList.propTypes = {
  deploymets: PropTypes.array.isRequired,
};

export default DeploymentList;
