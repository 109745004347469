import producer from 'immer';
import { createSelector } from 'reselect';
import DashBoardData from '../components/DashBoardData/DashBoardData';

import {
  GETTING_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_ERROR,
  GET_DASHBOARD_DATA_RECIEVED,
} from '../actions/dashboard_actions';

const INIT_STATE = {
  dashboardData: null,
  loading: false,
  error: null,
  dataMap: null,
};

const dashboard_reducer = producer((draft, action) => {
  switch (action.type) {
    case GETTING_DASHBOARD_DATA:
      draft.loading = true;
      break;
    case GET_DASHBOARD_DATA_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    case GET_DASHBOARD_DATA_RECIEVED:
      draft.loading = false;
      draft.dashboardData = action.payload.data;
      draft.dataMap = action.payload.dataMap;
      break;
  }
}, INIT_STATE);

// selector

const selectDashData = state => state.dashboardData;
const selectDashDataMap = state => state.dataMap;
const selectFilters = state => state.filters;

export const selectWrangledData = createSelector(
  selectDashData,
  selectDashDataMap,
  (rawData, dataMap) => {
    const data_inst = new DashBoardData({ data: rawData, dataMap: dataMap });
    return data_inst.data;
  },
);

export const selectFilteredData = createSelector(
  selectWrangledData,
  selectFilters,
  (dataObjects, filters) => {},
);

export const getKeys = createSelector(
  selectDashData,
  selectFilters,
  (dashBoardData, filters) => {},
);

export default dashboard_reducer;
