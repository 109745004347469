import * as d3 from 'd3';
import moment from 'moment';
import { debounce } from 'lodash';
import { setArrowStyles } from '../setArrowStyles';

const margin = { left: 45, right: 48 };
const unit = {
  daily: 'day',
  month: 'month',
  qtr: 'quarter',
  year: 'year',
  ttm: 'month',
  alltime: 'month',
};

const insertLinebreaks = function(d) {
  var el = d3.select(this);
  var words = d3
    .select(this)
    .text()
    .replace("'", '')
    .split(' ');
  el.text('');

  for (var i = 0; i < words.length; i++) {
    var tspan = el.append('tspan').text(words[i]);
    if (i === 0) tspan.attr('x', 0).attr('dy', '5');
    if (i === 1) tspan.attr('x', 0).attr('dy', '10');
    if (i === 2) tspan.attr('x', 0).attr('dy', '9');
  }
};

const dateFormatters = {
  qtr: function(ts) {
    return moment(ts).format('\\QQ YYYY');
  },
  month: function(ts) {
    return moment(ts).format('M/YY');
    // return moment(ts).month() === 0 ? moment(ts).format('M/YY') : moment(ts).format('MMM');
  },
  daily: function(ts) {
    return moment(ts).format("MMM DD 'YYYY");
  },
  alltime: function(ts) {
    return moment(ts).format('MMM YYYY');
  },
  year: function(ts) {
    return moment(ts).format('YYYY');
  },
  // test v ttm
  ttm: function(ts) {
    return (
      moment(ts)
        .subtract(11, 'Months')
        .format('MMM YYYY') +
      ' - ' +
      moment(ts).format('MMM YYYY')
    );
  },
  now: function(ts) {
    return moment(ts).format('MMM DD hh:mm');
  },
};

class D3RangeSlider {
  constructor(options) {
    this.options = options;
    this.filters = options.filters;
    this.rangeValues = [];
    this.tickValues = [];
    this.tickFormat = dateFormatters[this.filters.grain];
    this.width = window.innerWidth * 0.7 - 80;
    this.updateRange();
  }

  updateRange = () => {
    const { series } = this.filters.dateRanges[this.filters.grain];
    this.rangeValues = [];
    this.tickValues = [];
    for (let i = 0; i < series.length; i++) {
      this.rangeValues.push(moment(series[i]).valueOf());
    }
    this.rangeValues.push(
      moment(this.rangeValues[this.rangeValues.length - 1])
        .add(1, unit[this.filters.grain])
        .valueOf(),
    );
    if (this.filters.grain !== 'daily' && this.filters.grain !== 'ttm') {
      this.tickValues = [...this.rangeValues];
    } else {
      const jumpVal = this.filters.grain === 'daily' ? 10 : 2;
      for (let i = 0; i < this.rangeValues.length; i += jumpVal) {
        this.tickValues.push(this.rangeValues[i]);
        if (i + jumpVal >= this.rangeValues.length)
          this.tickValues.push(this.rangeValues[this.rangeValues.length - 1]);
      }
    }
    this.tickFormat = dateFormatters[this.filters.grain];
  };

  create = el => {
    const component = this;

    const { selected } = this.filters.dateRanges[this.filters.grain];

    const height = 100;

    const setSize = () => {
      const { selected } = component.filters.dateRanges[
        component.filters.grain
      ];

      component.width = window.innerWidth * 0.7 - 80;
      el.select('svg').attr('width', component.width);
      component.xScale.range([0, component.width - margin.left - margin.right]);
      component.handle.attr('width', component.calcHandleWidth());
      component.track
        .attr('x1', component.xScale.range()[0])
        .attr('x2', component.xScale.range()[1]);
      d3.select('.track-inset')
        .attr('x1', component.xScale.range()[0])
        .attr('x2', component.xScale.range()[1]);
      d3.select('.track-overlay')
        .attr('x1', component.xScale.range()[0])
        .attr('x2', component.xScale.range()[1]);
      component.slider.call(component.xAxis);
      component.setHandleValue(selected);
      if (this.filters.grain === 'daily') {
        this.slider.selectAll('text').each(insertLinebreaks);
      }
    };

    const isTimeseries = window.location.href.includes('/timeseries');
    // append svg
    // replace select(#slider) with element (passed as parameter) ?
    this.slider = el
      .append('svg')
      .attr('width', this.width)
      .attr('height', height)
      .style('margin-top', -14)
      .append('g')
      .classed('slider', true)
      .style(
        'opacity',
        isTimeseries && this.filters.grain !== 'daily' ? 0.4 : 1,
      )
      .attr(
        'transform',
        'translate(' + margin.left + ', ' + (height / 3 - 5.5) + ')',
      );

    // using clamp here to avoid slider exceeding the range limits
    this.xScale = d3
      .scaleLinear()
      .domain([this.tickValues[0], this.tickValues[this.tickValues.length - 1]])
      .range([0, this.width - margin.left - margin.right])
      .clamp(true);
    const { xScale } = this;

    // // array useful for step sliders
    // const rangeValues = d3.range(range[0], range[1], step);
    // TODO: remove duplicate dates

    this.xAxis = d3
      .axisBottom(xScale)
      .tickValues(this.tickValues)
      .tickFormat(this.tickFormat);

    // drag behavior initialization
    const drag = d3
      .drag()
      .on('start.interrupt', () => {
        this.slider.interrupt();
      })
      .on('start drag', () => {
        component.dragged(d3.event.x);
      });

    if (!this.slider.node()) return;

    // this is the main bar with a stroke (applied through CSS)
    this.track = this.slider
      .append('line')
      .attr('class', 'track')
      .attr('x1', xScale.range()[0])
      .attr('x2', xScale.range()[1]);

    // this.slider bar
    d3.select(
      this.slider.node().appendChild(this.track.node().cloneNode()),
    ).attr('class', 'track-inset');
    // ticks
    this.slider
      .append('g')
      .attr('class', 'ticks')
      .attr('transform', 'translate(0, 4)')
      .call(this.xAxis);

    //console.log(this.filter.grain);

    if (this.filters.grain === 'daily') {
      this.slider.selectAll('text').each(insertLinebreaks);
    }
    this.handle = this.slider
      .append('rect')
      .classed('handle', true)
      .attr('rx', 4.5)
      .attr('ry', 4.5)
      .attr('y', -4)
      .attr('width', this.calcHandleWidth())
      .attr('height', 9);
    this.setHandleValue(selected);

    // track on top - is transparent & handles drag
    d3.select(this.slider.node().appendChild(this.track.node().cloneNode()))
      .attr('class', 'track-overlay')
      .call(drag);

    window.addEventListener(
      'resize',
      debounce(() => {
        setSize();
      }, 500),
    );
  };

  calcHandleWidth = () => {
    const { width } = this;
    const { dateRanges, grain } = this.filters;
    const { series, selected } = dateRanges[grain];

    const adjustment =
      grain === 'year'
        ? -15
        : grain === 'month' || grain === 'daily'
        ? 3
        : grain === 'alltime' && selected === 2
        ? -30
        : 0;

    return (width - margin.left) / series.length + adjustment;
  };

  update = filters => {
    this.filters = filters;
    this.updateRange();

    const { selected } = this.filters.dateRanges[this.filters.grain];

    this.xScale.domain([
      this.tickValues[0],
      this.tickValues[this.tickValues.length - 1],
    ]);
    this.xAxis.tickValues(this.tickValues).tickFormat(this.tickFormat);

    this.handle.attr('width', this.calcHandleWidth());
    this.slider.select('.ticks').call(this.xAxis);
    if (this.filters.grain === 'daily') {
      this.slider.selectAll('text').each(insertLinebreaks);
    }

    this.setHandleValue(selected);

    const isTimeseries = window.location.href.includes('/timeseries');
    this.slider.style(
      'opacity',
      isTimeseries && this.filters.grain !== 'daily' ? 0.4 : 1,
    );
    setArrowStyles(this.filters);
  };

  setHandleValue = newSelected => {
    const { xScale, rangeValues } = this;
    let x = xScale(rangeValues[newSelected]);
    this.handle
      .transition()
      .duration(200)
      .attr('x', x - 3);
  };

  dragged = val => {
    const isTimeseries = window.location.href.includes('/timeseries');
    // DISABLE USELESS DATE RANGE CHANGE
    if (this.filters.grain !== 'daily' && isTimeseries) return;

    const { xScale, rangeValues, options } = this;
    const { changeFilters } = options;
    const { series, selected } = this.filters.dateRanges[this.filters.grain];
    let x = xScale.invert(val),
      newSelected = selected;
    // calculate clicked value
    for (let i = 0; i < rangeValues.length - 1; i++) {
      if (x >= rangeValues[i] && x <= rangeValues[i + 1]) {
        newSelected = i;
        break;
      }
    }

    // const value = x < midPoint ? rangeValues[index] : rangeValues[index + 1];
    if (newSelected < series.length) {
      const dateRanges = { ...this.filters.dateRanges };
      dateRanges[this.filters.grain].selected = newSelected;
      changeFilters({ dateRanges });
      this.setHandleValue(newSelected);
    }
  };
}

export default D3RangeSlider;
