import TreemapMain from '../../components/Charts/Treemap/TreemapMain';
import { connect } from 'react-redux';
import * as d3 from 'd3';

import {
  fetchDataThunk,
  setStatsData,
  resetStatsData,
  setLeafFilterValue,
  turnNullOnOff,
  setFilterData,
  filterDataOnOff,
  setTopNodeFalse,
  setTopNodeTrue,
  turnNullOff,
  filterDataOff,
} from '../../actions/treemap_actions';

const mapStateToProps = state => {
  return {
    data: state.treemap.data,
    totalDevices: state.treemap.total_devices,
    category: state.treemap.category,
    brand: state.treemap.brand,
    devices: state.treemap.devices,
    id: state.treemap.id,
    filter_value: state.treemap.filter_value,
    showNull: state.treemap.show_null,
    maxLeefValue:
      state.treemap.data === null
        ? null
        : d3.max(state.treemap.data, d => d.value),
    useFilterData: state.treemap.useFilterData,
    filter_data: state.treemap.filter_data,
    topNode: state.treemap.topNode,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getData: () => {
      dispatch(fetchDataThunk());
    },
    setStatsData: ({ category, devices, brand, id }) =>
      dispatch(setStatsData({ category, devices, brand, id })),

    resetStatsData: () => {
      dispatch(resetStatsData());
    },
    setFilterValue: value => {
      dispatch(setLeafFilterValue(value));
    },
    turnNullOnOff: () => {
      dispatch(turnNullOnOff());
    },
    setFilterData: data => {
      dispatch(setFilterData(data));
    },
    filterDataOnOff: () => {
      dispatch(filterDataOnOff());
    },
    turnNullOff: () => {
      dispatch(turnNullOff());
    },
    setTopNodeFalse: () => {
      dispatch(setTopNodeFalse());
    },
    setTopNodeTrue: () => {
      dispatch(setTopNodeTrue());
    },
    filterDataOff: () => {
      return dispatch(filterDataOff());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TreemapMain);
