import { useEffect } from 'react';

////WARNING to use this hook chart must be a ref React ref
/// WARNING chart.current must be an instance of your chart
////WARNING chart.current.remove() and chart.current.redraw() must be methods of your chart

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function resizeChart(chart, el) {
  return async () => {
    if (el.current === null) return null;
    if (chart.current === null) return null;

    chart.current.remove();
    await sleep(180);

    const height = el.current.getBoundingClientRect().height;
    const width = el.current.getBoundingClientRect().width;

    chart.current.redraw({
      height: height,
      width: width,
    });
  };
}

//
// chart and current are React ref meaning that they should have acces to .current property
//
//chart must have remove method to remove it.
//
//el must be the is the ref to the container element of the chart
//
function useResizeChart(chart, el, data) {
  useEffect(
    () => {
      if (chart.current !== null) {
        const resizeChartFunc = resizeChart(chart, el);
        const resizeDebounce = debounce(resizeChartFunc, 200);
        window.addEventListener('resize', resizeDebounce);
        return () => {
          window.removeEventListener('resize', resizeDebounce);
        };
      }
      // eslint-disable-next-line
  }, [chart.current]);
}

export { useResizeChart };
