
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export const routeNames = {
  menu: 'Dashboard',
  profile: 'Profile',
  launcher: 'Dashboard',
  'map-channel-changes': 'Map Channel Changes',
  'map-interference': 'Map Interference',
  'optimization-interference-since-onboarding':
    'Optimization Interference Since Onboarding',
  'optimization-interference-all-changes':
    'Optimization Interference All Changes',
  'sankey-interference': 'Sankey Interference',
  'timeseries-area': 'Timeseries Area',
  'choropleth-zoom': 'Choropleth Zoom',
  composite: 'Timeseries',
  timeseries: 'Timeseries',
  glossary: 'Glossary',
  'metric-correlations': 'Metric Correlations',
  'devices-bubbles': 'Devices Bubbles',
  'under-contract': 'Households Under Contract',
  'balloon-map': 'Balloon Map',
  splitchord: 'Split Chord Chart',
  radial: 'Radial Chart',
  'churn-facet': 'Churn Facet',
};

export const OKTA_REDIRECT_URI = window.location.origin + '/implicit/callback';

export const NAVBAR_HEIGHT = 40;
export const SCROLLBAR_WIDTH = 12.5;
export const UNAUTHORIZED = 401;
export const UNAUTHORIZED_MESSAGE = 'Unauthorized';
