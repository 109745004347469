import axios from 'axios';
import { UNAUTHORIZED } from '../utils/constants';
import { API_URL } from '../config';

import { logoutUser } from './auth_actions';
import { MODULES_GET_OWN } from './modules_actions';

export const GET_CLIENTS = 'GET_CLIENTS';

export const getClients = () => async dispatch => {
  try {
    const response = await axios.get(`${API_URL}/clients`);

    const { modules } = response.data;
    dispatch({ type: MODULES_GET_OWN, payload: modules });
    return {
      type: GET_CLIENTS,
      payload: response.data,
    };
  } catch (err) {
    if (err.response && err.response.status === UNAUTHORIZED) {
      return logoutUser();
    }
    return {
      type: GET_CLIENTS,
      payload: [],
    };
  }
};
