import React from 'react';
import classNames from 'classnames';

import ChangeAxis from './ChangeAxis';
import FilterDeployments from './FilterDeployments';
import Header from './Header';

import './chartFilter.scss';

const ChartFilter = ({
  closeAction,
  handleKeyFilter,
  keys,
  open,
  colorScale,
}) => {
  return (
    <div
      className={classNames('KPI-chart-filter-cont', {
        'hidden-filter': !open,
      })}
    >
      <Header close={closeAction} />
      <FilterDeployments
        deployments={keys}
        colorScale={colorScale}
        handleKeyFilter={handleKeyFilter}
      />
      <ChangeAxis />
    </div>
  );
};

export default ChartFilter;
