import { dataSkeleton } from '../../utils/dataSkeletonNew';

// the UI uses a very specific data that is derived from the dataSkeleton
// the dataSkeleton provides names for the main menu and their csv names
// this is transformed given additional data from the dashboardData API
// here is an example   show the scale metrics with the first segment
// it holds the breakdown by deployment breakdownPRior (by deployment)
// csv name matches to api metric name
// delta is the number change from the n-1 period
// number is the total value right now
// numberPrior is the number prior
//
// {color: "Red"
// name: "Scale"
// segments:[
// {
// breakdown: Object { beta: "120835", sigma: "2001935", chi: "309629486", … }
// breakdownPrior: Object { chi: "286632676", delta: "72850756", beta: "117749", … }
// csvName: "devicesseennew"
// delta: 50199497
// formatter: "bigNum"
// links:[ {…}, {…}, {…} ]
// name: "Total Cumulative Unique Devices"
// number: 634936247
// numberPrior: 584736750
// deltaPct:12%
// }

export const getFilteredData = (
  grain,
  dateRanges,
  dashboardData,
  filters,
  liveData = false,
  skeleton,
) => {
  const [ts, tsPrior] = ts_and_tsPrior(dateRanges, grain);
  if (dashboardData) {
    const dataStoreNow = makeDataStore(dashboardData, grain, ts);
    const dataStorePrior = makeDataStore(dashboardData, grain, tsPrior);
    const uiFormattedData = turnDataSkeletonIntoUIData(
      dataStoreNow,
      dataStorePrior,
      dataSkeleton,
    );
    return uiFormattedData;
  }
  return null;
};

function turnDataSkeletonIntoUIData(
  dataStoreNow,
  dataStorePrior,
  dataSkeletonData,
) {
  const newDataSkeleton = dataSkeletonData.map(item => {
    const segments = item.segments.map(seg => {
      const apiData = apiDataFormatted(
        dataStoreNow[seg.csvName],
        dataStorePrior[seg.csvName],
      );
      seg = { ...seg, ...apiData };
      return seg;
    });
    return { ...item, segments: segments };
  });
  return newDataSkeleton;
}

function apiDataFormatted(dataNow, dataPrior) {
  //this checks that the datNow is actually defined as
  //not all are defined in all grains/times

  if (dataNow) {
    let breakDownData = { ...dataNow };
    delete breakDownData.total;

    let breakDownDataPrior = { ...dataPrior };
    delete breakDownDataPrior.total;
    const totalNow = parseFloat(dataNow.total);
    const totalPrior =
      dataPrior === undefined ? NaN : parseFloat(dataPrior.total);
    const formattedAPiData = {
      number: totalNow,
      numberPrior: totalPrior,
      breakdown: breakDownData,
      breakdownPrior: breakDownDataPrior,
      delta:
        dataPrior === undefined ? NaN : calculateDelta(totalNow, totalPrior),
      deltaPct:
        dataPrior === undefined ? NaN : calculatePercent(totalNow, totalPrior),
    };
    return formattedAPiData;
  } else {
    const formattedAPiData = {
      delta: NaN,
      deltaPct: 'NaN',
    };
    return formattedAPiData;
  }
}

//this takes wrangledData and turns it into a store key by a csv name
function makeDataStore(wrangledData, grain, ts) {
  const data = {};
  wrangledData.forEach(item => {
    if (item.grain === grain && item.ts === ts) {
      if (data[item.metric] === undefined) {
        data[item.metric] = { [item.deployment]: item.value };
      } else {
        data[item.metric][item.deployment] = item.value;
      }
    }
  });
  return data;
}

function calculateDelta(now, nowminusOne) {
  return now - nowminusOne;
}

function calculatePercent(now, nowMinusOne) {
  if (now === nowMinusOne) return 0;
  if (now > nowMinusOne) return (now - nowMinusOne) / nowMinusOne;
  return ((nowMinusOne - now) / nowMinusOne) * -1;
}

function ts_and_tsPrior(dateRanges, grain) {
  if (grain === undefined) {
    grain = 'daily';
  }
  const ts = dateRanges[grain].series[dateRanges[grain].selected];
  const tsPrior =
    dateRanges[grain].selected > 0
      ? dateRanges[grain].series[dateRanges[grain].selected - 1]
      : false;
  return [ts, tsPrior];
}
