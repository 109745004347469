import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './changeXYStyles.scss';
import * as d3 from 'd3';
import { formatAxis, formatXAxis } from '../helpers/formatAxis';
import {
  setMinX,
  setMaxX,
  setMaxY,
  setMinY,
} from '../../actions/timeSeries_actions';
import { getStartEndPix } from './getXMinMax';

import parseDate from '../helpers/d3/utils/formatTimeHelpers';

// This is a component for the filters that work with the timeseries chart
//this component creates a d3 Xbrush and a d3 y brush that control the timeseries chart
class ChangeXY extends Component {
  constructor(props) {
    super(props);

    //bind this to function

    this.createYScale = this.createYScale.bind(this);
    this.createYaxis = this.createYaxis.bind(this);
    this.createXAxisBrush = this.createXAxisBrush.bind(this);
    this.createYaxisBrusher = this.createYaxisBrusher.bind(this);
    this.createXScale = this.createXScale.bind(this);
    this.createXaxisBrusher = this.createXaxisBrusher.bind(this);
    this.updateXScaleAndAxis = this.updateXScaleAndAxis.bind(this);
    this.updateYScaleAndAxis = this.updateYScaleAndAxis.bind(this);

    //this is moved here becuase we need these to be set before the component mounts
    //the svg that the y axis brush will be in
    this.margin = { l: 40, t: 10, r: 70, b: 10 };
    this.yHeight = 200 - (this.margin.t + this.margin.b);
    this.yWidth = 140 - (this.margin.l + this.margin.r);

    //the svg that the x axis brush will be in
    this.marginX = { l: 45, t: 33, r: 60, b: 40 };
    this.xHeight = 103 - (this.marginX.t + this.marginX.b);
    this.xWidth = 320 - (this.marginX.l + this.marginX.r);
  }

  componentDidMount() {
    //init the brushing us d3 for both the Y axis and the X axis
    this.createYaxisBrush();
    this.createXAxisBrush();
  }

  //creates the Y axis Brush

  createYaxisBrush() {
    this.Ysvg = d3
      .select(this.yRef)
      .append('svg')
      .attr('class', 'change-y-svg')
      .attr('height', this.yHeight + (this.margin.t + this.margin.b))
      .attr('width', this.yWidth + (this.margin.l + this.margin.r));
    this.Yg = this.Ysvg.append('g').attr(
      'transform',
      'translate(' + this.margin.l + ',' + this.margin.t + ')',
    );

    //this is the rest of the brush as this function is also the initializer with scales
    this.createYScale();
    this.createYaxis();
    this.createYaxisBrusher();
  }

  //creates the X axis Brush
  createXAxisBrush() {
    this.Xsvg = d3
      .select(this.xRef)
      .append('svg')
      .attr('class', 'change-x-svg')
      .attr('height', this.xHeight + (this.marginX.t + this.marginX.b))
      .attr('width', this.xWidth + (this.marginX.l + this.marginX.r));
    this.Xg = this.Xsvg.append('g').attr(
      'transform',
      'translate(' + this.marginX.l + ',' + this.marginX.t + ')',
    );

    //this is the rest of the brush as this function is also the initializer with scales
    this.createXScale();
    this.createXAxis();
    this.createXaxisBrusher();
  }

  //this creates the scale for the X brush
  //this scale is the same as the one that is used for the timeSeries
  //the scale uses the prop axisLabels which corosponds to the timeseries chart

  createXScale() {
    //this.xScale is used to to pass around the scale to differnt methods

    this.xScale = d3
      .scaleBand()
      .domain(this.props.series)
      .range([0, this.xWidth]);
  }

  //creates the X axis

  createXAxis() {
    //tickvalues are filtered for style so ticks don't overlap
    //function formatXAxis is used to format the axis it is imported
    var filterDivider =
      this.props.grain === 'daily' ? 35 : this.props.grain === 'month' ? 6 : 2;
    var startTime = null;
    this.XAxis = d3
      .axisBottom(this.xScale)
      .tickFormat(formatXAxis(this.props.grain))
      .tickValues(
        this.xScale.domain().filter((d, i) => {
          if (i === 0) {
            startTime = d;
            return true;
          }
          if (this.props.grain === 'daily') {
            if (d3.timeMonth.offset(startTime, 3).getTime() === d) {
              startTime = d3.timeMonth.offset(startTime, 3).getTime();
              return true;
            } else {
              return false;
            }
          }
          return !(i % filterDivider);
        }),
      );

    //call XAxis

    this.Xg.append('g')
      .attr('class', 'x-change-axis-g')
      .attr('transform', 'translate(' + 0 + ',' + this.xHeight + ')')
      .call(this.XAxis);
  }

  //this create the X axis brusher

  createXaxisBrusher() {
    let self = this;

    //function brushed is the callback event for when the brushing event happens
    const brushed = () => {
      if (d3.event.selection === null || d3.event.selection === 'undefined') {
        return null;
      }
      //selection is a d3 event that returns an array of the brush event at where the ends of the brush are at
      const selection = d3.event.selection;

      //this.xMAx and this.xMin is the assigned the selection statrt and end
      this.xMax = selection[1];
      this.xMin = selection[0];
      this.startPix = selection[0];
      this.endPix = selection[1];

      //this creates the bars at the end of the brush and updates them everytime this event is called

      //step calculates how long we have between axis ticks in a linear scale as we take the width of our brush
      //and divide it by the width of the brush
      const step = self.xScale.step();

      //indexMax finds the max idex by rounding down the selection max/our step

      let indexMax = Math.floor(selection[1] / step);

      //indexMin finds the min idex by rounding down the selection min/our step
      const indexMin = Math.floor(selection[0] / step);

      //corects indexMax so that our brush does not go beyond the axis
      indexMax =
        indexMax > self.props.series.length - 1
          ? self.props.series.length - 1
          : indexMax;

      // const valueMin = self.xScale.invert(selection[0]);
      // const valueMax = self.xScale.invert(selection[1]);
      this.valueXMax = self.props.series[indexMax];
      this.valueXMin = self.props.series[indexMin];

      this.createBars();
      self.props.setMaxX(self.props.series[indexMax]);
      self.props.setMinX(self.props.series[indexMin]);
    };

    const [startPix, endPix] = getStartEndPix(
      this.props.series,
      self.props.axisLabels,
      this.xScale,
    );

    this.startPix = startPix;
    this.endPix = endPix;

    const brush = d3
      .brushX()
      .extent([[0, 0], [this.xWidth, this.xHeight]])
      .on('start brush', brushed);

    this.brushX = brush;

    this.Xg.append('g')
      .attr('class', 'brush-x-g')
      .append('line')
      .attr('y1', this.xHeight / 2)
      .attr('y2', this.xHeight / 2)
      .attr('x1', 0)
      .attr('x2', this.xWidth)
      .attr('stroke-width', '2px')
      .attr('stroke', '#676565');

    d3.select('.brush-x-g')
      .call(brush)
      .call(brush.move, [this.startPix, this.endPix]);

    this.xMax = this.xScale.range()[1];
    this.xMin = this.xScale.range()[0];

    this.createBars();
  }

  createYScale() {
    this.yScale = d3
      .scaleLinear()
      .domain([this.props.chartYmin, this.props.chartYmax])
      .range([this.yHeight, 0])
      .nice();
  }

  createYaxis() {
    const axisFormatter = formatAxis(this.props.axisType);

    this.YAxis = d3
      .axisLeft(this.yScale)
      .tickFormat(axisFormatter)
      .tickValues(
        this.yScale.ticks().filter(function(d, i) {
          if (d >= 10) {
            return true;
          }
          return Number.isInteger(d);
        }),
      )
      .ticks(10);

    this.Yg.append('g')
      .attr('class', 'y-change-axis-g')
      .attr('transform', 'translate(' + 0 + ',' + 0 + ')')
      .call(this.YAxis);
  }

  createYaxisBrusher() {
    let self = this;
    const brushed = () => {
      const selection = d3.event.selection;

      const valueMax = self.yScale.invert(selection[0]);
      const valueMin = self.yScale.invert(selection[1]);

      this.valueMax = self.yScale.invert(selection[0]);
      this.valueMin = self.yScale.invert(selection[1]);

      this.yMax = selection[0];

      this.yMin = selection[1];
      this.createYbars();

      self.props.setMaxY(valueMax);
      self.props.setMinY(valueMin);
    };

    const brush = d3
      .brushY()
      .extent([[0, 0], [this.yWidth, this.yHeight]])
      .on('start brush', brushed);

    this.brushY = brush;

    this.Yg.append('g')
      .attr('class', 'brush-y-g')
      .append('line')
      .attr('y1', 0)
      .attr('y2', this.yHeight)
      .attr('x1', this.yWidth / 2)
      .attr('x2', this.yWidth / 2)
      .attr('stroke-width', '2px')
      .attr('stroke', '#676565');

    d3.select('.brush-y-g')
      .call(brush)
      .call(brush.move, this.yScale.range().reverse());

    this.yMax = this.yScale.range().reverse()[0];

    this.yMin = this.yScale.range().reverse()[1];
    this.createYbars();
  }

  updateXScaleAndAxis() {
    this.createXScale();

    //this x axis update
    var filterDivider =
      this.props.grain === 'daily' ? 35 : this.props.grain === 'month' ? 6 : 5;
    var startTime = null;
    this.XAxis = d3
      .axisBottom(this.xScale)
      .tickFormat(formatXAxis(this.props.grain))
      .tickValues(
        this.xScale.domain().filter((d, i) => {
          if (i === 0) {
            startTime = d;
            return true;
          }
          if (this.props.grain === 'daily') {
            if (d3.timeMonth.offset(startTime, 3).getTime() === d) {
              startTime = d3.timeMonth.offset(startTime, 3).getTime();
              return true;
            } else {
              return false;
            }
          }
          return !(i % filterDivider);
        }),
      );

    this.Xg.select('.x-change-axis-g')
      .transition()
      .call(this.XAxis);

    const [startPix, endPix] = getStartEndPix(
      this.props.series,
      this.props.axisLabels,
      this.xScale,
    );

    d3.select('.brush-x-g').call(this.brushX.move, [startPix, endPix]);
  }

  updateYScaleAndAxis() {
    this.createYScale();

    //this updates the yaxis
    const axisFormatter = formatAxis(this.props.axisType);

    this.YAxis = d3
      .axisLeft(this.yScale)
      .tickFormat(axisFormatter)
      .tickValues(
        this.yScale.ticks().filter((d, i) => {
          if (d >= 10) {
            return true;
          }
          return Number.isInteger(d);
        }),
      )
      .ticks(10);

    this.Yg.select('.y-change-axis-g')
      .transition()
      .call(this.YAxis);

    d3.select('.brush-y-g').call(
      this.brushY.move,
      this.yScale.range().reverse(),
    );
  }

  createBars() {
    this.createXBars();
  }

  createYbars() {
    //ymax and ymin hild the pixel ponints where the min and max should be in pixels on the tool brush
    const dataY = [this.yMax, this.yMin];
    //valMax and ValueMin is the actual nominal amount for the y brush

    //datafro displaying the labels
    const labelsData = [
      { label: this.valueMax, value: this.yMax },
      { label: this.valueMin, value: this.yMin },
    ];

    const linesY = d3
      .select('.brush-y-g')
      .selectAll('.y-bar-brush')
      .data(dataY);

    linesY
      .enter()
      .append('line')
      .merge(linesY)
      .attr('class', 'y-bar-brush')
      .attr('x1', -3)
      .attr('x2', this.yWidth + 3)
      .attr('y1', d => d)
      .attr('y2', d => d)
      .attr('stroke', '#676565')
      .attr('stroke-width', '5px');

    const textLabelsY = d3
      .select('.brush-y-g')
      .selectAll('.y-label-brush')
      .data(labelsData);

    textLabelsY
      .enter()
      .append('text')
      .merge(textLabelsY)
      .attr('class', 'y-label-brush')
      .attr('x', this.yWidth + 10)
      .attr('y', d => d.value + 5)
      .attr('stroke', 'black')
      .attr('font-size', '12px')
      .text(d =>
        d.label < 10
          ? Math.round(d.label * 10) / 10
          : formatAxis(this.props.axisType)(d.label),
      );
  }

  createXBars() {
    const data = [this.startPix, this.endPix];

    const labelsData = [
      { label: this.valueXMax, value: this.endPix },
      { label: this.valueXMin, value: this.startPix },
    ];

    const lines = d3
      .select('.brush-x-g')
      .selectAll('.x-bar-brush')
      .data(data);

    lines
      .enter()
      .append('line')
      .merge(lines)
      .attr('class', 'x-bar-brush')
      .attr('x1', d => d)
      .attr('x2', d => d)
      .attr('y1', -3)
      .attr('y2', this.xHeight + 3)
      .attr('stroke', '#676565')
      .attr('stroke-width', '5px');

    const textLabelsX = d3
      .select('.brush-x-g')
      .selectAll('.x-label-brush')
      .data(labelsData);

    textLabelsX
      .enter()
      .append('text')
      .merge(textLabelsX)
      .attr('class', 'x-label-brush')
      .attr('x', (d, i) => (i === 0 ? d.value : d.value - 40))
      .attr('y', (d, i) => -6)
      .attr('stroke', 'black')
      .attr('font-size', '12px')
      .text(d => formatXAxis(this.props.grain)(d.label));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.axisLabels !== this.props.axisLabels) {
      this.updateXScaleAndAxis();
      this.updateYScaleAndAxis();
      return true;
    }
    if (
      prevProps.xMin !== this.props.xMin ||
      prevProps.xMax !== this.props.xMax
    ) {
      this.updateYScaleAndAxis();
      return true;
    }
  }

  render() {
    if (this.props.chartYmax === null || this.props.chartYmin === null) {
      return null;
    }
    return (
      <div>
        <div className="change-xy-title">Change Axis</div>
        <div className="change-y-inputs">
          <div
            style={{ display: 'inline-block' }}
            className="change-y"
            ref={el => (this.yRef = el)}
          />
          <div style={{ display: 'inline-block' }} className="max-min-y" />
          <div classNAme="max-min-x" />
        </div>

        <div className="change-x" ref={el => (this.xRef = el)} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    chartYmax: state.timeSeries.chartYmax,
    chartYmin: state.timeSeries.chartYmin,
    yMax: state.timeSeries.yMax,
    yMin: state.timeSeries.yMin,
    xMax: state.timeSeries.xMax,
    xMin: state.timeSeries.xMin,
    axisType: state.filters.axisType,
    axisLabels: ownProps.axisLabels,
    grain: state.filters.grain,
    series: state.filters.dateRanges[state.filters.grain].series.map(
      (time, i) => {
        return parseInt(d3.timeFormat('%Q')(new Date(parseDate(time))));
      },
    ),
    tranisiton: state.timeSeries.inTransition,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMaxY: val => {
      dispatch(setMaxY(val));
    },
    setMinY: val => {
      dispatch(setMinY(val));
    },
    setMaxX: val => {
      dispatch(setMaxX(val));
    },
    setMinX: val => {
      dispatch(setMinX(val));
    },
  };
};

const ChangeXYCont = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeXY);

//proprtypes that need to be passed to ChangeXYCont
ChangeXY.propTypes = {
  chartYmax: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired,
  yMin: PropTypes.number.isRequired,
  xMax: PropTypes.number.isRequired,
  xMin: PropTypes.number.isRequired,
  axisType: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
  axisLabels: PropTypes.array,
  grain: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
};

export default ChangeXYCont;
