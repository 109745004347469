import BaseXYChart from './BaseXYChart';
import * as d3 from 'd3';
import {
  formatHeight,
  formatRectWidth,
  formatRectPosition,
  formatRX,
  formatY,
} from './formatters';

class SeriesChartChart extends BaseXYChart {
  makeRects() {
    this.mainG
      .append('g')
      .attr('class', 'KPI-bar-chart-rects')
      .selectAll('.KPI-bar-chart-rects-g')
      .data(this.data)
      .join('g')
      .attr('fill', d => this.colorScale(d.key))
      .attr('class', 'KPI-bar-chart-rects-g')
      .selectAll('rect')
      .data(d => d)
      .join('rect')
      .attr('x', (d, i) =>
        formatRectPosition(
          d.data,
          formatRectWidth(d.data, formatHeight(d, this.yScale), this.xScale),
          this.xScale,
        ),
      )
      .attr('y', d => formatY(d, this.yScale))
      .attr('height', d => formatHeight(d, this.yScale))
      .attr('width', d =>
        formatRectWidth(d.data, formatHeight(d, this.yScale), this.xScale),
      )
      .attr('rx', d =>
        formatRX(
          formatRectWidth(d.data, formatHeight(d, this.yScale), this.xScale),
        ),
      );
  }

  makePopOver() {
    let self = this;
    this.mainG.selectAll('rect').on('mouseenter', function(d) {
      const deployment = d3.select(this.parentNode).data()[0].key;
      const value = parseInt(d.data[deployment]);
      const formattedValue = d3.format('~s')(value);
      const mouse = d3.mouse(this);
      const x = mouse[0];
      const y = mouse[1];
      const date = d.data.ts;
      d3.select('body')
        .append('div')
        .attr('class', 'kpi-series-popup-cont')
        .html(self.makePopOverHTML(deployment, formattedValue, x, y, date));
    });
    this.mainG.selectAll('rect').on('mouseout', d => {
      d3.selectAll('.kpi-series-popup-cont').remove();
    });
  }

  makePopOverHTML(deployment, formattedValue, x, y, date) {
    return `<div style="left:${x}px; top:${y}px" class='kpi-series-popup'>
          <div class='kpi-series-popup-title'>${deployment}:   ${formattedValue} </div>
          <div class='kpi-series-popup-title'>date: ${date}</div>
     </div>`;
  }

  render() {
    this.makeMainSVG();
    this.makeScales();
    this.makeAxis();
    this.makeRects();
    this.makePopOver();
  }

  reRender(options) {
    this.data = options.data;
    this.xAxisTicks =
      options.xAxisTicks === undefined ? this.xAxisTicks : options.xAxisTicks;
    this.formatXAxis =
      options.formatXAxis === undefined
        ? this.formatXAxis
        : options.formatXAxis;
    this.formatYAxis =
      options.formatYAxis === undefined
        ? this.formatYAxis
        : options.formatYAxis;
    this.mainSVG.remove();
    this.render();
  }

  redraw({ height, width }) {
    this.height = height - (this.margin.t + this.margin.b);
    this.width = width - (this.margin.l + this.margin.r);
    this.render();
  }

  remove() {
    this.mainSVG.remove();
  }
}

export default SeriesChartChart;
