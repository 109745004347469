export const SET_AXIS_LABELS = 'SET_AXIS_LABELS';
export const SET_MIN_X = 'SET_MIN_X';
export const SET_MAX_X = 'SET_MAX_X';
export const SET_MAX_Y = 'SET_MAX_Y';
export const SET_MIN_Y = 'SET_MIN_Y';
export const SET_CHART_Y_MAX = 'SET_CHART_Y_MAX';
export const SET_CHART_Y_MIN = 'SET_CHART_X_MIN';
export const TRANSITION_STARTED = 'TRANSITION_STARTED';
export const TRANSITION_STOPPED = 'TRANSITION_STOPPED';

function setAxisLabels(labels) {
  return {
    type: SET_AXIS_LABELS,
    labels,
  };
}

function setMinX(value) {
  return {
    type: SET_MIN_X,
    value,
    meta: {
      debounce: {
        time: 1,
      },
    },
  };
}

function setMaxX(value) {
  return {
    type: SET_MAX_X,
    value,
    meta: {
      debounce: {
        time: 1,
      },
    },
  };
}

function setMaxY(value) {
  return {
    type: SET_MAX_Y,
    value,
    meta: {
      debounce: {
        time: 1,
      },
    },
  };
}

function setMinY(value) {
  return {
    type: SET_MIN_Y,
    value,
    meta: {
      debounce: {
        time: 1,
      },
    },
  };
}

function setchartYmax(value) {
  return {
    type: SET_CHART_Y_MAX,
    value,
  };
}

function setchartYmin(value) {
  return {
    type: SET_CHART_Y_MIN,
    value,
  };
}

function transitionStarted() {
  return {
    type: TRANSITION_STARTED,
  };
}

function transitionStopped() {
  return {
    type: TRANSITION_STOPPED,
  };
}

export {
  setAxisLabels,
  setMinX,
  setMaxX,
  setMaxY,
  setMinY,
  setchartYmax,
  setchartYmin,
  transitionStarted,
  transitionStopped,
};
