import * as d3 from 'd3';
import { API_URL } from '../utils/constants';
import Token from '../components/okta/Token';

export const DATA_FETCHED = 'DATA_FETCHED';
export const SET_STATS_DATA = 'SET_STATS_DATA';
export const RESET_STATS_DATA = 'TOTAL_STATS_DATA';
export const SET_LEAF_FILTER_VALUE = 'SET_LEAF_FILTER_VALUE';
export const TURN_NULL_ON_OFF = 'TURN_NULL_ON_OFF';
export const TURN_NULL_OFF = 'TURN_NULL_OFF';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const FILTER_DATA_ON_OFF = 'FILTER_DATA_ON_OFF';
export const FILTER_DATA_OFF = 'FILTER_DATA_OFF';
export const SET_TOP_NODE_FALSE = 'SET_TOP_NODE_FALSE';
export const SET_TOP_NODE_TRUE = 'SET_TOP_NODE_TRUE';

function fetchData(payload) {
  return {
    type: DATA_FETCHED,
    data: payload,
    totalDevices: d3.sum(payload, d => {
      return d.DevicePopulation;
    }),
  };
}

function setStatsData({ category, devices, brand, id }) {
  return {
    type: SET_STATS_DATA,
    category,
    devices,
    brand,
    id,
  };
}

function setLeafFilterValue(value) {
  return {
    type: SET_LEAF_FILTER_VALUE,
    value,
    meta: {
      debounce: {
        time: 10,
      },
    },
  };
}

function resetStatsData() {
  return {
    type: RESET_STATS_DATA,
  };
}

function turnNullOnOff() {
  return {
    type: TURN_NULL_ON_OFF,
  };
}

function turnNullOff() {
  return {
    type: TURN_NULL_OFF,
  };
}

function setFilterData(data) {
  return {
    type: SET_FILTER_DATA,
    data,
  };
}

function filterDataOnOff() {
  return {
    type: FILTER_DATA_ON_OFF,
  };
}

function filterDataOff() {
  return {
    type: FILTER_DATA_OFF,
  };
}

function setTopNodeFalse() {
  return {
    type: SET_TOP_NODE_FALSE,
  };
}

function setTopNodeTrue() {
  return {
    type: SET_TOP_NODE_TRUE,
  };
}
//thunk

const fetchDataThunk = () => {
  const oktaToken = new Token(localStorage['okta-token-storage']);
  const token = 'Bearer ' + oktaToken.accessToken;

  return dispatch => {
    const dataAPI = `${API_URL}/data/devices-bubbles`;

    const devicePromise = d3.json(`${dataAPI}`, {
      headers: { Authorization: token },
    });

    Promise.all([devicePromise]).then(datas => {
      const deviceData = datas[0].data.map((val, i) => {
        return {
          ...val,
          ...{ value: parseInt(val['device_cummulative_cnts']) },
        };
      });

      dispatch(fetchData(deviceData));
    });
  };
};

export {
  fetchDataThunk,
  setStatsData,
  resetStatsData,
  setLeafFilterValue,
  turnNullOnOff,
  setFilterData,
  filterDataOnOff,
  setTopNodeFalse,
  setTopNodeTrue,
  turnNullOff,
  filterDataOff,
};
