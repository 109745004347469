import React, { Component } from 'react';
import { dateFormatters, makeRangeValues } from '../helpers/menuFormatters';

import { axisIndexStartStop, testForAxisChange } from './timeSlider_help.js';

import * as d3 from 'd3';

class Timeslider extends Component {
  constructor(props) {
    super(props);

    const tickIndex = axisIndexStartStop(
      makeRangeValues(this.props.grain, this.props.dates.series),
      this.props.currentDate,
      this.props.grain,
    );

    this.state = {
      mounted: false,
      tickValues: makeRangeValues(this.props.grain, this.props.dates.series),
      tickIndex: tickIndex,
    };

    this.margin = { l: 25, t: 45, r: 25, b: 5 };
    this.width =
      window.innerWidth * 0.65 - 80 - (this.margin.l + this.margin.r);

    this.height = 65 - (this.margin.t + this.margin.b);
    this.makeScales = this.makeScales.bind(this);

    this.createBaseElement = this.createBaseElement.bind(this);
    this.calcHandleWidth = this.calcHandleWidth.bind(this);
    this.handleXValue = this.handleXValue.bind(this);
    this.addhandleRect = this.addhandleRect.bind(this);
    this.createDrag = this.createDrag.bind(this);
  }

  makeScales() {
    this.tickValues = makeRangeValues(
      this.props.grain,
      this.props.dates.series,
    );

    this.tickIndex = axisIndexStartStop(
      makeRangeValues(this.props.grain, this.props.dates.series),
      this.props.currentDate,
      this.props.grain,
    );

    this.xScale = d3
      .scaleTime()
      .domain([
        new Date(this.tickValues[this.tickIndex[0]]),
        new Date(this.tickValues[this.tickIndex[1]]),
      ])
      .range([0, this.width])

      .clamp(true);
  }

  componentDidMount() {
    this.draw();
  }
  draw() {
    this.sliderElement.innerHTML = '';
    this.makeScales();
    this.createBaseElement();
    this.addBaseLine();
    this.addhandleRect();
    this.createTicks();
    this.createDrag();

    if (this.state.mounted === false) {
      this.setState({
        mounted: true,
      });
    }
  }

  createBaseElement() {
    this.svg = d3
      .select(this.sliderElement)
      .append('svg')
      .attr('width', this.width + (this.margin.l + this.margin.r))
      .attr('height', this.height + (this.margin.t + this.margin.b));

    this.g = this.svg
      .append('g')
      .attr(
        'transform',
        'translate(' + this.margin.l + ',' + this.margin.t + ')',
      );
  }

  addBaseLine() {
    this.g
      .append('line')
      .attr('class', 'track')
      .attr('x1', '0')
      .attr('x2', this.width)
      .attr('stroke', '#b7b5b5')
      .attr('stroke-opacity', 1)
      .attr('stroke-width', '10px')
      .attr('stroke-linecap', 'round')
      .style('cursor', 'none');
  }

  addhandleRect() {
    this.g
      .append('rect')
      .classed('handle', true)
      .attr('fill', 'white')
      .attr('rx', 4.5)
      .attr('ry', 4.5)
      .attr('y', -8)
      .attr('width', this.calcHandleWidth())
      .attr('height', 15)
      .attr('x', this.handleXValue())
      .style('cursor', 'none');
  }

  calcHandleWidth() {
    //const width = this.width;
    const grain = this.props.grain;
    //const dateRanges = this.props.dateRanges;
    const { series, selected } = this.props.dates;

    const adjustment =
      this.props.grain === 'year'
        ? 18
        : this.props.grain === 'month'
        ? 10
        : this.props.grain === 'qtr'
        ? 8
        : grain === 'alltime' && selected === 2
        ? -0
        : grain === 'alltime'
        ? 30
        : grain === 'ttm'
        ? 5
        : 3;

    return this.props.grain === 'month'
      ? (this.width - this.margin.l) / (series.length / 2) + adjustment
      : (this.width - this.margin.l) / series.length + adjustment;
  }

  handleXValue = () => {
    const selected = this.props.dates.series[this.props.dates.selected];
    let x = this.xScale(new Date(selected));
    return x - 3;
  };

  createTicks() {
    const tickFormat = dateFormatters[this.props.grain];

    this.xAxis = d3
      .axisTop(this.xScale)
      .tickValues(
        this.tickValues.slice(this.tickIndex[0], this.tickIndex[1] + 1),
      )
      .tickFormat(tickFormat);

    const translateAxis = this.props.grain === 'daily' ? -35 : -6;
    this.g
      .append('g')
      .attr('class', 'sliderTicks')
      .attr('transform', `translate(0, ${translateAxis})`)
      .call(this.xAxis);

    var grain = this.props.grain;

    this.g.selectAll('.sliderTicks g text').each(function(d) {
      tickFormats(grain, this);
    });
  }

  dragged(val) {
    //const isTimeseries = window.location.href.includes('/timeseries');
    // DISABLE USELESS DATE RANGE CHANGE
    //if (this.filters.grain !== 'daily' && isTimeseries) return;

    const rangeValues = this.props.dateRanges[this.props.grain].series;

    // const { changeFilters } = options;
    const selected = this.props.dateRanges[this.props.grain].selected;
    let x = this.xScale.invert(val);

    var newSelected = selected;

    for (let i = 0; i < rangeValues.length - 1; i++) {
      if (
        x.getTime() >= new Date(rangeValues[i]).getTime() &&
        x.getTime() <= new Date(rangeValues[i + 1]).getTime()
      ) {
        newSelected = i;

        break;
      }
    }

    if (x.getTime() > new Date(rangeValues[rangeValues.length - 1]).getTime()) {
      newSelected = rangeValues.length - 1;
    }

    return newSelected;
  }

  createDrag() {
    const overlay = this.g
      .append('line')
      .attr('class', 'track-overlays')
      .attr('x1', '0')
      .attr('x2', this.width)
      .attr('stroke', '#424141')
      .attr('stroke-opacity', 0.01)
      .attr('stroke-width', '40px')
      .attr('stroke-linecap', 'round')
      .style('cursor', 'pointer');

    const drag = d3
      .drag()
      .container(function() {
        return this;
      })
      .on('start drag', () => {
        //this is essentially a clamp on how far you can slide the month slider
        const x =
          this.props.grain === 'month' &&
          d3.event.x >
            this.xScale.domain()[1] - (this.xScale(this.xScale.ticks()[1]) - 5)
            ? this.xScale.domain()[1] -
              (this.xScale(this.xScale.ticks()[1]) - 10)
            : d3.event.x;
        const selectedIndex = this.dragged(x);
        this.props.changeSelected(selectedIndex);
      });

    overlay.call(drag);
  }

  dragHandle(selectedIndex) {
    const selected = this.props.dates.series[selectedIndex];
    let x = this.xScale(new Date(selected));

    return this.props.grain === 'daily' ? x : x - 3;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.className !== nextProps.className) {
      return true;
    }
    if (this.props.grain !== nextProps.grain) {
      this.setState(state => {
        return {
          tickValues: makeRangeValues(
            this.props.grain,
            this.props.dates.series,
          ),
        };
      });
      return true;
    }

    this.g
      .select('.handle')
      //.transition()
      .attr('x', this.dragHandle(nextProps.dates.selected));

    if (this.props.currentDate !== nextProps.currentDate) {
      const startTick = this.tickValues[this.tickIndex[0]];
      const endTick = this.tickValues[this.tickIndex[1]];

      return testForAxisChange(
        startTick,
        endTick,
        nextProps.currentDate,
        nextProps.grain,
      );
    }

    return false;
  }

  render() {
    const { className } = this.props;
    const { mounted } = this.state;
    if (mounted) {
      this.draw();
    }

    return (
      <div className={className}>
        <div ref={el => (this.sliderElement = el)} />
      </div>
    );
  }
}

export default Timeslider;

function tickFormats(grain, el) {
  var text, splitText, i, tspan;

  if (grain === 'daily') {
    el = d3.select(el);
    text = el.text();
    text = text.replace("'", '');
    splitText = text.split(' ');

    el.text('');

    for (i = 0; i < splitText.length; i++) {
      tspan = el.append('tspan').text(splitText[i]);
      if (i === 0) {
        tspan.attr('x', 0).attr('dy', '6');
      }
      if (i === 1) {
        tspan.attr('x', 0).attr('dy', '11');
      }
      if (i === 2) {
        tspan.attr('x', 0).attr('dy', '13');
      }
    }
  }

  if (grain === 'ttm') {
    el = d3.select(el);
    text = el.text();
    splitText = text.split('-');

    el.text('');

    for (i = 0; i < splitText.length; i++) {
      if (i === 0) {
        tspan = el.append('tspan').text(splitText[i] + '-');
        tspan.attr('x', 0).attr('dy', '-10');
      }
      if (i === 1) {
        tspan = el.append('tspan').text(splitText[i]);
        tspan.attr('x', 0).attr('dy', '10');
      }
    }
  }

  if (grain === 'qtr') {
    el = d3.select(el);
    text = el.text();
    splitText = text.split(' ');

    el.text('');

    for (i = 0; i < splitText.length; i++) {
      if (i === 0) {
        tspan = el.append('tspan').text(splitText[i]);
        tspan.attr('x', 0).attr('dy', '-10');
      }
      if (i === 1) {
        tspan = el.append('tspan').text(splitText[i]);
        tspan.attr('x', 0).attr('dy', '10');
      }
    }
  }
}
