export const CHANGE_FILTERS = 'CHANGE_FILTERS';
export const CHANGE_GRAIN = 'CHANGE_GRAIN';
export const CHANGE_FILTER_GRAIN_SELECTED = 'CHANGE_FILTER_GRAIN_SELECTED';
export const CHANGE_DEPLOYMENT_ENVIRONMENTS = 'CHANGE_DEPLOYMENT_ENVIRONMENTS';

export function changeGrain(grain) {
  return {
    type: CHANGE_GRAIN,
    grain,
  };
}

export const changeFilters = filters => {
  try {
    return {
      type: CHANGE_FILTERS,
      payload: filters,
    };
  } catch (err) {
    return {
      type: CHANGE_FILTERS,
      payload: {},
    };
  }
};

export const changeDeploymentEnvironments = payload => {
  return {
    type: CHANGE_DEPLOYMENT_ENVIRONMENTS,
    payload,
  };
};

export const changeFilterGrainSelected = index => {
  return {
    type: CHANGE_FILTER_GRAIN_SELECTED,
    index,
  };
};

//this function is for the filter changes it takes state and set localStorage.setItem('filters', state.filters);

function setlocalFilters(state) {
  localStorage.setItem('filters', JSON.stringify(state.filters));
}

//ReduxThunk

//this thunk changesFilterGrainSelected and sets filters

export const changeFilterGrainSelectedThunk = index => {
  const thunk = (dispatch, getState) => {
    dispatch(changeFilterGrainSelected(index));
    setlocalFilters(getState());
  };
  thunk.meta = {
    debounce: {
      time: 100,
      key: 'CHANGE_FILTER_GRAIN_SELECTED_THUNK',
    },
  };
  return thunk;
};

export const changeGrainThunk = grain => {
  const thunk = (dispatch, getState) => {
    dispatch(changeGrain(grain));
    setlocalFilters(getState());
  };
  return thunk;
};
