import React, { Component } from 'react';

class RadialToolTip extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const ratings_length =
      this.props.content === null ? 0 : this.props.content.split(' ').length;

    const className = this.props.on
      ? 'radial-tooltip-on'
      : 'radial-tooltip-off';
    return (
      <div
        style={{
          left: this.props.placement.x - 150 + 'px',
          top:
            this.props.placement.y > this.props.height / 2 + 100
              ? this.props.placement.y - ratings_length / 3 - 100 + 'px'
              : this.props.placement.y + 20 + 'px',
          maxWidth:
            ratings_length > 200
              ? ratings_length > 600
                ? '650px'
                : '650px'
              : '250px',
          maxHeight: '500px',
          overflow: 'scroll',
        }}
        className={'radial-plot-tooltip ' + className}
      >
        <div className="rating">Rating:{' ' + this.props.rating}</div>
        <div>{this.props.date}</div>
        <div className="review">{this.props.content}</div>
      </div>
    );
  }
}

export default RadialToolTip;
