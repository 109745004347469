export const SET_DATA_TIME = 'SET_DATA_TIME';

function setDataTime(time) {
  return {
    type: SET_DATA_TIME,
    time,
  };
}

export { setDataTime };
