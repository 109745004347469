export const SET_BUBBLE_DATE = 'SET_BUBBLE_DATE';

function setBubbleDate(date) {
  return {
    type: SET_BUBBLE_DATE,
    date,
  };
}

export { setBubbleDate };
