import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import GroupedBarChart from './GroupedBarChart';
import { formatXAxis, formatYAxis } from '../Series/SeriesChart/formatters';
import { useResizeChart } from '../../hooks/useResizeChart';

import './groupByChart.scss';

import { groupedByData, stackedData } from '../Series/SeriesChart/SeriesData';

const GroupBarChartComp = ({ data, color, filters, keys, fullData }) => {
  const chart = useRef(null);
  const el = useRef(null);
  const initialRender = useRef(true);

  const chartData = useMemo(
    () => {
      return groupedByData(stackedData(data, keys, fullData));
    },
    [data, keys, fullData],
  );

  const myChart = useCallback(
    node => {
      if (node !== null) {
        chart.current = new GroupedBarChart({
          el: node,
          data: chartData[1],
          height: node.getBoundingClientRect().height,
          width: node.getBoundingClientRect().width,
          xAxisTicks: chartData[0],
          colorScale: color,
          formatXAxis: formatXAxis(filters.grain),
          formatYAxis: formatYAxis,
          keys: keys,
        });
        chart.current.render();
        el.current = node;
      }
    },
    [null],
  );

  useEffect(
    () => {
      if (!initialRender.current) {
        chart.current.reRender({
          data: chartData[1],
          xAxisTicks: chartData[0],
        });
      }
      initialRender.current = false;
    },
    [chartData],
  );

  useResizeChart(chart, el);
  return <div ref={myChart} className="KPIDash-SChart" />;
};

export default GroupBarChartComp;
