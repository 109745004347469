// import React, { Component } from 'react';
// import { withRouter } from 'react-router';

export default class dataSkeleton {
  initColors() {
    this.dataSkeleton.forEach((d, i, a) => {
      const baseHue = (360 / (a.length + 1)) * i;
      const normalize360 = (deg, delta) => {
        return deg + delta > 360 ? deg + delta - 360 : deg + delta;
      };
      d.colors = {};
      d.colors.primaryHue = normalize360(baseHue, 10);
      d.colors.secondaryHue = normalize360(d.colors.primaryHue, 30);
      d.colors.complimentaryHue = normalize360(d.colors.secondaryHue, 180);
      d.colors.tertiaryHue1 = normalize360(d.colors.primaryHue, 120);
      d.colors.tertiaryHue2 = normalize360(d.colors.primaryHue, 240);
    });
  }

  // this builds a lookup table indexed from csvName
  buildCsvLookup() {
    this.csvLookup = {};
    this.dataSkeleton.forEach(row => {
      row.segments.forEach(seg => {
        seg.colors = row.colors;
        this.csvLookup[seg.csvName] = seg;
      });
    });
  }

  getHypercolor() {
    return this.hyperColor;
  }

  constructor() {
    this.hyperColor = true;
    this.dataSkeleton = [
      {
        name: 'Scale',
        segments: [
          {
            name: 'Total Cumulative Unique Devices',
            csvName: 'devicesseennew',
            formatter: 'bigNum',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../timeseries',
                options: 'seriesOrTotal',
                availGrains: ['daily', 'month', 'qtr', 'total'],
              },
              {
                type: 'Bubble',
                name: 'device bubbles',
                icon: 'icon-bubbles',
                url: '../devices-bubbles',
              },
              {
                type: 'relations',
                icon: 'icon-relations',
                url: '../metric-correlations',
              },
              {
                type: 'relations',
                icon: 'treemap',
                url: '../treemap',
              },
            ],
          },
          {
            name: 'Total Devices Online',
            csvName: 'devicesseennow',
            formatter: 'bigNum',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'seriesOrTotal',
                availGrains: ['now'],
              },
            ],
          },
          {
            name: 'Total Device Population',
            csvName: 'devicesseen',
            formatter: 'bigNum',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'seriesOrTotal',
                availGrains: ['daily', 'month', 'qtr', 'total'],
              },
              {
                type: 'link',
                name: 'device bubbles',
                icon: 'icon-bubbles',
                url: '../devices-bubbles',
              },
              {
                type: 'relations',
                icon: 'icon-relations',
                url: '../metric-correlations',
              },
            ],
          },
          {
            name: 'Active Nodes',
            csvName: 'activenode',
            formatter: 'bigNum',

            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'seriesOrTotal',
                availGrains: ['daily', 'month', 'qtr', 'total'],
              },
              {
                type: 'relations',
                icon: 'icon-relations',
                url: '../metric-correlations',
              },
            ],
          },
          {
            name: 'Cloud Data Stored',
            csvName: 'cloudstorage',
            formatter: 'bigData',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'seriesOrTotal',
                availGrains: ['daily', 'month', 'qtr', 'total'],
              },
              {
                type: 'relations',
                icon: 'icon-relations',
                url: '../metric-correlations',
              },
            ],
          },
        ],
      },
      {
        name: 'Households',
        segments: [
          {
            name: 'New Activations',
            csvName: 'newhh',
            formatter: 'bigNum',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'seriesOrTotal',
                availGrains: ['daily', 'month', 'qtr', 'total'],
              },
            ],
          },
          {
            name: 'Active Households',
            csvName: 'activehh',
            formatter: 'bigNum',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'seriesOrTotal',
                availGrains: ['daily', 'month', 'qtr', 'total'],
              },
            ],
          },
          {
            name: 'New Households Under Contract',
            formatter: 'bigNum',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month'],
              },
              {
                type: 'Under Contract',
                icon: 'icon-undercontract',
                url: '../under-contract',
              },
            ],
            csvName: 'householdsundercontract',
          },
        ],
      },
      {
        name: 'Revenue',
        segments: [
          {
            name: 'Revenue',
            csvName: 'revenue',
            formatter: 'bigCurrency',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month', 'qtr'],
              },
              {
                type: 'relations',
                icon: 'icon-relations',
                url: '../metric-correlations',
              },
            ],
            showTimeSeries: ['month', 'qtr'],
            graphTypes: 'total',
          },
          {
            name: 'ARR',
            csvName: 'arr',
            formatter: 'bigCurrency',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month', 'qtr'],
              },
            ],
          },
          {
            name: 'ARPU',
            csvName: 'arpu',
            formatter: 'currency',
            axisType: 'currency',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month', 'qtr'],
              },
            ],
          },
        ],
      },
      {
        name: 'Profitability',
        segments: [
          {
            name: 'GM',
            csvName: 'gm',
            formatter: 'percentage',
            axisType: 'percentage',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month', 'qtr'],
              },
            ],
          },
          {
            name: 'EBITDA',
            csvName: 'ebitda',
            formatter: 'bigCurrency',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month'],
              },
            ],
          },
          {
            name: 'FCF',
            csvName: 'fcf',
            formatter: 'bigCurrency',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month'],
              },
            ],
          },
        ],
      },
      {
        name: 'Operations',
        segments: [
          {
            name: 'CAC',
            csvName: 'cac',
            formatter: 'currency',
            axisType: 'currency',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month', 'qtr'],
              },
            ],
            invertDelta: true,
          },
          {
            name: 'CIR',
            csvName: 'cir',
            formatter: 'percentage',
            axisType: 'percentage',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month'],
              },
            ],
            invertDelta: true,
          },
          {
            name: 'Uptime',
            csvName: 'uptime',
            formatter: 'uptime',
            axisType: 'percentage',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month'],
              },
            ],
          },
        ],
      },
      {
        name: 'Customer Experience',
        segments: [
          {
            name: 'NPS',
            csvName: 'nps',
            formatter: 'default',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month'],
              },
            ],
          },
          {
            name: 'Churn',
            csvName: 'churn',
            formatter: 'percentage',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month'],
              },
              {
                type: 'churn',
                icon: 'icon-churn',
                url: '../churn-facet',
                options: 'total',
                availGrains: ['month'],
              },
            ],
            invertDelta: true,
            axisType: 'percentage',
          },
          {
            name: 'Ratings',
            csvName: 'ratings',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month'],
              },
              {
                type: 'radial',
                icon: 'icon-timerange',
                url: '../radial',
                options: 'total',
                availGrains: ['daily', 'month', 'qtr', 'year'],
              },
            ],
            tooltip: 'App and Amazon ratings average',
            formatter: 'rating',
          },
        ],
      },

      {
        name: 'SaaS EMCLOUD Index Metrics',
        color: 'Orange',
        segments: [
          {
            name: 'Revenue Growth Rate',
            csvName: 'revenuegrowthrate',
            formatter: 'percentage',
            axisType: 'percentage',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../timeseries',
                options: 'total',
                availGrains: ['month', 'qtr', 'year'],
              },
            ],
          },
          {
            name: 'LTM FCF Margin',
            csvName: 'ltmfcfmargin',
            formatter: 'percentage',
            axisType: 'percentage',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../timeseries',
                options: 'total',
                availGrains: ['month', 'qtr', 'year'],
              },
            ],
          },
          {
            name: 'Efficiency Score',
            csvName: 'efficiency',
            formatter: 'percentage',
            axisType: 'percentage',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../timeseries',
                options: 'total',
                availGrains: ['month', 'qtr', 'year'],
              },
            ],
          },
        ],
      },

      {
        name: 'People',
        segments: [
          {
            name: 'Engagement',
            csvName: 'satisfaction',
            formatter: 'default',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month'],
              },
            ],
          },
          {
            name: 'Attrition',
            csvName: 'attrition',
            formatter: 'integer',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month'],
              },
            ],
            invertDelta: true,
          },
          {
            name: 'New Hires',
            csvName: 'newhire',
            formatter: 'integer',
            links: [
              {
                type: 'series',
                icon: 'icon-series',
                url: '../composite',
                options: 'total',
                availGrains: ['month'],
              },
              {
                type: 'splitchord',
                icon: 'icon-splitchord',
                url: '../splitchord',
                options: 'seriesOrTotal',
                availGrains: ['month'],
              },
            ],
          },
        ],
      },
    ];

    this.initColors();
    this.buildCsvLookup();
  }

  getByCsvName(csvName) {
    return this.csvLookup[csvName];
  }

  getSkeleton() {
    return this.dataSkeleton;
  }
}
