import {
  DATA_FETCHED,
  SET_STATS_DATA,
  RESET_STATS_DATA,
  SET_LEAF_FILTER_VALUE,
  TURN_NULL_ON_OFF,
  SET_FILTER_DATA,
  FILTER_DATA_ON_OFF,
  SET_TOP_NODE_FALSE,
  SET_TOP_NODE_TRUE,
  TURN_NULL_OFF,
  FILTER_DATA_OFF,
} from '../actions/treemap_actions';

const INIT_STATE = {
  data: null,
  category: 'Total',
  brand: null,
  devices: null,
  total_devices: null,
  id: 'All Devices',
  filter_value: 0,
  show_null: false,
  filter_data: null,
  useFilterData: false,
  topNode: true,
};

export default function treemapReducer(state = INIT_STATE, action = null) {
  switch (action.type) {
    case DATA_FETCHED:
      return {
        ...state,
        ...{ data: action.data, total_devices: action.totalDevices },
      };
    case SET_STATS_DATA:
      return {
        ...state,
        ...{
          category: action.category,
          brand: action.brand,
          devices: action.devices,
          id: action.id,
        },
      };

    case SET_TOP_NODE_FALSE:
      return { ...state, ...{ topNode: false } };

    case SET_TOP_NODE_TRUE:
      return { ...state, ...{ topNode: true } };

    case RESET_STATS_DATA:
      return { ...state, ...{ category: 'Total', id: 'All Devices' } };

    case SET_LEAF_FILTER_VALUE:
      return { ...state, ...{ filter_value: action.value } };
    case TURN_NULL_ON_OFF:
      return { ...state, ...{ show_null: !state.show_null } };

    case SET_FILTER_DATA:
      return { ...state, ...{ filter_data: action.data, useFilterData: true } };

    case FILTER_DATA_ON_OFF:
      return { ...state, ...{ useFilterData: !state.useFilterData } };
    case FILTER_DATA_OFF:
      return { ...state, ...{ useFilterData: false } };
    case TURN_NULL_OFF:
      return { ...state, ...{ show_null: false } };

    default:
      return state;
  }
}
