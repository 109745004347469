/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import infoData from '../../static/info.json';

class InfoComponent extends Component {
  getInfoData = data => {
    let infoFound = infoData.find(function(element) {
      return element.csvName === data.csvName;
    });
    if (infoFound) {
      return infoFound;
    } else {
      return 'missing';
    }
  };

  render() {
    const {
      infoIsOpen,
      color,
      openGlossary,
      closeInfo,
      formatedData,
    } = this.props;
    return (
      <div
        className={
          infoIsOpen ? 'infoDiv breakdownBackground' + color : 'infoDiv hide'
        }
        onMouseLeave={() => closeInfo()}
        data-testid="info-component"
      >
        <div className="infoName">{formatedData.name}</div>
        <div className="infoDefinition" data-testid="info-component-definition">
          {this.getInfoData(formatedData).Definition}
        </div>
        <a
          className="active infoCloseBt icon-info-close"
          onClick={() => closeInfo()}
        >
          ✕
        </a>
        <span className="active icon-glossary" onClick={() => openGlossary()}>
          Glossary
        </span>
      </div>
    );
  }
}

export default InfoComponent;
