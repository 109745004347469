import React from 'react';
import { connect } from 'react-redux';
import { resetEnvironments } from '../../actions/filter_chart_actions';
import { DASHBOARD_ROUTE, routeNames } from '../../utils/routes';
import MenuControllerCont from '../MenuControllerCont';
import UserActions from '../../components/UserActions';
import { useLocation } from 'react-router-dom';

const NavHeader = ({ history, resetEnvironments }) => {
  const route = history.location.pathname.substring(1);

  const mainRoute = DASHBOARD_ROUTE;

  let index = route.indexOf('/');

  let shortRoute = route;
  if (index !== -1) shortRoute = route.substr(0, index);
  const location = useLocation();

  // This useEffect triggers location change and updates the view.
  // This is known work around for location not updating.
  React.useEffect(
    () => {
      // const path = location.pathname;
    },
    [location],
  );

  return (
    <ul id="navigation-tab">
      <div>
        <span
          className={`navSpan ${route === mainRoute ? 'active' : ''}`}
          onClick={() => {
            resetEnvironments();
            if (route === mainRoute) return;
            if (history.location.pathname.includes('devices-bubbles')) {
              window.location.href = `/${mainRoute}`;
            } else {
              history.push(`/${mainRoute}`);
            }
          }}
        >
          Dashboard
        </span>
        {route !== mainRoute && (
          <>
            <span className="chevron">></span>
            <span className="navSpan active">{routeNames[shortRoute]}</span>
          </>
        )}
      </div>
      <MenuControllerCont />
      <div>
        <UserActions />
      </div>
    </ul>
  );
};

const mapStateToPropsNav = (state, ownProps) => {
  return {
    history: ownProps.history,
  };
};

const mapStateToDispatchNav = dispatch => {
  return {
    resetEnvironments: () => {
      dispatch(resetEnvironments());
    },
  };
};

const NavHeaderCont = connect(
  mapStateToPropsNav,
  mapStateToDispatchNav,
)(NavHeader);

export default NavHeaderCont;
