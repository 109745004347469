import React, { Component } from 'react';
import * as d3 from 'd3';
// import BaseXYChart from './BaseXYChart';
// import LineChart from './LineChart';
import LineBubbleChart from './LineBubbleChart';
//import AreaChart from './AreaChart';

import { returnRadiusScaleMax } from './helpers';
import parseDate from '../../helpers/d3/utils/formatTimeHelpers';
import './chartXYStyles.scss';

class ChurnLineBubble extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let data = this.props.data.values;

    const xTimeRange = d3.extent(
      this.props.timeAxisValues,
      d => new Date(parseDate(d[0]).getTime()),
    );

    data = data.map(d => {
      return {
        date: d[0],
        name: d[1],
        value: parseFloat(d[2]),
        'active households': parseFloat(d[5]),
        'new activations': parseFloat(d[6]),
        'new households under contract': parseFloat(d[7]),
      };
    });

    const yMax = this.props.title === 'sigma' ? undefined : 3.9;
    const chartArea = new LineBubbleChart(
      {
        el: this.el,
        data: data,
        width: this.el.getBoundingClientRect().width,
        height: this.el.getBoundingClientRect().height,
        titleDescriptionOn: this.props.title === 'total' ? true : false,
        titleDescription:
          'Total is the weighted-average churn of available deployments',
        xValue: 'date',
        yValue: 'value',
        time: true,
        yMax: yMax,
        yTicks: 3,
        xGrid: true,
        title: this.props.title,
        titleColor: this.props.color,
        yAxisTransformExtra: 10,
        formatYLabel: d => d + '%',
        formatXLabel: (d, i) => {
          if (new Date(d).getMonth() === 0) {
            return d3.timeFormat('%b %Y')(d);
          }

          if (i % 2 === 0) {
            return '';
          }

          return d3.timeFormat('%b')(d);
        },
        xTimeRange: xTimeRange,
      },
      {
        strokeColor: 'rgb(255, 0, 0)',
        changeLine: true,
        changeLineColor: 'rgb(48, 230, 48)',
      },
      {
        rValue: this.props.radiusVariable,
        outerValue: null,
        outerColor: 'rgb(199, 180, 179)',
        radiusMax: this.props.radiusMax,
        legendOn: false,
        changeArea: true,
        formatToolTip: d3.format('.01%'),
        scaleRadiusMax: returnRadiusScaleMax(),
      },
    );

    chartArea.mainBaseChart();
    chartArea.makeMainBubble();
    chartArea.mainLine();

    this.chartArea = chartArea;
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.radiusVariable !== nextProps.radiusVariable) {
      this.chartArea.updateRadius({
        rValue: nextProps.radiusVariable,
        outerValue: null,
      });
    }

    return false;
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="churn-line-bubble-cont" ref={el => (this.el = el)} />
    );
  }
}

export default ChurnLineBubble;
