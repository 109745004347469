import React, { Component } from 'react';
import PeopleChordCont from '../../../containers/PeopleChordCont/PeopleChordCont';
import './chordStyles.scss';
import Token from '../../okta/Token';
import { API_URL } from '../../../config';
import * as d3 from 'd3';

export default class ResponsiveContainer extends Component {
  constructor(props) {
    super(props);
    this.height = 1000;
    this.width = 1000;
    this.state = {
      dimensions: {
        height: 0,
        width: 0,
      },
      data: null,
    };
  }
  componentDidMount() {
    const oktaToken = new Token(localStorage['okta-token-storage']);
    const token = 'Bearer ' + oktaToken.accessToken;
    const dataApi = `${API_URL}/data/people`;

    d3.json(`${dataApi}`, {
      headers: { Authorization: token },
    }).then(data => {
      this.setState({
        data: data.data,
      });
    });

    this.setState({
      dimensions: {
        height: this._rootNode.getBoundingClientRect().height,
        width: this._rootNode.getBoundingClientRect().width,
      },
    });
  }

  render() {
    const chart =
      this.state.dimensions.width <= 0 ||
      this.state.dimensions.height <= 0 ||
      this.state.data === null ? null : (
        <PeopleChordCont
          height={this.state.dimensions.height}
          width={this.state.dimensions.width}
          data={this.state.data}
        />
      );

    return (
      <div
        className="people-chord-conatainer"
        ref={el => {
          this._rootNode = el;
        }}
      >
        {chart}
      </div>
    );
  }
}
