export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const sigDecimals = val => {
  return Math.abs(val) > 100 ? 0 : Math.abs(val) > 10 ? 1 : 2;
};

export const formatters = {
  // returns prefix, value, suffix
  currency: function(x, concat) {
    const components = ['$', x.toFixed(2), null, 2];
    return concat ? components.slice(0, 3).join('') : components;
  },
  bigCurrency: function(x, concat) {
    x = x / 1000000;
    const components = ['$', x.toFixed(sigDecimals(x)), 'Mil', sigDecimals(x)];
    return concat ? components.slice(0, 3).join('') : components;
  },
  rating: function(x, concat) {
    const components = [null, x.toFixed(1), '/5', 1];
    return concat ? components.slice(0, 3).join('') : components;
  },
  bigNum: function(x, concat) {
    if (x < 100000) {
      x = x / 1000;
      const components = [null, x.toFixed(2), 'K', 2];
      return concat ? components.slice(0, 3).join('') : components;
    } else {
      x = x / 1000000;
      const components = [null, x.toFixed(2), 'Mil', 2];
      return concat ? components.slice(0, 3).join('') : components;
    }
  },
  bigData: function(x, concat) {
    const components = [null, parseFloat(x).toFixed(1), 'TB', null];
    return concat ? components.slice(0, 3).join('') : components;
  },
  medData: function(x, concat) {
    const components = [null, (parseFloat(x) * 1000).toFixed(1), 'GB', null];
    return concat ? components.slice(0, 3).join('') : components;
  },
  smallData: function(x, concat) {
    const components = [
      null,
      (parseFloat(x) * 1000 * 1000).toFixed(1),
      'MB',
      null,
    ];
    return concat ? components.slice(0, 3).join('') : components;
  },
  percentage: function(x, concat) {
    x = parseFloat(x);
    const components = [null, x.toFixed(sigDecimals(x)), '%', sigDecimals(x)];
    return concat ? components.slice(0, 3).join('') : components;
  },
  uptime: function(x, concat) {
    x = parseFloat(x);
    const components = [null, x === 100 ? x : x.toFixed(2), '%', 2];
    return concat ? components.slice(0, 3).join('') : components;
  },
  default: function(x, concat) {
    x = parseFloat(x);
    const components = [null, x.toFixed(1), null, 1];
    return concat ? components.slice(0, 3).join('') : components;
  },
  integer: function(x, concat) {
    x = numberWithCommas(parseInt(x));
    const components = [null, x, null, null];
    return concat ? components.slice(0, 3).join('') : components;
  },
};

export const limitDecimalPlaces = (num, statName) => {
  let parts = num.toString().split('.');
  if (parts.length === 1) {
    return num;
  }

  switch(statName){
    case 'ratings':
      return parseFloat(Math.round(10 * num) / 10).toFixed(1);
    case 'churn':
      return parseFloat(Math.round(100 * num) / 100).toFixed(2)
      default:
       return Math.round(100 * num) / 100;
    }
};
