import * as d3 from 'd3';
import infoData from '../../../static/info.json';
import { cloneDeep, groupBy } from 'lodash';

import { colorMap } from '../../../static/colorMap';

import dataSkeleton from '../../Skeleton/dataSkeleton';

class D3Glossary {
  constructor(options) {
    this.options = options;

    this.menu = null;
    this.skele = new dataSkeleton(this.options);
  }

  /**
   * Create instance of D3.js component
   * @param el
   */
  create = el => {
    let infoMap;
    const dataSkeleton = this.skele.dataSkeleton;
    const processedData = cloneDeep(dataSkeleton);

    loadInfo(infoData);
    function loadInfo(infoURL) {
      infoMap = [];
      // finish loader
      if (window.spinner) {
        window.spinner.finish();
      }
      setTimeout(() => {
        d3.select('#spinner').attr('display', 'none');
      }, 600);

      infoMap = infoData;
      const grouped = groupBy(infoMap, info => info.Metric);
      let scrollPane = d3.select('#scrollPane');
      for (var key in grouped) {
        scrollPane
          .append('div')
          .html(key)
          .classed('itemTitle', true);
        grouped[key].forEach(item => {
          scrollPane
            .append('div')
            .html(item.SubMetric)
            .classed('itemSubtitle', true);
          scrollPane
            .append('div')
            .html(item.Definition)
            .classed('itemDescription', true);
        });
      }
      let items = d3.selectAll('.itemTitle').data(processedData);

      items.style('color', (d, i, a) => {
        return colorMap[d.name];
      });
    }
  };
  destroy = () => {};
}

export default D3Glossary;
