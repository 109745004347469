import React, { Component } from 'react';

class IconComponent extends Component {
  render() {
    const { item, grain, csvName, dateRanges, color } = this.props;
    if (item.url === '../devices-bubbles') {
      var url = item.url;
      var graphTypes = item.options;
    } else {
      url =
        item.url +
        '/' +
        csvName +
        '/' +
        grain +
        '/' +
        dateRanges[grain].series[dateRanges[grain].selected];

      graphTypes = item.options;
    }
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className={'iconBt active ' + item.icon + ' button' + color}
        onClick={() => this.props.onClick(url, graphTypes, csvName)}
      >
        <div className="tooltip">{item.type}</div>
      </a>
    );
  }
}

export default IconComponent;
