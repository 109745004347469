import React from 'react';
import ChartLegendItem from './ChartLegendItem';

import './chartLegend.scss';

const ChartLegend = ({ options, color }) => {
  return (
    <div className="KPIDash-chart-legend">
      {options.map(item => (
        <ChartLegendItem {...item} color={color} />
      ))}
    </div>
  );
};

export default ChartLegend;
