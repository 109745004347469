import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Display extends Component {
  render() {
    const newCategory =
      this.props.category === '' ? 'Unknow' : this.props.category;
    const newBrand = this.props.brand === '' ? 'Unknow' : this.props.brand;
    const newidType = this.props.idType === '' ? 'Unknow' : this.props.idType;
    return (
      <div className="treemap-display">
        <div className="level-display-cont">
          <div
            style={
              this.props.level === 0 ? { textDecoration: 'underline' } : {}
            }
            className="display-level"
          >
            {this.props.category === 'category'
              ? this.props.category + ': All'
              : 'category: ' + newCategory}
          </div>
          <div
            style={
              this.props.level === 1 ? { textDecoration: 'underline' } : {}
            }
            className="display-level"
          >
            {this.props.brand === 'brand'
              ? this.props.brand + ': All'
              : 'brand: ' + newBrand}
          </div>
          <div
            style={
              this.props.level === 2 ? { textDecoration: 'underline' } : {}
            }
            className="display-level"
          >
            {this.props.idType === 'ID Type'
              ? this.props.idType + ': All'
              : 'ID Type ' + newidType}
          </div>
        </div>
        <div className="display-amount-cont">
          <div className="menu-title">{this.props.title}</div>
          <div className="menu-amount-title">Amount</div>
          <div className="menu-amount-value">{this.props.amount}</div>
        </div>
      </div>
    );
  }
}

Display.propTypes = {
  category: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  idType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default Display;
