export const LOGIN_ROUTE = 'login';
export const DASHBOARD_ROUTE = 'dashboard';
export const LAUNCHER_ROUTE = 'launcher';
export const PROFILE_ROUTE = 'profile';

export const routeNames = {
  menu: 'Dashboard',
  profile: 'Profile',
  launcher: 'Dashboard',
  'map-channel-changes': 'Map Channel Changes',
  'map-interference': 'Map Interference',
  'optimization-interference-since-onboarding':
    'Optimization Interference Since Onboarding',
  'optimization-interference-all-changes':
    'Optimization Interference All Changes',
  'sankey-interference': 'Sankey Interference',
  'timeseries-area': 'Timeseries Area',
  'choropleth-zoom': 'Choropleth Zoom',
  composite: 'Timeseries',
  timeseries: 'Timeseries',
  glossary: 'Glossary',
  'metric-correlations': 'Metric Correlations',
  'devices-bubbles': 'Devices Bubbles',
  'under-contract': 'Households Under Contract',
  'balloon-map': 'Balloon Map',
  splitchord: 'Split Chord Chart',
  radial: 'Radial Chart',
  'churn-facet': 'Churn Facet',
  treemap: 'TreeMap',
};
