//this is needed to reset some of the state to avoid bugs
function setInitialFilterState() {
  if (!JSON.parse(localStorage.getItem('filters'))) {
    return {
      grain: 'month',
      metric: 'activenode',
      environments: null,
    };
  }

  var state = JSON.parse(localStorage.getItem('filters'));

  state = {
    ...state,

    ...{
      environments:
        state.environments === null ||
        state.environments === undefined ||
        state.environments.length === 0
          ? null
          : state.environments.map((val, i) => {
              return { ...val, ...{ on: true } };
            }),
    },
  };

  return state;
}

export { setInitialFilterState };
