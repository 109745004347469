import React, { Component } from 'react';
import DataComponent from '../DataComponent';

class BoxComponent extends Component {
  redirect = (path, graphTypes, metric) => {
    if (path.includes('timeseries')) {
      this.props.changeFilters({ graphTypes, metric });
    }
    this.props.changeFilters({ metric });
    this.props.history.push(path);
  };

  render() {
    const { data, filters } = this.props;
    return (
      <div className="tier1" data-testid="box-component">
        <div className={'groupName title' + data.color}>{data.name}</div>
        <div className={'tier2wrap background' + data.color}>
          {data &&
            data.segments &&
            data.segments.map((item, index) => {
              return (
                <DataComponent
                  key={index}
                  data={item}
                  metric={item.csvName}
                  grain={filters.grain}
                  dateRanges={filters.dateRanges}
                  redirect={this.redirect}
                  color={data.color}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default BoxComponent;
