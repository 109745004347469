import React, { useState, useEffect } from 'react';

import './liveTitle.scss';

const LiveTitle = ({ openClick, closeClick, open }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(
    () => {
      let interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    },
    [null],
  );

  function handleClick() {
    if (open) {
      closeClick();
    } else {
      openClick();
    }
  }

  return (
    <span
      onClick={() => {
        handleClick();
      }}
      className="KPI-live-table-title-cont"
    >
      <span className="KPI-live-table-title-title">Live Count</span>
      {seconds % 2 === 0 ? (
        <span className="KPI-live-table-title-circle" />
      ) : null}
    </span>
  );
};

export default LiveTitle;
