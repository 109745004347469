import * as d3 from 'd3';

const calculateTime = date => {
  let now = new Date(Date.now());
  const nowMonth = now.getMonth();
  const nowYear = now.getFullYear();
  const endOfCurrentMonth = new Date(nowYear, nowMonth + 1, 1);

  const yearPrior = d3.timeYear.offset(endOfCurrentMonth, -1);

  if (
    date.getTime() >= yearPrior.getTime() &&
    date.getTime() < endOfCurrentMonth.getTime()
  ) {
    return d3.timeMonth.range(yearPrior, endOfCurrentMonth);
  }

  const halfYearPrior = d3.timeMonth.offset(date, -6);
  const halfYearFuture = d3.timeMonth.offset(date, +6);
  const monthRangeFullYear = d3.timeMonth.range(halfYearPrior, halfYearFuture);

  return monthRangeFullYear;
};

const formatDate = date => {
  const parseTime = d3.timeParse('%Y-%m-%d');
  const newDate = parseTime(date);
  return newDate;
};

const calculateHighlight = (date, grain) => {
  switch (grain) {
    case 'daily':
      return [date, date];
    case 'month':
      return [date, d3.timeMonth.offset(date, 1)];
    case 'qtr':
      return [date, d3.timeMonth.offset(date, 3)];
    case 'TTM':
      return [];
    case 'alltime':
      return [];
    case 'year':
      return [];
  }
};

export { calculateTime, formatDate, calculateHighlight };
