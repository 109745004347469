import React, { useMemo } from 'react';
import DeploymentList from './DeploymentList';
import { splitDeployments } from '../helpers';

import './filterDeployments.scss';

const FilterDeployments = ({ deployments, colorScale, handleKeyFilter }) => {
  const deploymentsSplit = useMemo(() => splitDeployments(deployments), [
    deployments,
  ]);

  return (
    <div className="KPI-filter-deployments">
      <div className="KPI-filter-deployments-title">Filter Deployments</div>
      <div className="KPI-filter-deployment-list-cont">
        {deploymentsSplit.map(list => (
          <DeploymentList
            deployments={list}
            colorScale={colorScale}
            handleKeyFilter={handleKeyFilter}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterDeployments;
