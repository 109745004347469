import * as d3 from 'd3';
// import { debounce } from 'lodash';

class D3LoadingSpinner {
  constructor(options) {
    this.options = options;
    this.svg = null;
    this.parentEl = null;
  }

  /**
   * Create instance of D3.js component
   * @param el
   */
  create = el => {
    // const { options } = this;
    this.parentEl = el;
    this.svg = d3
      .select(el)
      .append('svg')
      .attr('class', 'spinner')
      .attr('id', 'spinner');
    // this.svg = el ? d3.select(el) : d3.select('#spinner');

    const component = this;
    const setSize = () => {
      component.width = window.innerWidth;
      component.height = window.innerHeight;
      component.svg
        .attr('width', component.width)
        .attr('height', component.height)
        .html('');

      component.svg
        .select('g')
        .attr('width', component.width / 2)
        .attr('height', component.height / 2)
        .style('position', 'fixed')
        .style('top', 0)
        .style('left', 0);
      component.defs = component.svg.append('defs');

      component.defs
        .append('clipPath')
        .attr('id', 'clipCircle')
        .append('circle')
        .attr('r', 6)
        .attr('cx', 58)
        .attr('cy', 0);

      component.defs
        .append('path')
        .attr('id', 'bar')
        .attr('x', 0)
        .attr('y', 0)
        .attr(
          'd',
          'M17.56,26.77h0a3.61,3.61,0,0,1,0-3.61h0L26.71,7.36A8.75,8.75,0,0,1,34.28,3H54.86a1.55,1.55,0,0,0,1.56-1.25A1.5,1.5,0,0,0,55.19,0a1.06,1.06,0,0,0-.25,0H34.28A11.75,11.75,0,0,0,24.11,5.86L15,21.68h0a6.65,6.65,0,0,0,0,6.59h0l7.6,13.16a1.08,1.08,0,0,0,.94.55h2.38a.27.27,0,0,0,.27-.27.32.32,0,0,0,0-.14Z',
        )
        .attr('fill', 'white');

      component.g = component.svg
        .append('g')
        .attr('transform-origin', '38 32')
        .attr('width', component.width / 2)
        .attr('height', component.height / 2);

      component.barEnter = component.g
        .selectAll('circle')
        .data(d3.range(6))
        .enter();

      // console.log('spin.enter');
      component.barEnter
        .append('use')
        .attr('x', 0)
        .attr('y', 0)
        .attr('xlink:href', '#bar')
        .attr('clip-path', 'url(#clipCircle)')
        .attr('transform-origin', '38px 31px')
        .style(
          'transform',
          d => 'translate(0px, 0px) rotate(' + (d - 1) * 60 + 'deg)',
        );
    };
    setSize();

    /* START SPINNER */
    this.init();

    window.addEventListener('resize', setSize);
    /* START TESTING HERE... */
    // this will cycle the loader on and off, rather than just calling init() and finished() via some other component.
    // this.startTestFader = () => {
    //   this.init(); // normally another compone would call this.
    //   setTimeout(() => {
    //     this.hibernateFader();
    //   }, 5000);
    // }
    // this.hibernateFader = () => {
    //   this.finish(); // normally another compone would call this.
    //   setTimeout(() => {
    //     this.startTestFader();
    //   }, 5000);
    // }
    // this.startTestFader();
    /* END TESTING HERE... */

    // this.init(); // normally another compone would call this.
    // setTimeout(() => {
    //   this.finish(); // normally another compone would call this.
    // }, 4000);
  };

  init = () => {
    d3.select('#spinner').attr('display', 'block');
    // this.svg.style('display', 'block');
    this.svg.select('defs clipPath circle').attr('r', 5);
    this.loaded = false;
    this.svg.classed('visible', true);
    // setTimeout(() => {
    //   this.g.classed('finished', false);
    //   this.loaded = true;
    // }, 1250);
    // console.log('spinner.init');

    this.spin();
  };

  forceSpin = () => {
    // console.log('forceSpin');
    this.spin();
  };

  spin = () => {
    //console.log('spin: ', this.loaded);
    let delay = 400;
    if (this.loaded) {
      this._morphOpen();
      return;
    }

    this.g
      .style(
        'transform',
        'translate(' +
          (this.width / 2 - 20) +
          'px, ' +
          (this.height / 2 - 40) +
          'px) rotate(0deg)',
      )
      .transition()
      .ease(d3.easeLinear)
      .duration(delay)
      .style(
        'transform',
        'translate(' +
          (this.width / 2 - 20) +
          'px, ' +
          (this.height / 2 - 40) +
          'px) rotate(-120deg)',
      )
      .transition()
      .ease(d3.easeLinear)
      .duration(delay)
      .style(
        'transform',
        'translate(' +
          (this.width / 2 - 20) +
          'px, ' +
          (this.height / 2 - 40) +
          'px) rotate(-240deg)',
      )
      .transition()
      .ease(d3.easeLinear)
      .duration(delay)
      .style(
        'transform',
        'translate(' +
          (this.width / 2 - 20) +
          'px, ' +
          (this.height / 2 - 40) +
          'px) rotate(-360deg)',
      )
      .on('end', this.spin);
  };

  /**
   * Fire this when data is ready... it'll finish the roation of the spinner, then draw the lines/bars.
   */
  finish = () => {
    this.loaded = true;
    //d3.select("#animated-switch-div").remove();

  };

  /**
   * Reveals the bars
   */
  _morphOpen = () => {
    this.svg
      .select('defs clipPath circle')
      .transition()
      .duration(700)
      .ease(d3.easeLinear)
      .attr('r', 70);
    // this.svg && this.svg.classed('loaded',true);
    setTimeout(() => {
      this.hide();
    }, 900);
  };

  hide = () => {
    if (this.svg) {
      this.svg.classed('visible', false);
      this.g.classed('finished', true);
      d3.selectAll('#spinner').attr('display', 'none');
      // d3.selectAll('#spinner').remove();
      // this.svg.remove();
      // this.destroy();
    }
  };

  /**
   * Cleaning code...
   */
  destroy = () => {
    // this.svg && this.svg.remove();
  };
}

export default D3LoadingSpinner;
