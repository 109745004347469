import React, { Component } from 'react';
import * as d3 from 'd3';
import {
  processData,
  filterLeafs,
  //filterLeafsMin,
} from './helpers/processData';

import { groupByCategory } from './helpers/find_and_group';
import { formatIDs } from './helpers/formatIDs';
import colors from './helpers/colors';
import { pieData } from './helpers/pieData';

class Treemap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rerender: true,
      grouped: false,
      category: 'category',
      treeMapDeepCategory: null,
      width: false,
      height: false,
    };
  }

  componentDidMount() {
    this.initTreemap();
  }

  groupData = () => {
    this.groupedDataMap = groupByCategory(this.props.fullData);
    this.setState({ grouped: true });
  };

  makeTreeMapData = () => {
    if (this.props.viewTop && !this.props.showDeeper) {
      var topData = pieData(this.props.data);

      topData = topData.map(el => {
        return { ...el, ...{ category: el.name } };
      });
    }
    const [data, categories] = processData(this.props.data);
    this.data = data;

    this.categories = categories;

    const values =
      this.props.viewTop && !this.props.showDeeper ? topData : this.data;
    this.topNode = { key: 'All Product', values: values };
  };

  makeTreeMapLayout() {
    this.element_width = this.el.getBoundingClientRect().width;
    this.element_height = this.el.getBoundingClientRect().height;

    if (!this.state.height || !this.state.width) {
      this.setState({ width: true });
      this.setState({ height: true });
    }

    var paddingAmount =
      this.props.showDeeper &&
      (this.props.level === 0 || this.props.level === 1)
        ? 0.01
        : 0.4;

    this.treemap = data =>
      d3
        .treemap()
        .size([this.element_width, this.element_height])
        .paddingInner(1)
        .paddingOuter(paddingAmount)(
        d3
          .hierarchy(data, d => d.values)
          .sum(d => d.value)
          .sort((a, b) => b.value - a.value),
      );

    this.canvas = null;

    this.treeMapLayout = this.treemap(this.topNode);
  }

  createColorScale() {
    this.colors = d3.map();

    [''].concat(this.categories).forEach((val, i) => {
      this.colors.set(val, colors[i]);
    });
  }

  filteredTreeMap = (numb, data, showDeeper, category, keys) => {
    const filterData = data.filter((val, i) => {
      return val.value > numb;
    });

    if (!showDeeper) {
      var topData = pieData(filterData, category, keys);

      topData = topData.map(el => {
        return { ...el, ...{ category: el.name } };
      });
    }

    const returned_data = processData(filterData);

    this.data = returned_data[0];
    this.categories = returned_data[1];

    const values = !this.props.showDeeper ? topData : this.data;
    this.topNode = { key: 'All Product', values: values };

    this.makeTreeMapLayout();
    this.makeCanvasTreeMap();
    this.makeTitles();
  };

  makeCanvasTreeMap = () => {
    const canvas =
      d3.select('#canvas')._groups[0][0] === null
        ? d3
            .select(this.el)
            .append('canvas')
            .attr('id', 'canvas')
            .attr('width', this.element_width)
            .attr('height', this.element_height)
        : d3.select('#canvas');

    var context = canvas.node().getContext('2d');

    this.context = context;

    context.clearRect(0, 0, this.element_width, this.element_height);

    this.treeMapLayout.leaves().forEach((d, i) => {
      const x = d.x0;
      const y = d.y0;
      const t = d.x1 - d.x0;
      const s = d.y1 - d.y0;

      context.fillStyle = !this.props.topNode
        ? this.colors.get(this.state.treeMapDeepCategory)
        : this.colors.get(d.data.category);
      context.beginPath();
      context.fillRect(x, y, t, s);
      context.closePath();
      //this.canvasOnClick();
    });
    this.canvasOnClick();
  };

  makeTitles = () => {
    const context = this.context;
    const filterValue =
      this.props.level === 0 ? 2000 : this.props.level === 1 ? 50 : 0;

    filterLeafs(this.treeMapLayout.leaves(), filterValue).forEach((d, i) => {
      const x = d.x0 + 2;
      const y = d.y0;
      const t = d.x1 - d.x0;
      const category = d.data.category === '' ? 'unknown' : d.data.category;
      const brand = d.data.brand === '' ? 'unknown' : d.data.brand;
      const idtype = d.data.idtype === '' ? 'unknown' : d.data.idtype;

      context.fillStyle = 'black';
      context.beginPath();
      context.font = '12px wigrum-light';
      context.fillText(category, x, y + 10, t);
      context.closePath();

      if (this.props.showDeeper) {
        context.beginPath();
        context.font = '12px wigrum-light';
        context.fillText(brand, x, y + 20, t);
        context.closePath();

        context.beginPath();
        context.font = '12px wigrum-light';
        context.fillText(formatIDs(idtype), x, y + 30, t);
        context.closePath();
      }
    });
    if (this.props.level === 0) {
      this.canvasOnClick();
    } else if (this.props.level === 1) {
      this.canvasOnClickDeeper();
    } else {
      this.canvasOnClickOff();
    }
  };

  initTreemap = () => {
    this.makeTreeMapData();
    this.groupData();
    this.makeTreeMapLayout();
    this.createColorScale();
    this.makeCanvasTreeMap();
    this.makeTitles();
  };

  canvasOnClick = () => {
    var self = this;

    //remove any click that may be there
    d3.selectAll('#canvas').on('click', null);

    d3.select('#canvas').on('click', function(e) {
      self.setState({ rerender: false });
      self.props.setKeys(['brand', 'idtype']);

      self.setState({ category: 'brand' });
      self.props.setLevel(1);
      self.props.setTopNodeFalse();
      self.props.setViewTop(false);
      self.makeTreeMapData();
      self.makeTreeMapLayout();

      const cords = d3.mouse(this);
      const x = cords[0];
      const y = cords[1];
      const treemapData = self.treeMapLayout;

      let found = false;
      let filteredTreeData;
      let i = 0;

      while (!found) {
        if (
          treemapData.children[i].x0 < x &&
          treemapData.children[i].x1 > x &&
          treemapData.children[i].y0 < y &&
          treemapData.children[i].y1 > y
        ) {
          filteredTreeData = [treemapData.children[i]];
          found = true;
        }
        i = i + 1;
      }

      var category = filteredTreeData[0].data.key;
      self.setState({ treeMapDeepCategory: category });
      self.props.setCategory(category);

      let filtered_data = self.groupedDataMap.get(category);

      const total = d3.sum(filtered_data, d => d.value);
      self.props.setAmount(total, category);
      self.props.setDisplayValues('category', category);
      self.setState({ rerender: true });
      self.props.setFilterData(filtered_data);

      self.canvasOnClickDeeper();
    });
  };

  canvasOnClickDeeper = () => {
    var self = this;
    //remove any click that may be there
    d3.selectAll('#canvas').on('click', null);

    d3.select('#canvas').on('click', function(e) {
      self.setState({ rerender: false });
      self.props.setKeys(['idtype']);

      self.setState({ category: 'idtype' });

      self.props.setLevel(2);
      self.makeTreeMapData();
      self.makeTreeMapLayout();

      const cords = d3.mouse(this);
      const x = cords[0];
      const y = cords[1];
      const treemapData = self.treeMapLayout;

      const filteredTreeData = treemapData.children[0].children.filter(
        (val, i) => {
          return val.x0 < x && val.x1 > x && val.y0 < y && val.y1 > y;
        },
      );

      var brand = filteredTreeData[0].data.key;

      const filtered_data = self.props.data.filter((val, i) => {
        return val.brand === brand;
      });

      const total = d3.sum(filtered_data, d => d.value);
      self.props.setDisplayValues('brand', brand);
      self.props.setAmount(total, brand);
      self.setState({ rerender: true });
      self.props.setFilterData(filtered_data);

      self.canvasOnClickOff();
    });
  };

  canvasOnClickOff = () => {
    var self = this;

    //remove any click that may be there
    d3.selectAll('#canvas').on('click', null);

    d3.select('#canvas').on('click', function(e) {
      self.props.setKeys(['category', 'brand', 'idtype']);

      self.props.setLevel(0);
      self.props.setTopNodeTrue();
      self.props.setViewTop(true);
      self.makeTreeMapData();
      self.makeTreeMapLayout();

      const data = self.props.showNull
        ? self.props.fullData
        : self.props.fullData.filter(d => d.category !== '');
      const total = d3.sum(data, d => d.value);

      self.props.setAmount(total, 'Total');
      self.props.resetDisplayValues();
      self.props.filterDataOnOff();
      //self.treeMapLayout = filteredTreeData[0];
      //self.makeCanvasTreeMap();
    });
  };

  zoomCursor = () => {
    if (this.props.level === 2) {
      d3.select('#canvas').style('cursor', 'zoom-out');
    } else {
      d3.select('#canvas').style('cursor', 'zoom-in');
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.level === 1 && this.props.level === 2) {
      this.canvasOnClickDeeper();
    }
    if (nextProps.level === 0 && this.props.level === 1) {
      this.canvasOnClick();
    }

    if (!this.state.rerender) {
      return false;
    }
    return true;
  }

  render() {
    if (this.state.width && this.state.height) {
      this.makeTreeMapData();
      this.makeTreeMapLayout();
      this.filteredTreeMap(
        this.props.minLeafValue,
        this.props.data,
        this.props.showDeeper,
        this.props.keys[0],
        this.props.keys,
      );
      this.zoomCursor();
    }
    return (
      <div
        ref={el => {
          this.el = el;
        }}
        className="treemap-element"
      />
    );
  }
}

export default Treemap;
