import * as d3 from 'd3';

const DURATION = 800;

class D3NotificationPopup {
  constructor(options) {
    this.options = options;
  }

  create = el => {
    const { title, message, type = 'success' } = this.options;

    const notificationNumber = d3.selectAll('.notification-popup').nodes()
      .length;

    this.popup = d3
      .select(el)
      .append('div')
      .attr('class', `notification-popup ${type}`)
      .style('top', `${notificationNumber * 8}%`);

    this.popup
      .style('opacity', 0)
      .transition()
      .delay(1500)
      .duration(DURATION)
      .style('opacity', 1);

    this.popup.append('h4').text(title);

    this.popup.append('div').html(message);

    this.popup
      .append('span')
      .text('x')
      .on('click', this.destroy);

    const component = this;
    setTimeout(() => {
      component.destroy();
    }, 5000);
  };

  destroy = () => {
    const { popup } = this;
    if (popup) {
      popup
        .style('opacity', 1)
        .transition()
        .duration(DURATION)
        .style('opacity', 0);

      setTimeout(() => {
        popup.remove();
      }, DURATION * 2);
    }
  };
}

export default D3NotificationPopup;
