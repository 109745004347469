import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import D3UnderContract from '../../components/helpers/d3/underContract';
import { API_URL } from '../../config';
import { getVizFilters } from '../../utils/helper';

import { logoutUser } from '../../actions/auth_actions';
import { changeFilters } from '../../actions/filter_actions';

class UnderContract extends Component {
  d3Instance = new D3UnderContract({
    dataApi: `${API_URL}/data/under-contract`,
    logoutUser: this.props.logoutUser,
    filters: getVizFilters(this.props.filters, this.props.match.params),
  });

  async componentDidMount() {
    this.d3Instance.create(this._rootNode);
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    // this.d3Instance.destroy();
  }

  render() {
    return (
      <div
        className="main-container"
        id="under-contract"
        ref={c => {
          this._rootNode = c;
        }}
      >
        {/* <svg className="spinner" id="spinner" /> */}
      </div>
    );
  }
}

/*
export default connect(
  null,
  { logoutUser },
)(UnderContract);
*/

const mapStateToProps = ({ filters }) => ({ filters });

export default withRouter(
  connect(
    mapStateToProps,
    { changeFilters, logoutUser },
  )(UnderContract),
);
