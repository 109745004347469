import React from 'react';
import { LoginCallback } from '@okta/okta-react';

const CustomLoginCallback = () => {
  const renderError = props => {
    const { error } = props;

    if (error) {
      const { errorSummary } = error;
      return (
        <div>
          <p style={{ color: 'rgb(212,211,210)' }}>{errorSummary}</p>
        </div>
      );
    }
    return null;
  };
  return <LoginCallback errorComponent={renderError} />;
};

export default CustomLoginCallback;
