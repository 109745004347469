class Token {
  constructor(token_storage) {
    this._tokenStorage = token_storage;
  }

  set tokenStorage(token_storage) {
    this._tokenStorage = token_storage;
  }

  get idToken() {
    const token = JSON.parse(this._tokenStorage).idToken.idToken;
    return token;
  }

  get accessToken() {
    const token = JSON.parse(this._tokenStorage).accessToken.accessToken;
    return token;
  }
}

export default Token;
