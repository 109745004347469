import { AUTH_LOGOUT } from '../actions/auth_actions';

const INIT_STATE = {
  authenticated: true,
  loggedOff: false,
};

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_LOGOUT:
      localStorage.removeItem('authenticated');
      return { ...state, authenticated: false, loggedOff: true };
    default:
      return state;
  }
}
