class DashBoardData {
  constructor(options) {
    this.rawData = options.data; //this is the rawData from the API
    this.dataMap = options.dataMap; //this is the data map from tha API it maps index to the rawData
    this.data = this.wrangledData;
  }

  get wrangledData() {
    const dataJSON = [];
    this.rawData.forEach(d => {
      const dataRow = {};
      d.forEach((item, i) => {
        dataRow[this.dataMap[i]] =
          this.dataMap[i] === 'value' ? parseFloat(item) : item;
      });
      dataJSON.push(dataRow);
    });
    return dataJSON;
  }
}

export default DashBoardData;
