import axios from 'axios';

function getTokenFromLocalStorage() {
  const OKTA_TOKEN_STORAGE = localStorage.getItem('okta-token-storage');

  if (OKTA_TOKEN_STORAGE) {
    const json = JSON.parse(OKTA_TOKEN_STORAGE);
    if (json.accessToken && json.accessToken.accessToken) {
      return json.accessToken.accessToken;
    }
  }
  return null;
}

if (!axios.defaults.headers.common.Authorization) {
  const token = getTokenFromLocalStorage();
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}

axios.interceptors.request.use(
  config => {
    if (!axios.defaults.headers.common.Authorization) {
      const token = getTokenFromLocalStorage();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    }

    return config;
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 500) {
      return;
    }
    return error;
  },
);
