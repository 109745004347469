import axios from 'axios';

import { UNAUTHORIZED } from '../utils/constants';
import { API_URL } from '../config';
import { logoutUser } from './auth_actions';

export const MODULES_GET_OWN = 'MODULES_GET_OWN';

export const getOwnModules = async () => {
  try {
    const response = await axios.get(`${API_URL}/modules/own`);

    return {
      type: MODULES_GET_OWN,
      payload: response.data,
    };
  } catch (err) {
    if (err.response && err.response.status === UNAUTHORIZED) {
      logoutUser();
    }
    return {
      type: MODULES_GET_OWN,
      payload: [],
    };
  }
};
