import React, { Component } from 'react';
import PropTypes from 'prop-types';

//this is a generic component that takes an array of data as values and a callback function and

class SelectComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const options = this.props.values.map((value, i) => {
      return <option value={value.toUpperCase()}>{value}</option>;
    });

    return (
      <div className={'select-container ' + this.props.className}>
        <select
          value={this.props.defaultValue.toUpperCase()}
          style={{ width: '100%', height: '100%' }}
          onChange={this.props.handleDeviceChange}
        >
          {options}
        </select>
      </div>
    );
  }
}

SelectComponent.propTypes = {
  className: PropTypes.string,
  values: PropTypes.array.isRequired,
  handleDeviceChange: PropTypes.func.isRequired,
};

export default SelectComponent;
