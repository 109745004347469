import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

const HomeComponent = withOktaAuth(
  class HomeStead extends Component {
    constructor(props) {
      super(props);
      this.state = { authenticated: null };

    }

    getTokenFromLocalStorage() {
      const OKTA_TOKEN_STORAGE = localStorage.getItem('okta-token-storage');
      if (OKTA_TOKEN_STORAGE) {
        const json = JSON.parse(OKTA_TOKEN_STORAGE);
        if (json.accessToken && json.accessToken.accessToken) {
          return json.accessToken.accessToken;
        }
      }
      return null;
    }

    componentDidMount() {
      if (!axios.defaults.headers.common.Authorization) {
        const token = this.getTokenFromLocalStorage();
        if (token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
      }
    }

    componentDidUpdate() {
      this.checkAuthentication();
    }

    login = async () => {
      this.props.auth.login('/');
    };

    logout = async () => {
      this.props.auth.logout('/');
    };

    render() {
      return (
        <Fragment>
          <Redirect to="/dashboard" />
        </Fragment>
      );
    }
  },
);

const mapStateToProps = state => {
  return {
    mainRoute: state.auth.mainRoute,
  };
};

const Home = connect(
  mapStateToProps,
  {},
)(HomeComponent);

export default Home;
