import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { useResizeChart } from '../../../hooks/useResizeChart';
import { connect } from 'react-redux';

import SeriesChartChart from './SeriesChartChart';
import { stackedData } from './SeriesData';
import { formatXAxis } from './formatters';
import { getEndDataFromMetric, yAxisFormatters } from '../dataHelpers';

import './seriesChart.scss';

const SeriesChart = ({ data, filters, keys, total, color, fullData }) => {
  const chart = useRef(null);
  const el = useRef(null);
  const initialRender = useRef(true);
  const initialRender2 = useRef(true);
  const { grain } = filters;
  const currentTs =
    filters.dateRanges[grain].series[filters.dateRanges[grain].selected];

  const keysMemo = useMemo(
    () => {
      const keyArray = [];
      keys.forEach(item => {
        if (item.selected === true) keyArray.push(item.name);
      });
      return total === true ? ['total'] : keyArray;
    },
    [total, keys],
  );

  const chartData = useMemo(
    () => stackedData(data, keysMemo, fullData, currentTs),
    [data, keysMemo, fullData, currentTs],
  );

  useEffect(
    () => {
      if (initialRender.current !== true && chart.current !== null) {
        //chart.current.reRender({ data: chartData[0] });
      }
      initialRender.current = false;
    },
    [chartData],
  );
  const myChart = useCallback(
    node => {
      if (node !== null) {
        chart.current = new SeriesChartChart({
          el: node,
          data: chartData[0],
          height: node.getBoundingClientRect().height,
          width: node.getBoundingClientRect().width,
          xAxisTicks: chartData[1],
          colorScale: color,
          formatXAxis: formatXAxis(filters.grain),
          formatYAxis: yAxisFormatters(
            getEndDataFromMetric(filters.metric)('formatter'),
          ),
        });
        chart.current.render();
        el.current = node;
      }
    },
    [null],
  );

  useEffect(
    () => {
      if (!initialRender2.current) {
        chart.current.reRender({
          data: chartData[0],
          xAxisTicks: chartData[1],
          formatXAxis: formatXAxis(filters.grain),
          formatYAxis: yAxisFormatters(
            getEndDataFromMetric(filters.metric)('formatter'),
          ),
        });
      }
      initialRender2.current = false;
    },
    [fullData, grain, chartData],
  );
  useResizeChart(chart, el);

  return <div ref={myChart} className="KPIDash-SChart" />;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SeriesChart);
