function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function returnRadiusScaleMax() {
  if (window.innerWidth > 1455 && window.innerHeight > 722) {
    return 35;
  }

  if (
    window.innerWidth > 1050 ||
    (window.innerHeight < 722 && window.innerHeight > 500)
  ) {
    return 25;
  }

  return 15;
}

export { numberWithCommas, returnRadiusScaleMax };
