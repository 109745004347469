import React from 'react';

const ToolTip = props => {
  if (!props.show) {
    return null;
  } else {
    return (
      <div
        className="donut-tooltip"
        style={{ top: props.top, left: props.left, position: 'absolute' }}
      >
        <div className="tool-tip-title">{props.title}</div>
        <div className="tool-tip-body">{props.body}</div>
      </div>
    );
  }
};

export default ToolTip;
