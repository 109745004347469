import axios from 'axios';

import { UNAUTHORIZED } from '../utils/constants';
import { API_URL } from '../config';
import { logoutUser } from './auth_actions';
import { GET_CLIENTS } from './client_actions';
import { MODULES_GET_OWN } from './modules_actions';
import jwtDecode from 'jwt-decode';
import Token from '../components/okta/Token';

export const ME_GET = 'ME_GET';

export const getMe = () => async dispatch => {
  try {
    const response = await axios.get(`${API_URL}/users/current`);
    const user = JSON.parse(localStorage.getItem('user')) || response.data;
    if (user.isSuperAdmin) {
      const rsp = await axios.get(`${API_URL}/clients`);
      const [client] = rsp.data.filter(({ _id }) => user.client === _id);
      const { modules } = client;
      dispatch({ type: MODULES_GET_OWN, payload: modules });
      dispatch({
        type: GET_CLIENTS,
        payload: rsp.data,
      });
    }

    dispatch({
      type: ME_GET,
      payload: user,
    });
  } catch (err) {
    if (err.response && err.response.status === UNAUTHORIZED) {
      return logoutUser();
    }
    return {
      type: ME_GET,
      payload: null,
    };
  }
};

export const oktaGetMe = () => async dispatch => {
  try {
    const oktaToken = new Token(localStorage['okta-token-storage']);
    const token = 'Bearer ' + oktaToken.accessToken;
    const response = await axios.get(`${API_URL}/users/current`, {
      headers: {
        Authorization: token,
      },
    });
    const user = JSON.parse(localStorage.getItem('user')) || response.data;
    if (user.isSuperAdmin) {
      const rsp = await axios.get(`${API_URL}/clients`, {
        headers: {
          Authorization: token,
        },
      });
      const [client] = rsp.data.filter(({ _id }) => user.client === _id);
      const { modules } = client;
      dispatch({ type: MODULES_GET_OWN, payload: modules });
      dispatch({
        type: GET_CLIENTS,
        payload: rsp.data,
      });
    }

    if (
      user.first === undefined ||
      user.first === null ||
      user.last === undefined ||
      user.last === null
    ) {
      const idToken = oktaToken.idToken;
      const idDecoded = jwtDecode(idToken);

      user.first = idDecoded.firstName;
      user.last = idDecoded.lastName;
    }

    dispatch({
      type: ME_GET,
      payload: user,
    });
  } catch (err) {
    if (err.response && err.response.status === UNAUTHORIZED) {
      return logoutUser();
    }
    return {
      type: ME_GET,
      payload: null,
    };
  }
};

export const updateUser = (data, callback) => async dispatch => {
  try {
    const response = await axios.put(`${API_URL}/users/current`, data);

    dispatch({
      type: ME_GET,
      payload: response.data,
    });
    callback(true);
  } catch (err) {
    if (err.response && err.response.status === UNAUTHORIZED) {
      return logoutUser();
    }
    dispatch({
      type: ME_GET,
      payload: null,
    });
    callback(false);
  }
};
