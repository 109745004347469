import * as d3 from 'd3';

//

const processData = (data, keys = ['category', 'brand', 'idtype']) => {
  const categories = [];

  data.forEach((d, i) => {
    if (!categories.includes(d[keys[0]])) {
      categories.push(d[keys[0]]);
    }
  });
  var nestedData;
  if (keys.length === 3) {
    nestedData = d3
      .nest()
      .key(d => {
        return d[keys[0]];
      })
      .key(d => {
        return d[keys[1]];
      })
      .key(d => {
        return d[keys[0]];
      })
      .entries(data);
  } else if (keys.length === 2) {
    nestedData = d3
      .nest()
      .key(d => {
        return d[keys[0]];
      })
      .key(d => {
        return d[keys[1]];
      })
      .entries(data);
  } else if (keys.length === 1) {
    nestedData = d3
      .nest()
      .key(d => {
        return d[keys[0]];
      })
      .entries(data);
  }

  return [nestedData, categories];
};

function filterLeafs(leafs, thresholdValue) {
  const filtersleafs = leafs.filter((leaf, i) => {
    return leaf.data.value > thresholdValue;
  });

  return filtersleafs;
}

function filterLeafsMin(leafs, thresholdValue) {
  const filtersleafs = leafs.filter((leaf, i) => {
    return leaf.data.value < thresholdValue;
  });

  return filtersleafs;
}

export { processData, filterLeafs, filterLeafsMin };
