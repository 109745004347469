import React from 'react';
import { grainFormatters } from '../helpers/menuFormatters';

const CurrentRange = ({ grain, date, timeIndex, uniqueId }) => (
  <div id={uniqueId ? 'menu-range-area' : 'rangeArea'}>
    <div className="range">
      <div className="label headerArea grain"> {grainFormatters[grain]()} </div>
      <div className="label headerArea timeframe">{date}</div>
    </div>
  </div>
);

export default CurrentRange;
