import React, { Component } from 'react';
import { connect } from 'react-redux';
import D3LoadingSpinner from '../../components/helpers/d3/loadingSpinner';

//import { LOGIN_ROUTE } from '../../utils/routes';

import Routes from '../../components/Routes';

class AppNavigation extends Component {
  d3Spinner = new D3LoadingSpinner({});

  componentDidMount() {
    // if (!window.location.pathname.includes(LOGIN_ROUTE)) {
    //   this.d3Spinner.create('body');
    //   const { d3Spinner } = this;
    //   window.spinner = d3Spinner;
    // }
  }

  shouldComponentUpdate(nextProps) {
    return (
      JSON.stringify(this.props.filters) === JSON.stringify(nextProps.filters)
    );
  }

  render() {
    return <Routes />;
  }
}

export default connect(
  null,
  {},
)(AppNavigation);
