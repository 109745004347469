import React, { Component } from 'react';
import Treemap from './Treemap';
import DonutChart from './DonutChart';
import Controls from './Controls';
import Display from './Display';
import Button from './Button';
import { groupByCategory } from './helpers/find_and_group';
import './treemap_styles.scss';

import * as d3 from 'd3';

class TreemapMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataMap: null,
      treemap: {
        view: {
          top: true,
        },
        keys: ['category', 'brand', 'idtype'],
      },
      displayTitle: 'Total',
      displayAmount: null,
      display: {
        category: 'category',
        brand: 'brand',
        idType: 'ID Type',
      },
      showNull: false,
      level: 0,
      category: null,
      showDeeper: false,
      donutChart: {
        width: null,
        height: null,
      },
    };
  }

  componentDidMount = () => {
    this.props.getData();

    if (window.spinner) {
      setTimeout(function() {
        window.spinner.finish();
      }, 1);
    }
  };

  setDataMap = () => {
    this.setState({ dataMap: groupByCategory(this.props.data) });
  };

  donutElCallback = el => {
    if (el !== null) {
      this.setState((state, props) => {
        return {
          ...state,
          ...{
            donutChart: {
              width: el.getBoundingClientRect().width,
              height: el.getBoundingClientRect().height,
            },
          },
        };
      });
    }
  };

  setCategory = current_category => {
    this.setState({ category: current_category });
  };

  filterNullValues = () => {};

  handleDeeperChange = () => {
    // this.setState((state, props) => ({
    //     showDeeper: !state.showDeeper
    //     }));
  };

  handleSliderChange = e => {
    const filter_value = e.target.value;
    this.props.setFilterValue(filter_value);
  };

  handleNullChange = () => {
    if (this.state.level === 0) {
      if (this.props.showNull === false) {
        const total = d3.sum(this.props.data, d => d.value);
        this.setAmountAndTitle(total, 'Total');
      } else {
        const total = d3.sum(
          this.props.data.filter(d => d.category !== ''),
          d => d.value,
        );
        this.setAmountAndTitle(total, 'Total');
      }
    }

    this.props.turnNullOnOff();
  };

  handleDeeperChange = () => {
    this.setState((state, props) => {
      return { ...state, ...{ showDeeper: !state.showDeeper } };
    });
  };

  setLevel = level => {
    this.setState({ level: level });
  };

  setDisplayValues = (key, value) => {
    this.setState((state, props) => {
      return {
        ...state,
        ...{ display: { ...state.display, ...{ [key]: value } } },
      };
    });
  };
  resetDisplayValues = () => {
    this.setState((state, props) => {
      return {
        ...state,
        ...{
          display: {
            ...state.display,
            ...{ category: 'category', brand: 'brand', idType: 'ID Type' },
          },
        },
      };
    });
  };

  setAmountAndTitle = (amount, title) => {
    this.setState({
      displayAmount: d3.format(',')(amount),
      displayTitle: title,
    });
  };

  setInitDataAmount = () => {
    let total;
    if (this.props.showNull) {
      total = d3.sum(this.props.data, d => d.value);
    } else {
      total = d3.sum(
        this.props.data.filter(d => d.category !== ''),
        d => d.value,
      );
    }
    this.setAmountAndTitle(total, 'Total');
  };

  treeMapReset = () => {
    this.setViewTop(true);
    this.setLevel(0);
    this.setTreemapKeys(['category', 'brand', 'idtype']);
    this.props.setTopNodeTrue();
    this.setInitDataAmount();
    this.resetDisplayValues();
    this.setState({ showNull: false, showDeeper: false });
    this.props.turnNullOff();
    this.props.filterDataOff();
    this.props.setFilterValue(0);
  };

  setTreemapKeys = keys => {
    this.setState((state, props) => {
      return {
        ...state,
        ...{ treemap: { ...state.treemap, ...{ keys: keys } } },
      };
    });
  };
  setViewTop = boolean => {
    this.setState((state, props) => {
      return {
        ...state,
        ...{ treemap: { ...state.treemap, ...{ view: { top: boolean } } } },
      };
    });
  };

  brandToCategory = () => {
    this.setViewTop(true);
    this.setLevel(0);
    this.setTreemapKeys(['category', 'brand', 'idtype']);
    this.props.setTopNodeTrue();
    this.setInitDataAmount();
    this.resetDisplayValues();
    this.props.filterDataOff();
  };

  idTypeToBrand = () => {
    this.setTreemapKeys(['brand', 'idtype']);
    this.setState({ category: 'brand' });
    this.setLevel(1);

    const category = this.state.display.category;
    const filtered_data = this.state.dataMap.get(category);

    this.setCategory(category);

    const total = d3.sum(filtered_data, d => d.value);
    this.setAmountAndTitle(total, category);
    this.props.setFilterData(filtered_data);

    this.setState((state, props) => {
      return {
        ...state,
        ...{
          display: {
            ...state.display,
            ...{ brand: 'brand', idType: 'ID Type' },
          },
        },
      };
    });

    // const total = d3.sum(filtered_data, d => d.value);
    // self.props.setDisplayValues('brand', brand);
    // self.props.setAmount(total, brand);
    // self.setState({ rerender: true });
    // self.props.setFilterData(filtered_data);
    //
    // self.canvasOnClickOff();
  };

  handleNodeBackClick = () => {
    if (this.state.level === 1) {
      this.brandToCategory();
    } else if (this.state.level === 2) {
      this.idTypeToBrand();
    }
  };

  componentWillUnmount = () => {
    this.treeMapReset();
  };

  render() {
    if (this.state.displayAmount === null && this.props.data !== null) {
      this.setInitDataAmount();
      this.setDataMap();
    }

    if (this.props.data === null || this.state.displayAmount === null) {
      return null;
    }

    return (
      <div className="title-cont-main">
        <div className="title-treemap"> Device Treemap</div>
        <div className="treemap-main-cont" style={{ color: 'white' }}>
          <div className="left-controller-container">
            <div className="top-filler-cont">
              <Display
                category={this.state.display.category}
                brand={this.state.display.brand}
                idType={this.state.display.idType}
                title={this.state.displayTitle}
                amount={this.state.displayAmount}
                level={this.state.level}
              />
            </div>
            <Controls
              showNull={this.props.showNull}
              handleNullChange={this.handleNullChange}
              handleSliderChange={this.handleSliderChange}
              filterValue={this.props.filter_value}
              showDeeper={this.state.showDeeper}
              handleDeeperChange={this.handleDeeperChange}
            />
            <div
              ref={el => {
                return this.state.donutChart.width === null
                  ? this.donutElCallback(el)
                  : null;
              }}
              className="donut-chart-container"
            >
              {this.state.donutChart.width === null ||
              this.state.donutChart.height === null ? null : (
                <DonutChart
                  data={
                    this.props.useFilterData
                      ? this.props.filter_data
                      : this.props.showNull
                      ? this.props.data
                      : this.props.data.filter(d => d.category !== '')
                  }
                  width={
                    this.state.donutChart.height > this.state.donutChart.width
                      ? this.state.donutChart.width
                      : this.state.donutChart.height
                  }
                  height={
                    this.state.donutChart.height > this.state.donutChart.width
                      ? this.state.donutChart.width
                      : this.state.donutChart.height
                  }
                  minLeafValue={this.props.filter_value}
                  showNull={this.props.showNull}
                  topNode={this.props.topNode}
                  currentcategory={this.state.category}
                  level={this.state.level}
                />
              )}
            </div>
          </div>

          <div className="treemap-cont">
            <Treemap
              fullData={this.props.data}
              data={
                this.props.useFilterData
                  ? this.props.filter_data
                  : this.props.showNull
                  ? this.props.data
                  : this.props.data.filter(d => d.category !== '')
              }
              category={this.props.category}
              devices={this.props.devices}
              getData={this.props.getData}
              filterValue={this.props.filter_value}
              id={this.props.id}
              maxLeefValue={this.props.maxLeefValue}
              showNull={this.props.showNull}
              minLeafValue={this.props.filter_value}
              setFilterData={this.props.setFilterData}
              filterDataOnOff={this.props.filterDataOnOff}
              showDeeper={this.state.showDeeper}
              setTopNodeTrue={this.props.setTopNodeTrue}
              setTopNodeFalse={this.props.setTopNodeFalse}
              setCategory={this.setCategory}
              topNode={this.props.topNode}
              level={this.state.level}
              setLevel={this.setLevel}
              setAmount={this.setAmountAndTitle}
              setDisplayValues={this.setDisplayValues}
              resetDisplayValues={this.resetDisplayValues}
              viewTop={this.state.treemap.view.top}
              keys={this.state.treemap.keys}
              setKeys={this.setTreemapKeys}
              setViewTop={this.setViewTop}
            />
            <div style={{ display: 'inline-block', float: 'left' }}>
              <Button
                className={'top-node-button'}
                click={this.treeMapReset}
                text={'Reset Treemap'}
              />
              <div
                onClick={this.handleNodeBackClick}
                style={this.state.level === 0 ? { display: 'none' } : {}}
                className="back-node-arrow"
              >
                &#8592; Go Back a Node Level
              </div>
            </div>
            <div style={{ display: 'inline-block', float: 'right' }}>
              Click on Treemap to Show deeper Nodes
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TreemapMain;
