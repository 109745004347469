import * as d3 from 'd3';

//this is a base class for charts this will set up
//that work in xy fashion they can be used with time series

class BaseXYChart {
  constructor(options) {
    this.el = options.el;
    this.data = options.data;
    this.width = options.width;
    this.height = options.height;
    this.time =
      options.time === undefined || options.time === false ? false : true;
    this.xValue = options.xValue;
    this.yValue = options.yValue;
    this.yAxisL =
      options.yAxisL === undefined || options.yAxisL === true ? true : false;
    this.xAxisB =
      options.xAxisB === undefined || options.xAxisB === true ? true : false;

    this.title =
      options.title === undefined || options.title === false
        ? false
        : options.title;

    this.formatXLabel =
      options.formatXLabel === undefined || options.formatXLabel === null
        ? null
        : options.formatXLabel;
    this.formatYLabel =
      options.formatYLabel === undefined || options.formatYLabel === null
        ? null
        : options.formatYLabel;

    this.titleColor =
      options.titleColor === undefined ? 'white' : options.titleColor;

    //you can set the Y maximum value

    this.yMax = options.yMax === undefined ? 'undefined' : options.yMax;
    this.yTicks = options.yTicks === undefined ? null : options.yTicks;

    this.yAxisTransformExtra =
      options.yAxisTransformExtra === undefined ||
      options.yAxisTransformExtra === null
        ? 0
        : options.yAxisTransformExtra;

    this.titleDescriptionOn =
      options.titleDescriptionOn !== true ? false : options.titleDescriptionOn;

    this.titleDescription =
      options.titleDescription === undefined ||
      options.titleDescription === null
        ? ''
        : options.titleDescription;

    this.xTimeRange = options.xTimeRange;
  }

  drawBaseChart() {
    this.margin = { l: 40, t: 20, r: 40, b: 20 };
    this.chartWidth = this.width - (this.margin.l + this.margin.r);
    this.chartHeight = this.height - (this.margin.t + this.margin.b);

    this.svg = d3
      .select(this.el)
      .append('svg')
      .attr('class', 'svg-line-bubble')
      .attr('height', this.chartHeight + (this.margin.t + this.margin.b))
      .attr('width', this.chartWidth + (this.margin.l + this.margin.r));

    this.g = this.svg
      .append('g')
      .attr(
        'transform',
        'translate(' + this.margin.l + ',' + this.margin.t + ')',
      );

    this.setScales();
    this.setAxis();

    if (this.title !== false) {
      this.setTitle();
    }
  }

  setScales() {
    this.setYScale();
    if (this.time === true) {
      this.setXTimeScale();
      this.setYScale();
    } else {
      this.setXScale();
      this.setYScale();
    }
  }

  setAxis = () => {
    this.setYAxis();
    this.setXAxis();
  };

  setYAxis = () => {
    if (this.yAxisL) {
      this.yAxis = d3
        .axisLeft(this.yScale)
        .ticks(this.yTicks)
        .tickFormat(this.formatYLabel);
      this.g
        .append('g')
        .attr('class', 'y-axis-group')
        .attr(
          'transform',
          'translate(' + -this.yAxisTransformExtra + ',' + 0 + ')',
        )
        .call(this.yAxis);
    } else {
      this.yAxis = d3.axisRight(this.yScale).ticks(this.yTicks);
      this.g
        .append('g')
        .attr('class', 'y-axis-group')
        .attr('transform', 'translate(' + this.chartWidth + ',' + 0 + ')')
        .call(this.yAxis);
    }
  };

  setXAxis = () => {
    if (this.xAxisB) {
      this.xAxis = d3.axisBottom(this.xScale).tickFormat(this.formatXLabel);
      this.g
        .append('g')
        .attr('class', 'x-axis-group')
        .attr('transform', 'translate(' + 0 + ',' + this.chartHeight + ')')
        .call(this.xAxis);
    } else {
      this.xAxis = d3.axisTop(this.xScale);
      this.g
        .append('g')
        .attr('class', 'x-axis-group')
        .attr('transform', 'translate(' + 0 + ',' + 0 + ')')
        .call(this.xAxis);
    }
  };

  setXScale = () => {
    this.xScale = d3
      .scaleLinear()
      .domain([0, d3.max(this.data, d => d[this.xValue])])
      .range([0, this.chartWidth])
      .nice();
  };

  setYScale = () => {
    this.yMax =
      this.yMax === 'undefined'
        ? d3.max(this.data, d => d[this.yValue])
        : this.yMax;

    this.yScale = d3
      .scaleLinear()
      .domain([0, this.yMax])
      .range([this.chartHeight, 0])
      .nice();
  };

  setXTimeScale = () => {
    this.xScale = d3
      .scaleTime()
      .domain([new Date(this.xTimeRange[0]), new Date(this.xTimeRange[1])])
      .range([0, this.chartWidth])
      .nice();
  };

  setTitle() {
    this.g
      .append('text')
      .attr('x', 15)
      .attr('y', -5)
      .attr('stroke', d3.rgb(this.titleColor).darker(0))
      //.attr('fill', this.titleColor)
      .text(this.title.toUpperCase());

    if (this.titleDescriptionOn) {
      d3.select(this.el)
        .insert('div', '.svg-line-bubble')
        .attr('class', 'info-chart-div');

      d3.select(this.el)
        .select('.svg-line-bubble')
        .style('margin-top', '-20px');

      var self = this;
      d3.select(this.el)
        .select('.info-chart-div')
        .on('mouseover', function(d, i) {
          const x = d3.event.pageX - 20;
          const y = d3.event.pageY + 20;

          d3.select('body')
            .append('div')
            .attr('class', 'bubble-tool-div')
            .style('position', 'absolute')
            .style('left', x + 'px')
            .style('top', y + 'px')
            .html(
              `<div class='tooltip-info'>
                  ${self.titleDescription}
                </div>
                `,
            );
        });

      d3.select(this.el)
        .select('.info-chart-div')
        .on('mouseout', function() {
          d3.select('.bubble-tool-div').remove();
        });
    }
  }

  mainBaseChart = () => {
    this.drawBaseChart();
  };
}

export default BaseXYChart;
