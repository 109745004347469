import React, { Component } from 'react';
import * as d3 from 'd3';

class StarRatings extends Component {
  constructor(props) {
    super(props);
    this.numberOfStars = d3.range(0, this.props.max, 1);

    this.symbolGenerator = d3
      .symbol()
      .size(100)
      .type(d3.symbolStar);

    this.width = 18 * this.props.max;
  }

  createScales() {
    this.clipWidthScale = d3
      .scaleLinear()
      .domain([0, 5])
      .range([0, this.width]);
  }

  render() {
    this.createScales();
    const starsClipped = this.numberOfStars.map((start, i) => {
      return (
        <g transform={`translate(${i * 18},0)`}>
          <path
            d={this.symbolGenerator()}
            fill="#FFD700"
            strokeWidth=".5px"
            stroke="lightgrey"
          />
        </g>
      );
    });

    const starsUnClipped = this.numberOfStars.map((start, i) => {
      return (
        <g transform={`translate(${i * 18},0)`}>
          <path
            d={this.symbolGenerator()}
            fill="none"
            strokeWidth=".5px"
            stroke="lightgrey"
          />
        </g>
      );
    });

    return (
      <g>
        <clipPath id="starClip">
          <rect
            x="-8.5"
            y="-10"
            height="25"
            width={this.clipWidthScale(this.props.currentRating)}
          />
        </clipPath>
        <g>{starsUnClipped}</g>
        <g clipPath="url(#starClip)">{starsClipped}</g>
      </g>
    );
  }
}

export default StarRatings;
