import * as d3 from 'd3';
import { correctDateOffset } from './formatters';

class BaseXYChart {
  constructor(options) {
    this.margin = { r: 20, t: 20, l: 80, b: 20 };
    this.data = options.data;
    this.el = options.el;
    this.height = options.height - (this.margin.t + this.margin.b);
    this.width = options.width - (this.margin.l + this.margin.r);
    this.xAxisTicks = options.xAxisTicks;
    this.colorScale = options.colorScale;
    this.formatXAxis = options.formatXAxis;
    this.formatYAxis = options.formatYAxis;
  }
  makeMainSVG() {
    this.mainSVG = d3
      .select(this.el)
      .append('svg')
      .attr('height', this.height + (this.margin.t + this.margin.b))
      .attr('width', this.width + (this.margin.l + this.margin.r));

    this.mainG = this.mainSVG
      .append('g')
      .attr('transform', `translate(${this.margin.l}, ${this.margin.t})`);
  }

  makeScales() {
    const yMax = d3.max(this.data, d => d3.max(d, d => d[1]));
    let yMin = d3.min(this.data, d => d3.min(d, d => d[1]));
    yMin = yMin < 0 ? yMin : 0;
    this.yScale = d3
      .scaleLinear()
      .domain([yMin, yMax])
      .range([this.height, 0])
      .nice();
    this.xScale = d3
      .scaleBand()
      .domain(this.xAxisTicks)
      .range([0, this.width])
      .paddingOuter(0.1)
      .paddingInner(0.1);
  }

  getTicks() {
    const length = this.xAxisTicks.length;
    if (length <= 5) return this.xAxisTicks;
    const start = this.xAxisTicks[0];
    const middle = this.xAxisTicks[Math.floor(length / 2)];
    const end = this.xAxisTicks[length - 1];
    return [start, middle, end];
  }

  makeAxis() {
    this.yAxis = d3.axisRight(this.yScale).tickFormat(this.formatYAxis);
    const ticks = this.getTicks();
    this.xAxis = d3
      .axisBottom(this.xScale)
      .tickFormat(d => this.formatXAxis(new Date(correctDateOffset(d))))
      .tickValues(ticks)
      .tickSizeOuter(0);

    this.mainG
      .append('g')
      .attr('transform', `translate(0,${this.height})`)
      .attr('class', 'KPI-series-xAxis')
      .call(this.xAxis);

    this.mainG
      .append('g')
      .attr('class', 'KPI-series-yAxis')
      .call(this.yAxis)
      .call(g => g.select('.domain').remove())
      .call(g =>
        g
          .selectAll('.tick line')
          .attr('stroke-opacity', 0.75)
          .attr('stroke-dasharray', '2,2')
          .attr('x2', this.width),
      )
      .call(g =>
        g
          .selectAll('.tick text')
          .attr('x', -60)
          .attr('dy', 3),
      )
      .attr('font-size', '14px')
      .attr('font-weight', '400')
      .attr('opacity', 0.75);
  }
}

export default BaseXYChart;
