import { MODULES_GET_OWN } from '../actions/modules_actions';

const INIT_STATE = JSON.parse(localStorage.getItem('modules')) || [];

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case MODULES_GET_OWN:
      localStorage.setItem('modules', JSON.stringify(action.payload));
      return action.payload;
    default:
      return state;
  }
}
