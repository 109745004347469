import React from 'react';

//this is a simple button it takes  click function  className and returns a button
const Button = props => (
  <button className={props.className} onClick={props.click}>
    {props.text}
  </button>
);

export default Button;
