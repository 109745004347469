import React, { Component } from 'react';
import { roundNumbers } from './helpers';
import * as d3 from 'd3';

class BulletChartContainer extends Component {
  render() {
    let height =
      this.props.dimensions.height / 4 > 75
        ? 75
        : this.props.dimensions.height / 4;

    const width = this.props.dimensions.width - 5;

    return (
      <div className={this.props.className}>
        <div id="bullet-chart-title">
          {this.props.title}{' '}
          <svg
            className="center-hover-arrow"
            transform="translate(0,8)"
            onMouseEnter={ev => {
              this.props.onMouseOver(ev);
            }}
            onMouseLeave={ev => {
              this.props.onMouseLeave(ev);
            }}
            xmlns="http://www.w3.org/2000/svg"
            stroke="white"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
          </svg>
        </div>
        <BulletChart
          title={'Amazon'}
          target={4.7}
          max={5}
          currentValue={parseFloat(roundNumbers(4.5, 2)).toFixed(2)}
          bulletFillColor={'#f08804'}
          height={height}
          width={width}
        />
        <BulletChart
          title={'iOS'}
          target={4.7}
          max={5}
          currentValue={parseFloat(
            roundNumbers(this.props.ratings['ios'], 2),
          ).toFixed(2)}
          bulletFillColor={'rgb(0,122,255)'}
          height={height}
          width={width}
        />
        <BulletChart
          title={'Android'}
          target={4.7}
          max={5}
          currentValue={parseFloat(
            roundNumbers(this.props.ratings['android'], 2),
          ).toFixed(2)}
          bulletFillColor={'#A4C639'}
          height={height}
          width={width}
        />
      </div>
    );
  }
}

class BulletChart extends Component {
  constructor(props) {
    super(props);

    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    this.draw();
  }

  draw() {
    this.margin = { l: 75, t: 5, r: 25, b: 16 };
    this.width = this.props.width - (this.margin.r + this.margin.l);
    this.height = this.props.height - (this.margin.t + this.margin.b);
    this.height = this.height <= 20 ? 21 : this.height;

    this.svg = d3
      .select(this._rootNode)
      .append('svg')
      .attr('height', this.height + (this.margin.t + this.margin.b))
      .attr('width', this.width + (this.margin.r + this.margin.l))
      .attr('opacity', '0.8');
    this.svg
      .append('rect')
      .attr('height', this.height + (this.margin.t + this.margin.b))
      .attr('width', this.width + (this.margin.r + this.margin.l))
      .attr('stroke', 'grey')
      .attr('fill-opacity', '0.0')
      .attr('rx', '10');

    this.g = this.svg
      .append('g')
      .attr(
        'transform',
        'translate(' + this.margin.l + ',' + this.margin.t + ')',
      );

    this.makeScales();
    this.makeAxis();
    this.makeBackgroundcolors();
    this.makelines();
    this.AddTitles();
  }

  makeScales() {
    this.xScale = d3
      .scaleLinear()
      .domain([0, this.props.max])
      .range([0, this.width]);
  }

  makeAxis() {
    this.xAxis = d3.axisBottom(this.xScale).ticks(5);
    this.g
      .append('g')
      .attr('class', 'x-axis-group')
      .attr('transform', 'translate(' + 0 + ',' + this.height + ')')
      .call(this.xAxis);
  }
  makeBackgroundcolors() {
    const backgroundColors = ['#E8E8E8', '#BEBEBE', '	#808080'];

    this.g
      .append('rect')
      .attr('height', this.height)
      .attr('width', this.xScale(this.props.max))
      .attr('fill', backgroundColors[0]);

    this.g
      .append('rect')
      .attr('height', this.height)
      .attr('width', this.xScale(this.props.target))
      .attr('fill', backgroundColors[1]);

    this.g
      .append('rect')
      .attr('height', this.height)
      .attr('width', this.xScale(4))
      .attr('fill', backgroundColors[2]);
  }

  makelines() {
    this.g
      .append('rect')
      .attr('x', 0)
      //.attr('width', this.height / 2)
      .attr('width', this.xScale(this.props.currentValue))
      .attr('y', this.height / 2 - 10)
      .attr('fill', this.props.bulletFillColor)
      .attr('height', '20px')
      .attr('stroke-width', '0.25px')
      .attr('stroke', 'white')
      .attr('rx', 10);
    //.attr('stroke-linecap','round');

    const heightTest = this.height - 5 <= 20 ? true : false;
    const x1 = heightTest ? 0 : 5;
    const x2 = heightTest ? this.height : this.height - 5;

    this.g
      .append('line')
      .attr('x1', this.xScale(this.props.target))
      .attr('y1', x1)
      .attr('x2', this.xScale(this.props.target))
      .attr('y2', x2)
      .attr('stroke', 'black')
      .attr('stroke-width', '3px');
  }

  AddTitles() {
    this.svg
      .append('text')
      .attr('y', this.height / 2 + 10)
      .attr('x', '5')
      .attr('fill', 'lightgrey')
      .text(this.props.title);
    this.svg
      .append('text')
      .attr('y', this.height / 2 + 25)
      .attr('x', '5')
      .attr('fill', 'lightgrey')
      .text(`(${this.props.currentValue})`);
  }

  render() {
    return (
      <div
        ref={el => {
          this._rootNode = el;
        }}
      />
    );
  }
}

export default BulletChartContainer;
