import LineChart from './lineChart';
import { numberWithCommas } from './helpers';
//import AreaChart from './AreaChart';
import * as d3 from 'd3';
import parseDate from '../../helpers/d3/utils/formatTimeHelpers';

class LineBubbleChart extends LineChart {
  constructor(options, lineOptions, lineBubbleOptions) {
    super(options, lineOptions);

    this.makeMouseEvents = this.makeMouseEvents.bind(this);

    this.innerColor =
      lineBubbleOptions.innerColor === undefined
        ? 'white'
        : lineBubbleOptions.innerColor;
    this.outerColor =
      lineBubbleOptions.outerColor === undefined
        ? 'red'
        : lineBubbleOptions.outerColor;
    this.rValue = lineBubbleOptions.rValue;
    this.radiusMax =
      lineBubbleOptions.radiusMax === undefined ||
      lineBubbleOptions.radiusMax === false
        ? false
        : lineBubbleOptions.radiusMax;

    this.legendOn =
      lineBubbleOptions.legendOn === undefined ||
      lineBubbleOptions.legendOn !== true
        ? false
        : true;

    this.changeArea =
      lineBubbleOptions.changeArea !== true ||
      lineBubbleOptions.changeArea === undefined
        ? false
        : true;

    this.formatToolTip =
      lineBubbleOptions.formatToolTip === undefined
        ? false
        : lineBubbleOptions.formatToolTip;

    this.scaleRadiusMax =
      lineBubbleOptions.scaleRadiusMax === undefined
        ? 35
        : lineBubbleOptions.scaleRadiusMax;

    this.outerValue =
      lineBubbleOptions.outerValue === null ||
      lineBubbleOptions.outerValue === undefined
        ? null
        : lineBubbleOptions.outerValue;
  }

  setRadiusScale() {
    const outerKey = this.outerValue + ' outer';
    const innerKey = this.rValue + ' inner';

    this.outerKey = outerKey;
    this.innerKey = innerKey;

    const changeAreaData = this.data.map((val, i) => {
      return {
        ...val,
        ...{
          [outerKey]: val[this.rValue],
          [innerKey]: val[this.rValue] - val[this.outerValue],
        },
      };
    });

    this.changeAreaData = changeAreaData;

    this.radiusScale = d3
      .scaleLinear()
      .domain([
        0,
        this.radiusMax !== false
          ? this.radiusMax
          : d3.max(changeAreaData, d =>
              this.outerValue === null ? d[this.rValue] : d[outerKey],
            ),
      ])
      .range([0, this.scaleRadiusMax]);
  }

  makeBubbleChart() {
    const bubbleCircles = this.g.selectAll('.top-circle').data(this.data);

    bubbleCircles
      .enter()
      .append('circle')
      .attr('class', 'top-circle')
      .merge(bubbleCircles)
      .attr('r', d =>
        d[this.rValue] === 0 ? 0 : this.radiusScale(d[this.rValue]),
      )
      .attr('cx', d =>
        this.time === true
          ? this.xScale(new Date(parseDate(d[this.xValue]).getTime()))
          : this.xScale(d[this.xValue]),
      )
      .attr('cy', d => this.yScale(d[this.yValue]))
      .attr('stroke', this.outerColor)
      .attr('stroke-width', '1px')
      .attr('fill', 'rgba(30, 30, 30, 0)');
  }

  makeMouseEvents() {
    var self = this;

    this.g.selectAll('.layered-circle').on('mouseover', function(d, i) {
      const x = d3.event.pageX - 50;
      const y = d3.event.pageY - 50;

      d3.select('body')
        .append('div')
        .attr('class', 'bubble-tool-div')
        .style('position', 'absolute')
        .style('left', x + 'px')
        .style('top', y + 'px')
        .html(
          `<div class='tooltip'>
                  <div>
                    Churn -  ${self.formatToolTip(d.value / 100)}
                  </div>
                <div class='tool-tip-attr'>
                  ${self.rValue} -  ${numberWithCommas(d[self.rValue])}
                </div>
                <div class='tool-tip-attr'>
                  ${self.outerValue} -  ${numberWithCommas(d[self.outerValue])}
                </div>
                <div class='tool-tip-attr'>
                  New Activations Ratio -  ${self.formatToolTip(
                    d[self.outerValue] / d[self.rValue],
                  )}
                </div>
                </div>
              </div>
              `,
        );
    });

    d3.selectAll('.layered-circle').on('mouseout', function(d, i) {
      d3.selectAll('.bubble-tool-div').remove();
    });

    this.g.selectAll('.top-circle').style('pointer-events', 'none');
  }

  makeNewActivationMouseOver = () => {
    var self = this;

    this.g.selectAll('.top-circle').style('pointer-events', 'auto');

    this.g.selectAll('.top-circle').on('mouseover', function(d, i) {
      const x = d3.event.pageX - 50;
      const y = d3.event.pageY - 50;

      d3.select('body')
        .append('div')
        .attr('class', 'bubble-tool-div')
        .style('position', 'absolute')
        .style('left', x + 'px')
        .style('top', y + 'px')
        .html(
          `<div class='tooltip'>
                  <div>
                    Churn -  ${self.formatToolTip(d.value / 100)}
                  </div>
                <div class='tool-tip-attr'>
                  ${self.rValue} -  ${numberWithCommas(d[self.rValue])}
                </div>
                </div>
              </div>
              `,
        );
    });

    d3.selectAll('.top-circle').on('mouseout', function(d, i) {
      d3.selectAll('.bubble-tool-div').remove();
    });
  };
  updateBubbleChart() {
    const layered_circle = this.g
      .selectAll('.layered-circle')
      .data(this.changeAreaData);

    layered_circle
      .enter()
      .append('circle')
      .merge(layered_circle)
      .transition()
      .attr('r', d => this.radiusScale(d[this.outerKey]))
      .attr('fill', 'rgb(192, 193, 209,.8)');

    const top_circle = this.g
      .selectAll('.top-circle')
      .data(this.changeAreaData);

    top_circle
      .enter()
      .append('circle')
      .merge(top_circle)
      .transition()
      .attr('r', d => this.radiusScale(d[this.innerKey]))
      .attr('fill', 'rgba(30, 30, 30, 0)');
  }

  updateChangeArea = () => {};

  makeLegend = () => {
    this.g
      .append('g')
      .attr('class', 'circle-legend')
      .attr('transform', 'translate(20, 40)');
  };

  makeChangeArea = () => {
    const outerKey = this.outerKey;
    const changeAreaData = this.changeAreaData;

    this.g.selectAll('.top-circle').remove();

    const bottom_circle = this.g
      .selectAll('.layered-circle')
      .data(changeAreaData);

    bottom_circle
      .enter()
      .append('circle')
      .attr('class', 'layered-circle')
      .attr('r', d => {
        if (d[outerKey] === 0) {
          return 0;
        }
        return this.radiusScale(d[outerKey]);
      })
      .attr('cx', d =>
        this.time === true
          ? this.xScale(new Date(parseDate(d[this.xValue]).getTime()))
          : this.xScale(d[this.xValue]),
      )
      .attr('cy', d => this.yScale(d[this.yValue]))
      .attr('stroke', this.outerColor)
      .attr('stroke-width', '1px')
      .attr('fill', 'rgba(30, 30, 30, 0)');

    const top_circle = this.g.selectAll('.top-circle').data(changeAreaData);

    top_circle
      .enter()
      .append('circle')
      .attr('class', 'top-circle')
      .attr('r', d => {
        if (d[this.innerKey] === 0) {
          return 0;
        }
        return this.radiusScale(d[this.innerKey]);
      })
      .attr('cx', d =>
        this.time === true
          ? this.xScale(new Date(parseDate(d[this.xValue]).getTime()))
          : this.xScale(d[this.xValue]),
      )
      .attr('cy', d => this.yScale(d[this.yValue]))
      .attr('stroke', this.outerColor)
      .attr('stroke-width', '1px')
      .attr('fill', 'rgba(30, 30, 30, 0)');
  };

  updateNull = () => {
    this.g.selectAll('.layered-circle').attr('r', 0);

    const top_circle = this.g
      .selectAll('.top-circle')
      .data(this.changeAreaData);

    top_circle
      .enter()
      .append('circle')
      .merge(top_circle)
      .transition()
      .attr('r', d => {
        return this.radiusScale(d[this.rValue]);
      })
      .attr('fill', 'rgba(30, 30, 30, 0)');
  };

  updateRadius(updateOptions) {
    this.radiusMax =
      updateOptions.radiusMax === undefined
        ? this.radiusMax
        : updateOptions.radiusMax === false
        ? false
        : updateOptions.radiusMax;
    this.rValue = updateOptions.rValue;
    this.outerValue = updateOptions.outerValue;
    this.setRadiusScale();

    if (this.outerValue === null) {
      this.updateNull();
      this.makeNewActivationMouseOver();
    } else {
      this.updateBubbleChart();
      this.makeMouseEvents();
    }
    // this.makeMouseEvents();
    // if (this.legendOn) {
    //   this.makeLegend();
    // }
  }

  makeLayeredCircles() {
    const bottom_circle = this.g.selectAll('.layered-circle').data(this.data);

    bottom_circle
      .enter()
      .append('circle')
      .attr('class', 'layered-circle')
      .attr('r', d => {
        return 0;
      })
      .attr('cx', d =>
        this.time === true
          ? this.xScale(new Date(parseDate(d[this.xValue]).getTime()))
          : this.xScale(d[this.xValue]),
      )
      .attr('cy', d => this.yScale(d[this.yValue]))
      .attr('stroke', this.outerColor)
      .attr('stroke-width', '1px')
      .attr('fill', 'rgba(30, 30, 30, 0)');

    const layeredCircles = this.g.selectAll('.ltop-circle').data(this.data);

    layeredCircles
      .enter()
      .append('circle')
      .attr('class', 'top-circle')
      .merge(layeredCircles)
      .attr('r', d =>
        d[this.rValue] === 0 ? 0 : this.radiusScale(d[this.rValue]),
      )
      .attr('cx', d =>
        this.time === true
          ? this.xScale(new Date(parseDate(d[this.xValue]).getTime()))
          : this.xScale(d[this.xValue]),
      )
      .attr('cy', d => this.yScale(d[this.yValue]))
      .attr('stroke', this.outerColor)
      .attr('stroke-width', '1px')
      .attr('fill', 'rgba(30, 30, 30, 0)');
  }

  makeMainBubble() {
    this.setRadiusScale();

    if (this.changeArea && this.outerValue !== null) {
      this.makeChangeArea();
      this.makeMouseEvents();
    }

    if (this.outerValue === null) {
      this.makeLayeredCircles();
      this.makeNewActivationMouseOver();
    }

    if (this.legendOn) {
      this.makeLegend();
    }
  }
}
export default LineBubbleChart;
