import React, { Component } from 'react';
import RangeArea from './RangeArea';
import Timeslider from './Timeslider';
import './menu_controller_styles.scss';
import * as d3 from 'd3';
import { dateFormatters_Formal } from '../helpers/menuFormatters';
import parseDate from '../../components/helpers/d3/utils/formatTimeHelpers';

class MenuController extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleLeftArrowClick = this.handleLeftArrowClick.bind(this);
    this.menushow = false;

    this.state = {
      circleHover: false,
      showDateRange: false,
      rangeArea: {
        show: false,
      },
    };
  }

  handleMenuArrowHover(ev) {
    this.props.menuOnOff();
  }

  handleLeftArrowClick(ev) {
    const newSelected =
      this.props.dates[this.props.grain].selected === 0
        ? 0
        : this.props.dates[this.props.grain].selected - 1;
    this.props.changeSelected(newSelected);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleRightArrowClick(ev) {
    const newSelected =
      this.props.dates[this.props.grain].selected ===
      this.props.dates[this.props.grain].series.length - 1
        ? this.props.dates[this.props.grain].series.length - 1
        : this.props.dates[this.props.grain].selected + 1;
    this.props.changeSelected(newSelected);
  }

  handleLeftTripleArrowClick() {
    if (this.props.grain !== 'daily' && this.props.grain !== 'month') {
      return null;
    }

    if (this.props.grain === 'month') {
      let newSelected =
        this.props.dates[this.props.grain].selected -
        Math.ceil(this.props.dates[this.props.grain].series.length / 2);
      newSelected = newSelected < 0 ? 0 : newSelected;
      this.props.changeSelected(newSelected);
    }

    if (this.props.grain === 'daily') {
      let newSelected = this.props.dates[this.props.grain].selected - 60;
      newSelected = newSelected < 0 ? 0 : newSelected;
      this.props.changeSelected(newSelected);
    }
  }

  handleRightTripleArrowClick() {
    if (this.props.grain !== 'daily' && this.props.grain !== 'month') {
      return null;
    }

    if (this.props.grain === 'month') {
      let newSelected =
        this.props.dates[this.props.grain].selected +
        Math.ceil(this.props.dates[this.props.grain].series.length / 2);

      newSelected =
        newSelected > this.props.dates[this.props.grain].series.length - 1
          ? this.props.dates[this.props.grain].series.length - 1
          : newSelected;
      this.props.changeSelected(newSelected);
    }

    if (this.props.grain === 'daily') {
      let newSelected = this.props.dates[this.props.grain].selected + 60;
      newSelected =
        newSelected > this.props.dates[this.props.grain].series.length - 1
          ? this.props.dates[this.props.grain].series.length - 1
          : newSelected;
      this.props.changeSelected(newSelected);
    }
  }

  componentDidUpdate() {
    if (this.props.headerMenu_visible) {
      document.addEventListener('mousedown', this.handleClickOutside);
    } else {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.menuOnOff();
    }
  }

  handleDateClick = e => {
    this.props.menuOff();
    this.setState(state => ({
      rangeArea: {
        show: !state.rangeArea.show,
      },
    }));
  };

  showRange = (ev, uniqueName) => {
    this.setState(state => ({
      rangeArea: {
        show: !state.rangeArea.show,
      },
    }));
  };

  showCurrentRange = ev => {
    this.setState(state => ({
      rangeArea: {
        show: !state.rangeArea.show,
      },
    }));
  };

  dotIn = () => {
    this.setState({ circleHover: true });
  };

  dotOut = () => {
    this.setState({ circleHover: false });
  };
  render() {
    const {
      dates,
      choroplethDataCreation,
      showGrainMenu,
      grain,
      changeGrain,
      availableGrains,
      currentDate,
      isChoropleth,
      headerMenu_visible,
      changeSelected,
      isBubbleChart,
      bubbleChartDate,
      isTreemap,
      treemapChartDate,
    } = this.props;
    if (dates === null) {
      return null;
    }

    const showText = isChoropleth || isBubbleChart || isTreemap;

    let choroPlethText = (
      <div style={{ textAlign: 'center' }}>
        {' '}
        {isBubbleChart
          ? `The data is cumulative and was created on ${d3.timeFormat(
              '%a %b %d %Y',
            )(bubbleChartDate)}`
          : `The data is cumulative and the data was created on ${choroplethDataCreation}`}
      </div>
    );

    choroPlethText = isTreemap ? (
      <div style={{ textAlign: 'center' }}>
        {' '}
        {treemapChartDate !== null
          ? `The data is cumulative and was created on ${d3.timeFormat(
              '%A %B %d %Y',
            )(
              parseDate(treemapChartDate),
            )}, and is accurate up to the time that it was created.`
          : null}
      </div>
    ) : (
      choroPlethText
    );

    const showTimeSlider = showGrainMenu || grain === 'daily';
    //this is for the dot and range finder that sits on our menu selector

    const dateAndDot = (
      <div className="date-dot-container" style={{ display: 'inline-block' }}>
        <span
          onMouseEnter={this.dotIn}
          onMouseOut={this.dotOut}
          onClick={this.handleDateClick}
          className="dot-date-picker"
        />{' '}
        {
          <RangeArea
            changeGrain={changeGrain}
            dates={dates}
            currentGrain={grain}
            availableGrains={availableGrains}
            isDash={this.props.isDashboard}
            uniqueId={'menu-range-area'}
            showRange={this.showRange}
            showRangeArea={this.state.rangeArea.show}
            showCurrentRange={this.showCurrentRange}
            selected={!this.props.headerMenu_visible}
          />
        }
      </div>
    );
    const rangeArea = showGrainMenu ? (
      <RangeArea
        uniqueName={'range-area-slider'}
        changeGrain={changeGrain}
        dates={dates}
        currentGrain={grain}
        availableGrains={availableGrains}
        isDash={this.props.isDashboard}
        showRange={this.showRange}
        showRangeArea={this.state.rangeArea.show}
        showCurrentRange={this.showCurrentRange}
        selected={this.props.headerMenu_visible}
      />
    ) : null;
    return (
      <div ref={this.setWrapperRef} className="menuFullContainer">
        <div className="top-container">
          {/*menushow= {this.menushow ? 'SHOW' : "HIDE"}*/}

          <div
            style={{ color: 'white', display: 'inline-block' }}
            onClick={ev => {
              this.handleLeftArrowClick(ev);
              ev.stopPropagation();
            }}
            className={'left-arrow-controller menu-arrow display-left-arrow'}
          >
            {'<<'}
          </div>

          <div
            onClick={ev => {
              return this.state.circleHover || this.state.rangeArea.show
                ? null
                : this.handleMenuArrowHover(ev);
            }}
            className="caretMenu"
          >
            <div className="menuGrain">
              {isBubbleChart || isTreemap ? 'All Time' : dateAndDot}
            </div>
            <div className="menuCaret" />
            <div className="menuRange">
              {isBubbleChart && bubbleChartDate !== null
                ? d3.timeFormat('%b %d, %Y')(bubbleChartDate)
                : isTreemap && treemapChartDate !== null
                ? d3.timeFormat('%b %d, %Y')(
                    new Date(parseDate(treemapChartDate)),
                  )
                : dateFormatters_Formal[grain](currentDate)}
            </div>
          </div>
          <div
            style={{ color: 'white', display: 'inline-block' }}
            onClick={ev => {
              this.handleRightArrowClick(ev);
              ev.stopPropagation();
            }}
            className={'right-arrow-controller menu-arrow display-right-arrow'}
          >
            {'>>'}
          </div>
        </div>
        {showText ? (
          <div
            className={
              headerMenu_visible ? 'headerMenu open' : 'headerMenu hidden'
            }
          >
            {choroPlethText}
          </div>
        ) : (
          <div
            style={isChoropleth ? { display: 'none' } : null}
            className={
              headerMenu_visible ? 'headerMenu open' : 'headerMenu hidden'
            }
          >
            {rangeArea}
            <div
              onClick={el => {
                this.handleLeftTripleArrowClick();
              }}
              className={
                showTimeSlider
                  ? grain === 'daily' || grain === 'month'
                    ? 'triple-left-arrow-controller menu-arrow'
                    : 'triple-left-arrow-controller menu-arrow arrow-hidden'
                  : grain === 'daily' || grain === 'month'
                  ? 'triple-left-arrow-controller menu-arrow disabled'
                  : 'triple-left-arrow-controller menu-arrow arrow-hidden disabled'
              }
            >
              {' '}
              {'<<<'}
            </div>
            <div
              onClick={ev => {
                this.handleLeftArrowClick(ev);
                ev.stopPropagation();
              }}
              className={
                showTimeSlider
                  ? 'left-arrow-controller menu-arrow'
                  : 'left-arrow-controller menu-arrow disabled'
              }
            >
              {'<<'}
            </div>

            <div id="rangeSlider">
              <Timeslider
                grain={grain}
                currentDate={currentDate}
                dateRanges={dates}
                dates={dates[grain]}
                changeSelected={changeSelected}
                className={
                  showTimeSlider
                    ? 'menu-time-slider'
                    : 'menu-time-slider disabled'
                }
              />
            </div>

            <div
              onClick={ev => {
                this.handleRightArrowClick(ev);
              }}
              className={
                showTimeSlider
                  ? 'right-arrow-controller menu-arrow'
                  : 'right-arrow-controller menu-arrow disabled'
              }
            >
              {'>>'}
            </div>
            <div
              onClick={ev => {
                this.handleRightTripleArrowClick(ev);
              }}
              className={
                showTimeSlider
                  ? this.props.grain === 'daily' || this.props.grain === 'month'
                    ? 'triple-right-arrow-controller menu-arrow'
                    : 'triple-right-arrow-controller menu-arrow arrow-hidden'
                  : this.props.grain === 'daily' || this.props.grain === 'month'
                  ? 'triple-right-arrow-controller menu-arrow disabled'
                  : 'triple-right-arrow-controller menu-arrow arrow-hidden disabled'
              }
            >
              {' '}
              {'>>>'}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MenuController;
