import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import authReducer from './auth_reducers';
import meReducer from './me_reducers';
import modulesReducer from './modules_reducers';
import filtersReducer from './filtersReducer';
import clientsReducer from './client_reducers';

import peopleReducer from './people_reducer';
import ratingsReducer from './ratings_reducer';
import headerMenuReducer from './headerMenu_reducer';
import choroplethReducer from './choropleth_reducer';
import filterChartReducer from './filter_chart_reducer';
import timeSeriesReducer from './timeSeries_reducer';
import bubbleChartReducer from './bubble_reducer';
import treemapReducer from './treemap_reducer';

import dashboardReducer from './dashboard_reducer';

import churnReducer from './churn_reducer';

const appReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  me: meReducer,
  modules: modulesReducer,
  filters: filtersReducer,
  people: peopleReducer,
  clients: clientsReducer,
  ratings: ratingsReducer,
  headerMenu: headerMenuReducer,
  choropleth: choroplethReducer,
  filter_chart: filterChartReducer,
  timeSeries: timeSeriesReducer,
  bubble: bubbleChartReducer,
  dashboard: dashboardReducer,
  churn: churnReducer,
  treemap: treemapReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
