import * as d3 from 'd3';
import React, { Component } from 'react';
import { legendColor } from 'd3-svg-legend';

class RadialLegend extends Component {
  constructor(props) {
    super(props);
    this.mouseoverEvents = this.mouseoverEvents.bind(this);
  }

  componentDidMount() {
    const ordinal = d3
      .scaleOrdinal()
      .domain([
        `Amazon (${this.props.deviceCount.amazon})`,
        `iOS (${this.props.deviceCount['IOs']})`,
        `Android (${this.props.deviceCount['android']})`,
      ])
      .range(['#f08804', 'rgb(0,122,255)', '#A4C639']);

    const lineOrdinal = d3
      .scaleOrdinal()
      .domain(['Amazon', 'iOS', 'Android'])
      .range(['#f08804', 'rgb(0,122,255)', '#A4C639']);

    const svg = d3
      .select(this._rootNode)
      .append('svg')
      .attr('height', this.props.dimensions.height)
      .attr('width', this.props.dimensions.width);

    const reviewTitleG = svg
      .append('g')
      .attr('class', 'legend-title-reviews')
      .attr('transform', 'translate(' + 15 + ',' + 29 + ')');

    reviewTitleG
      .append('text')
      .attr('fill', 'white')
      .attr('dy', 0)
      .text('Reviews');

    reviewTitleG
      .append('line')
      .attr('x1', 0)
      .attr('y1', 1.5)
      .attr('x2', 115)
      .attr('y2', 1.5)
      .style('stroke', 'white');

    svg
      .append('g')
      .attr('class', 'radial-ordinal-g')
      .attr('transform', 'translate(' + 20 + ',' + 40 + ')');

    const legendOrdinal = legendColor()
      .shape('circle')
      .shapeRadius(4)
      .scale(ordinal);

    const translateX = window.innerHeight > 830 ? 20 : 175;
    const translateY = window.innerHeight > 830 ? 140 : 40;

    svg
      .append('g')
      .attr('class', 'radial-ordinal-lines')
      .attr('transform', 'translate(' + translateX + ',' + translateY + ')');

    const legendLineOrdinal = legendColor()
      .shape('rect')
      .shapeHeight(4)
      .scale(lineOrdinal);

    const translateTitleX = window.innerHeight > 830 ? 15 : 170;
    const translateTitleY = window.innerHeight > 830 ? 129 : 29;

    const ratingsTitleG = svg
      .append('g')
      .attr('class', 'legend-title-reviews')
      .attr(
        'transform',
        'translate(' + translateTitleX + ',' + translateTitleY + ')',
      );

    ratingsTitleG
      .append('text')
      .attr('fill', 'white')
      .attr('dy', 0)
      .text('Ratings avg.');

    ratingsTitleG
      .append('line')
      .attr('x1', 0)
      .attr('y1', 1.5)
      .attr('x2', 115)
      .attr('y2', 1.5)
      .style('stroke', 'white');

    svg.select('.radial-ordinal-g').call(legendOrdinal);

    svg.select('.radial-ordinal-lines').call(legendLineOrdinal);

    this.backGroundButtons();
    this.mouseoverEvents();
  }

  backGroundButtons() {
    d3.select('.radial-ordinal-g')
      .selectAll('g')
      .each(addBackGroundColor);

    d3.selectAll('.radial-ordinal-lines')
      .selectAll('g')
      .each(addBackGroundColor);

    function addBackGroundColor(d, i) {
      var corporateColors = [[], '#f08804', 'rgb(0,122,255)', '#A4C639'];
      if (i > 0) {
        const background = d3.select(this).insert('rect', ':first-child');

        d3.select(this)
          .selectAll('circle')
          .attr('stroke', 'white');

        d3.selectAll('rect.swatch').attr('stroke', 'white');

        background
          .attr('width', '120')
          .attr('height', '16')
          .attr('class', 'legend-rect-shadow')
          .attr('fill', (d, j) => {
            return corporateColors[i];
          })
          .attr('x', '-10')
          .attr('y', '-8')
          .attr('rx', '8')
          .attr('ry', '8');
      }
    }
  }

  mouseoverEvents() {
    var self = this;

    d3.select('.radial-ordinal-g')
      .selectAll('.cell')
      .on('mouseover', handleDotMoouseOver)
      .on('mouseout', handleDotMoouseOut);

    d3.select('.radial-ordinal-lines')
      .selectAll('.cell')
      .on('mouseover', handleDotMoouseOver)
      .on('mouseout', handleDotMoouseOut);

    function handleDotMoouseOver(d, i) {
      const device = d.split(' ')[0];
      self.props.deviceFilter(device);
    }

    function handleDotMoouseOut() {
      self.props.deviceFilter('All Platforms');
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <div ref={el => (this._rootNode = el)} />
      </div>
    );
  }
}

export default RadialLegend;
