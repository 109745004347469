import React, { Component } from 'react';
import * as d3 from 'd3';
//import scaleRadial from '../../../helpers/scaleRadial';
import './ratingsRadial.scss';
import SelectComponent from '../general_components/SelectComponent.jsx';
import RadialLegend from './RatingsLegend';
import RadialToolTip from './RadialToolTip';
import RatingsMatchLegend from './RatingsMatchLegend';
import BulletChartContainer from './BulletChartContainer';
import StarRatings from './StarRatings';
import WordCloud from '../WordCloud/WordCloud';
import PopUp from './PopUp';
import { makeWordArray } from '../WordCloud/helpers';
import { formatDate } from '../../../../containers/RadialCont/calculateTime';
import { filterDataByDateLine } from '../../../../containers/RadialCont/filterTime';
import { centerRatingsText, currentRatingsTargetText } from './text';

//import { labelMap } from '../../../helpers/ratings_helpers';

class RatingsRadial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      radialLegend: {
        dimensions: {
          height: -1,
          width: -1,
        },
      },
      wordCloud: {
        dimensions: {
          height: -1,
          width: -1,
        },
      },
      bulletChart: {
        dimensions: {
          height: -1,
          width: -1,
        },
      },
      selectWidth: 0,
      tooltip: {
        on: false,
        raiting: null,
        date: null,
        content: null,
        placement: {
          x: null,
          y: null,
        },
      },
      popUp: {
        display: false,
        text: null,
        x: null,
        y: null,
      },
    };

    this.margin = { t: 100, r: 100, b: 100, l: 100 };

    this.width =
      this.props.width * (4.85 / 9) > this.props.height - this.margin.t - 50
        ? this.props.height - (this.margin.t + 50)
        : this.props.width * (4.85 / 9) - 50;

    this.height = this.width - 40;

    //this.width = 1000;
    this.outerRadius = this.width / 2;
    this.innerRadius = this.outerRadius - 10;

    this.innerCircleRadious = this.innerRadius * 0.25;
    this.createAxisData = this.createAxisData.bind(this);
    this.deviceChange = this.deviceChange.bind(this);
    this.innerRadiusOffset = -45;
    this.selectArray = ['android', 'iOS', 'amazon', 'All Platforms'];
    this.axisLabelFormat = d3.timeFormat('%b %Y');

    //geting correct intervals for monthLabels
    this.monthTimeData = d3.timeMonth.range(
      new Date(2019, 0, 1),
      new Date(2020, 0, 1),
    );

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];

    const monthData = months.map((val, i) => {
      return {
        title: val,
        index: i,
        value: 1,
        rating: Math.random() * 5,
        date: new Date(2019, i, 1),
      };
    });

    this.months = months;
    this.monthData = monthData;

    //this.arcData = this.arcDataGenerator(monthData);
    this.arcGenerator = this.arcGeneratorFunction();
    this.axisData = this.createAxisData();
    this.lineGenerator = this.createLineGenerator();

    //createarc data
    this.arcData = this.monthTimeData.map((time, i) => {
      if (i < 11) {
        return {
          startAngle: this.timeScale(time),
          endAngle: this.timeScale(this.monthTimeData[i + 1]),
        };
      }

      return {
        startAngle: this.timeScale(time),
        endAngle: Math.PI * 2,
      };
    });

    this.arcData[this.arcData.length - 1]['endAngle'] =
      this.arcData[0]['startAngle'] + Math.PI * 2;

    this.handleYearChange = this.handleYearChange.bind(this);
    this.handlePointMouseOver = this.handlePointMouseOver.bind(this);
    this.handlePointMouseLeave = this.handlePointMouseLeave.bind(this);
  }

  arcDataGenerator(data) {
    const value = d => d.value;

    var arcs = d3
      .pie()

      .value(value)(data);

    return arcs;
  }
  arcGeneratorFunction() {
    return d3
      .arc()
      .innerRadius(this.innerRadius)
      .outerRadius(this.outerRadius)
      .padAngle(0.005)
      .cornerRadius(12);
  }

  createAxisData() {
    //axis scale
    const axisScale = d3
      .scaleLinear()
      .domain([0, 5])
      .range([
        this.innerCircleRadious,
        this.innerRadius + this.innerRadiusOffset,
      ]);

    const ticks = axisScale.ticks(5);
    const axisData = ticks.map((tick, i) => {
      return { tickValue: tick, transforValue: axisScale(tick) };
    });

    return axisData;
  }

  createLineGenerator() {
    this.timeScale = d3
      .scaleTime()
      .domain([
        this.props.dateAxis[0],
        d3.timeYear.offset(this.props.dateAxis[0], 1),
      ])
      .range([0, Math.PI * 2]);

    this.radialScale = d3
      .scalePow()
      .exponent(1.2)
      .domain([0, 5])
      .range([
        this.innerCircleRadious,
        this.innerRadius + this.innerRadiusOffset / 3,
        this.innerRadius + this.innerRadiusOffset,
      ]);

    const lineGenerator = d3
      .lineRadial()
      .angle(d => this.timeScale(d.date))
      .radius(d => this.radialScale(d.rating))
      .curve(d3.curveCatmullRom.alpha(0.8));

    return lineGenerator;
  }

  componentDidMount = async () => {
    if (!this.props.dateRangesSet) {
      await this.props.getDashboardData();
    }
    this.props.getData();

    if (window.spinner) {
      var { spinner } = window;

      setTimeout(
        function() {
          spinner.finish();
        },
        100,
        spinner,
      );
    }
  };

  componentDidUpdate(prevProps, PrevState) {
    if (this._selectRef !== undefined && this.state.selectWidth === 0) {
      this.setState(() => ({
        selectWidth: this._selectRef.getBoundingClientRect().width,
      }));
    }

    //  {
    //   this.setState(()=>({
    //     selectWidth:this._selectRef,
    //   }));
    // }
  }

  handleYearChange(event) {
    const year = event.target.value;
    this.props.handleYearChange(year);
  }

  deviceChange(event) {
    const device = event.target.value;
    this.props.deviceFilter(device);
  }

  handlePointMouseOver(e, i) {
    const x = e.clientX;
    const y = e.clientY;

    const data = this.props.data[i];

    this.setState(() => ({
      tooltip: {
        on: true,
        raiting: data.rating,
        date: formatDate(data.date),
        content: data.content,
        placement: {
          x: x,
          y: y,
        },
      },
    }));

    this.props.highlightCircle(i);
  }
  handlePointMouseLeave(e) {
    this.setState(state => ({
      tooltip: { ...state.tooltip, ...{ on: false } },
    }));
    this.props.highlightCircleOff();
  }

  calcAverageRating(lineData, startDate, endDate) {
    const filteredData = filterDataByDateLine(startDate, endDate, lineData);

    let rating = 0;
    let count = 0;

    filteredData.forEach((line, i) => {
      line.forEach((data, j) => {
        rating += parseFloat(data.rating);
        count++;
      });
    });

    if (rating === 0) {
      return 'n/a';
    }
    return d3.format('.1f')(rating / count);
  }
  // shouldComponentUpdate(){
  //   return false;
  // }

  renderRadialLegend(el) {
    if (
      el !== undefined &&
      this.state.radialLegend.dimensions.width < 0 &&
      this.state.radialLegend.dimensions.height < 0
    ) {
      this.setState(() => ({
        radialLegend: {
          dimensions: {
            width: el.getBoundingClientRect().width,
            height: el.getBoundingClientRect().height,
          },
        },
      }));
    }
  }

  renderWordCloud(el) {
    if (
      el !== undefined &&
      this.state.wordCloud.dimensions.width < 0 &&
      this.state.wordCloud.dimensions.height < 0
    ) {
      this.setState(() => ({
        wordCloud: {
          dimensions: {
            width: el.getBoundingClientRect().width,
            height: el.getBoundingClientRect().height,
          },
        },
      }));
    }
  }

  renderBullCharts(el) {
    if (
      el !== undefined &&
      this.state.bulletChart.dimensions.width < 0 &&
      this.state.bulletChart.dimensions.height < 0
    ) {
      this.setState(() => ({
        bulletChart: {
          dimensions: {
            width: el.getBoundingClientRect().width,
            height: el.getBoundingClientRect().height,
          },
        },
      }));
    }
  }
  handleCenterRatingMouseOver(ev) {
    const xLocation = ev.pageX - 100;
    const yLocation = ev.pageY - 10;

    this.setState((state, props) => ({
      popUp: {
        ...state.popUp,
        ...{
          display: true,
          x: xLocation,
          y: yLocation,
          text: centerRatingsText,
        },
      },
    }));
  }

  handlePopUpgMouseLeave(ev) {
    this.setState((state, props) => ({
      popUp: { ...state.popUp, ...{ display: false } },
    }));
  }

  handelBulletTitleMouseOver(ev) {
    const xLocation = ev.pageX - 100;
    const yLocation = ev.pageY;

    this.setState((state, props) => ({
      popUp: {
        ...state.popUp,
        ...{
          display: true,
          x: xLocation,
          y: yLocation,
          text: currentRatingsTargetText,
        },
      },
    }));
  }

  render() {
    this.arcGenerator = this.arcGeneratorFunction();
    this.axisData = this.createAxisData();
    this.lineGenerator = this.createLineGenerator();

    if (this.props.data === null) {
      return null;
    }

    const startDatePlusTwoPi =
      this.timeScale(new Date(this.props.dateAxis[0])) + Math.PI * 2;
    const endDateDate = this.timeScale(
      new Date(this.props.dateAxis[this.props.dateAxis.length - 1]),
    );

    const rotationOffset =
      (((startDatePlusTwoPi + endDateDate) / 2 - endDateDate) / (Math.PI * 2)) *
      360;

    const popUp = this.state.popUp.display ? (
      <PopUp
        text={this.state.popUp.text}
        y={this.state.popUp.y}
        x={this.state.popUp.x}
      />
    ) : null;

    const arcs = this.arcData.map((arc, i) => {
      return (
        <path
          display={arc.startAngle > 5.5 && arc.startAngle < 6.0 ? 'none' : ''}
          d={this.arcGenerator(arc)}
          fill="#7a7676"
          //fill={`hsla(${this.props.colors.primaryHue},70%,50%,1)`}
          strokeWidth="0px"
          stroke="white"
          opacity="0.65"
        />
      );
    });

    const allTicks = this.monthTimeData.map((val, i) => {
      return (
        <g
          height="0"
          width="0"
          className="axis-lines-group"
          transform={` rotate(180) rotate(${(this.timeScale(val) /
            (Math.PI * 2)) *
            360})`}
        >
          <line
            y1={this.axisData[0].transforValue}
            x1="0"
            y2={this.axisData[this.axisData.length - 1].transforValue + 70}
            x2={0}
            fill="none"
            strokeWidth="1px"
            stroke="white"
            opacity="0.35"
          />
        </g>
      );
    });

    const axisCircles = this.radialScale.ticks(5).map((val, i) => {
      return (
        <g>
          <circle
            r={this.radialScale(val)}
            fill="none"
            strokeWidth="1px"
            stroke="white"
            opacity="0.25"
          />
        </g>
      );
    });

    const axisLabel = this.radialScale.ticks(5).map((val, i) => {
      return (
        <g
          transform={`rotate(${-rotationOffset}) translate(0,${-(
            this.radialScale(val) + 2
          )})`}
        >
          <text
            className="axis-label-text"
            fill="white"
            fontSize="16"
            opacity="0.90"
          >
            {val}
          </text>
        </g>
      );
    });

    const monthLabels = this.props.dateAxis.map((val, i) => {
      const angle = this.timeScale(val);
      const radius = this.axisData[this.axisData.length - 1].transforValue + 70;
      const x = radius * Math.sin(angle);
      const y = radius * Math.cos(angle) - 10;

      return (
        <g className="month-label-group">
          <text
            transform={`rotate(-15,${x},${-y})`}
            fill="white"
            y={-y - 15}
            x={x}
            textAnchor={angle > Math.PI ? 'end' : 'begin'}
          >
            {this.axisLabelFormat(val).split(' ')[0]}
          </text>
          <text
            transform={`rotate(-15,${x},${-y})`}
            fill="white"
            y={-y}
            x={x}
            textAnchor={angle > Math.PI ? 'end' : 'begin'}
          >
            {this.axisLabelFormat(val).split(' ')[1]}
          </text>
        </g>
      );
    });

    const dotRadius = window.innerWidth < 1400 ? 3 : 4;
    const points = this.props.data.map((d, i) => {
      const show =
        this.props.device === 'All Platforms' ||
        this.props.device === 'All Platforms'.toUpperCase()
          ? 'show-radial-point'
          : this.props.device.toUpperCase() === d.device.toUpperCase()
          ? 'show-radial-point'
          : 'hide-radial-point';

      const created = d.device === 'amazon' ? d.date : d.created;

      return (
        <g
          transform={`rotate(180) rotate(${(this.timeScale(new Date(created)) /
            (Math.PI * 2)) *
            360})`}
        >
          <circle
            onMouseOver={e => {
              this.handlePointMouseOver(e, i);
            }}
            onMouseLeave={e => this.handlePointMouseLeave(e)}
            cx={0}
            cy={this.radialScale(d.rating)}
            r={this.props.circleSelected === i ? '8' : dotRadius}
            className={d.device + '-class ' + show + ' radial-point'}
            stroke={'lightgrey'}
            strokeWidth="1.5"
          />
        </g>
      );
    });

    const lines = this.props.lineData.map((line, i) => {
      if (line[0] === undefined) {
        return null;
      }
      const show =
        this.props.device === 'All Platforms' ||
        this.props.device === 'All Platforms'.toUpperCase()
          ? 'show-radial-point'
          : this.props.device.toUpperCase() === line[0].device.toUpperCase()
          ? 'show-radial-point'
          : 'hide-radial-point';
      return (
        <path
          d={this.lineGenerator(line)}
          fill="none"
          strokeWidth="4px"
          className={line[0].device.toUpperCase() + '-class ' + show}
          opacity="1"
        />
      );
    });

    const lineHighlights = this.props.datesToHighlight.map((time, i) => {
      return (
        <g
          height="0"
          width="0"
          className="highlight-lines"
          transform={` rotate(180) rotate(${(this.timeScale(time) /
            (Math.PI * 2)) *
            360})`}
        >
          <line
            y1={this.axisData[0].transforValue}
            x1="0"
            y2={this.axisData[this.axisData.length - 1].transforValue + 50}
            x2={0}
            fill="none"
            strokeWidth="1.5px"
            stroke="#ffff00"
            opacity="0.35"
          />
        </g>
      );
    });

    // const labels = this.months.map((val, i) => {
    //   return <g transform={`rotate(${i * (360 / 12)})`} />;
    // });

    const avgRating = round(
      this.props.lineData[3][this.props.lineData[3].length - 1].rating,
      1,
    );
    const ratingsLegendMatch =
      this.state.selectWidth === 0 ? null : (
        <RatingsMatchLegend
          dateAxis={this.props.dateAxis}
          lineData={this.props.lineData}
          width={this.state.selectWidth - 15}
        />
      );

    const radialLegend =
      this.state.radialLegend.dimensions.height < 0 ||
      this.state.radialLegend.dimensions.width < 0 ? null : (
        <RadialLegend
          deviceFilter={this.props.deviceFilter}
          deviceCount={this.props.deviceCount}
          className={'radial-legend-container-component'}
          parent={this.radialLegendNode}
          dimensions={this.state.radialLegend.dimensions}
        />
      );

    const wordCloud =
      this.state.wordCloud.dimensions.height < 0 ||
      this.state.wordCloud.dimensions.width < 0 ? null : (
        <WordCloud
          words={makeWordArray(this.props.data)}
          dimensions={this.state.wordCloud.dimensions}
        />
      );
    const bulletChart =
      this.state.bulletChart.dimensions.height < 0 ||
      this.state.bulletChart.dimensions.width < 0 ? null : (
        <BulletChartContainer
          className={'bullet-chart'}
          title={'Current Ratings Targets'}
          ratings={this.props.currentRatings}
          dimensions={this.state.bulletChart.dimensions}
          onMouseOver={this.handelBulletTitleMouseOver.bind(this)}
          onMouseLeave={this.handlePopUpgMouseLeave.bind(this)}
        />
      );
    return (
      <div
        style={{ width: this.props.width, height: this.props.height }}
        className="ratings-radial-main"
      >
        {popUp}
        <div
          className="select-component-div"
          ref={el => {
            this._selectRef = el;
          }}
        >
          <div
            style={{
              color: 'white',
              fontSize: '12px',
              marginTop: '10px',
              marginLeft: '35px',
            }}
          >
            {' '}
            Filter by Platform{' '}
          </div>
          <SelectComponent
            values={this.selectArray}
            defaultValue={this.props.device}
            handleDeviceChange={this.deviceChange}
            className={'select-component'}
          />
          <div className="left-flex-filler" />
          {ratingsLegendMatch}
          />
        </div>
        <div className="radial-chart-container">
          <svg
            width={this.width + (this.margin.l + this.margin.r)}
            height={this.height + (this.margin.t + this.margin.b)}
          >
            <text
              stroke="white"
              strokeWidth="0.25px"
              fontSize="24px"
              fill="rgba(51, 64, 204, 0.9)"
              x={this.width / 2 + this.margin.l - 45.86}
              y={25}
            >
              Reviews
            </text>
            <g
              opacity="0.75"
              transform={`translate(${this.margin.l},${
                this.margin.t
              }) translate(${this.width / 2},${this.height /
                2}) rotate(${rotationOffset})`}
            >
              {axisCircles}
              {allTicks}
              {axisLabel}
              {monthLabels}

              <g opacity="0.75" className="radial-outer-arcs">
                {arcs}
              </g>
              <g>{lineHighlights}</g>
              <g opacity="0.75" className="innerCircle">
                <g
                  className="innerCircle-text"
                  transform={`translate(${-26},${26}) rotate(${rotationOffset *
                    -1})`}
                >
                  <text
                    fill="white"
                    strokeWidth="1px"
                    stroke="white"
                    fontSize="45"
                    opacity={avgRating === 'n/a' ? 0.25 : 1}
                    x={avgRating === 'n/a' ? 6 : 4}
                    y={-35}
                  >
                    {avgRating}
                  </text>
                  <text
                    fill="white"
                    strokeWidth="1px"
                    stroke="white"
                    fontSize="20"
                    opacity={avgRating === 'n/a' ? 0 : 1}
                    x={50}
                    y={-30}
                  >
                    /5
                  </text>
                  <g
                    className="center-hover-arrow"
                    transform="translate(25,10)"
                    onMouseEnter={ev => {
                      this.handleCenterRatingMouseOver(ev);
                    }}
                    onMouseLeave={ev => {
                      this.handlePopUpgMouseLeave(ev);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="white"
                      fill="white"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
                    </svg>
                  </g>

                  <text
                    fill="white"
                    strokeWidth="1px"
                    stroke="white"
                    fontSize="12"
                    opacity={avgRating === 'n/a' ? 0.55 : 1}
                    x={2}
                    y={-15}
                  >
                    Avg. Rating
                  </text>
                  <StarRatings max={5} currentRating={avgRating} />
                </g>
              </g>
              <g className="data-point-class">{points}</g>
              <g className="data-lines-class">{lines}</g>
            </g>
          </svg>
        </div>
        <div className="right-legend-bullet-container">
          <div
            className="radial-legend-container"
            ref={el => {
              this.renderRadialLegend(el);
            }}
          >
            {radialLegend}
          </div>
          <div
            className="right-legend-filler"
            ref={el => {
              this.renderWordCloud(el);
            }}
          >
            {wordCloud}
          </div>
          <div
            className="bullet-chart-container"
            ref={el => {
              this.renderBullCharts(el);
            }}
          >
            {bulletChart}
          </div>
          <div className="flex-filler-right-radial" />
        </div>

        <RadialToolTip
          on={this.state.tooltip.on}
          content={this.state.tooltip.content}
          date={d3.timeFormat('%d-%b-%y')(this.state.tooltip.date)}
          placement={this.state.tooltip.placement}
          rating={this.state.tooltip.raiting}
          height={this.height}
        />
      </div>
    );
  }
}

// const TicksComponent = ({ axisData }) => {
//   const ticks = axisData.map((val, i) => {
//     return (
//       <g transform={`translate(0,${val.transforValue * -1})`}>
//         <circle r="2" fill="lightgrey" />
//       </g>
//     );
//   });
//   return <g>{ticks}</g>;
// };

export default RatingsRadial;

function round(numb, dec) {
  switch (dec) {
    case 1:
      return Math.round(10 * numb) / 10;
  }
}
