import axios from 'axios';
import { dispatchFilters } from './helpers/filters';

import { API_URL } from '../config';
import { getDateRanges } from '../components/helpers/d3/utils/helpers.js';
import { changeFilters } from '../actions/filter_actions';
import Token from '../components/okta/Token';

export const GETTING_DASHBOARD_DATA = 'GETTING_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_RECIEVED = 'GET_DASHBOARD_DATA_RECIEVED';
export const GET_DASHBOARD_DATA_ERROR = 'GET_DASHBOARD_ERROR';

const gettingDashBoardData = () => ({
  type: GETTING_DASHBOARD_DATA,
});

const getDashBoardDataRecieved = (data, dataMap, dateCreated) => ({
  type: GET_DASHBOARD_DATA_RECIEVED,
  payload: {
    data: data,
    dataMap,
  },
});

export const getDataDashBoardError = () => ({
  type: GET_DASHBOARD_DATA_ERROR,
});

export const getDashboardData = () => async (dispatch, getState) => {
  try {
    dispatch(gettingDashBoardData());

    const oktaToken = new Token(localStorage['okta-token-storage']);
    const response = await axios.get(`${API_URL}/data/dashboard`, {
      headers: {
        Authorization: `Bearer ${oktaToken.accessToken}`,
      },
    });
    /// this sets the date depending on the data

    if (
      !response ||
      !response.data ||
      !response.data.data ||
      !response.data.data.items
    ) {
      // eslint-disable-next-line no-console
      console.error(`dashboard data is not available from ${API_URL}`);
      dispatch(getDataDashBoardError());
      return;
    }
    const dashboardData = response.data;
    const dataItems = dashboardData.data.items;
    const dataMap = dashboardData.data.map;
    const dateCreated = dashboardData.dateCreated;

    const dateRanges = dispatchFilters(
      dataItems,
      dataMap,
      getState().filters,
      getDateRanges,
    );
    dispatch(changeFilters({ dateRanges }));

    dispatch(getDashBoardDataRecieved(dataItems, dataMap, dateCreated));
  } catch (err) {
    // eslint-disable-next-line
    console.error('==========> we got an err', err);
    // eslint-disable-next-line
    if (err.response) {
      if (err.response.status === 500 || err.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    }
  }
};
