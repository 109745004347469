import * as d3 from 'd3';
import moment from 'moment';

//this function takes in the axisType and returns the formatted yAxis

const formatAxis = axisType => {
  return function(d) {
    //      console.log('formatAxis => ', axisType, d);

    if (d < 10 && d >= -10 && axisType !== 'currency') {
      return formatAxisTenOrUnder(d, axisType);
    }
    let p;

    if (axisType === 'percentage') {
      if (d === 100) {
        p = '100%';
      } else {
        p = d3.format('.2')(d);
        p = p + '%';
        //        console.log('perc => ', d, p);
      }
    } else if (axisType === 'currency') {
      p = '$' + d;
    } else {
      p = d3.format('.2s')(d);
    }
    return p;
  };
};
const formatAxisTenOrUnder = (d, axisType) => {
  let p;
  let r = d3.precisionFixed(1);
  if (axisType === 'percentage') {
    p = d3.format('.' + r + 'f')(d);
    p = p + '%';
  } else {
    p = d3.format('.' + r + 'f')(d);
  }
  return p;
};

// this return ticks under ten to equal itself

function numberOfTicks(MaxYVal) {
  if (MaxYVal <= 10) {
    return Math.ceil(MaxYVal);
  }
  return null;
}

function formatXAxis(grain) {
  let grains = {
    daily: {
      title: 'Day',
      tickFormatter: d3.timeFormat('%b %e'),
    },
    month: {
      title: 'Month',
      tickFormatter: d3.timeFormat('%b %y'),
    },
    qtr: {
      title: 'Quarter',
      tickFormatter: date => {
        return `Q${moment.unix(date / 1000).quarter()} ${moment
          .unix(date / 1000)
          .year()}`;
      },
    },
    ttm: {
      title: 'TTM',
      tickFormatter: d3.timeFormat('%b %Y'),
    },
    year: {
      title: 'Year',
      tickFormatter: d3.timeFormat('%Y'),
    },
    alltime: {
      title: 'All Time',
      tickFormatter: d3.timeFormat('%Y'),
    },
    now: {
      title: 'now',
      tickFormatter: d3.timeFormat('%H:%M'),
    },
  };

  return grains[grain].tickFormatter;
}

export { formatAxis, numberOfTicks, formatXAxis };
