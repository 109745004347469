export const ARC_CLICK = 'ARC_CLICK';
export const ARC_MOUSEOVER = 'ARC_MOUSEOVER';
export const ARC_MOUSELEAVE = 'ARC_MOUSELEAVE';
export const SET_STATE = 'SET_STATE';
export const CHANGE_RIBBON_STYLE = 'CHANGE_RIBBON_STYLE';

export function arcClick(index) {
  return {
    type: ARC_CLICK,
    index,
  };
}

export function arcMouseOver(index) {
  return {
    type: ARC_MOUSEOVER,
    index,
  };
}

export function arcMouseLeave() {
  return {
    type: ARC_MOUSELEAVE,
  };
}

export function setState(payload) {
  return {
    type: SET_STATE,
    payload,
  };
}

export function changeRibbonStyle() {
  return {
    type: CHANGE_RIBBON_STYLE,
  };
}
