import React, { Component } from 'react';
import ClipboardJS from 'clipboard';
import clipp from '../../img/clipp.svg';
import * as d3 from 'd3';

import {
  numberWithCommas,
  limitDecimalPlaces,
} from '../helpers/d3/utils/formatters';

class BreakdownComponent extends Component {
  formatBreakdownData = data => {
    if (data.breakdown) {
      const ordered = {};
      Object.keys(data.breakdown)
        .sort()
        .forEach(function(key) {
          ordered[key] = data.breakdown[key];
        });
      return Object.entries(ordered);
    } else return [];
  };

  formatBreakDownStatName = statName => {
    if (statName === 'paloalto') {
      return 'palo alto';
    }
    if(statName.length === 2){
      return statName.toUpperCase();
    }
    return statName;
  };

  componentDidMount() {
    const clipboard = new ClipboardJS(`.btn-${this.props.data.csvName}`);

    clipboard.on('success', e => {
      d3.select(`.btn-${this.props.data.csvName}`)
        .style('background', 'blue')
        .transition()
        .style('background', 'rgba(0,0,0,0)');
    });
  }

  render() {
    let show_avg = false;
    let total_block;
    const { data, color, show, toShowBreakdown, tohideBreakdown } = this.props;

    const formatedBreakdownData = this.formatBreakdownData(data);
    const total = formatedBreakdownData.length > 1 ? data.number : false;
    let copyString = '';

    if (formatedBreakdownData.length > 0) {
      formatedBreakdownData.forEach(d => {
        copyString += `${d[0]},${d[1]}\n `;
      });
      if(data.name === 'Churn' || data.name === 'Uptime' || data.name === 'Ratings') {
        copyString += total ? `Average,${total}` : '';
        show_avg= true;
      } else {
        copyString += total ? `total,${total}` : '';
        show_avg= false;
      }
      if(!show_avg) {
        if(total) {
        total_block = <div
        style={{ color: 'rgb(212,211,210)' }}
        className="breakdownStat"
        key={'100total'}
      >
        <div className="breakdownStatName">{`Total`}</div>
        <div className="breakdownNumber">
          {numberWithCommas(limitDecimalPlaces(total, data.csvName))}
        </div>
      </div>
        } else  total_block = null;
      } else {
        total_block = <div
      style={{ color: 'rgb(212,211,210)' }}
      className="breakdownStat"
      key={'100total'}
    >
      <div className="breakdownStatName">{`Average`}</div>
      <div className="breakdownNumber">
        {numberWithCommas(limitDecimalPlaces(total, data.csvName))}
      </div>
    </div>
      }
      return (
        <div
          style={show ? { opacity: 1 } : { pointerEvents: 'none' }}
          className={'breakdown active breakdownBackground' + color}
          onMouseEnter={toShowBreakdown}
          onMouseLeave={tohideBreakdown}
          data-testid="breakdown-component"
        >
          {formatedBreakdownData.map((item, index) => {
            return (
              <div className="breakdownStat" key={index}>
                <div className="breakdownStatName">
                  {this.formatBreakDownStatName(item[0])}
                </div>
                <div className="breakdownNumber">
                  {numberWithCommas(limitDecimalPlaces(item[1], data.csvName))}
                </div>
              </div>
            );
          })}
          {total_block}
          <button
            style={{
              backgroundColor: 'rgba(0,0,0,0)',
              border: 'none',
              float: 'right',
            }}
            className={`btn btn-${data.csvName}`}
            data-clipboard-text={copyString}
          >
            <img
              className="clippy"
              width="13"
              src={clipp}
              alt="Copy to clipboard"
            />
          </button>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default BreakdownComponent;
