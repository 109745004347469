import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FiltersButton from '../../ChartFilter/FiltersButton';
import ChartFilter from '../../ChartFilter';

const Filters = ({ keys, colorScale, handleKeyFilter }) => {
  const [open, setOpen] = useState(false);

  function closeAction() {
    setOpen(false);
  }
  // function openAction() {
  //   setOpen(false);
  // }
  function openClose() {
    const newOpen = !open;
    setOpen(newOpen);
  }

  return (
    <div className="KPI-filters-cont">
      <FiltersButton openClose={openClose} />
      <ChartFilter
        closeAction={closeAction}
        keys={keys}
        open={open}
        colorScale={colorScale}
        handleKeyFilter={handleKeyFilter}
      />
    </div>
  );
};

Filters.propTypes = {
  keys: PropTypes.array.isRequired,
};

export default Filters;
