import producer from 'immer';
//import { createSelector } from 'reselect';
import {
  //SET_AXIS_LABELS,
  SET_MIN_X,
  SET_MAX_X,
  // SET_MAX_Y,
  // SET_MIN_Y,
  // SET_CHART_Y_MAX,
  // SET_CHART_Y_MIN,
  // TRANSITION_STARTED,
  // TRANSITION_STOPPED,
} from '../actions/timeSeries_actions';

const init_state = {
  chartYmax: null,
  chartYmin: null,
  yMax: null,
  yMin: null,
  xMax: null,
  xMin: null,
  axisLabels: null,
  inTransition: false,
};

const timeSeriesReducer = producer((draft, action) => {
  switch (action.type) {
    case SET_MAX_X:
      draft.chartYmax = action.value;
      break;
    case SET_MIN_X:
      draft.chartYmin = action.value;
  }
}, init_state);

export default timeSeriesReducer;
